import { useState, useCallback } from 'react';
import DocumentAPI from '../services/documentAPI';

interface UploadDocumentParams {
  fieldName: string;
  simulationId: string;
  foyerPrecarite?: number;
  dateFacture?: string;
  refresh: () => void;
}

const useDocumentUpload = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleUploadDocument = useCallback(async (uploadedFile: File, { fieldName, simulationId, foyerPrecarite, dateFacture, refresh }: UploadDocumentParams) => {
    if (uploadedFile) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append('fichier', uploadedFile);
      formData.append('type', fieldName);
      if (foyerPrecarite) {
        formData.append('foyerPrecarite', foyerPrecarite.toString());
      }
      if (dateFacture) {
        formData.append('dateFacture', dateFacture);
      }

      try {
        const response = await DocumentAPI.postDocument(simulationId, formData);
        if (response.success) {
          refresh();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  return { handleUploadDocument, isLoading };
};

export default useDocumentUpload;
