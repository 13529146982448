import React from 'react';
import { Image } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
var useStyles = createUseStyles({
    encart: {
        position: 'relative',
        border: [1, 'solid', '#00556E'],
        borderRadius: 10,
        color: '#00556E',
        background: 'white',
        marginBottom: 5,
        padding: '5px 5px 2px',
        textAlign: 'center',
        fontWeight: 'bold',
        '& .icone': {
            width: '20%',
            position: 'absolute',
            left: 0,
            top: '2.5rem',
        },
        '& .value': {
            fontSize: '2rem',
        },
        '& .unite': {
            fontSize: '1rem',
        },
    },
});
export var EncartValue = function (_a) {
    var value = _a.value, unite = _a.unite, icone = _a.icone;
    var classes = useStyles();
    return (React.createElement("div", { className: classes.encart },
        React.createElement(Image, { src: icone, className: "icone" }),
        React.createElement("span", { className: "value" }, value),
        React.createElement("br", null),
        React.createElement("span", { className: "unite" }, unite)));
};
