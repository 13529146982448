/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
function getInputType(question) {
    switch (question.type) {
        case 'integer':
        case 'float': {
            return 'number';
        }
        case 'date': {
            return 'date';
        }
        default: {
            return 'text';
        }
    }
}
function parseValue(question, value) {
    var val = value;
    if (['integer', 'float'].includes(question.type)) {
        val = value.replace(/[a-zA-Z]/g, '');
    }
    switch (question.type) {
        case 'integer': {
            if (!/^\d+$/.test(val)) {
                return null;
            }
            val = parseInt(val, 10);
            return Number.isNaN(val) ? null : val;
        }
        case 'float':
            val = parseFloat(val.replace(',', '.'));
            return Number.isNaN(val) ? null : val;
        default:
            return val;
    }
}
function stringifyValue(question) {
    switch (question.type) {
        case 'float':
        case 'integer': {
            return typeof question.response === 'number' && !Number.isNaN(question.response) ? String(question.response) : '';
        }
        default:
            return typeof question.response === 'string' ? String(question.response) : '';
    }
}
function applyMaxAndMin(question, value) {
    var _a, _b;
    var newValue = value;
    if (!newValue)
        return newValue;
    if (((_a = question.rules) === null || _a === void 0 ? void 0 : _a.min) && Number(value) < question.rules.min) {
        newValue = question.rules.min;
    }
    if (((_b = question.rules) === null || _b === void 0 ? void 0 : _b.max) && Number(value) > question.rules.max) {
        newValue = question.rules.max;
    }
    return newValue;
}
export default function TextInput(_a) {
    var _b, _c;
    var question = _a.question, onChange = _a.onChange, _d = _a.showNumberButton, showNumberButton = _d === void 0 ? true : _d, _e = _a.autofocus, autofocus = _e === void 0 ? true : _e, onKeyPress = _a.onKeyPress, ignoreConstraints = _a.ignoreConstraints;
    var inputRef = useRef(null);
    var alias = question.alias;
    var handleKeyDown = function (e) {
        if (['integer', 'float'].includes(question.type) && ['e', '-', '+'].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        if (onKeyPress) {
            handleBlur(e);
            onKeyPress(e);
        }
        return true;
    };
    var handleChange = function (e) {
        if (!onChange) {
            return;
        }
        onChange(parseValue(question, e.target.value));
    };
    var handleBlur = function (e) {
        if (!onChange || !['float', 'integer'].includes(question.type)) {
            return;
        }
        if (ignoreConstraints) {
            onChange(Number(e.target.value));
        }
        else {
            onChange(applyMaxAndMin(question, Number(e.target.value)));
        }
    };
    var handleClick = function (increment) {
        if (!onChange) {
            return;
        }
        if (ignoreConstraints) {
            onChange(question.response + increment);
        }
        else {
            onChange(applyMaxAndMin(question, question.response + increment));
        }
    };
    useEffect(function () {
        var _a;
        if (autofocus) {
            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    var inpuType = getInputType(question);
    // if input type is date, the max year is 9999
    if (inpuType === 'date') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        question.rules.max = '9999-12-31';
    }
    return (React.createElement("div", { className: "diagnosticQuestionText" },
        React.createElement("label", { className: "diagnosticQuestionTextLabel", htmlFor: alias, 
            // eslint-disable-next-line
            dangerouslySetInnerHTML: {
                __html: question.labelInput,
            } }),
        React.createElement("div", { className: "numberWithControls" },
            showNumberButton && inpuType === 'number' && (React.createElement("button", { className: "buttonIncrement buttonLeft", type: "button", onClick: function () { return handleClick(-1); }, "data-cy": "minus" }, "-")),
            React.createElement("input", { className: "diagnosticQuestionTextInput ".concat(question.unit ? 'withUnit' : ''), type: inpuType, "data-cy": question.alias, name: alias, id: alias, onInput: handleChange, onBlur: handleBlur, value: stringifyValue(question), onKeyDown: handleKeyDown, ref: inputRef, min: (_b = question.rules) === null || _b === void 0 ? void 0 : _b.min, max: (_c = question.rules) === null || _c === void 0 ? void 0 : _c.max }),
            question.unit && React.createElement("div", { className: "diagnosticQuestionTextUnit" }, question.unit),
            showNumberButton && inpuType === 'number' && (React.createElement("button", { className: "buttonIncrement buttonRight", type: "button", onClick: function () { return handleClick(1); }, "data-cy": "plus" }, "+")))));
}
