import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { customerResource, labels } from 'core';
import { object, string, ref } from 'yup';
import { useSearchParams } from 'react-router-dom';
import { TextInput } from 'components/form/inputs';

import type { AxiosError } from 'axios';
import PasswordIndicator from 'components/account/password-indicator';

interface Props {
  handleComplete?: () => void;
}

type ResetPasswordType = { plainPassword: string; repeatedPassword: string };
const validationSchema = object({
  plainPassword: string().required().default('').min(12).matches(/[a-z]/g).matches(/[A-Z]/g).matches(/[0-9]/g).matches(/\W|_/g),
  repeatedPassword: string()
    .required()
    .default('')
    .oneOf([ref('plainPassword')], labels.validation.common.passwords_must_match),
});

const initialValues: ResetPasswordType = { plainPassword: '', repeatedPassword: '' };
const tokenExpired = 'expired';

export default function ResetPasswordForm({ handleComplete }: Props): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchParams] = useSearchParams();

  const onSubmit = (data: ResetPasswordType) => {
    setError(null);
    setLoading(true);

    customerResource
      .resetPassword(String(token), data.plainPassword)
      .then(() => {
        setLoading(false);
        if (typeof handleComplete === 'function') {
          handleComplete();
        }
      })
      .catch((e: AxiosError) => {
        if (e.response?.status === 400) {
          setToken(tokenExpired);
        } else {
          setError('Une erreur est survenue, veuillez réessayez.');
        }
      })
      .finally(() => setLoading(false));
  };

  const isResetPasswordTokenValid = (): boolean => {
    const resetPasswordToken = searchParams.get('token');

    if (typeof resetPasswordToken !== 'string') {
      return false;
    }

    const exp = resetPasswordToken.split(':')[1];

    if (typeof exp !== 'string') {
      return false;
    }

    return parseInt(exp, 10) > Date.now() / 1000;
  };

  useEffect(() => {
    setToken(!isResetPasswordTokenValid() ? tokenExpired : searchParams.get('token'));
  }, []);

  if (token === tokenExpired) {
    return (
      <div className="accountForgetPassword">
        <div className="accountForgetPasswordPicto">
          <i className="icon-error" />
        </div>
        <h1 className="accountForgetPasswordTitle">Le lien est expiré.</h1>
        <p className="accountForgetPasswordInfos">Veuillez recommencer.</p>
      </div>
    );
  }

  return (
    <>
      <h1 className="accountForgetPasswordTitle">Définissez votre nouveau mot de passe</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <Form className="accountForgetPasswordForm mtl">
          <TextInput name="plainPassword" type="password" label="Mot de passe" icon="icon-eye" />
          <PasswordIndicator property="plainPassword" />
          <TextInput name="repeatedPassword" type="password" label="Confirmation de votre nouveau mot de passe" icon="icon-eye" />
          <div className="text-center">
            <button className="btn btn-primary btn-icon-left" type="submit" disabled={loading}>
              <span>
                <i className="icon-validate" />
                {loading ? 'Chargement...' : 'Valider votre nouveau mot de passe'}
              </span>
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
}
