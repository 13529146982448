import React from 'react';
import { IconType } from 'react-icons';

interface BodyItemProps {
  icon: IconType;
  value: string;
}

const BodyItem: React.FC<BodyItemProps> = ({ icon: Icon, value }) => (
  <div className="form__container__content__body__item">
    <div className="form__container__content__body__item--icon">
      <Icon size={18} color="#08556D" />
    </div>
    <div className="form__container__content__body__item--value">{value}</div>
  </div>
);

export default BodyItem;
