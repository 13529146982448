import React from 'react';

import { TextInput } from 'components/form/inputs';
import Button from 'features/dashboard/components/button/button';
import lang from 'features/dashboard/lang/projets.json';

import { Col, Row } from 'react-bootstrap';
import formModel from 'features/dashboard/models/formModel';

interface CompanySearchOverlayProps {
  isDisabled: boolean;
  onSearchBySiretOrIdentifiant: () => void;
}

const CompanySearchOverlay: React.FC<CompanySearchOverlayProps> = ({ isDisabled, onSearchBySiretOrIdentifiant }) => {
  const { formField } = formModel;
  const { companyName } = formField;
  return (
    <div className="center">
      <div className="form__container__title text-center">{lang.searchProfessionalTitle}</div>
      <div className="form__container__content">
        <Row className="d-flex justify-content-center">
          <Col sm={6}>
            <TextInput rounded placeholder={companyName.Placeholder} label="" name={companyName.name} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center mt-4">
            <Button id="confirm-search-overlay" variant="primary" label={lang.search} type="button" isDisabled={isDisabled} onClick={onSearchBySiretOrIdentifiant} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default CompanySearchOverlay;
