import React from 'react';
import logoGoRenov from '../../assets/images/logo-go-renov.png';
export default function PreFilledLabel(_a) {
    var position = _a.position;
    return (React.createElement("div", { className: "goRenoveCard ".concat(position ? 'onLoader' : '') },
        React.createElement("div", { className: "goRenoveCardLogo" },
            React.createElement("img", { src: logoGoRenov, alt: "Go R\u00E9nov", className: "img-fluid" })),
        React.createElement("div", { className: "goRenoveCardInfos" },
            "Certaines de vos r\u00E9ponses sont pr\u00E9-remplies gr\u00E2ce aux informations provenant de Go R\u00E9nove (base de donn\u00E9es publique qui compile des donn\u00E9es relatives aux logements).",
            React.createElement("br", null),
            "Vous pouvez bien s\u00FBr modifier ces informations si elles sont inexactes.")));
}
