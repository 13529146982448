import { api, Resource } from 'core';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';
import { mapAPIQuestionAH } from '../mapper/ahQuestions';

interface IPostQuestion {
  questionId: string;
  value: string | number;
  response: string[];
}

export class AhQuestionAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  getAHQuestions(uuidSimulation: string): Promise<QuestionPrime[]> {
    return api.get(this.buildUri(`simulation/${uuidSimulation}/ah/questions`)).then(({ data }) => mapAPIQuestionAH(data));
  }

  postAHQuestion(uuidSimulation: string, query: IPostQuestion): Promise<{ success: boolean }> {
    return api
      .post(this.buildUri(`simulation/${uuidSimulation}/ah/questions`), {
        uuid_question: query.questionId,
        valeur: query.value,
        reponses: query.response,
      })
      .then(({ data }) => data);
  }

  patchAHQuestion(uuidSimulation: string): Promise<{ success: boolean }> {
    return api.patch(this.buildUri(`simulation/${uuidSimulation}/ah/questions`), null).then(({ data }) => data);
  }
}

export default new AhQuestionAPI();
