import React, { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { useSetSharedState, useSharedState } from 'states';

type Data = {
  show: boolean;
  title: string;
  content: string;
  callback?: () => void;
};

type ConfirmFn = (_title: string, _content: string) => Promise<void>;

export function useConfirm(): ConfirmFn {
  const setData = useSetSharedState('modal:confirm');

  return (title: string, content: string) =>
    new Promise((resolve) => {
      setData((d: Data) => ({
        ...d,
        show: true,
        title,
        content,
        callback: resolve,
      }));
    });
}

export default function Confirm(): React.ReactElement {
  const [data, setData] = useSharedState('modal:confirm');

  const hide = () => {
    setData((d: Data) => ({ ...d, show: false }));
  };

  const handleConfirm = useCallback(() => {
    if (typeof data.callback === 'function') {
      data.callback();
    }

    hide();
  }, [data]);

  return (
    <Modal show={data.show} onHide={hide} centered className="modalContact">
      <Modal.Header closeButton className="smallPadding">
        <Modal.Title>{data.title || 'Confirmer ?'}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="smallPadding text-center">
        {data.content}
        <div className="modal-form-actions mt-5">
          <button type="button" onClick={handleConfirm} className="btn btn-primary">
            <span>Confirmer</span>
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
