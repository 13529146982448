import React from 'react';
import './steps.scss';
import { Statut } from 'features/primes/simulation/resultat/interfaces/resultat';

type StepsProps = {
  status: Statut[];
  onGoToStep: (step: number) => void;
};

const Steps: React.FC<StepsProps> = ({ status, onGoToStep }) => {
  const sortedStatus = status.sort((a, b) => a.ordre - b.ordre);

  return (
    <div className="projets__container">
      <p className="projets__title">Les étapes de votre projet</p>
      <div className="projets__stepper">
        {sortedStatus.map((statut, index) => (
          <div className="projets__stepper__step" key={statut.libelle} onClick={() => onGoToStep(index)}>
            <div className="projets__stepper__step__line" title={statut.libelle}>
              <div className={`projets__stepper__step__progress ${statut.completion}`} data-cy={statut.libelle} />
            </div>
            <p className={`projets__stepper__step__label ${statut.completion}`}>{statut.libelle}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
