import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';

type Props = {
  onChange?: () => void;
};

export default function FormikListener({ onChange }: Props): null {
  const [currentValues, setCurrentValues] = useState('');
  const { values } = useFormikContext();

  useEffect(() => {
    const valuesStringified = JSON.stringify(values);

    if (JSON.stringify(currentValues) !== valuesStringified) {
      setCurrentValues(valuesStringified);

      if (typeof onChange === 'function') {
        onChange();
      }
    }
  }, [values]);

  return null;
}
