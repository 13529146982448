import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { TextInput } from 'components/form/inputs';
import { customerResource } from 'core';
import { object, string } from 'yup';

interface Props {
  handleComplete?: () => void;
}

type ForgotPasswordType = { email: string };
const validationSchema = object({ email: string().email().required() });
const initialValues: ForgotPasswordType = { email: '' };

export default function ForgotPasswordForm({ handleComplete }: Props): React.ReactElement {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = (data: ForgotPasswordType) => {
    setError(null);
    setLoading(true);

    customerResource
      .forgotPassword(data.email)
      .then(() => {
        setLoading(false);
        if (typeof handleComplete === 'function') {
          handleComplete();
        }
      })
      .catch(() => setError('Une erreur est survenue, veuillez réessayez'))
      .finally(() => setLoading(false));
  };

  return (
    <>
      <h1 className="accountForgetPasswordTitle">Vous avez oublié votre mot de passe&nbsp;?</h1>
      <p className="accountForgetPasswordInfos">Pas d&apos;inquiétude, vous pouvez le réinitialiser avec votre adresse email.</p>
      {error && <div className="alert alert-danger">{error}</div>}
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <Form className="accountForgetPasswordForm">
          <TextInput name="email" type="email" label="Adresse email utilisée lors de votre inscription" />
          <div className="text-center">
            <button className="btn btn-primary btn-icon-left" type="submit" disabled={loading}>
              <span>
                <i className="icon-validate" />
                {loading ? 'Chargement...' : 'Réinitialiser votre mot de passe'}
              </span>
            </button>
          </div>
        </Form>
      </Formik>
    </>
  );
}
