import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { Professionnel } from 'features/dashboard/types/professionnel';
import ListItem from './listItem';
import CompanySearchOverlay from '../searchProfessionnel/companySearchOverlay';

interface SearchProOverlayProps {
  listPro: Professionnel[];
  show: boolean;
  handleClose: () => void;
  onSelectProfessional: (professional: Professionnel) => void;
  onSearchBySiretOrIdentifiant: () => void;
  companyName: string;
  isSousTraitant?: boolean;
}

const SearchProOverlay: React.FC<SearchProOverlayProps> = ({ listPro, show, handleClose, onSelectProfessional, onSearchBySiretOrIdentifiant, companyName, isSousTraitant }) => {
  const professionalCount = listPro.length;

  const [showSearchForm, setShowSearchForm] = useState(false);

  const handleShow = () => setShowSearchForm(true);

  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Body>
        {showSearchForm ? (
          <CompanySearchOverlay isDisabled={false} onSearchBySiretOrIdentifiant={onSearchBySiretOrIdentifiant} />
        ) : (
          <div className="list-professionnel curved">
            <div className="list-professionnel__header">
              <h2 className="list-professionnel__title">
                <span className="list-professionnel__title--count">{professionalCount} </span>
                entreprises portent le nom “{companyName}”
              </h2>
              <div className="list-professionnel__filter">
                <span className="list-professionnel__filter__text" onClick={handleShow}>
                  <IoClose size={18} color="#444444" />
                  {companyName}
                </span>
              </div>
              <hr className="list-professionnel__separator" />
            </div>
            <ListItem listPro={listPro} onSelectProfessional={onSelectProfessional} isSousTraitant={isSousTraitant} />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SearchProOverlay;
