/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useState } from 'react';
import { object, string } from 'yup';
import Recaptcha from 'react-google-recaptcha';
import { Row, Col } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { buildCreateContactFormValidationSchema, contactFormResource } from 'core';
import security from '@concerto-home/security';
import { TextInput, TextareaInput } from 'components/form/inputs';
import { checkApiFormViolations } from 'utils/form';
import FormListener from 'components/form/listener';
import { getEnv } from 'utils/env';
import Error from 'components/form/error';

import type { FormikHelpers } from 'formik';
import type { ContactForm as ContactFormInterface } from 'core';

interface Props {
  handleComplete?: (_data: ContactFormInterface) => void;
  handleFailed?: () => void;
  handleChange?: () => void;
  actions?: React.ReactElement;
}

let validationSchema = buildCreateContactFormValidationSchema();
let initialValues: ContactFormInterface = validationSchema.getDefault();

export default function ContactForm({ handleComplete, handleFailed, handleChange, actions }: Props): React.ReactElement {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: ContactFormInterface, formik: FormikHelpers<ContactFormInterface>) => {
    setLoading(true);

    contactFormResource
      .create(data)
      .then((response) => typeof handleComplete === 'function' && handleComplete(response))
      .catch((e) => {
        checkApiFormViolations(e, formik).else(() => {
          if (typeof handleFailed === 'function') {
            handleFailed();
          }
        });
      })
      .finally(() => setLoading(false));
  };

  if (!security.user) {
    validationSchema = validationSchema.concat(object({ recaptcha: string().required() }));
    initialValues = validationSchema.getDefault();
  }

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ setFieldValue, setFieldTouched }) => (
        <Form className="diagnosticRejectedContentForm">
          <FormListener onChange={handleChange} />
          <Row>
            <Col sm={6}>
              <TextInput name="lastName" label="Nom" />
            </Col>
            <Col sm={6}>
              <TextInput name="firstName" label="Prénom" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextInput name="email" label="Adresse e-mail" type="email" />
            </Col>
            <Col sm={6}>
              <TextInput name="phone" label="Numéro de téléphone" />
            </Col>
          </Row>

          <TextInput name="socialReason" label="Raison sociale" />
          <TextareaInput name="message" label="Décrivez votre projet travaux" />

          <p className="diagnosticRejectedContentFormMentions">
            Les données personnelles recueillies font l’objet de traitements par Sonergia afin de traiter vos demandes de contact ou de renseignements sur les travaux de rénovation énergétique.
            <br />
            Les champs obligatoires sont signalés par un astérisque (*). A défaut de renseignement de ces champs nous ne pourrons traiter votre demande.
            <br />
            Lire notre{' '}
            <a href={`${getEnv('CMS_HOST')}/politique-de-confidentialite`} target="_blank" rel="noreferrer">
              Politique de confidentialité
            </a>{' '}
          </p>

          {!security.user && (
            <div className="mb-5">
              <Recaptcha
                sitekey={getEnv('GOOGLE_CAPTCHA_KEY')}
                onChange={(token: string | null) => {
                  setFieldTouched('recaptcha', true);
                  setFieldValue('recaptcha', token || '');
                }}
                onExpired={() => setFieldValue('recaptcha', '')}
              />
              <p className="mt-5">
                <Error name="recaptcha" />
              </p>
            </div>
          )}

          <div className="diagnosticRejectedContentFormCta">
            <button type="submit" className="btn btn-primary btn-icon-left" disabled={loading}>
              {loading ? (
                <span>
                  <i className="icon-loading" /> Envoi en cours...
                </span>
              ) : (
                <span>
                  <i className="icon-validate" /> Valider
                </span>
              )}
            </button>
            {actions}
          </div>
        </Form>
      )}
    </Formik>
  );
}
