import React from 'react';
import ProgressionContainer from './container';
import ProgressionItem from './group';
import ProgressBarMobile from './ProgressBarMobile';
export default function Progression(_a) {
    var _b;
    var isInProgress = _a.isInProgress, groups = _a.groups, currentGroup = _a.currentGroup, isPrimesApp = _a.isPrimesApp;
    // get last group label
    var lastGroupLabel = (_b = groups[groups.length - 1]) === null || _b === void 0 ? void 0 : _b.label;
    var isGroupActive = function (group) { return (currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.label) === group.label || group.active; };
    return (React.createElement("div", { className: "diagnosticChapter ".concat(isInProgress ? 'displayPrev' : '', " ").concat(isPrimesApp ? 'primes' : '') },
        React.createElement(ProgressionContainer, null, groups.map(function (group) {
            var _a, _b;
            return (React.createElement(ProgressionItem, { key: group.id, progression: (_a = group.progression) !== null && _a !== void 0 ? _a : 0, active: (_b = isGroupActive(group)) !== null && _b !== void 0 ? _b : false, label: group.label }));
        })),
        isPrimesApp && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "progressLabel" },
                React.createElement("span", { className: "diagnosticChapterListItemLabel" }, (currentGroup === null || currentGroup === void 0 ? void 0 : currentGroup.label) || lastGroupLabel)),
            React.createElement(ProgressionContainer, { mobile: true, isPrimesApp: isPrimesApp }, groups.map(function (group) {
                var _a, _b;
                return (React.createElement(ProgressBarMobile, { key: group.id, progression: (_a = group.progression) !== null && _a !== void 0 ? _a : 0, active: (_b = isGroupActive(group)) !== null && _b !== void 0 ? _b : false }));
            })))),
        !isPrimesApp && (React.createElement(ProgressionContainer, { mobile: true }, groups.map(function (group, i) {
            var _a, _b;
            return (React.createElement(ProgressionItem, { key: group.id, progression: (_a = group.progression) !== null && _a !== void 0 ? _a : 0, active: (_b = group.active) !== null && _b !== void 0 ? _b : false, label: String(i + 1), mobile: true }));
        })))));
}
