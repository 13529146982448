import { QuestionGroup } from 'core';
import { QuestionGroupApi } from '../interfaces/questionnaireAPI';

export function mapAPIQuestionGroups(questionGroups: QuestionGroupApi[]): QuestionGroup[] {
  return questionGroups.map((questionGroup) => ({
    id: questionGroup.id,
    label: questionGroup.libelle,
    order: questionGroup.ordre,
    stepProgressionCount: questionGroup.nombreEtapeProgression,
    progression: 0,
    active: false,
  }));
}
