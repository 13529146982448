import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { TextInput } from 'components/form/inputs';
import lang from 'features/dashboard/lang/projets.json';
import { FormikValues, useFormikContext } from 'formik';
import Button from '../../button/button';
import formModel from '../../../models/formModel';
import { FormInitialValues } from '../validation/formInitialValues';

interface EmailProfessionnelProps {
  onNextStep: () => void;
}

const EmailProfessionnel: React.FC<EmailProfessionnelProps> = ({ onNextStep }) => {
  const { formField } = formModel;
  const { email } = formField;

  const { values, errors } = useFormikContext<FormInitialValues & FormikValues>();
  const { email: emailValue } = values;
  const isValidEmail = emailValue !== '' && !errors.email;

  return (
    <div className="form__container pt-4">
      <div className="form__container__title">{lang.professionalEmailText}</div>
      <Row>
        <Col sm={6}>
          <TextInput rounded placeholder={email.Placeholder} label={email.label} name={email.name} />
        </Col>
      </Row>
      <div className="form__container__content d-flex justify-content-center gap-4 m-4 p-4">
        <Button id="confirm-email" variant="primary" isDisabled={!isValidEmail} label={lang.confirm} type="button" onClick={onNextStep} />
      </div>
    </div>
  );
};

export default EmailProfessionnel;
