import React from 'react';
import { Image } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import etiquetteSimpleA from '../../assets/images/classes-energetiques/simple/A.svg';
import etiquetteSimpleB from '../../assets/images/classes-energetiques/simple/B.svg';
import etiquetteSimpleC from '../../assets/images/classes-energetiques/simple/C.svg';
import etiquetteSimpleD from '../../assets/images/classes-energetiques/simple/D.svg';
import etiquetteSimpleE from '../../assets/images/classes-energetiques/simple/E.svg';
import etiquetteSimpleF from '../../assets/images/classes-energetiques/simple/F.svg';
import etiquetteSimpleG from '../../assets/images/classes-energetiques/simple/G.svg';
import etiquetteSecondA from '../../assets/images/classes-energetiques/second/A.svg';
import etiquetteSecondB from '../../assets/images/classes-energetiques/second/B.svg';
import etiquetteSecondC from '../../assets/images/classes-energetiques/second/C.svg';
import etiquetteSecondD from '../../assets/images/classes-energetiques/second/D.svg';
import etiquetteSecondE from '../../assets/images/classes-energetiques/second/E.svg';
import etiquetteSecondF from '../../assets/images/classes-energetiques/second/F.svg';
import etiquetteSecondG from '../../assets/images/classes-energetiques/second/G.svg';
var useStyles = createUseStyles({
    etiquetteSimple: {
        width: '100% !important',
        position: 'relative',
        zIndex: '1',
        borderBottom: ['1px', 'solid', 'white'],
    },
    etiquetteSecond: {
        marginTop: '-1em',
        width: '90% !important',
    },
});
export var EtiquetteCombinatoire = function (_a) {
    var statusAfter = _a.statusAfter, statusBefore = _a.statusBefore;
    var classes = useStyles();
    var etiquettesSimple = {
        A: etiquetteSimpleA,
        B: etiquetteSimpleB,
        C: etiquetteSimpleC,
        D: etiquetteSimpleD,
        E: etiquetteSimpleE,
        F: etiquetteSimpleF,
        G: etiquetteSimpleG,
    };
    var etiquettesSecond = {
        A: etiquetteSecondA,
        B: etiquetteSecondB,
        C: etiquetteSecondC,
        D: etiquetteSecondD,
        E: etiquetteSecondE,
        F: etiquetteSecondF,
        G: etiquetteSecondG,
    };
    var etiquetteSimple = etiquettesSimple[statusAfter];
    var etiquetteSecond = null;
    if (statusBefore) {
        etiquetteSecond = etiquettesSecond[statusBefore];
    }
    return (React.createElement("div", { className: "w-100 text-center" },
        React.createElement(Image, { src: etiquetteSimple, className: classes.etiquetteSimple }),
        etiquetteSecond && React.createElement(Image, { src: etiquetteSecond, className: classes.etiquetteSecond })));
};
