import React from 'react';
export default function Container(_a) {
    var mobile = _a.mobile, children = _a.children, isPrimesApp = _a.isPrimesApp;
    var className = mobile ? 'diagnosticChapterListMobile' : 'diagnosticChapterList';
    if (isPrimesApp) {
        className += ' primes';
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return React.createElement("ul", { className: className }, children);
}
