import { useState, useEffect } from 'react';
import { customerResource } from 'core';

export const usePreSuppression = (show: boolean) => {
  const [messagePreSuppression, setMessagePreSuppression] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setLoading(true);
      customerResource
        .preSuppression()
        .then(({ message }) => {
          setMessagePreSuppression(message);
          setLoading(false);
        })
        .catch(() => {
          setError('Une erreur est survenue, veuillez réessayer.');
          setLoading(false);
        });
    }
  }, [show]);

  return { messagePreSuppression, error, loading };
};
