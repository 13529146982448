import React from 'react';
import Button from 'features/dashboard/components/button/button';
import { Modal, ModalBody } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import Norge from 'features/dashboard/assets/images/non-rge.svg';

interface ProfessionnelNoRgeProps {
  show: boolean;
  onClick: () => void;
}

const ProfessionnelNoRge: React.FC<ProfessionnelNoRgeProps> = ({ show, onClick }) => {
  const navigate = useNavigate();
  return (
    <Modal show={show} centered>
      <div className="form__container__content__header">
        <img src={Norge} alt="Votre professionnel n’est pas RGE" className="w-100 " />
      </div>
      <Modal.Header>
        <Modal.Title>Votre professionnel n’est pas RGE</Modal.Title>
      </Modal.Header>
      <ModalBody>
        <p>La professionnel que vous avez choisi n&apos;est pas certifié RGE pour vos travaux. Veuillez sélectionner un autre professionnel afin de bénéficier d&apos;une prime à la rénovation de votre bien.</p>
        <div className="form__container__content d-flex justify-content-center gap-4 m-4 p-4">
          <Button id="no-subcontractor" variant="secondary-border" classNames="dark" label="Retour" type="button" onClick={() => navigate('/dashboard')} />
          <Button id="yes-subcontractor" variant="secondary" classNames="dark" label="Changer de professionnel" type="button" onClick={onClick} />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProfessionnelNoRge;
