import React from 'react';
import { Field, ErrorMessage, FieldProps } from 'formik';

interface TextFieldProps {
  name: string;
  label?: string;
  isNumeric?: boolean;
  isTextOnly?: boolean;
  readonly?: boolean;
  max?: number;
}

const TextField: React.FC<TextFieldProps> = ({ name, label, isNumeric, isTextOnly, readonly, max }) => {
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const inputElement = event.currentTarget;

    // Prevent typing non-numeric characters
    if (isNumeric && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }

    // Prevent typing numeric characters
    if (isTextOnly && /[0-9]/.test(event.key)) {
      event.preventDefault();
    }

    // Prevent typing more characters than max
    if (max && inputElement.value.length >= max) {
      event.preventDefault();
    }
  };

  return (
    <div className="m-4">
      <label className="diagnosticQuestionTextLabel" htmlFor={name}>
        {label}
      </label>
      <Field name={name}>{({ field }: FieldProps) => <input {...field} type="text" id={name} className="form-control" onKeyPress={handleKeyPress} disabled={readonly} />}</Field>
      <ErrorMessage name={name} component="div" className="text-danger text-bold mt-2" />
    </div>
  );
};

export default TextField;
