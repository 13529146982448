import React, { useCallback, useEffect } from 'react';
import ChoicesInput from '../choices';
import ChoiceInput from '../choice';
import { useSharedState, useSharedValue } from '../../../../states';
var RESPONSE_DESIRED_HEATING_SYSTEM = 5;
var checkResponse = function (value, responses) {
    if (!(value === null || value === void 0 ? void 0 : value.includes(RESPONSE_DESIRED_HEATING_SYSTEM))) {
        if (value && value.length === 0) {
            return 'Veuillez séléctionner au moins une réponse';
        }
        return null;
    }
    if (responses.disired_heating_system === undefined) {
        return 'Veuillez séléctionner un type de chauffage secondaire';
    }
    return null;
};
function PlannedRenovationWorkInput(_a) {
    var question = _a.question;
    var questions = useSharedValue('survey:questions');
    var responses = useSharedState('survey:responses')[0];
    var _b = useSharedState('survey:current:validation'), setValidation = _b[1];
    var desiredHeatingSystem = questions.filter(function (q) { return q.alias === 'disired_heating_system'; })[0];
    var validate = useCallback(function (value) { return checkResponse(value, responses); }, [responses]);
    useEffect(function () {
        setValidation([validate]);
        return function () { return setValidation(null); };
    }, [responses]);
    return (React.createElement(ChoicesInput, { question: question, nested: function (selected) {
            var _a;
            return !((_a = responses[question.alias]) === null || _a === void 0 ? void 0 : _a.includes(RESPONSE_DESIRED_HEATING_SYSTEM)) || Number(selected) !== RESPONSE_DESIRED_HEATING_SYSTEM ? null : (React.createElement("div", { className: "diagnosticQuestionListChildren" },
                React.createElement(ChoiceInput, { question: desiredHeatingSystem })));
        } }));
}
PlannedRenovationWorkInput.isDisplay = function (r) { return typeof checkResponse(r.planned_renovation_work, r) === 'string'; };
export default PlannedRenovationWorkInput;
