import React, { useState } from 'react';
import ResetPasswordForm from 'components/form/reset-password';
import { useSecurityLogin } from 'states';

export default function ForgotPassword(): React.ReactElement {
  const [success, setSuccess] = useState(false);
  const showLogin = useSecurityLogin()[1];

  const handleComplete = () => {
    setSuccess(true);
  };

  return (
    <div className="accountForgetPasswordContainer">
      <div className="accountForgetPassword">
        {success ? (
          <>
            <div className="accountForgetPasswordPicto">
              <i className="icon-validate" />
            </div>
            <h1 className="accountForgetPasswordTitle">La modification a été enregistrée avec succès&nbsp;!</h1>
            <button className="btn btn-primary btn-icon-left mtl" type="button" onClick={() => showLogin(true)}>
              <span>
                <i className="icon-validate" />
                Se connecter
              </span>
            </button>
          </>
        ) : (
          <ResetPasswordForm handleComplete={handleComplete} />
        )}
      </div>
    </div>
  );
}
