import React, { useEffect, useState } from 'react';
import { LoaderSimple } from '../../../../index';
export default function StartPage() {
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var startPage = useState('')[0];
    useEffect(function () {
        setLoading(false);
        return;
    }, []);
    var getOnlyTextInContent = function () { return startPage === null || startPage === void 0 ? void 0 : startPage.substring(startPage === null || startPage === void 0 ? void 0 : startPage.indexOf('<div class="diagnosticQuestion">\n')); };
    if (loading) {
        return React.createElement(LoaderSimple, null);
    }
    return startPage ? (
    // eslint-disable-next-line
    React.createElement("div", { dangerouslySetInnerHTML: { __html: String(getOnlyTextInContent()) } })) : null;
}
