import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import { TextInput } from 'components/form/inputs';
import { buildCustomerCreateValidationSchema, buildCustomerCreateValidationDiagnosticRenovSchema, customerResource, invitationResource } from 'core';
import { getUserSource, useSetSharedState, getUserPrescripteur, removeUserPrescripteur } from 'states';
import { checkApiFormViolations } from 'utils/form';
import { LuChevronLeftCircle, LuChevronRightCircle } from 'react-icons/lu';

import { useLocation, useSearchParams } from 'react-router-dom';

import type { AxiosError } from 'axios';
import type { FormikHelpers, FormikErrors } from 'formik';
import type { Customer } from 'core';
import { useSimulationFromLocalStorage } from 'features/primes/simulation/questionnaire/hooks/useSimulation';
import ErrorMessage from 'features/dashboard/components/errorMessage/errorMessage';
import PasswordIndicator from 'components/account/password-indicator';
import { getEnv } from 'utils/env';
import Steps from './register/Steps';
import Cgu from './register/inputs/Cgu';
import Soliha from './register/inputs/Soliha';

interface Props {
  handleComplete?: (_values: Customer) => void;
  currentStep: number;
  setCurrentStep: (_step: number) => void;
}

const fieldsByStep = [
  ['firstName', 'lastName', 'email', 'terms', 'phone', 'soliha'],
  ['plainPassword', 'plainPasswordConfirmation'],
];

export default function RegisterForm({ handleComplete, currentStep, setCurrentStep }: Props): React.ReactElement {
  const [error, setError] = useState<string | null>(null);
  const setSecurityLogin = useSetSharedState('security:login');
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('invitation');
  const email = searchParams.get('email');
  const userSource = getUserSource();
  const userPrescripteur = getUserPrescripteur();
  const [isNotAllowedToSubmit, setIsNotAllowedToSubmit] = useState(false);

  const validationSchema = userSource === 'concerto-renov' ? buildCustomerCreateValidationDiagnosticRenovSchema : buildCustomerCreateValidationSchema;
  const initialValues: Customer = validationSchema.getDefault();

  if (email) {
    initialValues.email = email;
  }

  if (invitationId) {
    initialValues.invitation = invitationResource.uri(invitationId);
  }

  if (userSource) {
    initialValues.source = userSource;
  }

  if (userPrescripteur) {
    initialValues.prescripteur = userPrescripteur;
  }

  const triggerComplete = async (user: Customer) => {
    removeUserPrescripteur();
    if (typeof handleComplete === 'function') {
      handleComplete(user);
    }

    if (user.source === 'concerto-primes') {
      await useSimulationFromLocalStorage({
        user,
      });
    }
  };

  function scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const handleNextStep = (errors: FormikErrors<Customer>, setFieldTouched: any, fields: string[]) => {
    let valid = true;

    if (Object.keys(errors).length > 0) {
      console.error(errors);

      fields.forEach((field) => {
        if (errors[field as keyof Customer]) {
          // The formik form has validateOnMount=true props
          // So errors are already present but not displayed
          // We touch the field to display the error
          setFieldTouched(field);
          valid = false;
          scrollTop();
        }
      });
    }

    if (valid) {
      setCurrentStep(currentStep + 1);
      scrollTop();
    }
  };

  const handleSubmit = async (item: Customer, formik: FormikHelpers<Customer>) => {
    if (isNotAllowedToSubmit) return;

    setIsNotAllowedToSubmit(true);

    const { query, survey } = (location.state as any) || {}; // eslint-disable-line

    if (survey) {
      // eslint-disable-next-line
      item.survey = survey;
    }

    setError(null);

    customerResource
      .create(item, query)
      .then((user: Customer) => {
        triggerComplete(user);
        setCurrentStep(currentStep + 1);
      })
      .catch((e: AxiosError) => {
        checkApiFormViolations(e, formik)
          .else(() => {
            const status = e.response?.status;
            const data = e.response?.data;

            switch (status) {
              case 409:
                setError(data['hydra:description']);
                break;
              default:
                setError('Une erreur est survenue, veuillez réessayer.');
            }
          })
          .catch(() => {
            const violations = e.response?.data?.violations;

            if (violations) {
              for (let step = 0, l = fieldsByStep.length; step < l; step += 1) {
                for (let i = 0, len = violations.length; i < len; i += 1) {
                  if (fieldsByStep[step].includes(violations[i].propertyPath)) {
                    setCurrentStep(step);
                    setIsNotAllowedToSubmit(false);
                    return;
                  }
                }
              }
            }
          });
      })
      .finally(() => {
        setIsNotAllowedToSubmit(false);
      });
  };

  useEffect(() => {
    setSecurityLogin((v: { [p: string]: unknown }) => ({ ...v, show: false }));
  }, []);

  const configs = {
    step1: {
      title: 'Inscrivez-vous pour obtenir votre estimation de prime',
      subtitle: 'Découvrez votre résultat et profitez de l’accompagnement de Sonergia',
      previous: 'Précédent',
      next: 'Suivant',
      fields: ['firstName', 'lastName', 'email', 'phone', 'terms'],
    },
    step2: {
      title: 'Sécurisons votre compte',
      subtitle: 'Configurez votre mot de passe. Votre mot de passe est strictement personnel, ne le partagez pas.',
      previous: 'Précédent',
      next: "Je m'inscris",
      fields: ['plainPassword', 'plainPasswordConfirmation'],
    },
  };

  // Ajouter le champ 'soliha' dans le step 1 si l'utilisateur vient de concerto-renov
  if (userSource === 'concerto-renov') {
    configs.step1.fields.push('soliha');
  }

  return (
    <>
      <div className="registerFormHeader">
        <Steps currentStep={currentStep} />
      </div>

      {invitationId && <div className="alert alert-warning mb-5">Inscription sur invitation pour l&apos;adresse &quot;{email}&quot;</div>}

      {error && <ErrorMessage message={error} className="mb-4 alert alert-danger" />}
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema} validateOnMount>
        {({ errors, setFieldTouched, isSubmitting }) => (
          <Form>
            {/* Profil */}
            <div className={`registerFormStepContent ${currentStep === 1 ? 'current' : ''}`}>
              <h2 className="registerFormStepContentTitle">{configs.step1.title}</h2>
              <p className="registerFormStepContentDetails">{configs.step1.subtitle}</p>
              <Row>
                <Col lg={6}>
                  <TextInput name="firstName" data-cy="firstName" type="text" label="Prénom" />
                </Col>
                <Col lg={6}>
                  <TextInput name="lastName" data-cy="lastName" type="text" label="Nom" />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <TextInput name="email" data-cy="email" type="email" label="Adresse email" disabled={typeof email === 'string' && email.length > 0} />
                </Col>
                <Col lg={6}>
                  <TextInput name="phone" data-cy="phone" type="text" label="Numéro de téléphone" />
                </Col>
              </Row>
              <Cgu />
              {userSource === 'concerto-renov' && <Soliha />}
              {/* TODO: Mettre le lien vers la politique de confidentialité */}
              <p className="registerFormStepContentInfos">
                Les données personnelles recueillies font l&apos;objet de traitement par Sonergia afin de créer votre compte et de traiter votre demande de renseignements sur les travaux de rénovation énergétique. Pour en savoir plus sur vos droits lire notre{' '}
                <a href={`${getEnv('CMS_HOST')}/politique-de-confidentialite`} target="_blank" rel="noreferrer">
                  politique de confidentialité
                </a>
              </p>
              <div className="registerFormStepContentCta">
                <button className="btn btn-primary btn-icon-right solo" data-cy={configs.step1.next} type="button" disabled={isSubmitting} onClick={() => handleNextStep(errors, setFieldTouched, configs.step1.fields)}>
                  <span>
                    {configs.step1.next} <LuChevronRightCircle size={22} className="m-2" />
                  </span>
                </button>
              </div>
            </div>

            {/* Mot de passe */}
            <div className={`registerFormStepContent ${currentStep === 2 ? 'current' : ''}`}>
              <h2 className="registerFormStepContentTitle">{configs.step2.title}</h2>
              <p className="registerFormStepContentDetails">{configs.step2.subtitle}</p>
              <Row>
                <Col lg={6}>
                  <TextInput name="plainPassword" data-cy="plainPassword" type="password" label="Mot de passe" icon="icon-eye" />
                </Col>
                <Col lg={6}>
                  {/* TODO: Gérer la confirmation du mot de passe */}
                  <TextInput name="plainPasswordConfirmation" data-cy="plainPasswordConfirmation" type="password" label="Confirmation du mot de passe" icon="icon-eye" />
                </Col>
              </Row>
              <PasswordIndicator property="plainPassword" />
              <div className="registerFormStepContentCta">
                <button className="btn btn-tertiary btn-icon-left" data-cy={configs.step2.previous} type="button" disabled={isSubmitting} onClick={() => setCurrentStep(currentStep - 1)}>
                  <span>
                    <LuChevronLeftCircle size={22} className="m-2" /> {configs.step2.previous}
                  </span>
                </button>

                <button className="btn btn-primary btn-icon-right" data-cy={configs.step2.next} type="submit" disabled={isSubmitting || isNotAllowedToSubmit}>
                  <span>
                    {isSubmitting || isNotAllowedToSubmit ? 'Chargement...' : configs.step2.next} <LuChevronRightCircle size={22} className="m-2" />
                  </span>
                </button>
              </div>
            </div>

            {/* Confirmation */}
            <div className={`registerFormStepContent ${currentStep === 3 ? 'current' : ''}`}>
              <div className="registerFormStepContentConfirm">
                <div className="registerFormStepContentConfirmIcon">
                  <i className="icon-validate" />
                </div>
                <h3 className="registerFormStepContentConfirmTitle" data-cy="last-step">
                  Vous y êtes presque&nbsp;!
                  <br /> Rendez-vous sur votre messagerie pour finaliser votre inscription.
                </h3>
                <p className="registerFormStepContentConfirmDetails">Sonergia vous a envoyé un email pour confirmer votre inscription. Cliquez sur le lien pour être redirigé vers votre espace client.</p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
