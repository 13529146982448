import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Popover, OverlayTrigger } from 'react-bootstrap';
import { Etiquette } from '@concerto-home/etiquette-energetique';
import illu from '../../../assets/images/illu-register.png';
import PrecoCard from './reco';
import { download, downloadPayload } from '../../../utils/survey';
import security from '@concerto-home/security';
import config from '../../../config';
import illuLaw from '../../../assets/images/loi-climat-resilience.jpg';
import { RiDownloadCloud2Line } from 'react-icons/ri';
var popoverInfo = (React.createElement(Popover, { className: "popoverInfo popoverInfoImage" },
    React.createElement(Popover.Body, { className: "popoverInfoBody" },
        React.createElement("i", { className: "icon-info popoverInfoBodyIcon" }),
        React.createElement("a", { className: "popoverInfoBodyDetails", href: illuLaw, target: "_blank", rel: "noreferrer" },
            React.createElement("img", { src: illuLaw, alt: "Illustation", className: "img-fluid" })))));
var popoverConfort = (React.createElement(Popover, { className: "popoverInfo" },
    React.createElement(Popover.Body, { className: "popoverInfoBody" },
        React.createElement("i", { className: "icon-info popoverInfoBodyIcon" }),
        React.createElement("p", { className: "popoverInfoBodyDetails" }, "Il s'agit du niveau de confort g\u00E9n\u00E9ral ressenti, calcul\u00E9 en fonction de la performance de votre syst\u00E8me de chauffage ainsi que de votre isolation."))));
var popoverScore = (React.createElement(Popover, { className: "popoverInfo" },
    React.createElement(Popover.Body, { className: "popoverInfoBody" },
        React.createElement("i", { className: "icon-info popoverInfoBodyIcon" }),
        React.createElement("p", { className: "popoverInfoBodyDetails" }, "La performance de votre syst\u00E8me de chauffage d\u00E9pend de vos \u00E9quipements et de leur anciennet\u00E9."))));
function Diagnostic(_a) {
    var survey = _a.survey, _b = _a.source, source = _b === void 0 ? 'concerto' : _b, debugCstb = _a.debugCstb, query = _a.query, onClickCta = _a.onClickCta;
    var _c = useState(false), showStickyAnchors = _c[0], setShowStickyAnchors = _c[1];
    var displayScrollIndicator = function () {
        if (window.scrollY > 350) {
            setShowStickyAnchors(true);
        }
        else {
            setShowStickyAnchors(false);
        }
    };
    var pluralize = function (str, value) { return str.replace(/\[value\]/g, String(value)).replace(/\[s\]/g, value > 1 ? 's' : ''); };
    useEffect(function () {
        function watchScroll() {
            window.addEventListener('scroll', displayScrollIndicator);
        }
        watchScroll();
        return function () {
            window.removeEventListener('scroll', displayScrollIndicator);
        };
    }, []);
    var diagnostic = survey.diagnostic;
    if (!diagnostic) {
        return React.createElement("div", null, "Pas de diagnostic");
    }
    var _d = diagnostic.outputs, initial = _d.initial, apple = _d.apple, apricot = _d.apricot, pear = _d.pear, peach = _d.peach;
    var debugCstbToolbar = debugCstb && (React.createElement("div", { className: "alert text-center" },
        React.createElement("button", { type: "button", className: "btn btn-default", onClick: function (e) {
                e.preventDefault();
                downloadPayload(String(survey.id), query);
            } }, "T\u00E9l\u00E9charger le payload"),
        "\u00A0|\u00A0",
        React.createElement("button", { type: "button", className: "btn btn-default", onClick: function (e) {
                e.preventDefault();
                download('result.json', JSON.stringify(diagnostic));
            } }, "T\u00E9l\u00E9charger le r\u00E9sultat")));
    if (survey.processingTaskError) {
        return (React.createElement("div", { className: "container alert alert-danger mt-3 text-center" },
            React.createElement("h3", { className: "alert-heading" }, "Une erreur est survenue"),
            React.createElement("p", { className: debugCstb ? '' : 'mb-0' },
                "Code erreur ",
                React.createElement("strong", null,
                    "D",
                    String(survey.processingTaskError.failingStage || 0).padStart(3, '0')),
                React.createElement("br", null),
                "Veuillez contacter le 04 28 70 82 73"),
            debugCstb && (React.createElement(React.Fragment, null,
                React.createElement("hr", null),
                debugCstbToolbar,
                React.createElement("p", { className: "mb-0" }, survey.processingTaskError.errorMessage)))));
    }
    var looses = [
        {
            value: initial.roof_losses_percentage,
            label: 'Toiture',
            icon: 'roof',
        },
        {
            value: initial.windows_losses_percentage,
            label: 'Fenêtres',
            icon: 'windows',
        },
        {
            value: initial.floor_losses_percentage,
            label: 'Planchers bas / hauts',
            icon: 'ground',
        },
        {
            value: initial.thermal_bridges_percentage,
            label: 'Ponts thermiques',
            icon: 'temp',
        },
        {
            value: initial.walls_losses_percentage,
            label: 'Murs',
            icon: 'walls',
        },
        {
            value: initial.ventilation_losses_percentage,
            label: 'Air renouvelé',
            icon: 'vmc',
        },
        {
            value: initial.infiltration_losses_percentage,
            label: "Fuite d'air",
            icon: 'air',
        },
    ];
    var levelConfort = initial.global_comfort_indicator;
    return (React.createElement("div", { className: "diagnosticResult" },
        React.createElement("div", { className: "diagnosticResultSave" },
            React.createElement(Container, { className: "diagnosticResultSaveContainer" },
                React.createElement("div", { className: "diagnosticResultSaveTitle" },
                    "Pr\u00EAt \u00E0 d\u00E9marrer votre projet\u00A0? ",
                    !security.user && 'Inscrivez-vous !'),
                React.createElement("div", { className: "diagnosticResultSaveCta" },
                    React.createElement("button", { type: "button", className: "btn btn-primary btn-icon-left", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('export'); } },
                        React.createElement("span", null,
                            React.createElement(RiDownloadCloud2Line, { size: 25, className: "m-4" }),
                            "Sauvegarder le r\u00E9sultat"))))),
        debugCstbToolbar,
        React.createElement("div", { className: "diagnosticResultBanner" },
            React.createElement(Container, { className: "diagnosticResultBannerContainer" },
                React.createElement("div", { className: "diagnosticResultBannerIllu" },
                    React.createElement("img", { src: illu, alt: "Illustration diagnostic", className: "img-fluid" })),
                React.createElement("div", { className: "diagnosticResultBannerInfos" },
                    React.createElement("h1", { className: "diagnosticResultBannerInfosTitle" },
                        "F\u00E9licitations !",
                        React.createElement("br", null),
                        "D\u00E9couvrez maintenant comment am\u00E9liorer la performance \u00E9nerg\u00E9tique de votre logement gr\u00E2ce \u00E0 nos propositions de travaux."),
                    React.createElement("div", { className: "diagnosticResultBannerInfosAnchors ".concat(showStickyAnchors ? 'sticky' : '') },
                        React.createElement("div", { className: "diagnosticResultBannerInfosAnchorsContainer" },
                            React.createElement("div", { className: "diagnosticResultBannerInfosAnchorsLeft" },
                                React.createElement("div", { className: "diagnosticResultBannerInfosAnchorsLabel" }, "Vos travaux souhait\u00E9s"),
                                React.createElement("a", { href: "#analyse", className: "diagnosticResultBannerInfosAnchorsListItemLink", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('click-header', 'Analyse'); } }, "\u00C9valuation")),
                            React.createElement("div", { className: "diagnosticResultBannerInfosAnchorsRight" },
                                React.createElement("div", { className: "diagnosticResultBannerInfosAnchorsLabel" }, "Nos pr\u00E9conisations de travaux"),
                                React.createElement("ul", { className: "diagnosticResultBannerInfosAnchorsList" },
                                    apple && (React.createElement("li", { className: "diagnosticResultBannerInfosAnchorsListItem" },
                                        React.createElement("a", { href: "#essentiel", className: "diagnosticResultBannerInfosAnchorsListItemLink", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('click-header', 'Essentiel'); } }, "Essentiel"))),
                                    pear && (React.createElement("li", { className: "diagnosticResultBannerInfosAnchorsListItem" },
                                        React.createElement("a", { href: "#equilibre", className: "diagnosticResultBannerInfosAnchorsListItemLink", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('click-header', 'Equilibre'); } }, "Equilibre"))),
                                    peach && (React.createElement("li", { className: "diagnosticResultBannerInfosAnchorsListItem" },
                                        React.createElement("a", { href: "#globale", className: "diagnosticResultBannerInfosAnchorsListItemLink", onClick: function () { return onClickCta === null || onClickCta === void 0 ? void 0 : onClickCta('click-header', 'Rénovation globale'); } }, "R\u00E9novation globale"))))))))),
            React.createElement("div", { className: "diagnosticResultBannerDate" },
                "Cette analyse a \u00E9t\u00E9 faite le\u00A0",
                new Date(String(diagnostic.date)).toLocaleDateString('default', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }),
                ".")),
        React.createElement("div", { className: "diagnosticResultPerf" },
            React.createElement(Container, null,
                React.createElement("h2", { className: "diagnosticResultTitle" },
                    "Quelle est la performance \u00E9nerg\u00E9tique de votre logement\u00A0?",
                    React.createElement("br", null),
                    React.createElement("small", null, "Cette \u00E9tude est une simulation, elle ne peut remplacer un DPE r\u00E9alis\u00E9 par un professionnel")),
                React.createElement(Row, null,
                    React.createElement(Col, { xl: 3, lg: 4 },
                        React.createElement("h3", { className: "diagnosticResultPerfTitle" }, "Votre logement"),
                        React.createElement("ul", { className: "diagnosticResultPerfHouse" },
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-home" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" }, diagnostic.type === 0 ? 'Maison' : 'Appartement')),
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-address" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" }, diagnostic.address)),
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-size" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" },
                                    diagnostic.surface,
                                    " m\u00B2"))),
                        React.createElement("hr", null),
                        React.createElement("ul", { className: "diagnosticResultPerfHouse" },
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-list" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" }, pluralize('[value] réponse[s] précise[s] au questionnaire', initial.number_of_exact_answers))),
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-question-circle" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" },
                                    pluralize('[value] réponse[s] approximative[s]', initial.number_of_approximate_answers),
                                    React.createElement("br", null),
                                    "ou \u00AB je ne sais pas \u00BB")),
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-data" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" },
                                    pluralize('[value] donnée[s] complémentaire[s]', initial.number_of_data_from_gorenove),
                                    React.createElement("br", null),
                                    "issues de Go R\u00E9nove")),
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: "icon-stats" })),
                                React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" },
                                    pluralize('[value] donnée[s] déduite[s] ', initial.number_of_inferred_data),
                                    "par enrichissement statistique"))),
                        React.createElement("hr", null),
                        React.createElement("ul", { className: "diagnosticResultPerfHouse" },
                            React.createElement("li", { className: "diagnosticResultPerfHouseItem" },
                                React.createElement("div", { className: "diagnosticResultPerfHouseItemPicto" },
                                    React.createElement("i", { className: initial.reliability_indicator > config.reliability_indicator_min ? 'icon-validate-on' : 'icon-error' })),
                                initial.reliability_indicator > config.reliability_indicator_min ? React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel active" }, "Etude proche du r\u00E9el") : React.createElement("span", { className: "diagnosticResultPerfHouseItemLabel" }, "Donn\u00E9es insuffisantes n\u00E9cessitant la visite d'un expert \u00E0 domicile")))),
                    React.createElement(Col, { xl: { span: 3, offset: 1 }, lg: 4 },
                        React.createElement("h3", { className: "diagnosticResultPerfTitle" },
                            "Votre \u00E9tiquette \u00E9nerg\u00E9tique*",
                            React.createElement(OverlayTrigger, { trigger: "click", placement: "bottom-start", overlay: popoverInfo, rootClose: true, rootCloseEvent: "click" },
                                React.createElement("i", { className: "icon-info popoverInfoButton" }))),
                        React.createElement("p", { className: "diagnosticResultPerfAlert" },
                            React.createElement("i", { className: "icon-warning" }),
                            React.createElement("span", { className: "diagnosticResultPerfAlertLabel" }, "La loi climat et r\u00E9silience a un impact sur votre \u00E9tiquette \u00E9nerg\u00E9tique.")),
                        React.createElement(Etiquette, { status: initial.dpe_status, statusValue: initial.dpe_status_value, co2EmissionsValue: initial.dpe_co_2_emissions_value, annualCostValue: initial.dpe_annual_cost_value }),
                        React.createElement("h3", { className: "diagnosticResultPerfTitle smallForMobile" },
                            "Votre niveau de confort",
                            React.createElement(OverlayTrigger, { trigger: "click", placement: "bottom-start", overlay: popoverConfort, rootClose: true, rootCloseEvent: "click" },
                                React.createElement("i", { className: "icon-info popoverInfoButton" }))),
                        React.createElement("div", { className: "diagnosticResultConfort", "data-level": levelConfort },
                            levelConfort > 2 && React.createElement("span", { className: "diagnosticResultConfortLabel first" }, "1"),
                            levelConfort < 10 && React.createElement("span", { className: "diagnosticResultConfortLabel last" }, "10")),
                        React.createElement("h3", { className: "diagnosticResultPerfTitle smallForMobile" },
                            "Score d'efficacit\u00E9 de votre chauffage",
                            React.createElement(OverlayTrigger, { trigger: "click", placement: "bottom-start", overlay: popoverScore, rootClose: true, rootCloseEvent: "click" },
                                React.createElement("i", { className: "icon-info popoverInfoButton" }))),
                        ['A', 'B'].includes(initial.dpe_status) ? (React.createElement("div", { className: "diagnosticResultPerfEfficiency good" },
                            React.createElement("i", { className: "icon-good" }),
                            " Bon")) : (React.createElement("div", { className: "diagnosticResultPerfEfficiency bad" },
                            React.createElement("i", { className: "icon-bad" }),
                            " Mauvais"))),
                    React.createElement(Col, { xl: { span: 4, offset: 1 }, lg: 4 },
                        React.createElement("h3", { className: "diagnosticResultPerfTitle" }, "D'o\u00F9 proviennent les pertes \u00E9nerg\u00E9tiques de votre logement\u00A0?"),
                        React.createElement("ul", { className: "diagnosticResultPerfLosses" }, looses
                            .sort(function (a, b) { return b.value - a.value; })
                            .map(function (item) { return (React.createElement("li", { key: item.label, className: "diagnosticResultPerfLossesItem" },
                            React.createElement("div", { className: "diagnosticResultPerfLossesItemPicto" },
                                React.createElement("i", { className: "icon-".concat(item.icon) })),
                            React.createElement("span", { className: "diagnosticResultPerfLossesItemNumber" },
                                item.value,
                                "%"),
                            React.createElement("span", { className: "diagnosticResultPerfLossesItemLabel" }, item.label))); })))))),
        React.createElement(Container, null,
            apricot && React.createElement(PrecoCard, { source: source, recommended: true, name: "analyse", title: "Analyse", data: apricot, initial: initial, onClickCta: onClickCta }),
            React.createElement("div", { id: "recommendation", className: "diagnosticResultRecommendation" },
                React.createElement("h2", { className: "diagnosticResultTitle" }, "Les pr\u00E9conisations de travaux Sonergia"),
                React.createElement("p", { className: "diagnosticResultIntro" },
                    "Suite \u00E0 vos r\u00E9ponses au questionnaire, et l'analyse compl\u00E8te de votre logement, voici diff\u00E9rentes propositions de travaux.",
                    React.createElement("br", null),
                    "Vous souhaitez en savoir plus avant d'affiner ces propositions et concr\u00E9tiser votre projet\u00A0? Contactez-nous\u00A0!")),
            apple && React.createElement(PrecoCard, { source: source, name: "essentiel", title: "Essentiel", subTitle: "S'il fallait n'en retenir qu'un", data: apple, initial: initial, onClickCta: onClickCta }),
            pear && React.createElement(PrecoCard, { source: source, name: "equilibre", title: "\u00C9quilibre", subTitle: "Le bon rapport performance / prix", data: pear, initial: initial, onClickCta: onClickCta }),
            peach && React.createElement(PrecoCard, { isNote: true, source: source, name: "globale", title: "R\u00E9novation globale", subTitle: "Maximiser votre performance \u00E9nergetique", data: peach, initial: initial, onClickCta: onClickCta }))));
}
export default Diagnostic;
