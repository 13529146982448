var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import ChoiceInput from '../choice';
import { useSharedValue, useSetSharedState, useSharedState } from '../../../../states';
export default (function (_a) {
    var question = _a.question;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var _c = useSharedState('survey:current'), current = _c[0], setCurrent = _c[1];
    var setResponse = useSetSharedState('survey:responses');
    var previous = useSharedValue('survey:previous');
    var hasHouseShape = useSharedValue('survey:has_house_shape');
    useEffect(function () {
        if (hasHouseShape) {
            setResponse(function (r) { return (__assign(__assign({}, r), { house_shape: null })); });
            setCurrent(previous > current ? current - 1 : current + 1);
            return;
        }
        setLoading(false);
    }, [current, previous, hasHouseShape]);
    if (loading) {
        return null;
    }
    return React.createElement(ChoiceInput, { question: question, onChange: function () { return setCurrent(current + 1); } });
});
