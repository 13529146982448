import { useEffect, useState } from 'react';
import ResultatAPI from 'features/primes/simulation/resultat/services/resultatAPI';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

const useSimulationResultat = (simulationId: string) => {
  const [simulation, setSimulation] = useState<Resultat>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>(null);

  const handleSimulation = async () => {
    setIsLoading(true);
    setError('');

    try {
      const simulationResponse = await ResultatAPI.getSimulation(simulationId);
      setSimulation(simulationResponse);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = () => {
    handleSimulation();
  };

  useEffect(() => {
    handleSimulation();

    return () => {
      setSimulation(undefined);
      setIsLoading(true);
      setError(null);
    };
  }, [simulationId]);

  return { simulation, isLoading, error, setSimulation, refresh };
};

export default useSimulationResultat;
