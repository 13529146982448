import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';

interface FileUploaderWarningProps {
  warnings: string[];
}

const FileUploaderWarning: React.FC<FileUploaderWarningProps> = ({ warnings }) => (
  <>
    {warnings.map((warning) => (
      <div key={warning} className="information-travaux__content__devis__uploads__avertissement">
        <div className="information-travaux__content__devis__uploads__avertissement__icon">
          <FaExclamationCircle size={25} color="#DB7927" />
        </div>
        <div className="information-travaux__content__devis__uploads__avertissement__text">{warning}</div>
      </div>
    ))}
  </>
);

export default FileUploaderWarning;
