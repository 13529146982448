import * as Yup from 'yup';
import formModel from 'features/dashboard/models/formModel';
import lang from '../../../lang/errorMessages.json';

const {
  formField: { companyName, siret, email, dateDevis, devis, facture, rib, ah },
} = formModel;

const { form } = lang;

const isFile = (value: unknown) => value instanceof File;

const dateDevisSchema = Yup.string()
  .required(form.devisDateRequired)
  .test('is-devis-date-valid', form.devisDateInvalid, (value) => {
    if (!value) return true;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const dateToValidate = new Date(value);
    dateToValidate.setHours(0, 0, 0, 0);
    return dateToValidate <= today;
  });

export default [
  Yup.object()
    .shape({
      [companyName.name]: Yup.string(),
      [siret.name]: Yup.string().test('is-siret-valid', form.siret, (value) => (value ? /^[0-9]{14}$/.test(value) : true)),
      [email.name]: Yup.string()
        .email(form.email)
        .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i, form.email)
        .required(form.emailRequired),
    })
    .test('is-at-least-one-field-present', form.siretOrNameRequired, (values) => !!values[companyName.name] || !!values[siret.name]),
  Yup.object().shape({
    [devis.name]: Yup.mixed().test('is-file', form.fileRequired, isFile).required(devis.requiredErrorMsg),
    [dateDevis.name]: dateDevisSchema,
  }),
  Yup.object().shape({}),
  Yup.object().shape({
    [facture.name]: Yup.mixed().test('is-file', form.fileRequired, isFile).required(facture.requiredErrorMsg),
    [rib.name]: Yup.mixed().test('is-file', form.fileRequired, isFile).required(rib.requiredErrorMsg),
    [ah.name]: Yup.mixed().test('is-file', form.fileRequired, isFile).required(ah.requiredErrorMsg),
  }),
];
