var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import { LuChevronRightCircle, LuChevronLeftCircle } from 'react-icons/lu';
import { OverlayTrigger, Popover } from 'react-bootstrap';
var errorBoxTimeout = null;
export function Actions(_a) {
    var info = _a.info, previousLabel = _a.previousLabel, nextLabel = _a.nextLabel, showNext = _a.showNext, back = _a.back, next = _a.next, disabled = _a.disabled, isSubmitting = _a.isSubmitting, errors = _a.errors, disabledNext = _a.disabledNext, disabledPrevious = _a.disabledPrevious;
    var _b = useState(false), showErrorBox = _b[0], setShowErrorBox = _b[1];
    var hasError = errors.length > 0;
    useEffect(function () {
        setShowErrorBox(hasError);
    }, [hasError]);
    var handleNext = function () {
        if (hasError) {
            setShowErrorBox(hasError);
            return;
        }
        if (next) {
            next();
        }
    };
    return (React.createElement("div", { className: "diagnosticFooter" },
        info,
        React.createElement("div", { className: "diagnosticFooterActions" },
            React.createElement("button", { type: "button", className: "btn btn-secondary btn-icon-left desktop", onClick: back, disabled: disabled || isSubmitting || disabledPrevious },
                React.createElement("span", null,
                    React.createElement(LuChevronLeftCircle, { size: 22, className: "m-2" }),
                    previousLabel)),
            showNext && (React.createElement(OverlayTrigger, { trigger: "click", show: showErrorBox, delay: { show: 250, hide: 250 }, overlay: function (props) { return (React.createElement(Popover, __assign({ className: "popoverInfo" }, props),
                    React.createElement(Popover.Body, { className: "popoverInfoBody" },
                        React.createElement("ul", { className: "popoverInfoBodyDetails" }, errors.map(function (error) { return (React.createElement("li", { key: error }, error)); }))))); }, onToggle: function (nextShow) {
                    if (nextShow) {
                        errorBoxTimeout = setTimeout(function () { return setShowErrorBox(false); }, 2000);
                    }
                    else if (errorBoxTimeout) {
                        clearTimeout(errorBoxTimeout);
                        errorBoxTimeout = null;
                    }
                } },
                React.createElement("button", { type: "button", className: "btn btn-primary btn-icon-right desktop", onClick: handleNext, disabled: disabled || isSubmitting || disabledNext },
                    React.createElement("span", null,
                        nextLabel,
                        React.createElement(LuChevronRightCircle, { size: 22, className: "m-2" }))))))));
}
export default Actions;
