import React from 'react';
import { HiOutlineLightBulb } from 'react-icons/hi';

interface ProjectNoteProps {
  note: string;
}

const ProjectNote: React.FC<ProjectNoteProps> = ({ note }) => (
  <div className="projets__note">
    <span className="projets__note__icon">
      <HiOutlineLightBulb size={25} color="#F4A008" />
    </span>
    <span className="projets__note--text">{note}</span>
  </div>
);

export default ProjectNote;
