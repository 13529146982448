import { QuestionChoice } from '@concerto-home/core';
import { ReponseAPI } from '../interfaces/questionnaireAPI';

export function mapAPIIncomeFiscal(responses: ReponseAPI[]): QuestionChoice[] {
  return responses.map((response) => ({
    id: response.uuid_reponse,
    label: response.libelle,
    picto: response.pictogramme,
    value: String(response.valeur),
  }));
}
