import React from 'react';
import Button from 'features/dashboard/components/button/button';
import { RGE } from 'features/dashboard/types/professionnel';

interface ProfessionalHeaderProps {
  name: string;
  avatar?: string;
  label: string;
  onClick: () => void;
  rge?: RGE | null;
}

const ProfessionalHeader: React.FC<ProfessionalHeaderProps> = ({ name, onClick, avatar = '', label, rge }) => {
  const { domaine, organisme, isRge } = rge || {};
  return (
    <div className="form__container__content__header">
      {avatar && (
        <div className="form__container__content__header--avatar">
          <img src={avatar} alt={name} />
        </div>
      )}
      <div className="form__container__content__header__content">
        <div className="form__container__content__header__content--name">{name}</div>
        {isRge && (
          <div className="form__container__content__header__content--rge">
            RGE : {organisme} - *{domaine}*
          </div>
        )}
        <div className="form__container__content__header__content--actions">
          <Button id="change-pro" variant="tertiary" label={label} type="button" onClick={onClick} />
        </div>
      </div>
    </div>
  );
};

export default ProfessionalHeader;
