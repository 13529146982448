import React, { ReactNode } from 'react';

type Props = {
  picto: string;
  alt: string;
  children: ReactNode;
};

export default function PictoItem({ picto, alt, children }: Props): React.ReactElement {
  return (
    <>
      <div className="goptimize-picto-item">
        <img src={picto} alt={alt} width="40" />
        {children}
      </div>
    </>
  );
}
