import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import type { RecoilState, SetterOrUpdater, RecoilValueReadOnly } from 'recoil';

import { surveyCurrent, surveyCurrentQuestion, surveyCurrentResponse, surveyErrors, surveyQuestions, surveyResponses, surveyId, surveyToken, surveyBnb, surveySubmitting, surveyPrevious, surveyHasHouseShape } from 'core';

import { securityUser, securityLogin, useSecurityLogin } from './security';
import { getUserSource, setUserSource, getSurveySource } from './source';
import { getUserPrescripteur, setUserPrescripteur, removeUserPrescripteur } from './prescripteur';
import { modalConfirm } from './modal';

type T = any; // eslint-disable-line

const states: RecoilState<T>[] = [surveyResponses, surveyCurrent, surveyErrors, surveyQuestions, surveyId, surveyToken, surveyBnb, surveySubmitting, surveySubmitting, surveyPrevious, surveyHasHouseShape, securityUser, modalConfirm, securityLogin];

const selectors: RecoilValueReadOnly<T>[] = [surveyCurrentQuestion, surveyCurrentResponse];

export function get(key: string, type: 'states' | 'selector' = 'states'): T {
  const target = type === 'states' ? states : selectors;

  for (let i = 0, len = target.length; i < len; i += 1) {
    if (target[i].key === key) {
      return target[i];
    }
  }

  throw new Error(`Try to using undefined ${type} "${key}"`);
}

export function useSelector(key: string): T {
  return useRecoilValue(get(key, 'selector'));
}

export function useSharedState(key: string): [T, SetterOrUpdater<T>] {
  return useRecoilState(get(key));
}

export function useSharedValue(key: string): T {
  return useRecoilValue(get(key));
}

export function useSetSharedState(key: string): SetterOrUpdater<T> {
  return useSetRecoilState(get(key));
}

export { useSecurityLogin, getUserSource, setUserSource, getSurveySource, getUserPrescripteur, setUserPrescripteur, removeUserPrescripteur };

export default states;
