import { setLocale } from 'yup';
import { labels, config } from '@concerto-home/core';
import api from '@concerto-home/api';
import { getEnv } from 'utils/env';

setLocale(labels.validation.yup);

export const initialize = (): void => {
  config.set('cms_url', getEnv('CMS_HOST')).set('autocomplete_address_url', getEnv('AUTOCOMPLETE_ADDRESS_URL')).set('gorenove_tiles_url', getEnv('GORENOVE_TILES_URL')).set('map_tiles_url', getEnv('MAP_TILES_URL')).set('reliability_indicator_min', getEnv('RELIABILITY_INDICATOR_MIN'));

  api.updateConfig({
    baseUrl: getEnv('API_URL'),
    tokenUrl: getEnv('API_TOKEN_PATH'),
    refreshTokenUrl: getEnv('API_REFRESH_TOKEN_PATH'),
  });
};

export * from '@concerto-home/core';
export { default as api } from '@concerto-home/api';
