import React, { useReducer } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { PiTrashSimple } from 'react-icons/pi';

import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import simulationsAPI from 'features/dashboard/services/simulationsAPI';
import { SIMULATION } from 'features/dashboard/helpers/utils';
import { useSimulations } from 'features/dashboard/hooks/dashboard';
import ModalConfirm from 'components/modal/modal';
import lang from '../../../../lang/dashboard.json';
import Button from '../../../button/button';
import MotifAbandoOverlay from './motifAbandoOverlay';

interface SimulationActionsProps {
  simulation: Resultat;
}

type State = {
  showModal: boolean;
  showConfirm: boolean;
};

type Action = {
  type: 'SHOW_MODAL' | 'HIDE_MODAL' | 'SHOW_CONFIRM' | 'HIDE_CONFIRM';
};

const initialState = {
  showModal: false,
  showConfirm: false,
};

const reducer = (state: State, action: Action) => {
  const actions = {
    SHOW_MODAL: () => ({ ...state, showModal: true }),
    HIDE_MODAL: () => ({ ...state, showModal: false }),
    SHOW_CONFIRM: () => ({ ...state, showConfirm: true }),
    HIDE_CONFIRM: () => ({ ...state, showConfirm: false }),
  };

  return actions[action.type]?.() || state;
};

const Actions: React.FC<SimulationActionsProps> = ({ simulation }) => {
  const { suppression } = lang || {};
  const { title, message, confirm, cancel } = suppression || {};

  const [state, dispatch] = useReducer(reducer, initialState);

  const { showModal, showConfirm } = state;

  const { actions, id } = simulation;
  const { isDeletable, isAbandonable } = actions;
  const { refresh } = useSimulations(SIMULATION.CREATED, false);

  const handleAbandonable = () => dispatch({ type: 'SHOW_MODAL' });
  const handleCloseModal = () => dispatch({ type: 'HIDE_MODAL' });
  const handleShowConfirm = () => dispatch({ type: 'SHOW_CONFIRM' });
  const handleCloseConfirm = () => dispatch({ type: 'HIDE_CONFIRM' });

  const handleDeleteSimulation = async () => {
    const response = await simulationsAPI.deleteSimulation(simulation?.id);

    if (response.suppression) {
      refresh();
      handleCloseModal();
    }
  };

  return (
    <div className="dashboard__demandes__list__item__prime__actions--group">
      <ModalConfirm
        data-cy="modal-suppression"
        show={showConfirm}
        onHide={handleCloseConfirm}
        action={handleDeleteSimulation}
        content={{
          title,
          message,
          confirm,
          cancel,
        }}
      />
      {isDeletable && <Button label="Supprimer" id="supprimer" variant="tertiary" type="button" icon={FaRegTrashAlt} onClick={handleShowConfirm} />}
      {!isDeletable && isAbandonable && <Button label="Abandonner" id="abandonner" variant="tertiary" type="button" icon={PiTrashSimple} onClick={handleAbandonable} />}
      {showModal && <MotifAbandoOverlay onClose={handleCloseModal} simulationId={id} />}
    </div>
  );
};

export default Actions;
