import React, { useEffect, useState } from 'react';

import { api, customerResource } from 'core';
import security from '@concerto-home/security';
import { useSetSharedState, getUserSource } from 'states';

import type { PropsWithChildren } from 'react';

import LoadingLayout from './loading-layout';

export default function AppWrapper({ children }: PropsWithChildren<unknown>): React.ReactElement {
  getUserSource();
  const setUser = useSetSharedState('security:user');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        if (security.user) {
          const userData = await customerResource.read(security.user);
          setUser(userData);
        }
      } catch (error) {
        console.error(error);
        api.handleExpiredCredentials();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return <LoadingLayout />;
  }

  return <>{children}</>;
}
