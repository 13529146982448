import React, { useEffect, useState, useRef } from 'react';
import { navbarContactLinkListenerRaw } from 'data-layer';

const js = `
  (function() {
    'use strict';

    var menuBurger = document.getElementsByClassName('headerNavBurger');
    var menuMobile = document.getElementsByClassName('headerNav');
    var menuStartDiagnostic = document.getElementsByClassName('headerNavRightItemLink');
    var itemNav= document.getElementsByClassName('headerNavLeftItemLink');
    var itemNavChild= document.getElementsByClassName('headerNavLeftItemChildren');
    var itemNavCatChild= document.getElementsByClassName('headerNavLeftItemChildrenCatTitle');
    var itemNavCatChildOfChild= document.getElementsByClassName('headerNavLeftItemChildrenCatList');

    var hideItemNavChild = function () {
        for (var i = 0; i < itemNavChild.length; i++) {
            itemNavChild[i].style.display = 'none';
        }
    };
    var hideItemNav = function () {
        for (var i = 0; i < itemNav.length; i++) {
            itemNav[i].style.display = 'none';
        }
    };
    var showItemNav = function () {
        for (var i = 0; i < itemNav.length; i++) {
            itemNav[i].style.display = 'flex';
        }
    };
    var removeSelectedClassItemNav= function () {
        for (var i = 0; i < itemNav.length; i++) {
            itemNav[i].classList.remove('selected');
        }
    };
    var hideItemNavChildOfChild = function () {
        for (var i = 0; i < itemNavCatChildOfChild.length; i++) {
            itemNavCatChildOfChild[i].style.display = 'none';
        }
    };
    var removeSelectedClassItemNavChild = function () {
        for (var i = 0; i < itemNavCatChild.length; i++) {
            itemNavCatChild[i].classList.remove('selected');
        }
    };
    var toggleNavChildren = function () {
        if (992 > window.innerWidth) {
            if (this.classList.contains('selected')) {
                this.nextElementSibling.style.display = 'none';
                showItemNav();
                hideItemNavChildOfChild();
                removeSelectedClassItemNavChild();
                this.classList.remove('selected');
                menuStartDiagnostic[0].style.display = 'flex';
            } else {
                if (null !== this.nextElementSibling) {
                    this.nextElementSibling.style.display = 'flex';
                    hideItemNav();
                    this.style.display = 'flex';
                    this.classList.add('selected');
                    menuStartDiagnostic[0].style.display = 'none';
                }
            }
        }
    };
    var toggleNavChildrenOfChild = function () {
        if (992 > window.innerWidth) {
            if (this.classList.contains('selected')) {
                this.nextElementSibling.style.display = 'none';
                this.classList.remove('selected');
            } else {
                if (null !== this.nextElementSibling) {
                    this.nextElementSibling.style.display = 'flex';
                    this.classList.add('selected');
                }
            }
        }
    };

    var toggleMenuMobile = function () {
      this.classList.toggle('open');
      document.body.classList.toggle('ns');
      if ('none' === window.getComputedStyle(menuMobile[0]).display) {
          menuMobile[0].style.display = 'block';
      } else {
          menuMobile[0].style.display = 'none';
          hideItemNavChildOfChild();
          removeSelectedClassItemNavChild();
          removeSelectedClassItemNav();
          showItemNav();
          hideItemNavChild();
          menuStartDiagnostic[0].style.display = 'flex';
      }
    };
    
    for (var i = 0; i < itemNav.length; i++) {
        itemNav[i].addEventListener('click', toggleNavChildren);
    }
    for (var i = 0; i < itemNavCatChild.length; i++) {
        itemNavCatChild[i].addEventListener('click', toggleNavChildrenOfChild);
    }

    menuBurger[0].addEventListener('click', toggleMenuMobile);

    window.addEventListener('resize', function() {
      if (991 < window.innerWidth) {
          menuMobile[0].style.display='flex';
          for (var i = 0; i < itemNavChild.length; i++) {
              itemNavChild[i].style.removeProperty('display');
          }
          for (var i = 0; i < itemNavCatChildOfChild.length; i++) {
              itemNavCatChildOfChild[i].style.removeProperty('display');
          }
      } else {
          menuMobile[0].style.display='none';
          menuBurger[0].classList.remove('open');
      }
    });

    ${navbarContactLinkListenerRaw}
  })();
`;

const MENU_SCRIPT_ID = 'menu-logic';

export default (): React.ReactElement => {
  const isCancelled = useRef(false);
  const [menu] = useState<string | null>(null);

  const appendScript = () => {
    if (!document.getElementById(MENU_SCRIPT_ID)) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = js;
      script.id = MENU_SCRIPT_ID;
      document.head.appendChild(script);
    }
  };

  useEffect(() => {
    if (menu) {
      appendScript();
    }

    return () => {
      isCancelled.current = true;
    };
  }, [menu]);

  /* eslint-disable-next-line */
  return <div dangerouslySetInnerHTML={{ __html: String(menu || '') }} />;
};
