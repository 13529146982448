import React, { useState } from 'react';
import ForgotPasswordForm from 'components/form/forgot-password';

export default function ForgotPassword(): React.ReactElement {
  const [success, setSuccess] = useState(false);

  const goToMessage = () => {
    const formTopElement = document.getElementById('success-msg');

    if (!formTopElement) {
      throw new Error('You must define an element with "success-msg" as ID placed before flash messages.');
    }

    formTopElement.scrollIntoView();
  };

  const handleComplete = () => {
    setSuccess(true);
    goToMessage();
  };

  return (
    <div className="accountForgetPasswordContainer">
      <div className="accountForgetPassword">
        {success ? (
          <>
            <div className="accountForgetPasswordPicto">
              <i className="icon-validate" />
            </div>
            <h1 className="accountForgetPasswordTitle">C&apos;est le moment d&apos;aller vérifier vos emails&nbsp;!</h1>
            <p className="accountForgetPasswordInfos">Cliquez sur le lien pour réinitialiser votre mot de passe.</p>
          </>
        ) : (
          <ForgotPasswordForm handleComplete={handleComplete} />
        )}
      </div>
    </div>
  );
}
