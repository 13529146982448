import { QuestionGroup } from '@concerto-home/core';
import { useEffect, useState } from 'react';
import GroupApi from 'features/primes/simulation/questionnaire/services/groupApi';

// Hook to fetch groups
const useGroups = () => {
  const [groups, setGroups] = useState<QuestionGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleGroups = async () => {
    try {
      setIsLoading(true);
      const groupsResponse = await GroupApi.getGroups();
      setGroups(groupsResponse);
    } catch (error) {
      console.error('Error fetching groups:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleGroups();
  }, []);

  return { groups, setGroups, isLoading };
};

export default useGroups;
