import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import './errorMessage.scss';

interface ErrorMessageProps {
  message?: string;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, className }) => {
  const classes = className ? `error-messages ${className}` : 'error-messages';

  return (
    <>
      {message && (
        <div className={classes}>
          <FaExclamationCircle className="error-icon" />
          <span className="error-text">{message}</span>
        </div>
      )}
    </>
  );
};

export default ErrorMessage;
