import { useState, useEffect } from 'react';
import InformationTravauxAPI, { ControleDatesResponse } from '../services/informationTravauxAPI';

const useCoherenceDates = (date: string, simulationId: string) => {
  const [erreursCoherence, setErreursCoherence] = useState<ControleDatesResponse>({
    success: false,
    erreursCoherence: [],
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!date) return;

      try {
        const response = await InformationTravauxAPI.postControllerCoherenceDate(simulationId, date);
        if (response.success) {
          setErreursCoherence(response);
        }
      } catch (error) {
        console.error('Erreur:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date, simulationId]);

  return {
    erreursCoherence: erreursCoherence.erreursCoherence,
    loading,
  };
};

export default useCoherenceDates;
