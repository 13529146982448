import React from 'react';
import { Modal, Button } from 'react-bootstrap';
export default function ModalSaveConfirm(_a) {
    var show = _a.show, onHide = _a.onHide, action = _a.action, content = _a.content;
    var continued = function () {
        onHide();
        action();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { show: show, onHide: onHide, centered: true },
            React.createElement(Modal.Header, { closeButton: true },
                React.createElement(Modal.Title, null, content.message)),
            React.createElement(Modal.Body, null,
                React.createElement("div", { className: "text-center mtl" },
                    React.createElement(Button, { className: "btn btn-secondary btn-icon-left", onClick: onHide },
                        React.createElement("span", null, "Annuler mes modifications")),
                    React.createElement(Button, { className: "btn btn-primary btn-icon-right prs mlm", onClick: continued },
                        React.createElement("span", null, content.button)))))));
}
