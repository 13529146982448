/* eslint-disable react/prop-types */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React from 'react';
import { useSharedState } from '../../../states';
export default function ChoicesInput(_a) {
    var question = _a.question, onChange = _a.onChange, value = _a.value, controlled = _a.controlled, nested = _a.nested, showChoice = _a.showChoice;
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var alias = question.alias, choices = question.choices;
    var currentValue = (value !== undefined ? value : responses[alias]) || [];
    function isSelected(v) {
        return currentValue && currentValue.includes(v);
    }
    function getNewValue(current, selection) {
        var consistency = question.rules.consistency || [];
        var newValue = __spreadArray([], current, true);
        if (newValue.includes(selection)) {
            newValue.splice(newValue.indexOf(selection), 1);
        }
        else {
            var consistencyValues_1 = [];
            for (var i = 0, len = (consistency === null || consistency === void 0 ? void 0 : consistency.length) || 0; i < len; i += 1) {
                if (consistency[i].includes(selection)) {
                    consistencyValues_1 = consistency[i];
                    break;
                }
            }
            if (consistency.length) {
                newValue = newValue.filter(function (n) { return consistencyValues_1.includes(n); });
            }
            newValue.push(selection);
        }
        return newValue;
    }
    function handleChange(nextValue) {
        if (typeof onChange === 'function') {
            onChange(nextValue);
        }
        if (!controlled) {
            setResponses(function (r) {
                var _a;
                return (__assign(__assign({}, r), (_a = {}, _a[alias] = getNewValue(r[alias] || [], nextValue), _a)));
            });
        }
    }
    var canDisplayChoice = function (v) { return (showChoice ? showChoice(v) : true); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "diagnosticQuestionListInfos" }, "*Multiples r\u00E9ponses possibles"),
        React.createElement("div", { className: "diagnosticQuestionList multiple ".concat(question.display === 'column' ? 'displayColumn' : '') }, choices &&
            choices.map(
            /* eslint-disable-next-line */
            function (_a) {
                var label = _a.label, value = _a.value, picto = _a.picto;
                return canDisplayChoice(value) && (React.createElement(React.Fragment, { key: value },
                    React.createElement("div", { className: "diagnosticQuestionListItem ".concat(isSelected(JSON.parse(value)) ? 'selected' : '', " ").concat(!picto ? 'justText' : ''), key: String(value), onMouseUp: function () { return handleChange(JSON.parse(value)); } },
                        React.createElement("div", { className: "diagnosticQuestionListItemContent" },
                            label,
                            picto && (React.createElement("div", { className: "diagnosticQuestionListItemPicto" },
                                React.createElement("img", { src: picto, alt: label, className: "img-fluid" }))))),
                    nested && nested(value)));
            }))));
}
