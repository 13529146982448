import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import useDocumentUpload from 'features/dashboard/hooks/useDocumentUpload';
import useDocumentActions from 'features/dashboard/hooks/useDocumentActions';
import { DocumentData } from 'features/dashboard/types/document';
import { filterDocuments } from 'features/dashboard/helpers/utils';
import Typography from '../typography/typography';
import lang from '../../lang/projets.json';
import DocumentsList from './DocumentsList';

interface DocumentsDemandesProps {
  documents: DocumentData[];
  refresh: () => void;
}

const DocumentsDemandes: React.FC<DocumentsDemandesProps> = ({ documents, refresh }) => {
  const { simulationId } = useParams();
  const { finalisation } = lang || {};

  if (!simulationId) {
    return null;
  }

  const { downloadDocument, deleteDocument } = useDocumentActions();
  const { handleUploadDocument, isLoading } = useDocumentUpload();

  const filteredDocuments = useMemo(() => filterDocuments(documents || []), [documents]);
  const { othersDocuments, avisImpositionDocuments } = filteredDocuments;

  const handleDownload = useCallback(
    async (idFile: number | null) => {
      await downloadDocument(simulationId, idFile);
    },
    [downloadDocument, simulationId]
  );

  const handleUpload = useCallback(
    async (file: File, fieldName: string, foyerPrecarite?: number) => {
      await handleUploadDocument(file, { simulationId, fieldName, foyerPrecarite, refresh });
    },
    [handleUploadDocument, simulationId, refresh]
  );

  const handleDelete = useCallback(
    async (idFile: number | null) => {
      await deleteDocument(simulationId, idFile).then(refresh);
    },
    [deleteDocument, simulationId, refresh]
  );

  return (
    <div className="information-travaux__content__rib">
      <Typography variant="h2" size="xl" fontWeight="bold" customClass="mb-4 d-flex gap-3 align-items-center">
        <div className="step__number">2</div> {finalisation.ribTitle}
      </Typography>

      <div className="information-travaux__content__documents__uploads">
        <DocumentsList documents={othersDocuments} onDownload={handleDownload} onUpload={handleUpload} onDelete={handleDelete} isDeletable isLoading={isLoading} />
        <DocumentsList documents={avisImpositionDocuments} onDownload={handleDownload} onUpload={handleUpload} onDelete={handleDelete} isAvisImposition isDeletable isLoading={isLoading} />
      </div>
    </div>
  );
};

export default React.memo(DocumentsDemandes);
