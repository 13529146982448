var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Resource from '..';
import api from '@concerto-home/api';
import { download } from '../../utils/survey';
var ContactFormResource = /** @class */ (function (_super) {
    __extends(ContactFormResource, _super);
    function ContactFormResource(entrypoint) {
        if (entrypoint === void 0) { entrypoint = '/contact_forms'; }
        var _this = _super.call(this, entrypoint) || this;
        _this.TYPE_SURVEY_REJECTED = 'survey_rejected';
        _this.TYPE_CONTACT_ADVISOR = 'contact_advisor';
        _this.TYPE_CONTACT_EXPERT = 'contact_expert';
        _this.TYPE_CONTACT_ARTISANT = 'contact_artisant';
        _this.TYPE_REFINE_BONUS = 'refine_bonus';
        _this.TYPE_OTHER = 'other';
        return _this;
    }
    ContactFormResource.prototype.assign = function (id, partner) {
        return api.put(this.buildUri(id, 'assign'), { partner: partner }, { force: 1 }).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    ContactFormResource.prototype.unassign = function (id, partner) {
        return api.put(this.buildUri(id, 'unassign', partner), {}, {}).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    ContactFormResource.prototype.export = function (filters, config) {
        return api.get(this.buildUri('export'), filters, __assign(__assign({}, config), { responseType: 'blob' })).then(function (_a) {
            var data = _a.data;
            return download('export.csv', data);
        });
    };
    ContactFormResource.prototype.exportLight = function (filters, config) {
        return api.get(this.buildUri('export_light'), filters, __assign(__assign({}, config), { responseType: 'blob' })).then(function (_a) {
            var data = _a.data;
            return download('export.csv', data);
        });
    };
    return ContactFormResource;
}(Resource));
export { ContactFormResource };
export default new ContactFormResource();
