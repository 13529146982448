import React from 'react';
import illustrationSimulationPrimes from 'assets/images/illustration_calcul.svg';

export type PanelProps = {
  currentStep: number;
};

export const Panel = ({ currentStep }: PanelProps): React.ReactElement => {
  const steps = [];
  steps[1] = {
    illustration: illustrationSimulationPrimes,
    title: 'Votre profil en quelques clics',
  };
  steps[2] = {
    illustration: illustrationSimulationPrimes,
    title: 'Inscrivez-vous pour sauvegarder vos résultats et profiter de notre accompagnement',
  };
  steps[3] = {
    title: 'Votre inscription est en cours de finalisation.',
  };

  return (
    <div className="registerInfos">
      {steps[currentStep]?.illustration && (
        <div className="registerInfosIllu">
          <img src={steps[currentStep].illustration} alt="Projet de rénovation" />
        </div>
      )}
      <h1 className="registerInfosTitle">{steps[currentStep].title}</h1>
    </div>
  );
};

export default Panel;
