import React, { useRef } from 'react';
import lang from 'features/primes/gestion-dossier/aat/lang/aat.json';
import downoald from 'features/primes/gestion-dossier/aat/assets/icons/downoald.svg';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Modal } from 'react-bootstrap';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { LoaderSimple } from '@concerto-home/core';
import Lottie from 'components/lottie/Lottie';
import { trackClickStartProject } from 'data-layer';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import Button from 'features/dashboard/components/button/button';
import usePdfGenerator from '../hooks/usePdfGenerator';
import envelope from '../lotties/envelope.json';
import sendEmailApi from '../services/sendAatApi';
import Steps from '../components/Steps';
import Banner from '../components/banner';

const EtapesAAT: React.FC = () => {
  const { simulationId } = useParams();
  const { state } = useLocation();
  const operation = state?.operation;

  if (!simulationId) {
    return null;
  }

  const printRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isDownloadPdf, generatePdf } = usePdfGenerator(printRef);
  const { simulation, isLoading } = useSimulationResultat(simulationId);
  const [isStart, setIsStart] = React.useState(false);
  const { amount } = simulation || {};

  const handleSendAAT = async () => {
    setIsStart(true);
    const reponse = await sendEmailApi.sendAAT(simulationId);
    if (reponse && reponse.success) {
      setIsStart(false);
      navigate(`/dashboard/constitution-dossier/${simulationId}`);
    }

    // Target Tracking - AAT - Start
    trackClickStartProject(simulation as Resultat);
  };

  const handleDownloadPdf = async () => {
    await generatePdf();
  };

  if (isLoading || isDownloadPdf) {
    return <LoaderSimple />;
  }

  return (
    <div className="aat-primes__modal">
      {isStart && (
        <Modal show className="aat-primes__modal" centered style={{ background: 'transparent' }}>
          <Lottie animationData={envelope} title={lang.lottie.title} middle />
        </Modal>
      )}
      <Banner operation={operation} />
      <div ref={printRef} className={isDownloadPdf ? 'm-5' : ''}>
        <div className="aat_wrapper">
          <div className="aat-primes__amount">
            <p className="aat-primes__amount-price">{amount} € </p>
            <p className="aat-primes__amount-title"> {lang.amount}</p>
            <p className="aat-primes__amount-text"> {lang.condition}</p>
          </div>
          <p className="aat-modal__presentation-title">{lang.presentation}</p>
          <div className="aat-primes__request">
            <div className="aat-primes__header">
              <p className="aat-primes__header-title">{lang.stepTitle}</p>
              <button type="button" className="aat-primes__header-download hideOnPdf" onClick={handleDownloadPdf}>
                <img src={downoald} alt="Télécharger" />
              </button>
            </div>
            <p className="aat-primes__request-text">{lang.stepRequest}</p>
            <div className="aat-primes__steps">
              <div className="aat-primes__steps-linear">&nbsp;</div>
              <Steps dataSteps={lang.steps} />
            </div>
          </div>
        </div>
        <div className="aat-primes__actions hideOnPdf">
          <Button type="button" label={lang.start} onClick={handleSendAAT} id="step-commencer" />
        </div>
        <div className="aat-prime__footer-info hideOnPdf">{lang.footerInfo}</div>
      </div>
    </div>
  );
};

export default EtapesAAT;
