import React, { useCallback, useEffect } from 'react';
import { useSharedValue } from 'states';
import { Customer, Survey, surveyResource } from 'core';
import { useConfirm } from 'utils/confirm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import security from '@concerto-home/security';
import { clearSimulationFromLocalStorage } from 'features/dashboard/helpers/utils';
import LoginForm from '../../components/form/login';

export default function Login(): React.ReactElement {
  const { callback, state } = useSharedValue('security:login');
  const user = useSharedValue('security:user');
  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = location.pathname === '/login';
  const isRegisterPage = location.pathname === '/register';
  const confirm = useConfirm();

  useEffect(() => {
    if (user && security.user) {
      navigate('/dashboard');
    }
  }, []);

  const getSurveyIdFromLocation = () => {
    const survey = location.state?.survey;
    return survey?.split('/').pop();
  };

  const attachSurveyToUser = async (surveyId: string | number) => {
    try {
      await surveyResource.linkSurveyToUser(surveyId);
    } catch (error) {
      console.error('Failed to link survey to user', error);
    }
  };

  const handleDeleteAndAttachSurvey = async (connectedUser: Customer) => {
    try {
      if (connectedUser.survey) {
        await surveyResource.delete((connectedUser.survey as Survey)?.id as string | number);
      }
      const surveyId = getSurveyIdFromLocation();
      if (surveyId) {
        await attachSurveyToUser(surveyId);
      }
    } catch (error) {
      console.error('Failed to delete or attach survey', error);
    }
  };
  const handleComplete = useCallback(
    async (connectedUser: Customer) => {
      const surveyId = getSurveyIdFromLocation();
      if (surveyId) {
        if (connectedUser) {
          if (connectedUser.survey === null) {
            await attachSurveyToUser(surveyId);
          } else {
            const confirmMessage = 'Attention ! Vous êtes sur le point de finaliser une nouvelle version du questionnaire et votre simulation précédente sera supprimée. Souhaitez-vous continuer ?';
            confirm('Attention !', confirmMessage).then(async () => {
              await handleDeleteAndAttachSurvey(connectedUser);
              window.location.reload();
            });
          }
        }
      }
      if (isLoginPage || isRegisterPage) {
        navigate((location.state as any)?.from || '/dashboard');
      }
    },
    [callback]
  );

  const handleClearSimulation = () => {
    clearSimulationFromLocalStorage();
  };

  return (
    <div>
      <h2 className="accountLoginCardTitle mb-4">J&apos;ai déjà un compte</h2>
      <LoginForm handleComplete={handleComplete} />
      <div className="accountLoginCardRegister pt-5">
        Vous n&apos;avez pas encore de compte ?
        <Link to="/register" state={state} className="btn btn-link" onClick={handleClearSimulation}>
          Inscrivez-vous en quelques clics&nbsp;!
        </Link>
      </div>
    </div>
  );
}
