/* eslint-disable react/prop-types */
import React from 'react';
export default function ChoiceInput(_a) {
    var question = _a.question, onChange = _a.onChange, value = _a.value, controlled = _a.controlled, nested = _a.nested, showChoice = _a.showChoice;
    var choices = question.choices;
    var currentValue = controlled ? value : question.response;
    var isSelected = function (v) { return currentValue === v; };
    var handleChange = function (nextValue) {
        if (typeof onChange === 'function') {
            onChange(nextValue);
        }
    };
    var canDisplayChoice = function (v) { return (showChoice ? showChoice(v) : true); };
    return (React.createElement("div", { className: "diagnosticQuestionList ".concat(question.display === 'column' ? 'displayColumn' : question.display === 'grid' ? 'displayGrid' : '') }, choices &&
        choices.map(function (choice) {
            return canDisplayChoice(choice.value) && (React.createElement(React.Fragment, { key: choice.value },
                React.createElement("div", { className: "diagnosticQuestionListItem ".concat(isSelected(choice.value) ? 'selected' : '', " ").concat(!choice.picto ? 'justText' : '') },
                    React.createElement("div", { className: "diagnosticQuestionListItemContent", "data-cy": choice.label, onMouseUp: function () { return handleChange(choice.value); } },
                        choice.label,
                        choice.picto && (React.createElement("div", { className: "diagnosticQuestionListItemPicto" },
                            React.createElement("img", { src: choice.picto, alt: choice.label, className: "img-fluid" }))))),
                nested && nested(choice.value)));
        })));
}
