import React, { useState } from 'react';
import { MdOutlineMail } from 'react-icons/md';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useParams } from 'react-router';
import { FormikValues, useFormikContext } from 'formik';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import informationTravauxAPI from 'features/dashboard/services/informationTravauxAPI';
import useDocumentActions from 'features/dashboard/hooks/useDocumentActions';
import useDocumentUpload from 'features/dashboard/hooks/useDocumentUpload';
import { LoaderSimple } from '@concerto-home/core';
import Typography from '../typography/typography';
import Button from '../button/button';
import FileUpload from '../fileUpload/fileUpload';
import { FormInitialValues } from '../identificationProfessionnel/validation/formInitialValues';
import EnvoiAHStepItem from './envoiAHStepItem';
import lang from '../../lang/projets.json';

interface AttestationHonneurProps {
  fieldName: string;
  refresh: () => void;
}

const AttestationHonneur: React.FC<AttestationHonneurProps> = ({ fieldName, refresh: refreshDocuments }) => {
  const [sendingAH, setSendingAH] = useState(false);
  const { simulationId } = useParams();
  const { finalisation } = lang || {};
  const { values } = useFormikContext<FormInitialValues & FormikValues>();

  const { facture } = values;
  const isFactureUploaded = facture?.name;

  const { idFile } = values[fieldName] || {};

  if (!simulationId) {
    return null;
  }

  const { downloadDocument } = useDocumentActions();
  const { handleUploadDocument, isLoading: isUploading } = useDocumentUpload();

  const handleDownload = async () => {
    downloadDocument(simulationId, idFile);
  };

  const { simulation, refresh, isLoading } = useSimulationResultat(simulationId);
  const { hasAh, professionnel } = simulation || {};
  const { email } = professionnel || {};

  const handleUpload = async (file: File) => {
    handleUploadDocument(file, { simulationId, fieldName, refresh: refreshDocuments });
  };

  const handleSendAH = async () => {
    setSendingAH(true);
    const response = await informationTravauxAPI.sendAH(simulationId);
    setSendingAH(false);
    if (response.success) {
      refresh();
    }
  };

  if (isLoading) {
    return <LoaderSimple />;
  }

  return (
    <div className="information-travaux__content__ah">
      <Typography variant="h2" size="xl" fontWeight="bold" customClass="mb-4 d-flex gap-3 align-items-center">
        <div className="step__number">3</div> {finalisation.ahTitle}
      </Typography>
      <div className="information-travaux__content__ah__information">
        <div className="information-travaux__content__ah__information__icon">
          <BsFillInfoCircleFill />
        </div>
        <div className="information-travaux__content__ah__information__text">
          <b> {finalisation.informationTitle}</b>
          <br /> {finalisation.informationText}
        </div>
      </div>
      <div className="information-travaux__content__ah__items">
        <EnvoiAHStepItem number={1} text="J’envoie mon attestation à mon professionnel">
          {hasAh && (
            <div className="information-travaux__content__ah__items__item__text__result" data-cy="ahdistination">
              {finalisation.ahDistination} : <br /> {email}
            </div>
          )}
        </EnvoiAHStepItem>
        <EnvoiAHStepItem number={2} text="Mon professionnel me renvoie mon attestation datée et signée" />
        <EnvoiAHStepItem number={3} text="Je signe à mon tour mon attestation et la dépose ici">
          {hasAh && (
            <div className="information-travaux__content__ah__items__item__text__uploader">
              <FileUpload name={fieldName} allowUpload onDownload={handleDownload} onUpload={handleUpload} className="mh-50" isLoading={isUploading} />
            </div>
          )}
        </EnvoiAHStepItem>
      </div>
      <Button variant="secondary" id="send-ah" isloading={sendingAH} size="medium" isDisabled={!isFactureUploaded || hasAh || sendingAH} label={finalisation.sendMyah} type="button" icon={MdOutlineMail} classNames="dark" onClick={handleSendAH} />
    </div>
  );
};

export default AttestationHonneur;
