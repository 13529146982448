import React from 'react';
import { Link } from 'react-router-dom';
import { FaScrewdriverWrench } from 'react-icons/fa6';
import { FaHome, FaPowerOff, FaUserSecret } from 'react-icons/fa';
import { useSharedState } from '../../states';

type Props = {
  handleClose?: () => void;
};

export default function AccountMenu({ handleClose }: Props): React.ReactElement {
  const [user] = useSharedState('security:user');
  return (
    <>
      <button type="button" className="accountMenuContainer" onClick={handleClose} onKeyDown={handleClose}>
        <ul className="accountMenuList">
          <li className="accountMenuListItem">
            <Link to="/dashboard" className="accountMenuListItemLink">
              <div className="accountMenuListItemIcon">
                <FaHome size={24} />
              </div>
              Mon tableau de bord
            </Link>
          </li>
          <li className="accountMenuListItem">
            <Link to="/account/managment/data" className="accountMenuListItemLink">
              <div className="accountMenuListItemIcon">
                <FaScrewdriverWrench size={24} />
              </div>
              Mes informations personnelles
            </Link>
          </li>
          {user.hasUtilisateurAccess && (
            <li className="accountMenuListItem">
              <Link to="/account/managment/access" className="accountMenuListItemLink">
                <div className="accountMenuListItemIcon">
                  <FaUserSecret size={24} />
                </div>
                Récupérer les données
              </Link>
            </li>
          )}
          <li className="accountMenuListItem" data-cy="logout">
            <Link to="/logout" className="accountMenuListItemLink" data-cy="logout">
              <div className="accountMenuListItemIcon">
                <FaPowerOff size={24} />
              </div>
              Déconnexion
            </Link>
          </li>
        </ul>
      </button>
    </>
  );
}
