import React from 'react';
import AccessUserData from '../../components/form/access-user-data';

export default function Access(): React.ReactElement {
  return (
    <>
      <h1 className="accountInfosTitle">
        <strong>Accéder aux données d’un utilisateur</strong>
      </h1>
      <AccessUserData />
    </>
  );
}
