import React from 'react';
import { ErrorMessage } from 'formik';

import type { ErrorMessageProps } from 'formik';

const ErrorMsg: any = ErrorMessage; // eslint-disable-line

export default function Error(props: ErrorMessageProps): React.ReactElement {
  return (
    <ErrorMsg
      render={(err: string) => (
        <div className="formError">
          <i className="icon-error" />
          <div className="formErrorLabel">{err}</div>
        </div>
      )}
      {...props}
    />
  );
}
