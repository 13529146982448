import { useEffect, useState } from 'react';
import { QuestionGroup } from '@concerto-home/core';
import PrimeApi from 'features/primes/simulation/questionnaire/services/primeApi';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

// hook to fetch initial question
const useInitialQuestion = (groups: QuestionGroup[]) => {
  const [questions, setQuestions] = useState<QuestionPrime[]>([]);
  const [isResolving, setIsResolving] = useState(true);

  const fetchInitialQuestion = async () => {
    const questionResponse = await PrimeApi.getQuestion();
    const questionsData = [];
    questionsData.push(questionResponse);
    setQuestions(questionsData);
    setIsResolving(questionResponse === null);
  };

  useEffect(() => {
    if (groups.length > 0) {
      fetchInitialQuestion();
    }
  }, [groups.length]);

  return [questions, setQuestions, isResolving] as const;
};

export default useInitialQuestion;
