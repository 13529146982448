var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import questionResource from '../../resources/question';
import { useSharedValue } from '../../states';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import config from '../../config';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
export default function Label(_a) {
    var question = _a.question;
    if (question.alias === questionResource.ALIAS_QUESTION_WINDOWS_PER_WALL) {
        return null;
    }
    var responses = useSharedValue('survey:responses');
    var heatingSystemType = responses[questionResource.ALIAS_QUESTION_HEATING_SYSTEM_TYPE];
    var questionLabel = (question.label || '').replace('href="/', "href=\"".concat(config.cms_url, "/"));
    var questionWhy = (question.why || '').replace('href="/', "href=\"".concat(config.cms_url, "/"));
    var questionMore = (question.more || '').replace('href="/', "href=\"".concat(config.cms_url, "/"));
    var questionHelp = (question.help || '').replace('href="/', "href=\"".concat(config.cms_url, "/"));
    var information = (question.information || '').replace('href="/', "href=\"".concat(config.cms_url, "/"));
    var label = questionLabel;
    switch (question.alias) {
        case questionResource.ALIAS_QUESTION_SURFACE_AREA:
            if (responses[questionResource.ALIAS_QUESTION_HAS_EXTENSION] === 1) {
                label = "".concat(label.slice(0, -1), " (extension habitable comprise) ?");
            }
            break;
        case questionResource.ALIAS_QUESTION_HEATING_SYSTEM_OPERATING_MODE:
            if ([5, 6, 7, 8].includes(heatingSystemType)) {
                label = "Est-ce que votre PAC produit de l'eau chaude sanitaire&nbsp;?";
            }
            else if ([1, 2, 3, 4].includes(heatingSystemType)) {
                label = "Est-ce que votre chaudière produit de l'eau chaude sanitaire&nbsp;?";
            }
            else if ([13, 12].includes(heatingSystemType)) {
                label = 'Votre système de chauffage produit-il de l’eau chaude (eau chaude sanitaire utilisée pour la douche par exemple)&nbsp;?';
            }
            break;
        default:
            break;
    }
    function toggleQuestionInfo() {
        var questionInfo = document.querySelector('.diagnosticQuestionWhy');
        if (questionInfo) {
            questionInfo.classList.toggle('open');
        }
    }
    return (React.createElement(React.Fragment, null,
        questionLabel && (
        // eslint-disable-next-line
        React.createElement("div", { className: "diagnosticQuestionLabelWrapper" },
            questionWhy && React.createElement("i", { className: "icon-info ", onClick: toggleQuestionInfo }),
            React.createElement("h2", { className: "diagnosticQuestionLabel", dangerouslySetInnerHTML: { __html: label } }))),
        questionMore && (React.createElement("h3", { className: "diagnosticQuestionLabelMore", 
            // eslint-disable-next-line
            dangerouslySetInnerHTML: { __html: questionMore } })),
        questionWhy && (React.createElement("div", { className: "diagnosticQuestionWhy" },
            React.createElement("div", { className: "diagnosticQuestionWhyIcon" }, information ? (React.createElement(OverlayTrigger, { overlay: function (props) { return (React.createElement(Tooltip, __assign({ className: "custom_tooltip" }, props), information)); }, placement: "bottom" },
                React.createElement("p", null,
                    React.createElement(BsFillInfoCircleFill, null)))) : (React.createElement(BsFillInfoCircleFill, null))),
            React.createElement("div", null,
                React.createElement("h3", { className: "diagnosticQuestionWhyTitle" }, "Pourquoi cette question ?"),
                React.createElement("p", { className: "diagnosticQuestionWhyDetails", 
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML: { __html: questionWhy } })))),
        questionHelp && (React.createElement("div", { className: "diagnosticQuestionHelp" },
            React.createElement("p", { className: "diagnosticQuestionHelpDetails", 
                // eslint-disable-next-line
                dangerouslySetInnerHTML: { __html: questionHelp } })))));
}
