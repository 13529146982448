import React from 'react';
import { resolveInputGeneric } from '@concerto-home/core';
import { Modal } from 'react-bootstrap';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';
import Button from '../../button/button';
import ErrorMessage from '../../errorMessage/errorMessage';

interface QuestionModalProps {
  show: boolean;
  handleClose: () => void;
  selectedQuestion: QuestionPrime;
  onSubmit: () => void;
  onChange: (newValue: string) => void;
  errors?: string[];
}

const QuestionModal: React.FC<QuestionModalProps> = ({ show, handleClose, selectedQuestion, onSubmit, onChange, errors = [] }) => {
  const Input = resolveInputGeneric(selectedQuestion);

  if (!Input) {
    return null;
  }

  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <Modal show={show} onHide={handleClose} className="information-travaux__modal" centered>
      <Modal.Header closeButton closeLabel="Fermer">
        <Modal.Title>{selectedQuestion.label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="information-travaux__modal__content">
          <div className="information-travaux__modal__content__description">{selectedQuestion.help}</div>
          <div className="information-travaux__modal__content__description">{selectedQuestion.labelInput}</div>
          <div className="information-travaux__modal__content__field ">
            <Input ignoreConstraints question={selectedQuestion} onChange={(newValue) => handleChange(newValue)} />
          </div>
          {errors && errors.map((error) => <ErrorMessage key={error} message={error} />)}
        </div>
        <div className="mt-4 d-flex justify-content-center">
          <Button id="confirm-question-overlay" label="Valider" type="button" onClick={onSubmit} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default QuestionModal;
