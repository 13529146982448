import React from 'react';

export type StepProps = {
  itemNumber: number;
  label: string;
  currentStep: number;
};

export const Step = ({ itemNumber, label, currentStep }: StepProps): React.ReactElement => (
  <div className={`registerFormStepItem ${currentStep === itemNumber ? 'active' : ''} ${currentStep > itemNumber ? 'validate' : ''}`}>
    <div className="registerFormStepItemNumber">{itemNumber}</div>
    <div className="registerFormStepItemLabel">{label}</div>
  </div>
);

export default Step;
