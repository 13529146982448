import React from 'react';
import './contact.scss';
import { PHONE_NUMBER, SERVICE_EMAIL } from 'utils/constants';
import contact from '../../assets/images/contact.webp';

const Contact: React.FC = () => (
  <section className="contact">
    <img src={contact} alt="contact" className="contact__image" />
    <h3 className="contact__title">Nous contacter :</h3>
    <address className="contact__info">
      <p className="contact__phone">
        <a href={`tel:${PHONE_NUMBER}`} aria-label="Phone number">
          {PHONE_NUMBER}
        </a>
      </p>
      <p className="contact__email">
        <a href={`mailto:${SERVICE_EMAIL}`} aria-label="Email address">
          {SERVICE_EMAIL}
        </a>
      </p>
      <p className="contact__hours">
        De <b>9h à 17h</b>
      </p>
      <p className="contact__schedule">Du lundi au vendredi</p>
    </address>
  </section>
);

export default Contact;
