import { customerResource } from 'core';
import security from '@concerto-home/security';
import type { Customer } from 'core';
import { getEnv } from './env';

export const openLogin = (callback: (_customer: Customer) => void): void => {
  const strWindowFeatures = 'toolbar=no, menubar=no, width=800, height=800, top=100, left=100';
  const windowObjectReference = window.open('', 'Connexion', strWindowFeatures) as Window;
  const authUrl = getEnv('API_LOGIN');

  windowObjectReference.document.title = 'Connexion';
  windowObjectReference.document.write(`
    <div>Chargement...</div>
    <script>window.location.href = '${authUrl}'</script>
  `);

  windowObjectReference.focus();

  function listenAuth(e: MessageEvent) {
    if (e.origin !== new URL(authUrl).origin) {
      return;
    }

    const { uuid, code, token, source, roles } = e.data || {};

    if (code === 'concerto-access-token') {
      security.authenticate(uuid, token.access_token, source, roles);
      windowObjectReference.close();

      setTimeout(() => {
        customerResource.read(security.user as string).then(callback);
        window.removeEventListener('message', listenAuth);
      }, 10);
    }
  }

  window.addEventListener('message', listenAuth);
  window.addEventListener('beforeunload', () => windowObjectReference.close());
};
