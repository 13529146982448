import React from 'react';

interface BannerProps {
  operation: string;
}

const Banner: React.FC<BannerProps> = ({ operation }) => (
  <div className="aat__container--header">
    <p className="header__subtitle">Mes demandes en cours</p>
    <h2 className="header__title">{operation}</h2>
  </div>
);

export default Banner;
