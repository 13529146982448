import { api, Resource } from 'core';
import { mapAPISimulation } from '../mapper/simulation.mapper';
import { ISimulation, EditSimulation, Simulation } from '../interfaces/questionnaire';

export class SimulationAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  postSimulation(payload: Simulation): Promise<ISimulation> {
    return api.post(this.buildUri('simulation'), payload).then(({ data }) => mapAPISimulation(data));
  }

  updateSimulation(simulationId: string, payload: Simulation): Promise<any> {
    return api.put(this.buildUri(`simulation/${simulationId}`), payload).then(({ data }) => mapAPISimulation(data));
  }

  updateSimulationInfos(simulationId: string, payload: EditSimulation): Promise<{ success: boolean }> {
    return api.patch(this.buildUri(`simulation/${simulationId}/beneficiaire`), payload).then(({ data }) => data);
  }
}

export default new SimulationAPI();
