import React from 'react';
import './modal.scss';
import { Modal, Button } from 'react-bootstrap';

type Props = {
  show: boolean;
  onHide: () => void;
  action: () => void;
  content: {
    title: string;
    message?: string | React.ReactElement;
    confirm?: string;
    cancel?: string;
  };
  classNames?: string;
};

export default function ModalConfirm({ show, onHide, action, content, classNames }: Props): React.ReactElement {
  const continued = () => {
    onHide();
    action();
  };

  const style = classNames || 'text-center';

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{content.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content.message && <div className={style}>{content.message}</div>}
        <div className="text-center mtl">
          {content.cancel && (
            <Button className="btn btn-secondary btn-icon-left" onClick={onHide} data-cy="cancel">
              <span>{content.cancel}</span>
            </Button>
          )}
          {content.confirm && (
            <Button className="btn btn-primary btn-icon-right prs mlm" onClick={continued} data-cy="confirm">
              <span>{content.confirm}</span>
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
