/* eslint-disable jsx-a11y/label-has-associated-control */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { LuMapPin } from 'react-icons/lu';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';
import leaflet from 'leaflet';
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid';
import proj4 from 'proj4';
import { useSharedState, useSharedValue } from '../../../../states';
import customStyles from './building-select.styles';
import { surveyResource } from '../../../..';
import config from '../../../../config';
var markerIcon = leaflet.icon({ iconUrl: '/images/marker-icon.png' });
var labelizeFeature = function (feature) {
    var properties = feature.properties;
    return "".concat(properties.name, " - ").concat(properties.postcode, " ").concat(properties.city.toUpperCase());
};
var typingTimeout = null;
var bnbId = '';
var colorDefault = '#ffffff';
var colorActive = '#F4A008';
var colorHover = colorActive;
export default function BuildingSelect(_a) {
    var question = _a.question;
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var id = useSharedValue('survey:id');
    var token = useSharedValue('survey:token');
    var _c = useState(), coordinates = _c[0], setCoordinates = _c[1];
    var _d = useState(undefined), address = _d[0], setAddress = _d[1];
    var _e = useState(null), map = _e[0], setMap = _e[1];
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var alias = question.alias;
    var response = responses[alias];
    var tileStyle = function (feature) { return ({
        fillColor: feature.bnb_id === bnbId ? colorActive : 'transparent',
        color: colorDefault,
        weight: 0.1,
        fill: true,
        fillOpacity: 1,
    }); };
    var handleSelect = function (option) {
        if (!option) {
            setCoordinates(null);
            return;
        }
        // Leaflet has coordinates inversed (compared to google maps or gouv API)
        setCoordinates([option.value[1], option.value[0]]);
    };
    var handleSelectBuilding = function (value) {
        bnbId = value;
        setResponses(function (r) {
            var _a;
            return (__assign(__assign({}, r), (_a = {}, _a[alias] = value, _a)));
        });
    };
    var loadFeatures = function (inputValue, callback) {
        setCoordinates(null);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        typingTimeout = setTimeout(function () {
            if (inputValue.length < 3) {
                return Promise.resolve(null);
            }
            return axios
                .get(config.autocomplete_address_url, {
                params: {
                    q: inputValue,
                    autocomplete: 1,
                    // type: 'housenumber',
                    limit: 15,
                },
            })
                .then(function (_a) {
                var data = _a.data;
                callback(data.features.map(function (feature) { return ({
                    label: labelizeFeature(feature),
                    value: feature.geometry.coordinates,
                }); }));
            });
        }, 800);
        return typingTimeout;
    };
    useEffect(function () {
        var onComponentDidUnmount = function () {
            bnbId = '';
        };
        if (!id) {
            setLoading(false);
            bnbId = response;
            return onComponentDidUnmount;
        }
        setLoading(true);
        surveyResource.read(id, { security_token: token }).then(function (survey) {
            bnbId = survey.extra.bnb;
            var coord2154 = survey.extra.coordinates;
            // Coordinates inferior to 50 & 2 are only valid on EPSG:4326 (WGS84)
            // In Lambert-93 format, combination under 50 & 2 is not located in France
            if ((coord2154 === null || coord2154 === void 0 ? void 0 : coord2154[0]) > 50 && (coord2154 === null || coord2154 === void 0 ? void 0 : coord2154[1]) > 2) {
                coord2154 = proj4('EPSG:2154').inverse(coord2154);
            }
            setCoordinates(coord2154 === null || coord2154 === void 0 ? void 0 : coord2154.reverse());
            setAddress(survey.extra.address);
            setLoading(false);
        });
        return onComponentDidUnmount;
    }, []);
    useEffect(function () {
        if (map) {
            var L_1 = leaflet; // eslint-disable-line
            var tiles_1 = L_1.vectorGrid
                .protobuf(config.gorenove_tiles_url, {
                maxNativeZoom: 14,
                interactive: true,
                vectorTileLayerStyles: {
                    sql_statement: tileStyle,
                },
                getFeatureId: function (feature) { return feature.properties.bnb_id; },
            })
                .addTo(map)
                .on('mouseover', function (_a) {
                var layer = _a.layer;
                tiles_1.setFeatureStyle(layer.properties.bnb_id, __assign(__assign({}, tileStyle(layer.properties)), { fillColor: colorHover, fillOpacity: 0.5 }));
            })
                .on('mouseout', function (_a) {
                var layer = _a.layer;
                tiles_1.setFeatureStyle(layer.properties.bnb_id, tileStyle(layer.properties));
            })
                .on('click', function (_a) {
                var layer = _a.layer;
                tiles_1.setFeatureStyle(bnbId, tileStyle(layer.properties));
                bnbId = '';
                handleSelectBuilding(layer.properties.bnb_id);
                tiles_1.setFeatureStyle(layer.properties.bnb_id, tileStyle(layer.properties));
            });
        }
    }, [map]);
    if (loading) {
        return (React.createElement("div", { className: "diagnosticQuestionText" },
            React.createElement("label", { className: "diagnosticQuestionTextLabel", htmlFor: alias, 
                // eslint-disable-next-line
                dangerouslySetInnerHTML: {
                    __html: question.labelInput ? question.labelInput : question.label,
                } }),
            React.createElement("i", null, "Chargement...")));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "diagnosticQuestionText mb-5" },
            React.createElement("label", { className: "diagnosticQuestionTextLabel", htmlFor: alias, 
                // eslint-disable-next-line
                dangerouslySetInnerHTML: {
                    __html: question.labelInput ? question.labelInput : question.label,
                } }),
            React.createElement(AsyncSelect, { id: alias, styles: customStyles, loadOptions: loadFeatures, onChange: handleSelect, loadingMessage: function () { return 'Récupération des adresses en cours..'; }, defaultInputValue: address }),
            React.createElement("div", { className: "diagnosticQuestionTextIcon" },
                React.createElement(LuMapPin, { size: 24 }))),
        coordinates && (React.createElement("div", { className: "mt-5" },
            React.createElement("h4", { className: "mb-4" },
                React.createElement("strong", null, "Aidez-nous \u00E0 localiser votre logement en cliquant sur le plan :")),
            React.createElement(MapContainer
            // rotate={false}
            , { 
                // rotate={false}
                center: coordinates, 
                // maxNativeZoom={19}
                zoom: 18, minZoom: 14, maxZoom: 21, scrollWheelZoom: false, style: { height: 400, zIndex: 0 }, ref: function (map) { return setMap(map); }, whenReady: function () { return window.scrollTo(0, document.body.scrollHeight); } },
                React.createElement(TileLayer, { url: config.map_tiles_url, maxZoom: 22, detectRetina: true }),
                React.createElement(Marker, { position: coordinates, icon: markerIcon })))),
        React.createElement("div", { className: "mt-5" },
            React.createElement("small", null,
                React.createElement("b", null, "Cette information ne sera pas conserv\u00E9e, elle nous permet de pr\u00E9-remplir les questions qui suivent, et ainsi de vous faire gagner du temps.")))));
}
