import React, { useState } from 'react';

import { useFormikContext, useField } from 'formik';
import FormError from 'components/form/error';

import Tooltip from 'components/tooltip/Tooltip';
import { MdInfo } from 'react-icons/md';
import type { CommonProps } from '.';

interface Props extends CommonProps {
  inline?: boolean;
  rounded?: boolean;
  tooltip?: string;
}

export default function CheckboxInput({ name, label, inline = false, rounded = false, tooltip = '' }: Props): React.ReactElement {
  const [id] = useState(`${name}-${String(Math.floor(Math.random() * 10000))}`);
  const { handleChange, handleBlur, values } = useFormikContext();
  const [, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <>
      <div className={`customCheckbox ${inline ? 'inline' : ''} ${rounded ? 'rounded' : ''} ${hasError ? 'withError' : ''}`}>
        <input
          id={id}
          name={name}
          data-cy={name}
          type="checkbox"
          onChange={handleChange}
          onBlur={handleBlur}
          // eslint-disable-next-line
          // @ts-ignore
          value={values[name] || ''}
          checked={(values as Record<string, boolean>)[name]}
        />
        <label className={`customCheckboxLabel ${tooltip ? 'with-tooltip' : ''} `} htmlFor={id} data-cy={name}>
          {label}
          {tooltip && (
            <Tooltip showTooltip={!!tooltip} text={tooltip} placement="bottom">
              <sup>
                <MdInfo size={20} color="#A0A0A0" />
              </sup>
            </Tooltip>
          )}
        </label>
      </div>
      <FormError name={name} />
    </>
  );
}
