import React from 'react';
import { useSecurityLogin } from 'states';
import { useLocation, useNavigate } from 'react-router-dom';
import arrow from 'assets/images/arrow-top.png';
import Step from './Step';

export type StepsProps = {
  currentStep: number;
};

export const Steps = ({ currentStep }: StepsProps): React.ReactElement => {
  const setShowLogin = useSecurityLogin()[1];
  const navigate = useNavigate();
  const location = useLocation();

  const handleConnexion = () => {
    setShowLogin(true, () => {
      navigate((location.state as any)?.from || '/account'); // eslint-disable-line
    });
  };

  return (
    <>
      <div className="registerFormStep">
        <Step itemNumber={1} label="Profil" currentStep={currentStep} />
        <Step itemNumber={2} label="Mot de passe" currentStep={currentStep} />
        <Step itemNumber={3} label="Confirmation" currentStep={currentStep} />
      </div>

      {currentStep === 1 && (
        <div className="registerFormHeaderLogin">
          <div>
            Vous avez déja un compte ?
            <br />
            <button type="button" className="registerFormHeaderLoginLink" onClick={handleConnexion}>
              Connectez-vous
            </button>
          </div>
          <div className="registerFormHeaderLoginArrow">
            <img src={arrow} alt="Flèche vers le haut" width="40" />
          </div>
        </div>
      )}
    </>
  );
};

export default Steps;
