export default {
    cms_url: '',
    autocomplete_address_url: 'https://api.gorenove.fr/v2/efficacity/addresses/search',
    gorenove_tiles_url: 'https://api.gorenove.fr/v2/efficacity/tiles/public/buildings/{z}/{x}/{y}.pbf',
    map_tiles_url: 'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=Bh03oshrybZeiEKNi1Iu',
    reliability_indicator_min: 20,
    set: function (key, value) {
        // eslint-disable-next-line
        this[key] = value;
        return this;
    },
};
