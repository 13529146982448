import React, { useEffect } from 'react';
import { useSharedState } from 'states';
import Login from '../../pages/security/login';

export default function AccountLogin(): React.ReactElement {
  const [{ show }, setContext] = useSharedState('security:login');
  const [user] = useSharedState('security:user');
  const handleClose = () => setContext({ show: false });

  useEffect(() => {
    const onComponentDidUnmount = () => document.body.classList.remove('no-scroll');

    if (show) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return onComponentDidUnmount;
  }, [show, user]);

  return (
    show && (
      <>
        <div className="accountLogin">
          <button type="button" className="accountLoginOverlay" aria-label="Fermer" onClick={handleClose} />
          <div className="accountLoginCard">
            <div>
              <button type="button" className="accountLoginClose" aria-label="Fermer" onClick={handleClose}>
                <i className="icon-close" />
              </button>
              <Login />
            </div>
          </div>
        </div>
      </>
    )
  );
}
