import React, { useState, useCallback } from 'react';

import { surveyResource, customerResource } from 'core';
import { useSharedState } from 'states';

import type { Customer } from 'core';

type Props = {
  id: number | string;
  callback?: () => void;
};

export default function ReplacingSurvey({ id, callback }: Props): React.ReactElement {
  const [replacingSurvey, setReplacingSurvey] = useState(false);
  const [user, setUser] = useSharedState('security:user');

  const handleReplaceExistantSurvey = useCallback(() => {
    const userSurvey = user.survey;
    const userSurveyId = typeof userSurvey === 'string' ? userSurvey : surveyResource.uriToId(userSurvey['@id']);

    setReplacingSurvey(true);

    surveyResource.delete(userSurveyId).then(() => {
      customerResource.update({ id: user.id, survey: surveyResource.uri(id) }).then((customer: Customer) => {
        setUser(customer);
        callback?.();
      });
    });
  }, [user]);

  return (
    <div className="alert alert-danger text-center">
      Le diagnostic ne peut pas être relié à votre compte car vous possédez déjà un diagnostic.
      <br />
      <button type="button" className="btn btn-danger btn-xs mt-3" onClick={handleReplaceExistantSurvey} disabled={replacingSurvey}>
        {replacingSurvey ? 'Opération en cours...' : 'Remplacer mon diagnostic existant par celui-ci'}
      </button>
    </div>
  );
}
