import React from 'react';
import FileUpload from '../../fileUpload/fileUpload';

interface UploaderProps {
  isUploadAllowed: boolean;
  onDownload: () => void;
  name: string;
  onUpload?: (file: File) => Promise<void>;
  isLoading?: boolean;
}

const Uploader: React.FC<UploaderProps> = ({ isUploadAllowed, onDownload, name, onUpload, isLoading }) => (
  <div className="information-travaux__content__devis__uploads">
    <FileUpload name={name} allowUpload={isUploadAllowed} onDownload={onDownload} onUpload={onUpload} isLoading={isLoading} />
  </div>
);

export default Uploader;
