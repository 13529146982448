import React from 'react';
import ContentLoader from 'react-content-loader';

export const ListLoader = () => (
  <ContentLoader viewBox="0 0 380 70" backgroundColor="#dadfe1">
    {/* Only SVG shapes */}
    <rect x="0" y="0" rx="5" ry="5" width="70" height="70" />
    <rect x="80" y="5" rx="4" ry="4" width="25%" height="10" />
    <rect x="80" y="20" rx="3" ry="3" width="35%" height="10" />
    <rect x="80" y="50" rx="3" ry="3" width="25%" height="10" />

    <rect x="75%" y="0" rx="5" ry="5" width="25%" height="40" />
    <rect x="75%" y="50" rx="5" ry="5" width="25%" height="20" />
  </ContentLoader>
);
