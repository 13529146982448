var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from 'react';
import { calcGroupProgression, isDisplay, transformResponsesForState } from '../../../utils/survey';
import { resolveInput } from '../inputs';
import Label from '../label';
import Progression from '../../progression';
import ScrollIndicator from '../scroll-indicator';
import PreFilledLabel from '../prefilled-label';
import questionResource from '../../../resources/question';
import surveyResource from '../../../resources/survey';
import LoaderSimple from '../loader/loader-simple';
import { useSetSharedState, useSharedState } from '../../../states';
function SurveyReadOnly(_a) {
    var _b;
    var id = _a.id;
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useSharedState('survey:responses'), responses = _d[0], setResponses = _d[1];
    var _e = useSharedState('survey:questions'), questions = _e[0], setQuestions = _e[1];
    var _f = useSharedState('survey:id'), setId = _f[1];
    var _g = useSharedState('survey:current'), current = _g[0], setCurrent = _g[1];
    var setHasHouseShape = useSetSharedState('survey:has_house_shape');
    var _h = useSharedState('survey:bnb'), setBnbId = _h[1];
    var groups = [];
    var question = questions[current];
    var handleBack = function () {
        var nextIndex = current - 1;
        while (questions[nextIndex] && !isDisplay(questions[nextIndex], responses)) {
            nextIndex -= 1;
        }
        setCurrent(nextIndex);
        window.scrollTo(0, 0);
    };
    var handleContinue = useCallback(function () {
        var nextIndex = current + 1;
        while (questions[nextIndex] && !isDisplay(questions[nextIndex], responses)) {
            nextIndex += 1;
        }
        if (questions[nextIndex]) {
            setCurrent(nextIndex);
        }
        window.scrollTo(0, 0);
    }, [question]);
    var isPreFilled = useCallback(function (alias) {
        for (var i = 0, len = questions.length; i < len; i += 1) {
            if (questions[i].alias === alias) {
                for (var j = 0, l = responses.length; j < l; j += 1) {
                    if (responses[j].question === questions[i]['@id']) {
                        return responses[j].preFilled;
                    }
                }
            }
        }
        return false;
    }, [questions, responses]);
    useEffect(function () {
        setId(id);
        questionResource.all().then(function (collection) {
            var _a;
            if (((_a = collection[0]) === null || _a === void 0 ? void 0 : _a.alias) === 'start_page') {
                collection.shift();
            }
            setQuestions(collection);
            surveyResource.read(id).then(function (item) {
                var _a;
                var surveyResponses = transformResponsesForState(collection, item.responses);
                setCurrent(0);
                setBnbId(surveyResponses[questionResource.ALIAS_QUESTION_ADDRESS]);
                setResponses(surveyResponses);
                setHasHouseShape(((_a = item.extra) === null || _a === void 0 ? void 0 : _a.has_house_shape) || false);
                setLoading(false);
            });
        });
    }, []);
    if (loading) {
        return React.createElement(LoaderSimple, null);
    }
    var Input = resolveInput(question);
    for (var i = 0, len = questions.length; i < len; i += 1) {
        if (i !== 0 && questions[i].group && !groups[questions[i].group.id]) {
            groups[questions[i].group.id] = __assign(__assign({}, questions[i].group), { progression: calcGroupProgression(questions[i].group, questions, responses, current), active: String((_b = question.group) === null || _b === void 0 ? void 0 : _b.id) === questions[i].group.id });
        }
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "diagnostic" },
            React.createElement(Progression, { isInProgress: current > 0, groups: groups, back: handleBack }),
            React.createElement("div", { className: "diagnosticQuestion ".concat(question.alias === 'number_of_windows_per_wall' ? 'coordinate' : '') },
                React.createElement(Label, { question: question }),
                isPreFilled(question.alias) && React.createElement(PreFilledLabel, null),
                React.createElement("div", { style: { pointerEvents: 'none', zIndex: 1000 } },
                    React.createElement(Input, { question: question, onChange: function () { return null; } })),
                React.createElement("div", { className: "diagnosticFooter" },
                    React.createElement("button", { type: "button", className: "diagnosticFooterSave" },
                        React.createElement("span", { className: "diagnosticFooterSaveLabel" }, "Mode consultation")),
                    React.createElement("div", { className: "diagnosticFooterActions" },
                        current > 0 && (React.createElement("button", { type: "button", className: "btn btn-secondary btn-icon-left", onClick: handleBack },
                            React.createElement("span", null,
                                React.createElement("i", { className: "icon-prev" }),
                                "Pr\u00E9c\u00E9dent"))),
                        React.createElement("button", { type: "button", className: "btn btn-primary btn-icon-right desktop", onClick: function () { return handleContinue(); } },
                            React.createElement("span", null,
                                "Continuer\u00A0",
                                React.createElement("i", { className: "icon-next" }))))))),
        React.createElement(ScrollIndicator, null)));
}
export default SurveyReadOnly;
