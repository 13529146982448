import React from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

type IconStateProps = {
  isAnswerd: boolean | undefined;
};

const IconState: React.FC<IconStateProps> = ({ isAnswerd }) => {
  const iconSize = 25;
  const answeredColor = '#23C275';
  const notAnsweredColor = '#FFC107';
  const sharedStyle = { background: '#ffffff', borderRadius: '50%' };

  return <>{isAnswerd ? <FaCheckCircle style={sharedStyle} size={iconSize} color={answeredColor} /> : <FaExclamationCircle style={sharedStyle} size={iconSize} color={notAnsweredColor} />}</>;
};

export default IconState;
