import React from 'react';
import './demandeDetails.scss';
import Header from 'features/dashboard/components/header/header';
import List from 'features/dashboard/components/simulations/list/list';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { useNavigate, useParams } from 'react-router';
import { ListLoader } from 'features/dashboard/components/contentLoader/list.skeleton';
import NotFound from 'pages/not-found';
import Breadcrumbs from 'features/dashboard/components/breadcrumbs';
import { trackClickDemandPrime } from 'data-layer';

const DemandeDetails: React.FC = () => {
  const { simulationId } = useParams();
  const navigate = useNavigate();

  if (!simulationId) {
    return null;
  }

  const { simulation, isLoading } = useSimulationResultat(simulationId);
  const { actions, hasAat } = simulation || {};
  const { isRemplassableCarRevision, isRemplassableCarDesactivation } = actions || {};
  const isRemplassable = isRemplassableCarDesactivation || isRemplassableCarRevision;

  if (isLoading) {
    return <ListLoader />;
  }

  if (!simulation) {
    return <NotFound />;
  }

  const handleDemandePrime = () => {
    // Target tracking event for demandes
    trackClickDemandPrime({
      buttonId: 'request-prime-detail',
      buttonName: 'Demander cette prime',
      housing: simulation.logement?.typeLogement,
      exactWork: simulation.travaux?.operation,
      amount: simulation.amount?.toString(),
    });

    if (isRemplassable) {
      navigate('/simulation-prime', {
        state: { simulationId, update: true },
      });
      return;
    }

    if (hasAat) {
      navigate(`/dashboard/constitution-dossier/${simulationId}`);
      return;
    }

    navigate(`/simulation-prime/informations-AAT/${simulationId}`);
  };

  return (
    <div className="demande__details">
      <Header />
      <Breadcrumbs onBack={() => navigate('/dashboard')} />
      <div className="demande__details__content">
        <h2 className="demande__details__content__title">{simulation.travaux.operation}</h2>
        <List simulation={simulation} isDetail onClick={handleDemandePrime} />
      </div>
    </div>
  );
};

export default DemandeDetails;
