import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSharedValue } from 'states';
import type { PropsWithChildren, ReactElement } from 'react';
import security from '@concerto-home/security';

export default function PrivateRouteGuard({ children }: PropsWithChildren<unknown>): ReactElement | null {
  const [loading, setLoading] = useState(true);
  const user = useSharedValue('security:user');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!user || !security.user) {
      security.deAuthenticate();
      navigate('/login', {
        state: {
          from: location.pathname + location.search,
        },
      });
    } else {
      setLoading(false);
    }
  }, [user]);

  return loading ? null : <>{children}</>;
}
