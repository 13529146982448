import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Row, Col } from 'react-bootstrap';
import { TextInput } from 'components/form/inputs';
import { HiOutlineCheckCircle } from 'react-icons/hi';

import { buildCustomerDataValidationSchema as validationSchema, customerResource } from 'core';
import { useSharedState } from 'states';
import { checkApiFormViolations } from 'utils/form';

import type { AxiosError } from 'axios';
import type { FormikHelpers } from 'formik';
import type { Customer } from 'core';
import FormikListener from './listener';

export default function PersonalDataForm(): React.ReactElement {
  const [initialValues, setInitialValues] = useState<Customer | null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [user, setUser] = useSharedState('security:user');

  const handleFormChange = () => {
    setSuccess(null);
    setError(null);
  };

  const onSubmit = (item: Customer, formik: FormikHelpers<Customer>) => {
    setError(null);
    setSuccess(null);
    setSubmitting(true);

    customerResource
      .update({ ...item, survey: undefined })
      .finally(() => setSubmitting(false))
      .then((u: Customer) => {
        setUser(u);
        setSuccess('Les modifications ont été sauvegardées');
      })
      .catch((e: AxiosError) => {
        checkApiFormViolations(e, formik).else(() => {
          setError('Une erreur est survenue, veuillez réessayer.');
        });
      });
  };

  useEffect(() => {
    customerResource
      .read(String(user.id))
      .then((u: Customer) => setInitialValues(u))
      .catch(() => setError('Une erreur est survenue, veuillez réessayer.'))
      .finally(() => setLoading(false));
  }, []);

  const ErrorMessage = (): React.ReactElement => (error ? <div className="alert alert-danger">{error}</div> : <span />);
  const SuccessMessage = (): React.ReactElement => (success ? <div className="alert alert-success">{success}</div> : <span />);

  if (loading || !initialValues) {
    return <ErrorMessage />;
  }

  return (
    <>
      <ErrorMessage />
      <SuccessMessage />
      <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
        <Form>
          <FormikListener onChange={handleFormChange} />
          <Row>
            <Col sm={6}>
              <TextInput name="lastName" type="text" label="Nom" disabled={submitting} />
            </Col>
            <Col sm={6}>
              <TextInput name="firstName" type="text" label="Prénom" disabled={submitting} />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextInput name="phone" type="tel" label="Numéro de téléphone" disabled={submitting} />
            </Col>
          </Row>
          <button type="submit" className="btn btn-primary btn-icon-left" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="icon-loading" /> Envoi en cours...
              </span>
            ) : (
              <span>
                <HiOutlineCheckCircle size={20} className="m-1" /> Sauvegarder les modifications
              </span>
            )}
          </button>
        </Form>
      </Formik>
    </>
  );
}
