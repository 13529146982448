import React, { FC, useEffect, useState } from 'react';
import { FormikValues, useFormikContext } from 'formik';
import { useParams } from 'react-router';
import NotFound from 'pages/not-found';
import { DocumentData } from 'features/dashboard/types/document';
import useDocQuestions from 'features/dashboard/hooks/useDocQuestions';
import formModel from 'features/dashboard/models/formModel';
import useDocuments from 'features/dashboard/hooks/useDocuments';
import useDocumentUpload from 'features/dashboard/hooks/useDocumentUpload';

import useCoherenceDates from 'features/dashboard/hooks/useCoherenceDates';
import QAFileUploaderPanel from '../QAFileUploaderPanel/QAFileUploaderPanel';

import DocumentsDemandes from './documentsDemandes';
import AttestationHonneur from './attestationHonneur';
import Button from '../button/button';
import ValidateMandataire from './validateMandataire';
import lang from '../../lang/projets.json';

interface FinalisationDossierProps {
  refresh: () => void;
}

const FinalisationDossier: FC<FinalisationDossierProps> = ({ refresh: refreshSimulation }) => {
  const [showPopup, setShowPopup] = useState(false);
  const { simulationId } = useParams();
  const { formField } = formModel;
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const [file, setFile] = useState<File | null>(null);

  const { isMandataireUnique, dateFacture } = values;

  if (!simulationId) {
    return <NotFound />;
  }

  const { facture: factureField, rib: ribField, ah: ahField, dateFacture: dateFactureField } = formField;

  const { docQuestions } = useDocQuestions(simulationId);
  const { facture: factureQuestions } = docQuestions || {};
  const { documents, refresh } = useDocuments({ simulationId });

  const isAllDocumentsUploaded = documents?.every((doc) => doc.idFile !== null);

  const retrievedDocuments = {
    facture: documents?.find((doc) => doc.type === 'facture'),
    rib: documents?.find((doc) => doc.type === 'rib'),
    ah: documents?.find((doc) => doc.type === 'ah'),
    avisImposition: documents?.filter((doc) => doc.type === 'avis_imposition'),
  };

  const setDocumentField = (field: (typeof formField)[keyof typeof formField], document: DocumentData | undefined) => {
    if (document) {
      setFieldValue(field.name, {
        name: document.fileName,
        idFile: document.idFile,
      });
    }
  };

  const { erreursCoherence, loading } = useCoherenceDates(dateFacture, simulationId);
  const { handleUploadDocument, isLoading } = useDocumentUpload();

  const invalidDateFacture = (!loading && erreursCoherence?.find((erreur) => erreur.alias === 'facture_aat')) || null;

  useEffect(() => {
    setDocumentField(factureField, retrievedDocuments.facture);
    setDocumentField(ribField, retrievedDocuments.rib);
    setDocumentField(ahField, retrievedDocuments.ah);

    if (retrievedDocuments.avisImposition) {
      retrievedDocuments.avisImposition.forEach((doc, index) => {
        setFieldValue(`avis_imposition-${index}`, {
          name: doc.fileName,
          idFile: doc.idFile,
        });
      });
    }
  }, [documents]);

  const handleUpload = async (fileUploaded: File) => {
    if (invalidDateFacture?.messageErreur) {
      return;
    }
    setFile(fileUploaded);
  };

  useEffect(() => {
    const isValidDate = !loading && !invalidDateFacture?.messageErreur;

    if (file && isValidDate) {
      handleUploadDocument(file, { simulationId, fieldName: factureField.name, dateFacture, refresh });
    }
  }, [invalidDateFacture, loading, dateFacture, file]);

  const handleCloseModal = () => setShowPopup(false);
  const handleOpenModal = () => setShowPopup(true);

  return (
    <div className="information-travaux">
      {factureQuestions && <QAFileUploaderPanel isLoading={isLoading} step={2} label={lang.yourInvoice} fieldName={factureField.name} documentQuestions={factureQuestions} simulationId={simulationId} onUpload={handleUpload} dateLabel={dateFactureField.label} dateFieldName={dateFactureField.name} DateFieldError={invalidDateFacture?.messageErreur} />}
      <DocumentsDemandes documents={documents || []} refresh={refresh} />
      <AttestationHonneur fieldName={ahField.name} refresh={refresh} />
      <div className="realisation-travaux__actions">
        <Button id="send-documents" type="button" isDisabled={!isAllDocumentsUploaded} variant="primary" label={lang.sendMydocuments} onClick={handleOpenModal} />
      </div>
      <ValidateMandataire isValidateMandataire={isMandataireUnique} show={showPopup} close={handleCloseModal} onRefresh={refreshSimulation} />
    </div>
  );
};

export default FinalisationDossier;
