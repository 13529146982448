import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string, ref } from 'yup';
import { Row, Col } from 'react-bootstrap';
import { TextInput } from 'components/form/inputs';
import { api, labels, customerResource } from 'core';
import { useSharedState } from 'states';
import { checkApiFormViolations } from 'utils/form';
import PasswordIndicator from 'components/account/password-indicator';

import type { AxiosError } from 'axios';
import type { FormikHelpers } from 'formik';
import type { User } from 'core';
import FormikListener from './listener';

type PasswordUpdate = {
  email: string;
  password: string;
  plainPassword: string;
  repeatedPassword: string;
};

const validationSchema = object({
  email: string().required().default(''),
  password: string().required().default(''),
  plainPassword: string().required().default('').min(12).matches(/[a-z]/g).matches(/[A-Z]/g).matches(/[0-9]/g).matches(/\W|_/g),
  repeatedPassword: string()
    .required()
    .default('')
    .oneOf([ref('plainPassword')], labels.validation.common.passwords_must_match),
});

const initialValues: PasswordUpdate = validationSchema.getDefault();

export default function PersonalInfosForm(): React.ReactElement {
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [user, setUser] = useSharedState('security:user');
  const [submitting, setSubmitting] = useState(false);

  const handleFormChange = () => {
    setSuccess(null);
    setError(null);
  };

  const onSubmit = ({ password, plainPassword }: PasswordUpdate, formik: FormikHelpers<PasswordUpdate>) => {
    setError(null);
    setSuccess(null);
    setSubmitting(true);

    api
      .login({ email: user.email, password })
      .then(() => {
        customerResource
          .update({ id: user.id, plainPassword })
          .then((u: User) => {
            setUser(u);

            api
              .login({ email: user.email, password: plainPassword })
              .then(() => {
                formik.resetForm();
                setSubmitting(false);
                setSuccess('Les modifications ont été sauvegardées');
                window.scrollTo(0, 0);
              })
              .catch(() => {
                setError('Une erreur est survenue, veuillez réessayer.');
                setSubmitting(false);
              });
          })
          .catch((e: AxiosError) => {
            checkApiFormViolations(e, formik).else(() => {
              setError('Une erreur est survenue, veuillez réessayer.');
            });

            setSubmitting(false);
          });
      })
      .catch((e) => {
        if (e.response?.status === 401) {
          formik.setFieldError('password', 'Mot de passe invalide');
        } else {
          setError('Une erreur est survenue, veuillez réessayer.');
        }

        setSubmitting(false);
      });
  };

  return (
    <>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <Formik onSubmit={onSubmit} initialValues={{ ...initialValues, email: String(user.email) }} validationSchema={validationSchema}>
        <Form>
          <FormikListener onChange={handleFormChange} />
          <Row>
            <Col sm={6}>
              <TextInput name="email" type="email" label="Adresse email" disabled />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextInput name="password" type="password" label="Mot de passe actuel" icon="icon-eye" disabled={submitting} autocomplete="current-password" />
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <TextInput name="plainPassword" type="password" label="Nouveau mot de passe" icon="icon-eye" disabled={submitting} autocomplete="new-password" />
            </Col>
            <Col sm={6}>
              <TextInput name="repeatedPassword" type="password" label="Confirmation nouveau mot de passe" icon="icon-eye" disabled={submitting} autocomplete="new-password" />
            </Col>
          </Row>

          <PasswordIndicator property="plainPassword" />

          <button type="submit" className="btn btn-primary btn-icon-left" disabled={submitting}>
            {submitting ? (
              <span>
                <i className="icon-loading" /> Envoi en cours...
              </span>
            ) : (
              <span>
                <i className="icon-validate" /> Sauvegarder les modifications
              </span>
            )}
          </button>
        </Form>
      </Formik>
    </>
  );
}
