import { ConfettiConfig } from 'react-dom-confetti';

// Configuration for confetti
export const confettiConfig: ConfettiConfig = {
  angle: 90,
  spread: 360,
  startVelocity: 100,
  elementCount: 200,
  dragFriction: 0.07,
  duration: 4860,
  stagger: 8,
  width: '20px',
  height: '40px',
  colors: ['#f00', '#0f0', '#00f'],
};
