import { DocumentData } from '../types/document';

export function mapAPIDocuments(documents: DocumentData[]): DocumentData[] {
  return documents.map((document, index) => ({
    id: `${document.id}-${index}`,
    idFile: document.idFile,
    fileName: document.fileName,
    type: document.type,
    foyerPrecarite: document?.foyerPrecarite,
  }));
}
