import { Professionnel, ProfessionnelAPI } from '../types/professionnel';

export function mapAPIRechercheProfessionnel(professionnelsAPI: ProfessionnelAPI[]): Professionnel[] {
  const mappedProfessionnels: Professionnel[] = professionnelsAPI.map((professionnelAPI) => ({
    siret: professionnelAPI.siret,
    raisonSociale: professionnelAPI.raisonSociale,
    address: {
      street: professionnelAPI.adresse.rue,
      complement: professionnelAPI.adresse.complement,
      postalCode: professionnelAPI.adresse.code_postal,
      city: professionnelAPI.adresse.ville,
    },
    email: professionnelAPI.email,
    rge: professionnelAPI.rge,
  }));

  return mappedProfessionnels;
}
