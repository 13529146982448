import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AccountMenuLeft, AccountMenuAccordion } from 'components/account/managment-menu';

export default function AccountManagementLayout(): React.ReactElement {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const setDimension = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', setDimension);
    return () => {
      window.removeEventListener('resize', setDimension);
    };
  }, []);

  return windowWidth > 767 ? (
    <div className="accountInfos">
      <AccountMenuLeft />
      <div className="accountInfosContent">
        <Outlet />
      </div>
    </div>
  ) : (
    <div className="accountInfos">
      <AccountMenuAccordion>
        <Outlet />
      </AccountMenuAccordion>
    </div>
  );
}
