import { atom } from 'recoil';
import type { User } from 'core';
import { useSharedState } from 'states';

export const securityUser = atom<User | null>({
  key: 'security:user',
  default: null,
});

type State = { [key: string]: any }; // eslint-disable-line
type SecurityLoginContext = {
  show: boolean;
  callback?: () => void;
  state?: State;
};

export const securityLogin = atom<SecurityLoginContext>({
  key: 'security:login',
  default: {
    show: false,
  },
});

// eslint-disable-next-line
export const useSecurityLogin: any = () => {
  const [value, setValue] = useSharedState('security:login');

  return [
    value.show,
    (show: boolean, callback?: () => void, state?: State) => {
      setValue({ show, callback, state });
    },
  ];
};
