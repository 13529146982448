import React from 'react';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

import PrimeDetails from './prime/primeDetails';

import Note from './content/note';
import ItemContent from './content/contentItem';

import lang from '../../../lang/dashboard.json';

interface ListProps {
  simulation: Resultat;
  isDetail?: boolean;
  isClosed?: boolean;
  onClick: () => void;
}

const List: React.FC<ListProps> = ({ simulation, isDetail, isClosed, onClick }) => (
  <div className={`dashboard__demandes__list__item ${isClosed ? 'closed' : ''}`}>
    {isDetail && <div className="dashboard__demandes__list__item__content__infos--actions--condition--date--texte--detail">Créée le {simulation.dateCreationSimulation}</div>}
    <div className="dashboard__demandes__list__item--wrapper ">
      <div className="dashboard__demandes__list__item__image">
        <img src={simulation.travaux.picto} alt="demande" />
      </div>

      <ItemContent isDetail={isDetail} simulation={simulation} />
      <PrimeDetails simulation={simulation} isClosed={isClosed} isDetail={isDetail} onClick={onClick} />
    </div>
    {isDetail && <Note note={lang.dashboard.note} />}
  </div>
);

export default List;
