import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { LoaderSimple } from '@concerto-home/core';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import { getFullAdress } from 'features/dashboard/helpers/utils';
import { trackClickCurrentProject } from 'data-layer';
import { countInProgressSimulationsSelector } from 'features/dashboard/hooks/dashboard';
import { useRecoilValue } from 'recoil';

interface SubNavigationItemProps {
  simulation: Resultat;
  isLoading: boolean;
}

const SubNavigationItem: React.FC<SubNavigationItemProps> = ({ simulation, isLoading }) => {
  const { simulationId } = useParams();

  // used in tracking analytics
  const countInProgressSimulations = useRecoilValue(countInProgressSimulationsSelector);

  if (isLoading) {
    return <LoaderSimple />;
  }

  // Target Tracking - Select Project
  const handleClickProject = () => trackClickCurrentProject(simulation as Resultat, countInProgressSimulations);

  return (
    <NavLink key={simulation.id} onClick={handleClickProject} to={`/dashboard/constitution-dossier/${simulation.id}`} data-cy={`sub-navigation-${simulation.id}`} className={`navigation-item__sub-navigation__item ${simulationId === simulation.id ? 'navigation-item__sub-navigation__item--active' : ''}`}>
      {`${simulation.travaux.operation} : ${getFullAdress(simulation.logement.address)}`}
    </NavLink>
  );
};

export default SubNavigationItem;
