/* eslint-disable jsx-a11y/label-has-associated-control */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useRef } from 'react';
import { useSharedState } from '../../../states';
function getInputType(question) {
    switch (question.type) {
        case 'integer':
        case 'float': {
            return 'number';
        }
        default: {
            return 'text';
        }
    }
}
function parseValue(question, value) {
    var val = value;
    if (['integer', 'float'].includes(question.type)) {
        val = value.replace(/[a-zA-Z]/g, '');
    }
    switch (question.type) {
        case 'integer': {
            if (!/^\d+$/.test(val)) {
                return null;
            }
            val = parseInt(val, 10);
            return Number.isNaN(val) ? null : val;
        }
        case 'float':
            val = parseFloat(val.replace(',', '.'));
            return Number.isNaN(val) ? null : val;
        default:
            return val;
    }
}
function stringifyValue(question, value) {
    switch (question.type) {
        case 'float':
        case 'integer': {
            return typeof value === 'number' && !Number.isNaN(value) ? String(value) : '';
        }
        default:
            return typeof value === 'string' ? String(value) : '';
    }
}
export default function TextInput(_a) {
    var _b, _c;
    var question = _a.question, _d = _a.autofocus, autofocus = _d === void 0 ? true : _d;
    var _e = useSharedState('survey:responses'), responses = _e[0], setResponses = _e[1];
    var inputRef = useRef(null);
    var alias = question.alias;
    var handleKeyDown = function (e) {
        if (['integer', 'float'].includes(question.type) && ['e', '-', '+'].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
        return true;
    };
    var handleChange = function (e) {
        setResponses(function (r) {
            var _a;
            return (__assign(__assign({}, r), (_a = {}, _a[alias] = parseValue(question, e.target.value), _a)));
        });
    };
    useEffect(function () {
        var _a;
        if (autofocus) {
            (_a = inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (React.createElement("div", { className: "diagnosticQuestionText" },
        React.createElement("label", { className: "diagnosticQuestionTextLabel", htmlFor: alias, 
            // eslint-disable-next-line
            dangerouslySetInnerHTML: {
                __html: question.labelInput ? question.labelInput : question.label,
            } }),
        React.createElement("input", { className: "diagnosticQuestionTextInput ".concat(question.unit ? 'withUnit' : ''), type: getInputType(question), name: alias, id: alias, onInput: handleChange, value: stringifyValue(question, responses[alias]), onKeyDown: handleKeyDown, ref: inputRef, min: (_b = question.rules) === null || _b === void 0 ? void 0 : _b.min, max: (_c = question.rules) === null || _c === void 0 ? void 0 : _c.max }),
        question.unit && React.createElement("div", { className: "diagnosticQuestionTextUnit" }, question.unit)));
}
