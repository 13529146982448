import React, { useEffect, useRef } from 'react';
import { Answer, ChoicesChoixFormule, ModeReponseType, QuestionPrime, QuestionType } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';
import { CHOIX_FORMULE, QUESTION_CHOIX_FORMULE, QUESTION_ELIGIBILITE, QUESTION_FORMULES, VALORISATION_FORMULE } from 'utils/constants';
import { QuestionChoice } from 'core';

export function addQuestions(questions: QuestionPrime[], setQuestions: React.Dispatch<React.SetStateAction<QuestionPrime[]>>, currentQuestionIndex: number, fetchedQuestions: QuestionPrime[] | QuestionPrime): void {
  const updatedQuestions = [...questions];
  const newQuestions = Array.isArray(fetchedQuestions) ? fetchedQuestions : [fetchedQuestions];
  updatedQuestions.splice(currentQuestionIndex + 1, updatedQuestions.length, ...newQuestions);

  setQuestions(updatedQuestions);
}

// filtrer les questions par type
const filterQuestionsByType = (questions: QuestionPrime[], type: QuestionType): QuestionPrime[] =>
  questions.filter((question) => {
    switch (type) {
      case 'operande':
        return question.isTravaux;
      case 'bonification':
        return question.isBonification;
      case 'question':
        return !question.isTravaux && !question.isBonification && !question.emptyQuestion;
      default:
        return false;
    }
  });

const extractValidUserAnswers = (questions: QuestionPrime[]): Answer[] => questions.map((question) => question.userAnswer).filter((answer) => answer.value !== '' && answer.response?.length > 0);

export const getUserAnswersByType = (questions: QuestionPrime[], type: QuestionType): Answer[] => {
  const filteredQuestions = filterQuestionsByType(questions, type);
  return extractValidUserAnswers(filteredQuestions);
};

// TODO : remove this function when we will have a real backend id
// create a unique id by number and math.random
export function generatedID(): number {
  return Number(`${Math.random() * 1000000}${Date.now()}`);
}

export function useEffectOnce(effect: () => void, dependency: any[]): void {
  const hasRunRef = useRef(false);

  useEffect(() => {
    if (!hasRunRef.current) {
      effect();
      hasRunRef.current = true;
    }
  }, dependency);
}

// trouver l'index de la question suivante de bonification
export const findPreviousBonificationQuestionIndex = (currentIdx: number, questions: QuestionPrime[]) => {
  for (let i = currentIdx - 1; i >= 0; i -= 1) {
    if (questions[i].isBonification && !questions[i].skip) {
      return i;
    }
  }
  return -1;
};

// réinitialiser les questions ignorées
export function resetSkippedQuestions(bonifications: QuestionPrime[]): QuestionPrime[] {
  return bonifications.map((question) => {
    if (question.isBonification) {
      const updatedQuestion = { ...question };
      delete updatedQuestion.skip;
      return updatedQuestion;
    }
    return question;
  });
}

export const removeDuplicatesByQuestionId = (arr: QuestionPrime[]) => {
  const seen = new Set();
  return arr.filter((item) => {
    const isDuplicate = seen.has(item.questionId);
    seen.add(item.questionId);
    return !isDuplicate;
  });
};

function deleteDuplicateFormulesQuestionsByLevel(arr: QuestionPrime[]): QuestionPrime[] {
  const seenQuestions: { [key: string]: string } = {};
  const result: QuestionPrime[] = [];

  arr.forEach((item) => {
    if (item.questionId) {
      const { questionId, parcoursId } = item;

      if (!seenQuestions[questionId]) {
        seenQuestions[questionId] = parcoursId;
        result.push(item);
      } else if (seenQuestions[questionId] === parcoursId) {
        result.push(item);
      }
    } else {
      result.push(item);
    }
  });

  return result;
}

// ignorer les questions inéligibles et celles qui ont déjà été répondues
export function skipIneligibleAndAnsweredQuestions(bonifications: QuestionPrime[], selectedQuestion: QuestionPrime, selectedChoice: QuestionChoice | undefined): QuestionPrime[] {
  const levelsToSkip = new Set<number>();
  const questionIdsToSkipInOtherLevels = new Set<string>();
  const levelsWithAnySkippedQuestions = new Set<number>();

  // Marquer les niveaux et les questionIds spécifiques à ignorer
  bonifications.forEach((question) => {
    const currentLevel = +question.parcoursId.split('-')[1].replace('level', '');

    // Si la question sélectionnée n'est pas éligible, marquer le niveau actuel comme devant être ignoré.
    if (question.questionId === selectedQuestion.questionId && !selectedChoice?.eligible) {
      levelsToSkip.add(currentLevel);
      questionIdsToSkipInOtherLevels.add(question.questionId);
    }

    // Si la question a une réponse non vide, marquer ce questionId pour être ignoré dans les autres niveaux.
    if (question.response !== '') {
      questionIdsToSkipInOtherLevels.add(question.questionId);
    }
  });

  // Ignorer les questions dans les niveaux marqués et celles avec les questionIds marqués.
  bonifications.forEach((question) => {
    const currentLevel = +question.parcoursId.split('-')[1].replace('level', '');

    // Ignorer les questions dans les niveaux marqués pour être ignorés.
    if (levelsToSkip.has(currentLevel)) {
      // eslint-disable-next-line
      question.skip = true;
    }

    // Ignorer le même questionId dans les niveaux suivants s'il a été répondu ou marqué comme non éligible.
    if (questionIdsToSkipInOtherLevels.has(question.questionId)) {
      bonifications.forEach((otherQuestion) => {
        const otherLevel = +otherQuestion.parcoursId.split('-')[1].replace('level', '');
        if (otherQuestion.questionId === question.questionId && otherLevel > currentLevel) {
          // eslint-disable-next-line
          otherQuestion.skip = true;
        }
      });
    }

    // Track les niveaux avec des questions ignorées
    if (question.skip) {
      levelsWithAnySkippedQuestions.add(currentLevel);
    }
  });

  return bonifications;
}

// créer une réponse utilisateur pour une question
export const createUserAnswer = (question: QuestionPrime, newValue: string, selectedChoice: QuestionChoice | undefined) => {
  const { questionId, parcoursId, isTravaux = false, isBonification = false, formuleId } = question;

  const isChoiceType = question.type === ModeReponseType.choix_unique;

  const responseValue = isChoiceType ? selectedChoice?.id?.toString() || selectedChoice?.value?.toString() || '' : newValue;

  return {
    questionId,
    parcoursId,
    value: newValue,
    response: [responseValue],
    label: selectedChoice?.label,
    isTravaux,
    isBonification,
    ...(formuleId && { formuleId }),
  };
};

// catégoriser les questions (bonification, eligibilité, formules, etc.)
export const categorizeQuestions = (questions: QuestionPrime[]) => {
  // Initialize categories
  const categories: Record<string, QuestionPrime[]> = {
    nonBonification: [],
    bonificationEligibilty: [],
    bonificationChoicesFormule: [],
    bonificationFormules: [],
    choicesFormule: [],
    valorisationFormules: [],
  };

  const getQuestionType = (parcoursId: string): string => {
    if (parcoursId.includes(QUESTION_ELIGIBILITE)) return 'bonificationEligibilty';
    if (parcoursId.includes(QUESTION_CHOIX_FORMULE)) return 'bonificationChoicesFormule';
    if (parcoursId.includes(QUESTION_FORMULES)) return 'bonificationFormules';
    if (parcoursId.includes(CHOIX_FORMULE)) return 'choicesFormule';
    if (parcoursId.includes(VALORISATION_FORMULE)) return 'valorisationFormules';
    return 'nonBonification';
  };

  questions.forEach((question) => {
    const category = getQuestionType(question.parcoursId);
    categories[category].push(question);
  });

  return {
    nonBonificationQuestions: categories.nonBonification,
    bonificationEligibiltyQuestions: categories.bonificationEligibilty,
    bonificationChoicesFormuleQuestions: removeDuplicatesByQuestionId(categories.bonificationChoicesFormule),
    bonificationFormulesQuestions: deleteDuplicateFormulesQuestionsByLevel(categories.bonificationFormules),
    choicesFormuleQuestions: categories.choicesFormule,
    valorisationFormulesQuestions: categories.valorisationFormules,
  };
};

function getNumericFormuleId(value: number, choices: ChoicesChoixFormule[]): number | null {
  const foundChoice = choices.find((choice) => (choice.min === null || value >= choice.min) && (choice.max === null || value <= choice.max));
  return foundChoice ? foundChoice.formuleId : null;
}

function getUniqueChoiceFormuleId(value: string, choices: ChoicesChoixFormule[]): number | null {
  const foundChoice = choices.find((choice) => choice.value === value);
  return foundChoice ? foundChoice.formuleId : null;
}

// obtenir l'id de la formule en fonction de la réponse de l'utilisateur
export function getFormuleId(value: string, questionChoixFormule: QuestionPrime): number | null {
  const { choicesChoixFormule, type } = questionChoixFormule;
  if (!choicesChoixFormule) {
    return null;
  }

  switch (type) {
    case ModeReponseType.saisie_numerique:
      return getNumericFormuleId(Number(value), choicesChoixFormule);
    case ModeReponseType.choix_unique:
      return getUniqueChoiceFormuleId(value, choicesChoixFormule);
    default:
      return null;
  }
}
