import React, { useEffect } from 'react';
import { Professionnel } from 'features/dashboard/types/professionnel';
import lang from 'features/dashboard/lang/projets.json';
import { FormikValues, useFormikContext } from 'formik';
import { LoaderSimple } from 'core';
import useProfessionalSearch from 'features/dashboard/hooks/useProfessionalSearch';
import ProfessionalHeader from './professionalHeader';
import circular from '../../../assets/images/circular.png';
import ProfessionalBody from './professionalBody';
import Button from '../../button/button';
import { FormInitialValues } from '../validation/formInitialValues';

interface MyProfessionnelProps {
  professional: Professionnel;
  onShowSearchProForm: () => void;
  onShowSubSousTraitanceForm: () => void;
  subSousTraitantPro: Professionnel | null;
}

const MyProfessionnel: React.FC<MyProfessionnelProps> = ({ professional, onShowSearchProForm, onShowSubSousTraitanceForm, subSousTraitantPro }) => {
  const { values } = useFormikContext<FormInitialValues & FormikValues>();
  const { siret, codeTravaux } = values;

  const { professionals, searchProfessional, loading } = useProfessionalSearch();

  useEffect(() => {
    if (siret && codeTravaux) {
      searchProfessional({ siret, codeTravaux });
    }
  }, [siret, codeTravaux]);

  const { rge } = professionals[0] || {};

  if (loading) {
    return <LoaderSimple />;
  }

  return (
    <div className="form__container">
      <div className="form__container__title">{lang.myProfessional}</div>
      <div className="form__container__content">
        <ProfessionalHeader avatar={circular} name={professional.raisonSociale} onClick={onShowSearchProForm} label={lang.changeProfessional} rge={professional?.rge || rge} />
        <ProfessionalBody professional={professional} />

        {subSousTraitantPro && (
          <>
            <div className="form__container__title">{lang.mySubcontractor}</div>
            <ProfessionalHeader name={subSousTraitantPro?.raisonSociale || ''} onClick={onShowSubSousTraitanceForm} label={lang.changeSubcontractor} rge={subSousTraitantPro.rge || rge} />
            <ProfessionalBody professional={subSousTraitantPro} />
          </>
        )}
        <div className="d-flex justify-content-center mt-4  ">
          <Button id="confirm-pro" variant="primary" label={lang.confirm} type="submit" classNames="m-4" />
        </div>
      </div>
    </div>
  );
};

export default MyProfessionnel;
