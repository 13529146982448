import React from 'react';
import conseiller from '../../assets/icons/conseiller.svg';
import sandtime from '../../assets/icons/sandtime.svg';

interface DossierEncoursProps {
  amount: number | undefined;
}

const DossierEncours: React.FC<DossierEncoursProps> = ({ amount }) => (
  <>
    <div className="controle-dossier__title">Nos équipes procèdent à la vérification de votre dossier</div>
    <div className="controle-dossier__subtitle">
      <p>Elles vous contacteront sous 45 jours.</p>
    </div>
    <div className="controle-dossier__info">
      <div className="controle-dossier__info__icon">
        <img src={conseiller} alt="conseiller" />
      </div>
      <div className="controle-dossier__info__content">
        <p>
          Après validation, un conseiller peut contrôler la bonne réalisation des travaux.
          <br /> Ce contrôle peut être réalisé par téléphone ou sur place.
        </p>
        <p className="controle-dossier__info__content__note">Opération gratuite réalisée par un bureau de contrôle partenaire</p>
      </div>
    </div>
    <div className="controle-dossier__prime">
      <div className="controle-dossier__prime__icon">
        <img src={sandtime} alt="En cours d’examen" />
        <p className="controle-dossier__prime__icon__text">En cours d’examen</p>
      </div>
      <div className="controle-dossier__prime__content">
        <p className="controle-dossier__prime__content__price">{amount} €</p>
        <p className="controle-dossier__prime__content__title">Prime Énergie Sonergia</p>
        <p className="controle-dossier__prime__content__text">
          Suite au contrôle de votre dossier nous vous ferons un retour sous <b>45 jours ouvrés.</b> <br />
          Vous recevrez votre Prime Énergie Sonergia par virement sous 45 jours ouvrés après validation de votre dossier.
        </p>
      </div>
    </div>
  </>
);

export default DossierEncours;
