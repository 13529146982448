import React from 'react';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import IdentificationProfessionnel from '../identificationProfessionnel/identificationProfessionnel';
import InformationTravaux from '../informationTravaux/informationTravaux';
import RealisationTravaux from '../realisationTravaux/realisationTravaux';
import FinalisationDossier from '../finalisationDossier/finalisationDossier';
import ControleDossier from '../controleDossier/controleDossier';
import VersementPrime from '../controleDossier/versementPrime';

interface MultiStepFormContentProps {
  step: number;
  refresh: () => void;
  simulation?: Resultat;
}

const MultiStepFormContent: React.FC<MultiStepFormContentProps> = ({ step, refresh, simulation }) => {
  const steps = [<IdentificationProfessionnel />, <InformationTravaux refresh={refresh} />, <RealisationTravaux refresh={refresh} />, <FinalisationDossier refresh={refresh} />, <ControleDossier />, <VersementPrime amount={simulation?.amount} />];

  return steps[step] || <div>Non trouvé</div>;
};

export default MultiStepFormContent;
