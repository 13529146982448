import { object, string, boolean } from 'yup';
import contactFormResource from '.';
import labels from '../../labels';
import { phoneRegExp } from '../../utils/regex';
export function buildCreateContactFormValidationSchema() {
    return object({
        firstName: string().required().default(''),
        lastName: string().required().default(''),
        email: string().email().required().default(''),
        phone: string().matches(phoneRegExp, labels.validation.common.phone).default(null).nullable(),
        socialReason: string().default(''),
        type: string().default(contactFormResource.TYPE_SURVEY_REJECTED),
        message: string().required().default(''),
        mailable: boolean().default(false),
        contactByMail: boolean().default(false),
        contactByPhone: boolean().default(false),
    });
}
export function buildAccountContactFormValidationSchema() {
    return object({
        type: string().required().default(''),
        message: string().required().default(''),
        contactByPhone: boolean().default(false),
    });
}
