type Env = {
  [_prop: string]: unknown;
  source: (_e: Env) => void;
};

export const env: Env = {
  ...process.env,
  source(e: Env) {
    // eslint-disable-next-line
    for (const n in e) {
      this[n] = e[n];
    }
  },
};

export function getEnv(key: string): string {
  const upperKey = key.toUpperCase();
  return String(env[upperKey] || env[`REACT_APP_${upperKey}`] || '');
}
