import React, { useEffect, useMemo } from 'react';
import { handleCurrentPageLoaded } from 'data-layer';
import { useLocation } from 'react-router-dom';
import { useSharedValue } from 'states';

export default function DataLayerListener(): React.ReactElement {
  const location = useLocation();
  const { pathname } = location;
  const user = useSharedValue('security:user');

  // Create a memoized user value to ensure the dependency array only changes when user data actually changes.
  const memoizedUser = useMemo(() => user, [user]);

  useEffect(() => {
    handleCurrentPageLoaded(pathname, memoizedUser);
  }, [pathname, memoizedUser]);

  return <></>;
}
