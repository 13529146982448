import { useEffect, useState } from 'react';
import { RejectionReasons } from '../types/dashboard';
import informationTravauxAPI from '../services/informationTravauxAPI';

type UseRejectionReasonsProps = {
  simulationId: string;
};

type UseRejectionResult = {
  rejectionReasons: RejectionReasons | undefined;
  error: unknown;
  isLoading: boolean;
};

const useRejectionReasons = ({ simulationId }: UseRejectionReasonsProps): UseRejectionResult => {
  const [rejectionReasons, setRejectionReasons] = useState<RejectionReasons | undefined>();
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await informationTravauxAPI.getMotifsRejet(simulationId);
      setRejectionReasons(res);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setRejectionReasons(undefined);
      setError(null);
      setIsLoading(false);
    };
  }, [simulationId]);

  return { rejectionReasons, error, isLoading };
};

export default useRejectionReasons;
