var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Resource from '..';
import api from '@concerto-home/api';
var UserResource = /** @class */ (function (_super) {
    __extends(UserResource, _super);
    function UserResource(entrypoint) {
        if (entrypoint === void 0) { entrypoint = '/users'; }
        var _this = _super.call(this, entrypoint) || this;
        _this.ROLE_CUSTOMER = 'ROLE_CUSTOMER';
        _this.ROLE_PARTNER = 'ROLE_PARTNER';
        _this.SOURCE_CONCERTO = 'concerto';
        _this.SOURCE_CONCERTO_RENOV = 'concerto-renov';
        return _this;
    }
    // updatePassword(id: number, plainPassword: string) {
    //   api.put(this.buildUri(id), { plainPassword });
    // }
    UserResource.prototype.forgotPassword = function (email) {
        return api.post(this.buildUri('forgot_password'), { email: email }).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    UserResource.prototype.resetPassword = function (resetPasswordToken, plainPassword) {
        return api.post(this.buildUri('reset_password'), { resetPasswordToken: resetPasswordToken, plainPassword: plainPassword }).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    UserResource.prototype.verifyAccount = function (signInValidationToken, plainPassword) {
        return api.post(this.buildUri('validation'), { signInValidationToken: signInValidationToken, plainPassword: plainPassword }).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    return UserResource;
}(Resource));
export { UserResource };
export default new UserResource();
