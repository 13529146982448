import React, { useState } from 'react';

import { TextInput } from 'components/form/inputs';
import Button from 'features/dashboard/components/button/button';
import lang from 'features/dashboard/lang/projets.json';

import { Col, Row } from 'react-bootstrap';

import { trackClickNoPro } from 'data-layer';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import { handleEnterKeyPress } from 'utils/form';
import ProfessionnelHelp from '../myProfessionnel/professionnelHelp';
import formModel from '../../../models/formModel';

interface SearchProfessionnelProps {
  isDisabled: boolean;
  onSearchBySiretOrIdentifiant: () => void;
  children?: React.ReactNode;
  isSousTraitant?: boolean;
  errorMessage: string;
  simulation?: Resultat;
}

const SearchProfessionnel: React.FC<SearchProfessionnelProps> = ({ isDisabled, onSearchBySiretOrIdentifiant, children, isSousTraitant = false, errorMessage, simulation }) => {
  const { formField } = formModel;
  const { companyName, siret, sousTraitantCompanyName, sousTraitantSiret } = formField;
  const [show, setShow] = useState(false);

  const handleNoPro = () => {
    setShow(true);
    // Target Event : Click on "Je n'ai pas de professionnel"
    trackClickNoPro(simulation as Resultat);
  };

  return (
    <div className="form__container nospace">
      <div className="form__container__title">{isSousTraitant ? lang.searchSubcontractor : lang.searchProfessional}</div>
      <div className="form__container__content">
        <Row>
          <Col sm="4">
            <TextInput rounded placeholder={companyName.Placeholder} data-cy={isSousTraitant ? sousTraitantCompanyName.name : companyName.name} label={companyName.label} name={isSousTraitant ? sousTraitantCompanyName.name : companyName.name} tooltip={lang.companyNameTooltip} onKeyPress={handleEnterKeyPress(onSearchBySiretOrIdentifiant)} />
          </Col>

          <Col sm="1" className="d-flex align-items-center justify-content-center form__container__content__or ">
            <div className="form__container__content__or--text">{lang.orText}</div>
          </Col>
          <Col sm="4">
            <TextInput rounded placeholder={siret.Placeholder} label={siret.label} name={isSousTraitant ? sousTraitantSiret.name : siret.name} kind="siret" onKeyPress={handleEnterKeyPress(onSearchBySiretOrIdentifiant)} />
          </Col>

          <Col sm="3" className="d-flex align-items-center ">
            <Button id="no-pro" variant="tertiary" label={lang.dontHavePro} type="button" onClick={handleNoPro} size="small" />
          </Col>
        </Row>

        <Row>
          {errorMessage && (
            <Col sm="12" className="d-flex justify-content-center mt-4">
              <div className="text-danger text-bold">{errorMessage}</div>
            </Col>
          )}
          <Col className="d-flex justify-content-center mt-4">
            <Button id="confirm-search" variant="primary" label={lang.search} type="button" isDisabled={isDisabled} onClick={onSearchBySiretOrIdentifiant} />
          </Col>
        </Row>
        {children}
        <ProfessionnelHelp show={show} closeModal={() => setShow(false)} />
      </div>
    </div>
  );
};

export default SearchProfessionnel;
