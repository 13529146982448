var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect } from 'react';
import { useSharedState, useSharedValue } from '../../../../states';
import ChoiceInput from '../choice';
var RESPONSE_BETWEEN_DAYS_VALUE = 3;
var checkResponse = function (value, responses) {
    if (Number(value) !== RESPONSE_BETWEEN_DAYS_VALUE) {
        return null;
    }
    return typeof responses.number_of_occupancy_days_per_week === 'number' || 'Veuillez compléter la question';
};
function OccupancyProfileInput(_a) {
    var question = _a.question, onChange = _a.onChange;
    var questions = useSharedValue('survey:questions');
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var _c = useSharedState('survey:current:validation'), setValidation = _c[1];
    var qOccupancyDays = questions.filter(function (q) { return q.alias === 'number_of_occupancy_days_per_week'; })[0];
    var validate = useCallback(function (value) { return checkResponse(value, responses); }, [responses]);
    var handleChange = useCallback(function (value, days) {
        setResponses(function (r) { return (__assign(__assign({}, r), { occupancy_profile: value, number_of_occupancy_days_per_week: days })); });
        if (typeof onChange === 'function' && value !== RESPONSE_BETWEEN_DAYS_VALUE && !days) {
            onChange(value);
        }
    }, [responses]);
    useEffect(function () {
        setValidation([validate]);
        return function () { return setValidation(null); };
    }, [responses]);
    return (React.createElement(ChoiceInput, { controlled: true, question: question, onChange: handleChange, value: responses.occupancy_profile, nested: function (value) {
            if (Number(value) !== RESPONSE_BETWEEN_DAYS_VALUE || responses.occupancy_profile !== RESPONSE_BETWEEN_DAYS_VALUE) {
                return null;
            }
            return (React.createElement("div", { className: "diagnosticQuestionListChildren" },
                React.createElement(ChoiceInput, { controlled: true, question: qOccupancyDays, onChange: function (days) { return handleChange(Number(value), days); }, value: responses.number_of_occupancy_days_per_week }),
                React.createElement("br", null)));
        } }));
}
OccupancyProfileInput.isDisplay = function (r) { return typeof checkResponse(r.occupancy_profile, r) === 'string'; };
export default OccupancyProfileInput;
