var _a;
import TextInput from './text';
import ChoiceInput from './choice';
import BuildingSelectInput from './complex/building-select';
import questionResource from '../../resources/question';
import { SwitchInput } from './switchInput';
var inputs = {
    text: TextInput,
    float: TextInput,
    email: TextInput,
    integer: TextInput,
    date: TextInput,
    choice: ChoiceInput,
    switch: SwitchInput,
};
var inputsByQuestionAlias = (_a = {},
    _a[questionResource.ALIAS_QUESTION_ADDRESS] = BuildingSelectInput,
    _a);
export function resolveInput(question) {
    var customInput = inputsByQuestionAlias[question === null || question === void 0 ? void 0 : question.alias];
    if (customInput) {
        return customInput;
    }
    var input = inputs[question === null || question === void 0 ? void 0 : question.type];
    if (!input) {
        console.warn("Undefined input for question type ".concat(question === null || question === void 0 ? void 0 : question.type));
    }
    return input;
}
export default inputs;
