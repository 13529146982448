import { api, Resource } from 'core';
import { mapMotifsRejet } from '../mapper/motifsRejet';
import { RejectionReasons } from '../types/dashboard';

interface ResponseRai {
  dateLimiteRai: string;
  raiEnCours: boolean;
}

export interface ControleDatesResponse {
  success: boolean;
  erreursCoherence: {
    messageErreur: string;
    alias: string;
  }[];
}

export class InformationTravauxAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  postInformationTravaux(uuidSimulation: string, query: FormData): Promise<{ success: boolean }> {
    return api.post(this.buildUri(`simulation/${uuidSimulation}/informations-travaux/terminer`), query).then(({ data }) => data);
  }

  verifyRai(uuidSimulation: string, query: string): Promise<ResponseRai> {
    return api
      .post(this.buildUri(`simulation/${uuidSimulation}/verifier-rai-en-cours`), {
        dateDevis: query,
      })
      .then(({ data }) => data);
  }

  postToFinalisationDossier(uuidSimulation: string): Promise<{ success: boolean }> {
    return api.post(this.buildUri(`simulation/${uuidSimulation}/statut/finalisation-dossier`)).then(({ data }) => data);
  }

  postToControleDossier(uuidSimulation: string): Promise<{ success: boolean }> {
    return api.post(this.buildUri(`simulation/${uuidSimulation}/statut/controle-dossier`)).then(({ data }) => data);
  }

  sendAH(uuidSimulation: string): Promise<{ success: boolean }> {
    return api.post(this.buildUri(`simulation/${uuidSimulation}/ah/send`)).then(({ data }) => data);
  }

  getMotifsRejet(uuidSimulation: string): Promise<RejectionReasons> {
    return api.get(this.buildUri(`simulation/${uuidSimulation}/motifs-rejet`)).then(({ data }) => mapMotifsRejet(data));
  }

  postControllerCoherenceDate(uuidSimulation: string, dateFacture: string): Promise<ControleDatesResponse> {
    return api
      .post(this.buildUri(`simulation/${uuidSimulation}/controler-coherence-dates`), {
        dateFacture,
      })
      .then(({ data }) => data);
  }
}

export default new InformationTravauxAPI();
