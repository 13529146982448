import React, { useState } from 'react';
import { useFormikContext, useField } from 'formik';
import FormError from 'components/form/error';

import Tooltip from 'components/tooltip/Tooltip';
import { MdInfo } from 'react-icons/md';
import type { CommonProps } from '.';

interface Props extends CommonProps {
  type?: string;
  disabled?: boolean;
  icon?: string;
  autocomplete?: string;
  placeholder?: string;
  rounded?: boolean;
  tooltip?: string;
  kind?: string;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function TextInput({ name, type = 'text', label, disabled, icon, autocomplete, placeholder, rounded, tooltip, kind, onKeyPress }: Props): React.ReactElement {
  const [id] = useState(`${name}-${String(Math.floor(Math.random() * 10000))}`);
  const { handleChange, handleBlur, values } = useFormikContext();
  const [, meta] = useField(name);
  const hasError = meta.touched && meta.error;
  const [showPassword, setShowPassword] = useState(false);

  interface AttributesInterface {
    id?: string;
    name: string;
    className: string;
    type?: string;
    onChange?: any;
    onBlur?: any;
    value: string;
    disabled?: boolean;
    autoComplete?: string;
    placeholder?: string;
    onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    maxLength?: number;
    max?: string;
  }

  const attributes: AttributesInterface = {
    id,
    name,
    className: '',
    // eslint-disable-next-line
    // @ts-ignore
    value: values[name] ?? '',
    disabled,
    placeholder,
  };

  if (autocomplete) {
    attributes.autoComplete = autocomplete;
  }

  if (hasError) {
    attributes.className = 'withError';
  }

  if (type === 'date') {
    attributes.max = '9999-12-31';
  }

  /**
   * Sets the maxLength attribute to 14 and prevents the Enter key from submitting the form
   * and prevents non-numeric characters from being entered when the kind is 'siret'.
   *
   * @param {string} kind - The kind of input.
   * @param {object} attributes - The attributes object to modify.
   * @param {Function} attributes.onKeyPress - The onKeyPress event handler.
   * @param {KeyboardEvent} e - The keyboard event object.
   */
  if (kind === 'siret') {
    attributes.maxLength = 14;
    attributes.onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
      }
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    };
  }

  if (type) {
    attributes.type = showPassword && type === 'password' ? 'text' : type;
  }

  if (handleChange) {
    attributes.onChange = handleChange;
  }

  if (handleBlur) {
    attributes.onBlur = handleBlur;
  }
  if (onKeyPress) {
    attributes.onKeyPress = onKeyPress;
  }

  const customClass = type === 'date' ? 'custom__date' : '';

  return (
    <div className={`form-group ${disabled ? 'disabled' : ''} ${customClass} ${rounded ? 'rounded' : ''}`}>
      <label htmlFor={id}>
        <div className="form-group-label">
          {label}{' '}
          {tooltip && (
            <Tooltip showTooltip={!!tooltip} text={tooltip} placement="bottom">
              <sup>
                <MdInfo size={20} color="#A0A0A0" />
              </sup>
            </Tooltip>
          )}
        </div>
        <div className={`form-group-input ${icon !== '' ? 'withIcon' : ''}`}>
          <input {...attributes} />

          {icon !== '' &&
            (type === 'password' ? (
              // eslint-disable-next-line
              <i className={`form-group-input-icon ${icon}`} onClick={() => setShowPassword(!showPassword)} />
            ) : (
              <i className={`form-group-input-icon ${icon}`} />
            ))}
        </div>
        <FormError name={name} />
      </label>
    </div>
  );
}
