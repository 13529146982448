var _a;
import questionResource from '../../../resources/question';
import TextInput from './text';
import ChoiceInput from './choice';
import ChoicesInput from './choices';
import SelectInput from './select';
import BuildingSelectInput from './complex/building-select';
import WindowsPerWallInput from './complex/windows-per-wall';
import ThermostatInput from './complex/thermostat';
import NbWeeksOccupation from './complex/number-of-weeks';
import OccupancyProfile from './complex/occupancy-profile';
import NumberOfOccupants from './complex/number-of-occupants';
import StartPage from './complex/start-page';
import PlannedRenovationWork from './complex/planned-renovation-work';
import FloorType from './complex/floor-type';
import HeatingSystemType from './complex/heating-system-type';
import HeatingSystemOperatingMode from './complex/heating-system-operating-mode';
import HouseShape from './complex/house-shape';
var inputs = {
    text: TextInput,
    float: TextInput,
    email: TextInput,
    integer: TextInput,
    choice: ChoiceInput,
    choices: ChoicesInput,
    select: SelectInput,
};
var inputsByQuestionAlias = (_a = {},
    _a[questionResource.ALIAS_START_PAGE] = StartPage,
    _a[questionResource.ALIAS_QUESTION_ADDRESS] = BuildingSelectInput,
    _a[questionResource.ALIAS_QUESTION_WINDOWS_PER_WALL] = WindowsPerWallInput,
    _a[questionResource.ALIAS_QUESTION_THERMOSTAT] = ThermostatInput,
    _a[questionResource.ALIAS_QUESTION_NB_OF_WEEKS] = NbWeeksOccupation,
    _a[questionResource.ALIAS_QUESTION_OCCUPANCY_PROFILE] = OccupancyProfile,
    _a[questionResource.ALIAS_QUESTION_NUMBER_OF_OCCUPANTS] = NumberOfOccupants,
    _a[questionResource.ALIAS_QUESTION_PLANNED_RENOVATION_WORK] = PlannedRenovationWork,
    _a[questionResource.ALIAS_QUESTION_FLOOR_TYPE] = FloorType,
    _a[questionResource.ALIAS_QUESTION_HEATING_SYSTEM_TYPE] = HeatingSystemType,
    _a[questionResource.ALIAS_QUESTION_SECONDARY_HEATING_SYSTEM_TYPE] = HeatingSystemType,
    _a[questionResource.ALIAS_QUESTION_DOMESTIC_HOT_WATER_SYSTEM_TYPE] = HeatingSystemType,
    _a[questionResource.ALIAS_QUESTION_HEATING_SYSTEM_OPERATING_MODE] = HeatingSystemOperatingMode,
    _a[questionResource.ALIAS_QUESTION_HOUSE_SHAPE] = HouseShape,
    _a);
export function resolveInput(question) {
    var customInput = inputsByQuestionAlias[question.alias];
    if (customInput) {
        return customInput;
    }
    var input = inputs[question.type];
    if (!input) {
        throw new Error("Undefined input for question type ".concat(question.type));
    }
    return input;
}
export default inputs;
