import React from 'react';
import { HiOutlineExclamationCircle } from 'react-icons/hi';
import { useParams } from 'react-router';
import useRejectionReasons from 'features/dashboard/hooks/useRejectionReasons';
import { LoaderSimple } from '@concerto-home/core';
import InformationContact from '../contact/informationContact';

const DossierRejete: React.FC = () => {
  const { simulationId } = useParams<{ simulationId: string }>();
  if (!simulationId) return null;

  const { rejectionReasons, isLoading } = useRejectionReasons({ simulationId });

  if (isLoading) return <LoaderSimple />;

  return (
    <div className="dossier-wrapper">
      <div className="dossier-header">
        <div className="dossier-header__icon">
          <HiOutlineExclamationCircle size={40} />
        </div>
        <div className="dossier-header__title">
          <h1>Nous avons identifié un élément bloquant</h1>
          <p>
            Votre dossier ne remplit pas les exigences et contraintes du dispositif des CEE. <br /> Nous ne sommes malheureusement pas en mesure de répondre favorablement à votre demande de prime.
          </p>
        </div>
      </div>

      <div className="dossier-content">
        <div className="dossier-content__panel">
          <div className="dossier-content__panel__header">
            <h2>Motif du rejet</h2>
          </div>
          <div className="dossier-content__panel__body">
            {rejectionReasons?.motifsRejet.map((motif) => (
              <div key={motif}>
                <p>{motif}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <InformationContact />
    </div>
  );
};

export default DossierRejete;
