/* eslint no-template-curly-in-string: "off" */
export default {
    validation: {
        yup: {
            mixed: {
                default: "La valeur n'est pas valide",
                required: 'Ce champ est requis',
                oneOf: 'La valeur doit être une des valeurs suivante: ${values}',
                notOneOf: 'Ce champ ne doit pas contenir les valeurs suivantes: ${values}',
                defined: 'Ce champ doit être défini',
            },
            string: {
                length: 'Ce champ doit contenir ${length} caractères',
                min: 'Ce champ doit contenir au moins ${min} caractères',
                max: 'Ce champ doit contenir au maximum ${max} caractères',
                matches: "Ce champ n'a pas un format valide",
                email: 'Ce champ doit contenir un email valide',
                url: 'Ce champ doit contenir une url valide',
                uuid: 'Ce champ doit être un UUID valide',
                trim: 'Ce champ doit ne doit pas commencer ou finir par un espace',
                lowercase: 'Ce champ doit contenir que des minuscules',
                uppercase: 'Ce champ doit contenir que des majuscules',
                number: 'Le mot de passe doit contenir au moins un chiffre',
                special: 'Le mot de passe doit contenir au moins un caractère spécial',
            },
            number: {
                min: 'Ce champ doit être supérieur à ${min}',
                max: 'Ce champ doit être inférieur à ${max}',
                lessThan: 'Ce champ doit être inférieur à ${less}',
                moreThan: 'Ce champ doit être supérieur à ${more}',
                positive: 'Ce champ doit être un nombre positif',
                negative: 'Ce champ doit être un nombre négatif',
                integer: 'Ce champ doit être un entier',
            },
            date: {
                min: 'La date doit être supérieur à ${min}',
                max: 'La date doit être inféreur à ${max}',
            },
            boolean: {
                isValue: 'Ce champ doit être ${value}',
            },
            object: {
                noUnknown: 'Cet objet contient des champs supplémentaires : ${unknown}',
            },
            array: {
                min: 'Ce champ doit contenir au moins ${min} élément(s)',
                max: 'Ce champ doit contenir au maximum ${max} élément(s)',
                length: 'Ce champ doit contenir exactement ${length} éléments',
            },
        },
        common: {
            phone: 'Ce numéro de téléphone est invalide',
            terms: 'Vous devez accepter les Conditions Générales d’Utilisation pour vous inscrire à Sonergia',
            soliha: 'Vous devez accepter le partage des données avec SOLIHA pour vous inscrire à Sonergia',
            passwords_must_match: 'Les mots de passe doivent correspondre',
        },
    },
};
