var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from 'react';
import ChoiceInput from '../choice';
import { useSharedState } from '../../../../states';
export default (function (_a) {
    var question = _a.question;
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponse = _b[1];
    var _c = useSharedState('survey:current'), current = _c[0], setCurrent = _c[1];
    var previous = useSharedState('survey:previous')[0];
    var heatingSystemType = responses.heating_system_type;
    var heatingSystemEnergy = responses.heating_system_main_energy_vector;
    var _d = useState(true), loading = _d[0], setLoading = _d[1];
    useEffect(function () {
        // @see https://sonergia.atlassian.net/browse/CH-769
        if (heatingSystemEnergy === 1 && ![6, 7, 8].includes(heatingSystemType)) {
            setResponse(function (r) { return (__assign(__assign({}, r), { heating_system_operating_mode: null })); });
            setCurrent(previous > current ? current - 1 : current + 1);
            return;
        }
        setLoading(false);
    }, [current, previous]);
    if (loading) {
        return null;
    }
    return React.createElement(ChoiceInput, { question: question, onChange: function () { return setCurrent(current + 1); } });
});
