import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { CheckboxInput } from 'components/form/inputs';
import { getEnv } from '../../../../utils/env';

export const Soliha = (): React.ReactElement => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Partage de vos données à SOLIHA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Ce partage est nécessaire pour traiter la demande d’intervention du thermicien qui vous accompagnera dans le diagnostic énergétique de votre logement.
          <br />
          Pour en savoir plus nous vous invitions à consulter notre{' '}
          <a href={`${getEnv('CMS_HOST')}/politique-de-confidentialite`} target="_blank" rel="noreferrer">
            politique de confidentialité
          </a>
          .
        </Modal.Body>
      </Modal>

      <CheckboxInput
        name="soliha"
        label={
          <div>
            En cochant cette case vous consentez au{' '}
            <button type="button" className="link" onClick={handleShow} rel="noreferrer">
              partage de vos données
            </button>{' '}
            avec l’organisme SOLIHA du lieu de votre résidence.
          </div>
        }
      />
    </>
  );
};

export default Soliha;
