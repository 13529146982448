import { api, QuestionGroup, Resource } from 'core';
import { mapAPIQuestionGroups } from '../mapper/group.mapper';

export class GroupApi extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  getGroups(query?: { [p: string]: unknown }): Promise<QuestionGroup[]> {
    return api.get(this.buildUri('groupes'), query).then(({ data }) => mapAPIQuestionGroups(data['hydra:member']));
  }
}

export default new GroupApi();
