import React from 'react';
import { Professionnel } from 'features/dashboard/types/professionnel';
import { FaExclamationCircle } from 'react-icons/fa';
import Tooltip from 'components/tooltip/Tooltip';
import lang from '../../../lang/projets.json';

interface ListItemProps {
  listPro: Professionnel[];
  onSelectProfessional: (professional: Professionnel) => void;
  isSousTraitant?: boolean;
}

interface ProfessionalItemProps {
  professional: Professionnel;
  index: number;
  onSelectProfessional: (professional: Professionnel) => void;
  isSousTraitant?: boolean;
}

const ProfessionalItem: React.FC<ProfessionalItemProps> = ({ professional, index, onSelectProfessional, isSousTraitant }) => {
  const handleClick = () => {
    if (!(isSousTraitant && !professional.rge?.isRgeForTravaux)) {
      onSelectProfessional(professional);
    }
  };
  const messageTooltip = (): string => {
    if (!professional.rge?.isRge) {
      return lang.isNotRgeText;
    }
    if (!professional.rge?.isRgeForTravaux) {
      return lang.isNotRgeForTravauxText;
    }
    return '';
  };

  return (
    <div className="list-professionnel__item" key={professional.siret}>
      <div className={`list-professionnel__item__content ${isSousTraitant && !professional.rge?.isRgeForTravaux ? 'notAllowed' : ''}`} onClick={handleClick}>
        <div className="list-professionnel__item__content__key">{index + 1}</div>
        <div className="list-professionnel__item__content__name">{professional.raisonSociale}</div>
        <div className="list-professionnel__item__content__fullAddress">{professional.address.street}</div>
        <div className="list-professionnel__item__content__address">
          <p className="list-professionnel__item__content__address--codePostal">{professional.address.postalCode}</p>
          <p className="list-professionnel__item__content__address--city">{professional.address.city}</p>
        </div>
        {!professional.rge?.isRgeForTravaux && (
          <div className="list-professionnel__item__content__rge">
            <Tooltip showTooltip text={messageTooltip()} placement="left">
              <span className="list-professionnel__item__content__rge--icon">
                <FaExclamationCircle size={22} color="#DB7927" />
              </span>
              <span className="list-professionnel__item__content__rge--text">RGE non conforme</span>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

const ListItem: React.FC<ListItemProps> = ({ listPro, onSelectProfessional, isSousTraitant }) => (
  <div className="list-professionnel__body">
    {listPro.map((professional, i) => (
      <ProfessionalItem key={professional.siret} professional={professional} index={i} onSelectProfessional={onSelectProfessional} isSousTraitant={isSousTraitant} />
    ))}
  </div>
);

export default ListItem;
