import React, { FC } from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface PrequisItemProps {
  title: string;
}

const PrequisItem: FC<PrequisItemProps> = ({ title }) => (
  <div className="realisation-travaux__content__item">
    <div className="realisation-travaux__content__item__icon">
      <FaCheckCircle size={25} color="#23C275" />
    </div>
    <div className="realisation-travaux__content__item__title">{title}</div>
  </div>
);

export default PrequisItem;
