import { useState, useEffect } from 'react';
import InformationTravauxAPI from '../services/informationTravauxAPI';

interface RaiVerificationData {
  raiEnCours: boolean | null;
  dateLimiteRai: string;
}

const useRaiVerification = (formikValuesDateDevis: string, simulationId: string): RaiVerificationData => {
  const [raiVerificationData, setRaiVerificationData] = useState<RaiVerificationData>({
    raiEnCours: null,
    dateLimiteRai: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!formikValuesDateDevis) return;

      try {
        const response = await InformationTravauxAPI.verifyRai(simulationId, formikValuesDateDevis);
        setRaiVerificationData({
          raiEnCours: response.raiEnCours,
          dateLimiteRai: response.dateLimiteRai,
        });
      } catch (error) {
        console.error('Erreur:', error);
      }
    };

    fetchData();
  }, [formikValuesDateDevis, simulationId]);

  return raiVerificationData;
};

export default useRaiVerification;
