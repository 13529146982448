import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import security from '@concerto-home/security';
import RegisterForm from 'components/form/register';
import Panel from 'components/form/register/panels/Panel';
import { handleRegistrationStep, handleRegistrationSucceed } from 'data-layer';
import { clearSimulationFromLocalStorage } from 'features/dashboard/helpers/utils';
import { useSharedValue } from '../../states';

export default function Register(): React.ReactElement {
  const navigate = useNavigate();
  const user = useSharedValue('security:user');
  const [currentStep, setCurrentStep] = useState(1);
  const { state } = useLocation();

  // clear simulation from local storage if user comes from another page than the creation page
  useEffect(() => {
    if (state && state?.from !== '/simulation-prime/creation') {
      clearSimulationFromLocalStorage();
    }
  }, []);

  useEffect(() => {
    if (user && security.user) {
      navigate('/dashboard');
    }
  }, []);

  useEffect(() => {
    handleRegistrationStep(currentStep);
  }, [currentStep]);

  return (
    <>
      <div className={`register ${currentStep === 3 ? 'lastStep' : ''}`}>
        <Panel currentStep={currentStep} />

        <div className="registerForm">
          <RegisterForm currentStep={currentStep} setCurrentStep={setCurrentStep} handleComplete={handleRegistrationSucceed} />
        </div>
      </div>
    </>
  );
}
