import { useEffect, useRef } from 'react';
import { trackSimulationEnd, trackSimulationEndStep, trackSimulationPrimeStart, trackSimulationStartGroup } from 'data-layer';
import { REVENUE_QUESTION_ID, TYPE_QUESTION_ID, WORK_QUESTION_LABEL } from 'utils/constants';
import { QuestionPrime } from '../interfaces/questionnaire';

interface QuestionAnswerParams {
  selectedQuestion: QuestionPrime;
}

interface TrackingProps {
  trackQuestionAnswer: (params: QuestionAnswerParams) => void;
  trackEndSimulation: (params: QuestionAnswerParams) => void;
}

function trimAndRemoveSpace(value: string): string {
  return value?.replace(/\s/g, '')?.toString();
}

export const useTracking = (questions: QuestionPrime[], currentQuestionIndex: number): TrackingProps => {
  // Variables Used For Tracking
  const userAnswerForQuestionHousing = questions.find((question) => question.questionId === TYPE_QUESTION_ID)?.userAnswer?.label ?? '';
  const userAnswerForQuestionTravaux = questions.find((question) => trimAndRemoveSpace(question.label) === trimAndRemoveSpace(WORK_QUESTION_LABEL))?.userAnswer?.label ?? '';

  // Track the start of the simulation
  useEffect(() => {
    trackSimulationPrimeStart();
  }, []);

  // Track the answer of the user for a question
  const trackQuestionAnswer = ({ selectedQuestion }: QuestionAnswerParams): void => {
    const { group, questionId, userAnswer, label } = selectedQuestion;
    const userAnswerLable = userAnswer?.label;

    const housing = questionId === TYPE_QUESTION_ID ? userAnswerLable : userAnswerForQuestionHousing;
    const workType = trimAndRemoveSpace(label) === trimAndRemoveSpace(WORK_QUESTION_LABEL) ? userAnswerLable : userAnswerForQuestionTravaux;

    trackSimulationEndStep({
      chapter: group.label,
      housing: housing ?? userAnswerForQuestionHousing,
      step: (currentQuestionIndex + 1).toString(),
      questionLabel: label,
      workType,
    });
  };

  // Target Tracking - l'utilisateur finalise la soumission du simulateur de primes (revenus)
  const trackEndSimulation = ({ selectedQuestion }: Omit<QuestionAnswerParams, 'currentQuestionIndex'>): void => {
    if (selectedQuestion.questionId === REVENUE_QUESTION_ID) {
      trackSimulationEnd({
        housing: userAnswerForQuestionHousing,
        workType: userAnswerForQuestionTravaux,
      });
    }
  };

  // Target Tracking - question group changed
  const prevGroup = useRef<string>('');
  useEffect(() => {
    const currentGroup = questions[currentQuestionIndex]?.group?.label;
    if (prevGroup.current !== currentGroup && currentGroup !== undefined) {
      trackSimulationStartGroup({
        chapter: currentGroup,
        housing: userAnswerForQuestionHousing,
        step: (currentQuestionIndex + 1).toString(),
        questionLabel: questions[currentQuestionIndex]?.label,
        workType: userAnswerForQuestionTravaux,
      });
      prevGroup.current = currentGroup;
    }
  }, [questions]);

  return {
    trackQuestionAnswer,
    trackEndSimulation,
  };
};
