var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { UserResource } from '../user';
import api from '@concerto-home/api';
import { download } from '../../utils/survey';
var CustomerResource = /** @class */ (function (_super) {
    __extends(CustomerResource, _super);
    function CustomerResource(entrypoint) {
        if (entrypoint === void 0) { entrypoint = '/customers'; }
        var _this = _super.call(this, entrypoint) || this;
        _this.HOURS_MORNING = '9h-12h';
        _this.HOURS_AFTERNOON = '14h-18h';
        _this.DAY_MONDAY = 'monday';
        _this.DAY_TUESDAY = 'tuesday';
        _this.DAY_WEDNESDAY = 'wednesday';
        _this.DAY_THURSDAY = 'thursday';
        _this.DAY_FRIDAY = 'friday';
        return _this;
    }
    CustomerResource.prototype.invite = function (email) {
        return api.post(this.buildUri('invite'), { email: email });
    };
    CustomerResource.prototype.export = function (filters, config) {
        return api.get(this.buildUri('export'), filters, __assign(__assign({}, config), { responseType: 'blob' })).then(function (_a) {
            var data = _a.data;
            return download('export.csv', data);
        });
    };
    CustomerResource.prototype.preSuppression = function () {
        return api.post(this.buildUri('pre-suppression')).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    return CustomerResource;
}(UserResource));
export { CustomerResource };
export default new CustomerResource();
