var Storage = /** @class */ (function () {
    function Storage(alias) {
        this.alias = alias;
    }
    Object.defineProperty(Storage.prototype, "data", {
        get: function () {
            var name = "".concat(this.alias, "=");
            var decodedCookie = decodeURIComponent(document.cookie);
            var cookieData = decodedCookie.split(';');
            for (var i = 0; i < cookieData.length; i += 1) {
                var cookie = cookieData[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(name) === 0) {
                    return JSON.parse(cookie.substring(name.length, cookie.length));
                }
            }
            return {};
        },
        set: function (data) {
            var cookie = "".concat(this.alias, "=").concat(JSON.stringify(data), ";");
            var mainDomain = window.location.host;
            // Set domain and allow subdomain to access cookie
            cookie += "domain=".concat(mainDomain, "; path=/;");
            cookie += "secure;";
            // cookie += `httpOnly; `;
            // Set expiration date
            var d = new Date();
            var expDays = 7;
            d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);
            cookie += "expires=".concat(d.toUTCString(), ";");
            document.cookie = cookie;
        },
        enumerable: false,
        configurable: true
    });
    Storage.prototype.get = function (key) {
        return this.data[key];
    };
    Storage.prototype.has = function (key) {
        return this.data[key] !== undefined;
    };
    Storage.prototype.set = function (key, value) {
        var data = this.data;
        data[key] = value;
        this.data = data;
        return this;
    };
    Storage.prototype.unset = function (key) {
        var data = this.data;
        delete data[key];
        this.data = data;
        return this;
    };
    return Storage;
}());
export { Storage };
export default new Storage('concertohome');
