import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { api } from 'core';

export default function ApiListener(): null {
  const navigate = useNavigate();

  useEffect(() => {
    api.on('credentials_expired', () => navigate('/logout'));
  }, []);

  // event listener for request_error event
  useEffect(() => {
    api.on('request_error', (message, error) => {
      console.error(`Une erreur s'est produite lors de la demande : ${message}`, 'details:', error);
      toast.error('Un problème est survenu, réessayez plus tard');
    });
  }, []);

  return null;
}
