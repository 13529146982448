import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { customerResource } from 'core';
import { useSharedValue } from 'states';
import { useNavigate } from 'react-router-dom';
import { usePreSuppression } from 'features/dashboard/hooks/usePreSuppression';

type Props = {
  show: boolean;
  onHide: () => void;
};

export default function ModalDeleteAccount({ show, onHide }: Props): React.ReactElement {
  const user = useSharedValue('security:user');
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const { messagePreSuppression, error: preSuppressionError, loading: preSuppressionLoading } = usePreSuppression(show);

  useEffect(() => {
    if (preSuppressionError) {
      setError(preSuppressionError);
    }
  }, [preSuppressionError]);

  const deleteAccount = () => {
    setLoading(true);
    customerResource
      .delete(String(user.id))
      .then(() => {
        setSuccess('Votre compte a bien été supprimé');
      })
      .catch(() => {
        setError('Une erreur est survenue, veuillez réessayer.');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => navigate('/logout'), 3000);
    }
  }, [success]);

  if (preSuppressionLoading) {
    return <></>;
  }

  return (
    <>
      <Modal show={loading || show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Vous êtes sur le point de supprimer votre compte.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center">{messagePreSuppression}</p>
          <div className="text-center mtl">
            {error && <div className="alert alert-danger">{error}</div>}
            {success && <div className="alert alert-success">{success}</div>}
            <Button className="m-2" onClick={deleteAccount} disabled={loading}>
              <span>Oui</span>
            </Button>
            <Button className="m-2" onClick={onHide}>
              <span>Non</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
