import React, { useState } from 'react';
import './tooltip.scss';

interface Props {
  text: string;
  children: React.ReactNode;
  showTooltip?: boolean;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  classNames?: string;
}

const Tooltip: React.FC<Props> = ({ children, text, showTooltip, placement = 'top', classNames = '' }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const className = `tooltip-text ${placement} `;

  return (
    <div className={`tooltip-container ${classNames}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {children}
      {showTooltip && isVisible && <div className={className}>{text}</div>}
    </div>
  );
};

export default Tooltip;
