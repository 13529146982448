import React, { useEffect, useState } from 'react';
export default function ScrollIndicator() {
    var _a = useState(false), isScrollable = _a[0], setIsScrollable = _a[1];
    var displayScrollIndicator = function () {
        if (window.scrollY + window.innerHeight >= document.body.clientHeight - 0.5) {
            setIsScrollable(false);
        }
        else {
            setIsScrollable(true);
        }
    };
    var scrollToBottom = function () {
        var offsetBottom = document.body.offsetTop + document.body.offsetHeight;
        window.scrollTo({ top: offsetBottom, behavior: 'smooth' });
    };
    useEffect(function () {
        setIsScrollable(window.innerHeight < document.body.scrollHeight);
        function watchScroll() {
            window.addEventListener('scroll', displayScrollIndicator);
        }
        watchScroll();
        return function () {
            window.removeEventListener('scroll', displayScrollIndicator);
        };
    }, []);
    return (React.createElement("button", { type: "button", className: "footerScroll ".concat(isScrollable ? 'show' : ''), onClick: scrollToBottom },
        React.createElement("i", { className: "icon-chevron-down" })));
}
