import React, { useState } from 'react';
import { useSimulationFromLocalStorage } from 'features/primes/simulation/questionnaire/hooks/useSimulation';
import { useLocation, useNavigate } from 'react-router-dom';
import './create.scss';
import { useSharedValue } from 'states';
import Lottie from 'components/lottie/Lottie';
import analysis from 'features/primes/simulation/resultat/lotties/data-analysis.json';
import lang from '../lang/create.json';
import { useEffectOnce } from '../utils/functions';

const Create = (): React.ReactElement => {
  // react router
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const user = useSharedValue('security:user');
  const { state } = location;

  const sleepNow = (delay: number) => new Promise((resolve) => setTimeout(resolve, delay));

  const fetchSimulation = async () => {
    try {
      const simulationId = await useSimulationFromLocalStorage({ isEdit: state, user });
      if (simulationId) {
        await sleepNow(1000);
        navigate(`/simulation-prime/resultat/${simulationId}`);
      }
    } catch (e) {
      if (e instanceof Error) {
        console.log(e.message);
        setError(e.message);
      }
    }
  };

  useEffectOnce(() => {
    if (!user) {
      navigate('/register', {
        state: { from: location.pathname },
      });
      return;
    }

    fetchSimulation();
  }, [user]);

  return (
    <div className="result--loading">
      <Lottie animationData={analysis} loop title={lang.title} subtitle={error ? lang.error : lang.subtitle} centrered />
    </div>
  );
};

export default Create;
