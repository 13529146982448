import TravauxApi from 'features/primes/simulation/questionnaire/services/travauxApi';
import { Answer } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

export const getTravauxQuestions = async (parcoursId: string, userAnswersWithoutTravaux: Answer[]) => {
  const formattedUserAnswers = {
    parcoursId,
    userAnswers: userAnswersWithoutTravaux,
  };

  const travauxQuestions = await TravauxApi.getTravaux(formattedUserAnswers);

  return travauxQuestions;
};
