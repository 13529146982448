import React from 'react';
import { useSharedValue } from 'states';
import { getEnv } from 'utils/env';

export default function LegalNotice(): React.ReactElement {
  const user = useSharedValue('security:user');

  return (
    <>
      <h1 className="accountInfosTitle">
        <strong>Bonjour {user.firstName},</strong> bienvenue sur votre espace Sonergia
      </h1>
      <h2 className="accountInfosTitleSub">Mentions légales de votre compte</h2>

      <div className="customCheckbox">
        <input id="cgu" name="cgu" type="checkbox" checked disabled />
        {/* eslint-disable-next-line */}
        <label htmlFor="cgu" className="customCheckboxLabel text-underline">
          <span>
            Vous avez accepté les{' '}
            <a href={`${getEnv('CMS_HOST')}/conditions-generales-dutilisation`} target="_blank" rel="noreferrer">
              Conditions Générales d’Utilisation de Sonergia
            </a>
          </span>
        </label>
      </div>
      <div className="customCheckbox">
        <input id="privacy_policy" name="privacy_policy" type="checkbox" checked disabled />
        {/* eslint-disable-next-line */}
        <label htmlFor="privacy_policy" className="customCheckboxLabel text-underline">
          {/* TODO: Mettre le bon lien */}
          <span>
            Vous pouvez consulter notre{' '}
            <a href={`${getEnv('CMS_HOST')}/politique-de-confidentialite`} target="_blank" rel="noreferrer">
              Politique de confidentialité
            </a>
          </span>
        </label>
      </div>
    </>
  );
}
