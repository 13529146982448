/* eslint class-methods-use-this: "off" */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import jwtDecode from 'jwt-decode';
import Listenable from '@concerto-home/listenable';
import storage from '@concerto-home/storage';
var Security = /** @class */ (function (_super) {
    __extends(Security, _super);
    function Security() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Security.prototype.authenticate = function (uuid, token, source, roles) {
        if (roles === void 0) { roles = []; }
        var data = jwtDecode(token);
        // this.user = data.sub;
        this.tokenExp = data.exp;
        this.user = uuid;
        this.token = token;
        this.source = source;
        this.roles = roles;
    };
    Security.prototype.deAuthenticate = function (callback) {
        this.user = null;
        this.token = null;
        this.tokenExp = null;
        this.roles = [];
        if (typeof callback === 'function') {
            callback();
        }
        this.trigger('deAuthenticate');
    };
    Security.prototype.isAuthenticated = function () {
        return typeof this.user === 'string' && typeof this.token === 'string';
    };
    Security.prototype.isTokenExpired = function () {
        return this.tokenExp < Date.now() / 1000;
    };
    Security.prototype.getRole = function () {
        var roles = this.roles;
        if (roles.includes('ROLE_ADMIN')) {
            return 'ROLE_ADMIN';
        }
        if (roles.includes('ROLE_PARTNER')) {
            return 'ROLE_PARTNER';
        }
        if (roles.includes('ROLE_CUSTOMER')) {
            return 'ROLE_CUSTOMER';
        }
        return null;
    };
    Security.prototype.isGranted = function (role) {
        return this.roles.includes(role);
    };
    Object.defineProperty(Security.prototype, "user", {
        get: function () {
            return storage.get('user') || null;
        },
        set: function (user) {
            storage.set('user', user);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Security.prototype, "token", {
        get: function () {
            return storage.get('token') || null;
        },
        set: function (token) {
            storage.set('token', token);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Security.prototype, "tokenExp", {
        get: function () {
            var _a;
            return Number((_a = storage.get('token-exp')) !== null && _a !== void 0 ? _a : 0);
        },
        set: function (exp) {
            storage.set('token-exp', exp);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Security.prototype, "source", {
        get: function () {
            var _a;
            return (_a = storage.get('source')) !== null && _a !== void 0 ? _a : 'concerto';
        },
        set: function (source) {
            storage.set('source', source);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Security.prototype, "roles", {
        get: function () {
            return JSON.parse(storage.get('roles') || '[]');
        },
        set: function (roles) {
            storage.set('roles', JSON.stringify(roles));
        },
        enumerable: false,
        configurable: true
    });
    return Security;
}(Listenable));
export { Security };
export default new Security();
