import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export default function Mask({ children }: Props): React.ReactElement {
  return (
    <>
      <div className="goptimize-mask">
        <div className="goptimize-mask-transparent" />
        <div className="goptimize-mask-content">{children}</div>
      </div>
    </>
  );
}
