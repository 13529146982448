import React, { FC } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useParams } from 'react-router';
import { CheckboxInput } from 'components/form/inputs';
import InformationTravauxAPI from 'features/dashboard/services/informationTravauxAPI';
import formModel from 'features/dashboard/models/formModel';
import { trackClickSendAH } from 'data-layer';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { useRecoilValue } from 'recoil';
import { countInProgressSimulationsSelector } from 'features/dashboard/hooks/dashboard';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import lang from '../../lang/projets.json';
import Button from '../button/button';

interface ValidateMandataireProps {
  show: boolean;
  close: () => void;
  onRefresh: () => void;
  isValidateMandataire: boolean;
}

const ValidateMandataire: FC<ValidateMandataireProps> = ({ show, close, onRefresh, isValidateMandataire }) => {
  const { simulationId } = useParams();
  const { finalisation } = lang || {};

  if (!simulationId) {
    return null;
  }

  const { formField } = formModel;
  const { isMandataireUnique } = formField;

  const { simulation } = useSimulationResultat(simulationId);

  // used in tracking analytics
  const countInProgressSimulations = useRecoilValue(countInProgressSimulationsSelector);

  const handlePostToControleDossier = async () => {
    const response = await InformationTravauxAPI.postToControleDossier(simulationId);
    if (response.success) {
      onRefresh();
      // Target Tracking - AH Sent
      trackClickSendAH(simulation as Resultat, countInProgressSimulations);
    }
  };

  return (
    <Modal show={show} onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>Vous êtes sur le point de nous faire parvenir votre dossier</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <CheckboxInput tooltip={finalisation.declarationTooltip} rounded label={isMandataireUnique.label} name={isMandataireUnique.name} />
          </Col>
        </Row>
        <div className="d-flex justify-content-center mt-4">
          <Button variant="primary" id="validate-mandataire" isDisabled={!isValidateMandataire} label="Valider" type="button" classNames="m-4" onClick={handlePostToControleDossier} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ValidateMandataire;
