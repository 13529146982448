import { api, Resource } from 'core';
import { mapAPIResultat } from '../mapper/resultat.mapper';
import { Resultat } from '../interfaces/resultat';

export class ResultatAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  getSimulation(simulationId: string): Promise<Resultat> {
    return api
      .get(this.buildUri(`simulation/${simulationId}`))
      .then(({ data }) => mapAPIResultat(data))
      .catch((err) => {
        throw err;
      });
  }
}

export default new ResultatAPI();
