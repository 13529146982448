import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { surveyCurrent, surveyCurrentQuestion, surveyCurrentResponse, surveyErrors, surveyQuestions, surveyResponses, surveyId, surveyToken, surveyCurrentValidation, surveyBnb, surveySubmitting, surveyPrevious, surveyHasHouseShape } from './survey';
var states = [surveyResponses, surveyCurrent, surveyErrors, surveyQuestions, surveyId, surveyToken, surveyCurrentValidation, surveyBnb, surveySubmitting, surveyPrevious, surveyHasHouseShape];
var selectors = [surveyCurrentQuestion, surveyCurrentResponse];
export function get(key, type) {
    if (type === void 0) { type = 'states'; }
    var target = type === 'states' ? states : selectors;
    for (var i = 0, len = target.length; i < len; i += 1) {
        if (target[i].key === key) {
            return target[i];
        }
    }
    throw new Error("Try to using undefined ".concat(type, " \"").concat(key, "\""));
}
export function useSelector(key) {
    return useRecoilValue(get(key, 'selector'));
}
export function useSharedState(key) {
    return useRecoilState(get(key));
}
export function useSharedValue(key) {
    return useRecoilValue(get(key));
}
export function useSetSharedState(key) {
    return useSetRecoilState(get(key));
}
export default states;
