import React from 'react';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import Button from '../button/button';

interface FileUploaderCtaProps {
  onClick: () => void;
  isAllAnswered: boolean | null;
  fieldName: string;
}

const getClassNames = (isAllAnswered: boolean | null): string => {
  if (isAllAnswered === null) return '';
  return isAllAnswered ? 'verified' : 'not-verified';
};

const getLabel = (isAllAnswered: boolean | null, fieldName: string): string => {
  const isDevis = fieldName === 'devis';
  if (isAllAnswered === null) {
    return isDevis ? 'Votre devis est-il conforme ?' : 'Votre facture est-elle conforme ?';
  }
  const conformityStatus = isAllAnswered ? 'est conforme' : 'comporte des erreurs';
  return `Votre ${fieldName} ${conformityStatus}`;
};

const getIcon = (isAllAnswered: boolean | null) => {
  if (isAllAnswered === null) return null;
  return isAllAnswered ? FaCheckCircle : FaExclamationCircle;
};

const FileUploaderCta: React.FC<FileUploaderCtaProps> = ({ onClick, isAllAnswered, fieldName }) => {
  const classNames = getClassNames(isAllAnswered);
  const label = getLabel(isAllAnswered, fieldName);
  const Icon = getIcon(isAllAnswered);

  return (
    <div className="file-uploader__cta">
      <Button variant="full" onClick={onClick} id="upload-cta" label={label} type="button" classNames={classNames} icon={Icon} />
    </div>
  );
};

export default FileUploaderCta;
