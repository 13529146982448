/* eslint-disable react/prop-types */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSharedState } from '../../../states';
export default function ChoiceInput(_a) {
    var question = _a.question, onChange = _a.onChange, value = _a.value, controlled = _a.controlled, nested = _a.nested, showChoice = _a.showChoice;
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var alias = question.alias, choices = question.choices;
    var currentValue = controlled ? value : responses[alias];
    var isSelected = function (v) { return currentValue === v; };
    var handleChange = function (nextValue) {
        if (typeof onChange === 'function') {
            onChange(nextValue);
        }
        if (!controlled) {
            setResponses(function (r) {
                var _a;
                return (__assign(__assign({}, r), (_a = {}, _a[alias] = nextValue, _a)));
            });
        }
    };
    var canDisplayChoice = function (v) { return (showChoice ? showChoice(v) : true); };
    return (React.createElement("div", { className: "diagnosticQuestionList ".concat(question.display === 'column' ? 'displayColumn' : '') }, choices &&
        choices.map(function (choice) {
            return canDisplayChoice(choice.value) && (React.createElement(React.Fragment, { key: choice.value },
                React.createElement("div", { className: "diagnosticQuestionListItem ".concat(isSelected(JSON.parse(choice.value)) ? 'selected' : '', " ").concat(!choice.picto ? 'justText' : '') },
                    React.createElement("div", { className: "diagnosticQuestionListItemContent", onMouseUp: function () { return handleChange(JSON.parse(choice.value)); } },
                        choice.label,
                        choice.picto && (React.createElement("div", { className: "diagnosticQuestionListItemPicto" },
                            React.createElement("img", { src: choice.picto, alt: choice.label, className: "img-fluid" }))))),
                nested && nested(choice.value)));
        })));
}
