import React from 'react';
import { IconType } from 'react-icons';

interface BoxItemProps {
  icon: IconType;
  value: string | null;
}

const BoxItem: React.FC<BoxItemProps> = ({ icon: Icon, value }) => (
  <p className="dashboard__demandes__list__item__content__infos--box">
    <Icon size={20} color="#00556E" />
    {value}
  </p>
);

export default BoxItem;
