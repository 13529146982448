import React from 'react';
import { CheckboxInput } from 'components/form/inputs';
import { getEnv } from 'utils/env';

export const Cgu = (): React.ReactElement => (
  <CheckboxInput
    name="terms"
    label={
      <div>
        En cochant cette case, vous acceptez les{' '}
        <a href={`${getEnv('CMS_HOST')}/conditions-generales-dutilisation`} target="_blank" rel="noreferrer">
          Conditions Générales d&apos;Utilisation
        </a>
        .
      </div>
    }
  />
);

export default Cgu;
