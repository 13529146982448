import { ModeAffichageDisplay, ModeReponseType, QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';
import { InitialQuestionApi } from 'features/primes/simulation/questionnaire/interfaces/questionnaireAPI';

export function mapAPIQuestionAH(data: InitialQuestionApi[]): QuestionPrime[] {
  const mapQuestion = (item: InitialQuestionApi): QuestionPrime => {
    const question = item.question ?? {};

    let responsebyType;

    if (question.valeur !== null && question.valeur !== undefined) {
      responsebyType = question.valeur;
    } else if (question.valeur_defaut !== null && question.valeur_defaut !== undefined) {
      if (question.mode_reponse !== 'saisie_numerique') {
        responsebyType = question.valeur_defaut;
      } else {
        responsebyType = Number(question.valeur_defaut);
      }
    } else {
      responsebyType = '';
    }

    const userAnswer = {
      questionId: question.uuid_question,
      value: question.valeur_defaut ? Number(question.valeur_defaut) : '',
      parcoursId: item.uuid_parcours ?? '',
      response: question.reponses?.map((reponse) => String(reponse.uuid_reponse)) ?? [],
    };

    const choices = question.reponses?.map((reponse) => ({
      id: reponse.uuid_reponse,
      label: reponse.libelle,
      picto: reponse.pictogramme,
      value: String(reponse.valeur),
      eligible: !reponse.ineligible,
      children: mapAPIQuestionAH(reponse.children ?? []),
    }));

    return {
      parcoursId: item.uuid_parcours,
      questionId: question.uuid_question,
      blocName: question.bloc_name,
      errorLabel: question.libelle_erreur,
      label: question.libelle,
      labelInput: question.libelle_saisie_numerique ?? '',
      more: '',
      type: ModeReponseType[question.mode_reponse] ?? 'column',
      alias: ModeReponseType[question.mode_reponse] ?? null,
      display: ModeAffichageDisplay[question.mode_affichage],
      unit: question.unite_saisie_numerique ?? null,
      response: responsebyType,
      userAnswer,
      rules: {
        required: true,
        min: question.constraints?.minValue ?? 1,
        max: question.constraints?.maxValue ?? undefined,
      },
      why: question.libelle_info ?? '',
      help: question.libelle_aide ?? '',
      choices,
      group: {
        id: Math.random(),
        label: item.libelle_groupe,
        progression: question.progression,
      },
      isStepProgression: question.is_etape_progression,
      codeTravaux: item.code_travaux,
      isCodeTravauxRequired: question.code_travaux_requis,
      responseUrl: question.reponses_url,
      valid: !!question.valeur_defaut,
      message: {
        conditionMessage: question.message?.conditionMessage ?? '',
        message: question.message?.message ?? '',
      },
    };
  };

  return data.map(mapQuestion);
}
