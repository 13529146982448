import React from 'react';
import { FaLocationDot } from 'react-icons/fa6';
import { LuBuilding2 } from 'react-icons/lu';
import { Professionnel } from 'features/dashboard/types/professionnel';
import { getFullAdress } from 'features/dashboard/helpers/utils';
import BodyItem from './bodyItem';

interface ProfessionalBodyProps {
  professional: Professionnel;
}

const ProfessionalBody: React.FC<ProfessionalBodyProps> = ({ professional }) => (
  <div className="form__container__content__body">
    <BodyItem icon={FaLocationDot} value={getFullAdress(professional.address)} />
    <BodyItem icon={LuBuilding2} value={`SIRET : ${professional.siret}`} />
  </div>
);

export default ProfessionalBody;
