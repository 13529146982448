import { api } from 'core';
import security from '@concerto-home/security';
import React, { useEffect } from 'react';
import { useSetSharedState } from 'states';
import { getEnv } from 'utils/env';

export default function Logout(): React.ReactElement {
  const setUser = useSetSharedState('security:user');

  useEffect(() => {
    api.logout().finally(() => {
      security.deAuthenticate();
      setUser(null);
      window.location.href = getEnv('CMS_HOST');
    });
  }, []);

  return (
    <div className="container d-flex justify-content-center align-items-center mt-5">
      <i>Déconnexion en cours...</i>
    </div>
  );
}
