import React from 'react';
import { downloadPayload } from '../../../utils/survey';
var steps = [
    {
        text: 'Récupération des données du questionnaire',
        icon: 'icon-checklist',
    },
    {
        text: 'Pré-sélection des possibles travaux de rénovation',
        icon: 'icon-settings',
    },
    {
        text: 'Simulation des travaux de rénovation',
        icon: 'icon-work',
    },
    {
        text: 'Identification des postes de déperdition thermique',
        icon: 'icon-temp',
    },
    {
        text: 'Identification des scénarios de travaux performants',
        icon: 'icon-setting',
    },
    {
        text: 'Calcul des coûts et des primes',
        icon: 'icon-cost',
    },
];
export default function LoaderEnd(_a) {
    var _b;
    var currentStage = _a.currentStage, _c = _a.processingTaskUrl, processingTaskUrl = _c === void 0 ? '' : _c, id = _a.id, query = _a.query;
    var stage = currentStage + 1;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "loaderEndContainer" },
            React.createElement("i", { className: "loaderEndIcon ".concat(((_b = steps[stage]) === null || _b === void 0 ? void 0 : _b.icon) || 'icon-validate') }),
            React.createElement("h2", { className: "loaderEndTitle" },
                React.createElement("span", null, "L'analyse de votre logement est en cours"),
                React.createElement("div", { className: "ballLoader" },
                    React.createElement("div", { className: "ballLoaderBall ball1" }),
                    React.createElement("div", { className: "ballLoaderBall ball2" }),
                    React.createElement("div", { className: "ballLoaderBall ball3" }))),
            React.createElement("p", null, "Nous ne sommes pas magiciens \u2728 (m\u00EAme si parfois on aimerait bien !), cela peut prendre quelques minutes et c\u2019est tout \u00E0 fait normal ! Nous compilons toutes les donn\u00E9es de votre logement pour vous fournir l\u2019analyse la plus pr\u00E9cise possible."),
            React.createElement("p", null,
                React.createElement("ul", { className: "loaderEndList" }, steps.map(function (step, index) { return (React.createElement("li", { key: step.text, className: "loaderEndListItem ".concat(index > stage ? 'disabled' : '', " ").concat(index === stage ? 'current' : '') },
                    React.createElement("div", { className: "loaderEndListItemIcon" },
                        React.createElement("i", { className: stage === index ? 'icon-glassloader' : 'icon-validate-on' })),
                    step.text)); }))),
            React.createElement("p", null,
                "Cette analyse \u00E9nerg\u00E9tique vous est propos\u00E9e gr\u00E2ce \u00E0 un moteur de calcul con\u00E7u par le CSTB (Centre Scientifique et Technique du B\u00E2timent).",
                React.createElement("br", null),
                React.createElement("br", null),
                "Travaux, aides possibles, artisans\u2026 patientez pour d\u00E9couvrir nos solutions personnalis\u00E9es, concr\u00E8tes et parfaitement adapt\u00E9es pour r\u00E9aliser des \u00E9conomies d\u2019\u00E9nergie."),
            processingTaskUrl && (React.createElement("small", { className: "mt-5 text-center" },
                React.createElement("a", { href: processingTaskUrl, target: "_blank", rel: "noreferrer" }, "O\u00F9 en est le CSTB ?"),
                React.createElement("br", null),
                React.createElement("button", { className: "btn", type: "button", onClick: function (e) {
                        e.preventDefault();
                        downloadPayload(String(id), query);
                    } }, "T\u00E9l\u00E9charger le payload"))))));
}
