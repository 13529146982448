import { useField } from 'formik';
import React, { useEffect, useState } from 'react';

type Props = {
  property: string;
};

export default function PasswordIndicator({ property }: Props): React.ReactElement {
  const [{ value }] = useField(property);

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordCharacter, setPasswordCharacter] = useState('pending');
  const [passwordLetter, setPasswordLetter] = useState('missing');
  const [passwordNumber, setPasswordNumber] = useState('ok');
  const [passwordSpecial, setPasswordSpecial] = useState('ok');

  useEffect(() => {
    if (!value) {
      setPasswordStrength(0);
      setPasswordCharacter('pending');
      setPasswordLetter('pending');
      setPasswordNumber('pending');
      setPasswordSpecial('pending');
      return;
    }

    let strength = 0;

    if (value.length >= 14) {
      setPasswordCharacter('ok');
      strength += 1;
    } else {
      setPasswordCharacter('missing');
    }

    if (value.match(/[a-z]/g) && value.match(/[A-Z]/g)) {
      setPasswordLetter('ok');
      strength += 1;
    } else {
      setPasswordLetter('missing');
    }

    if (value.match(/[0-9]/g)) {
      setPasswordNumber('ok');
      strength += 1;
    } else {
      setPasswordNumber('missing');
    }

    if (value.match(/\W|_/g)) {
      setPasswordSpecial('ok');
      strength += 1;
    } else {
      setPasswordSpecial('missing');
    }

    setPasswordStrength(strength);
  }, [value]);

  return (
    <div className="accountInfosPassword">
      <h3 className="accountInfosPasswordTitle">Niveau de sécurité du mot de passe :</h3>
      <div className="accountInfosPasswordStrength">
        <div className={`accountInfosPasswordStrengthItem weak ${passwordStrength === 1 || passwordStrength === 2 ? 'active' : ''}`}>Faible</div>
        <div className={`accountInfosPasswordStrengthItem medium ${passwordStrength === 3 ? 'active' : ''}`}>Moyen</div>
        <div className={`accountInfosPasswordStrengthItem strong ${passwordStrength === 4 ? 'active' : ''}`}>Fort</div>
      </div>
      <p className="accountInfosPasswordDetails">Le mot de passe doit comporter 14 caractères (chiffres, lettres et caractères spéciaux).</p>
      <div className="accountInfosPasswordRequired">
        <div className={`accountInfosPasswordRequiredItem ${passwordCharacter === 'pending' ? 'disabled' : ''} ${passwordCharacter === 'missing' ? 'withError' : ''}`}>
          <div className="accountInfosPasswordRequiredItemIcon">
            <i className={passwordCharacter === 'missing' ? 'icon-error' : 'icon-validate-on'} />
          </div>
          <div className="accountInfosPasswordRequiredItemLabel">Au moins 14 caractères</div>
        </div>

        <div className={`accountInfosPasswordRequiredItem ${passwordLetter === 'pending' ? 'disabled' : ''} ${passwordLetter === 'missing' ? 'withError' : ''}`}>
          <div className="accountInfosPasswordRequiredItemIcon">
            <i className={passwordLetter === 'missing' ? 'icon-error' : 'icon-validate-on'} />
          </div>
          <div className="accountInfosPasswordRequiredItemLabel">Lettres (minuscules et majuscules)</div>
        </div>

        <div className={`accountInfosPasswordRequiredItem ${passwordNumber === 'pending' ? 'disabled' : ''} ${passwordNumber === 'missing' ? 'withError' : ''}`}>
          <div className="accountInfosPasswordRequiredItemIcon">
            <i className={passwordNumber === 'missing' ? 'icon-error' : 'icon-validate-on'} />
          </div>
          <div className="accountInfosPasswordRequiredItemLabel">Chiffres</div>
        </div>

        <div className={`accountInfosPasswordRequiredItem ${passwordSpecial === 'pending' ? 'disabled' : ''} ${passwordSpecial === 'missing' ? 'withError' : ''}`}>
          <div className="accountInfosPasswordRequiredItemIcon">
            <i className={passwordSpecial === 'missing' ? 'icon-error' : 'icon-validate-on'} />
          </div>
          <div className="accountInfosPasswordRequiredItemLabel">Caractères spéciaux</div>
        </div>
      </div>
    </div>
  );
}
