import React, { useCallback, useState } from 'react';
import './fileUpload.scss';
import { useDropzone } from 'react-dropzone';
import { FiFileText, FiAlertCircle, FiDownload } from 'react-icons/fi';
import { LiaHourglassHalfSolid } from 'react-icons/lia';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FormikValues, useField, useFormikContext } from 'formik';
import { SUPPORTED_FILE_TYPES } from 'features/dashboard/helpers/utils';
import Typography from '../typography/typography';
import { FormInitialValues } from '../identificationProfessionnel/validation/formInitialValues';
import errorMessage from '../../lang/errorMessages.json';

interface FileUploadProps {
  name: string;
  acceptedFileTypes?: string[];
  allowUpload?: boolean;
  onDownload?: () => void;
  onUpload?: (file: File) => Promise<void>;
  onDelete?: () => void;
  className?: string;
  isLoading?: boolean;
  isDeletable?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({ name, acceptedFileTypes = SUPPORTED_FILE_TYPES, allowUpload = true, onDownload, onUpload, className, isLoading, onDelete, isDeletable }) => {
  const [field] = useField(name);
  const [error, setError] = useState<string | null>(null);
  const { setFieldValue, setFieldError } = useFormikContext<FormInitialValues & FormikValues>();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const invalidFiles = acceptedFiles.filter((file) => acceptedFileTypes.length > 0 && !acceptedFileTypes.includes(file.type));

      if (invalidFiles.length > 0) {
        setError(errorMessage.fileUpload.invalidFileType);
        setFieldError(name, errorMessage.fileUpload.invalidFileType);
      } else {
        setError(null);
        setFieldValue(name, acceptedFiles[0]);
        if (field.value || acceptedFiles[0]) {
          await onUpload?.(acceptedFiles[0]);
        }
      }
    },
    [acceptedFileTypes, setFieldValue, setFieldError, name, field.value, onUpload]
  );

  const renderFileIcon = (file: File) => {
    const fileType = file?.type?.split('/')[0];
    if (fileType === 'image') return <img className="file-icon" src={URL.createObjectURL(file)} alt="preview" />;
    return <FiFileText className="file-icon" />;
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({ onDrop, disabled: !allowUpload, maxSize: 10 * 1024 * 1024, multiple: false });

  const errorMessagesFr = fileRejections.map((err) => {
    let errorMessageFr = '';
    err.errors.forEach((errorFile) => {
      errorMessageFr = errorFile.code === 'file-too-large' ? errorMessage.fileUpload.invalidFileType : errorFile.message;
    });
    return errorMessageFr;
  });

  const handleDownload = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // if field.value is a file, so we can download it locally without calling the server
    if (field.value && field.value instanceof File) {
      const url = URL.createObjectURL(field.value);
      window.open(url, '_blank');
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (onDownload) onDownload();
  };

  const handleReplace = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setFieldValue(field.value, null);
  };

  const customClass = `dropzone ${!allowUpload ? 'notAllowed' : ''} ${isDragActive ? 'active' : ''} ${className}`;
  const existingFileName = field.value?.name;
  const allowToDelete = Boolean(isDeletable && existingFileName && !isLoading);

  return (
    <div className={`file-upload ${!allowUpload ? 'notAllowed' : ''}`}>
      <div {...getRootProps()} className={customClass}>
        <input {...getInputProps()} data-cy={name} />

        {existingFileName ? (
          <div className="file-list">
            <div key={existingFileName} className="file-item">
              <div className="file-info" title={existingFileName}>
                {existingFileName && renderFileIcon(existingFileName)}
                <span className="file-name">{existingFileName}</span>
              </div>
              <div className="file-actions">
                <button type="button" className="remove-btn" onClick={handleDownload}>
                  Consulter
                </button>
                <button type="button" className="remove-btn" onClick={handleReplace}>
                  Remplacer
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={`wrapper ${!allowUpload ? 'notAllowed' : ''}`}>
            <FiDownload size={40} />
            <Typography variant="h3" size="xl" fontWeight="normal" color="black" customClass="mt-4">
              Glisser mes documents ou
            </Typography>
            <Typography variant="h3" size="xl" fontWeight="600" color="black" customClass="mt-4">
              Cliquer ici
            </Typography>
          </div>
        )}
        {isLoading && (
          <div className="spinner-container">
            <LiaHourglassHalfSolid className="loading-icon" size={25} />
            <p className="spinner-text">Téléchargement en cours...</p>
          </div>
        )}
      </div>

      {allowToDelete ? (
        <div className="delete-button-container">
          <div className="delete-button-info">
            <FiFileText size={20} />
            <span className="delete-button-info__text" title={existingFileName}>
              {existingFileName}
            </span>
          </div>
          <button className="delete-button" type="button" onClick={onDelete}>
            <FaRegTrashAlt size={20} />
          </button>
        </div>
      ) : null}

      {error && (
        <div className="error-message">
          <FiAlertCircle className="error-icon" />
          {error}
        </div>
      )}
      {errorMessagesFr.length > 0 && (
        <div className="error-message">
          <FiAlertCircle className="error-icon" />
          {errorMessagesFr.join('')}
        </div>
      )}
    </div>
  );
};

export default FileUpload;
