import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { motifsAbandonStates } from 'features/dashboard/states/motifsAbandon';
import { useRecoilValue } from 'recoil';
import simulationsAPI from 'features/dashboard/services/simulationsAPI';

import { useSimulations } from 'features/dashboard/hooks/dashboard';
import { SIMULATION } from 'features/dashboard/helpers/utils';
import { MOTIF_OTHER_ID } from 'utils/constants';
import Button from '../../../button/button';

interface MotifOverlayProps {
  simulationId: string;
  onClose: () => void;
}

const MotifOverlay: React.FC<MotifOverlayProps> = ({ simulationId, onClose }) => {
  const [selectedChoice, setSelectedChoice] = useState('');
  const [otherReason, setOtherReason] = useState(false);
  const [comment, setComment] = useState('');
  const { refresh } = useSimulations(SIMULATION.IN_PROGRESS, false);

  const choices = useRecoilValue(motifsAbandonStates.state);

  const handleOnClose = () => onClose();

  const handleSelectedChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedChoice(e.target.id);
    if (e.target.id === MOTIF_OTHER_ID) {
      setOtherReason(true);
    } else {
      setOtherReason(false);
    }
  };

  const handleAbandonSimulation = async () => {
    const response = await simulationsAPI.abandonSimulation(simulationId, {
      motifAbandon: selectedChoice,
      ...(otherReason && { commentaire: comment }),
    });

    if (response.abandon) {
      refresh();
      onClose();
    }
  };

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const isDisabled = selectedChoice === '' || (otherReason && comment === '');

  return (
    <Modal show centered onHide={handleOnClose}>
      <Modal.Header>
        <Modal.Title>Abandonner cette demande ?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="motif__Overlay__subtitle">Expliquez-nous les raisons de votre choix :</p>
        <div className="motif__Overlay__choices">
          {choices.map((choice) => (
            <div key={choice.id} className="motif__Overlay__choices__choice">
              <input type="checkbox" id={choice.id} value={selectedChoice} checked={choice.id === selectedChoice} onChange={handleSelectedChoice} />
              <label htmlFor={choice.id}>{choice.libelle}</label>
            </div>
          ))}
        </div>
        {otherReason && (
          <div className="motif__Overlay__otherReason">
            <textarea id="otherReason" value={comment} onChange={onChangeComment} data-cy="comment-motif" />
          </div>
        )}
        <p className="motif__Overlay__disclaimer"> En cliquant sur “confirmer” vous refusez la proposition de prime faite par Sonergia pour les travaux mentionnés. </p>
        <div className="motif__Overlay__actions d-flex justify-content-center gap-4 ">
          <Button variant="secondary" id="annuler" label="Annuler" type="button" onClick={handleOnClose} />
          <Button variant="primary" id="confirmer" label="Confirmer" type="button" isDisabled={isDisabled} onClick={handleAbandonSimulation} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MotifOverlay;
