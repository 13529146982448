import React from 'react';
import { FaChevronDown } from 'react-icons/fa';

export default function Footer(): React.ReactElement {
  const scrollToBottom = () => {
    const offsetBottom = document.body.offsetTop + document.body.offsetHeight;
    window.scrollTo({ top: offsetBottom, behavior: 'smooth' });
  };

  return (
    <>
      <button type="button" onClick={scrollToBottom} className={`footerScroll ${window.innerHeight < document.body.scrollHeight ? 'show' : ''}`}>
        <FaChevronDown size={24} />
      </button>
    </>
  );
}
