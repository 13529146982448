import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const DossierTermine: React.FC = () => (
  <>
    <div className="controle-dossier__title">Nos équipes procèdent à la vérification de votre dossier</div>
    <div className="controle-dossier__subtitle">
      <p>Elles vous contacteront sous 45 jours.</p>
    </div>
    <div className="controle-dossier__incomplet_information done">
      <div className="controle-dossier__incomplet_information__icon">
        <FaCheckCircle size={40} color="#23C275" />
      </div>
      <div className="controle-dossier__incomplet_information__title done">Votre dossier à été validé par nos équipes</div>
    </div>
  </>
);

export default DossierTermine;
