import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRouteGuard from 'components/core/private-route-guard';

import Survey from 'pages/survey';
import Diagnostic from 'pages/survey/diagnostic';
import Logout from 'pages/security/logout';
import Login from 'pages/security/login';
import Register from 'pages/security/register';
import ForgotPassword from 'pages/security/forgot-password';
import ResetPassword from 'pages/security/reset-password';
import NotFound from 'pages/not-found';
import Account from 'pages/account';
import AccountManagementLayout from 'pages/account/managment-layout';
import PersonalInfos from 'pages/account/personal-infos';
import PersonalData from 'pages/account/personal-data';
import LegalNotice from 'pages/account/legal-notice';
import VerifyAccount from 'pages/security/verify-account';
import App from 'components/app';
import Questionnaire from 'features/primes/simulation/questionnaire/pages/questionnaire';
import Create from 'features/primes/simulation/questionnaire/pages/create';
import Results from 'features/primes/simulation/resultat/pages/results';
import Index from 'features/dashboard/pages';
import Projets from 'features/dashboard/pages/projets/projets';
import Dashboard from 'features/dashboard/pages/dashboard/dashboard';
import DemandeDetails from 'features/dashboard/pages/demandes/demandeDetails';
import InformationsAAT from 'features/primes/gestion-dossier/aat/pages/informationsAAT';
import EtapesAAT from 'features/primes/gestion-dossier/aat/pages/etapesAAT';
import Access from './account/access';

export default (): React.ReactElement => (
  <BrowserRouter>
    <Routes>
      <Route path="" element={<App />}>
        <Route path="logout" element={<Logout />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgot_password" element={<ForgotPassword />} />
        <Route path="reset_password" element={<ResetPassword />} />
        <Route path="verify_account/:token" element={<VerifyAccount />} />
        <Route path="analyse-energetique/:id" element={<Survey />} />
        <Route path="analyse-energetique/:id/diagnostic" element={<Diagnostic />} />
        <Route path="simulation-prime" element={<Questionnaire />} />
        <Route path="simulation-prime/creation" element={<Create />} />
        <Route
          path="simulation-prime/resultat/:simulationId"
          element={
            <PrivateRouteGuard>
              <Results />
            </PrivateRouteGuard>
          }
        />

        <Route
          path="/simulation-prime/*"
          element={
            <PrivateRouteGuard>
              <Index />
            </PrivateRouteGuard>
          }
        >
          <Route
            path="informations-AAT/:simulationId"
            element={
              <PrivateRouteGuard>
                <InformationsAAT />
              </PrivateRouteGuard>
            }
          />
          <Route
            path="les-etapes/:simulationId"
            element={
              <PrivateRouteGuard>
                <EtapesAAT />
              </PrivateRouteGuard>
            }
          />
        </Route>

        <Route
          path="/dashboard/*"
          element={
            <PrivateRouteGuard>
              <Index />
            </PrivateRouteGuard>
          }
        >
          <Route path="" element={<Dashboard />} />
          <Route path=":simulationId" element={<DemandeDetails />} />
          <Route path="constitution-dossier/:simulationId" element={<Projets />} />
        </Route>

        <Route
          path="account"
          element={
            <PrivateRouteGuard>
              <Account />
            </PrivateRouteGuard>
          }
        />

        <Route path="account/managment" element={<AccountManagementLayout />}>
          <Route
            path=""
            element={
              <PrivateRouteGuard>
                <PersonalInfos />
              </PrivateRouteGuard>
            }
          />
          <Route
            path="data"
            element={
              <PrivateRouteGuard>
                <PersonalData />
              </PrivateRouteGuard>
            }
          />
          <Route
            path="legal"
            element={
              <PrivateRouteGuard>
                <LegalNotice />
              </PrivateRouteGuard>
            }
          />
          <Route
            path="access"
            element={
              <PrivateRouteGuard>
                <Access />
              </PrivateRouteGuard>
            }
          />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  </BrowserRouter>
);
