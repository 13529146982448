var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSharedState, useSharedValue } from '../../../../states';
export default (function (_a) {
    var onChange = _a.onChange;
    var questions = useSharedValue('survey:questions');
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var qNbrOccupants = questions.filter(function (q) { return q.alias === 'number_of_occupants'; })[0];
    var handleNoResponse = function () {
        setResponses(function (r) { return (__assign(__assign({}, r), { number_of_occupants: null })); });
        if (typeof onChange === 'function') {
            onChange(null);
        }
    };
    var applyMaxAndMin = function (val) {
        var value = val;
        var _a = qNbrOccupants.rules, min = _a.min, max = _a.max;
        if (Number(value) < min) {
            value = min;
        }
        if (Number(value) > max) {
            value = max;
        }
        return value;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "diagnosticQuestionListItemOccupant" },
            React.createElement("div", { className: "diagnosticQuestionListItemOccupantIncrement" },
                React.createElement("div", { className: "diagnosticQuestionListItemIncrement" },
                    React.createElement("button", { className: "diagnosticQuestionTextInputBtn", type: "button", onClick: function () {
                            return setResponses(function (r) { return (__assign(__assign({}, r), { number_of_occupants: responses.number_of_occupants > 1 ? applyMaxAndMin(Number(responses.number_of_occupants) - 1) : null })); });
                        } }, "-"),
                    React.createElement("input", { id: qNbrOccupants.value, className: "diagnosticQuestionTextInput increment", type: "text", onChange: function (e) {
                            var value = e.target.value.replace(/\D/g, '');
                            setResponses(function (r) { return (__assign(__assign({}, r), { number_of_occupants: value ? applyMaxAndMin(Number(value)) : null })); });
                        }, value: responses.number_of_occupants || '' }),
                    React.createElement("button", { className: "diagnosticQuestionTextInputBtn", type: "button", onClick: function () {
                            return setResponses(function (r) { return (__assign(__assign({}, r), { number_of_occupants: responses.number_of_occupants ? applyMaxAndMin(Number(responses.number_of_occupants) + 1) : 1 })); });
                        } }, "+"))),
            React.createElement("button", { type: "button", onClick: function () { return handleNoResponse(); }, className: "diagnosticQuestionListItem justText ".concat(!responses.number_of_occupants ? 'selected' : '') },
                React.createElement("div", { className: "diagnosticQuestionListItemContent" }, "Je ne pr\u00E9f\u00E8re pas r\u00E9pondre")))));
});
