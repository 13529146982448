import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { buildAccountContactFormValidationSchema, contactFormResource } from 'core';
import { TextareaInput, CheckboxInput } from 'components/form/inputs';
import { checkApiFormViolations } from 'utils/form';
import FormListener from 'components/form/listener';

import type { FormikHelpers } from 'formik';
import type { AccountContactForm } from 'core';

interface Props {
  handleComplete?: (_data: AccountContactForm) => void;
  handleFailed?: () => void;
  handleChange?: () => void;
  type: string;
}

const validationSchema = buildAccountContactFormValidationSchema();
const initialValues: AccountContactForm = validationSchema.getDefault();

export default function ContactAccountForm({ handleComplete, handleFailed, handleChange, type }: Props): React.ReactElement {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: AccountContactForm, formik: FormikHelpers<AccountContactForm>) => {
    setLoading(true);

    contactFormResource
      .create(data)
      .then((response) => {
        setLoading(false);

        if (typeof handleComplete === 'function') {
          handleComplete(response);
        }
      })
      .catch((e) => {
        checkApiFormViolations(e, formik)
          .else(() => {
            if (typeof handleFailed === 'function') {
              handleFailed();
            }
          })
          .finally(() => setLoading(false));
      });
  };

  return (
    <Formik onSubmit={onSubmit} initialValues={{ ...initialValues, type }} validationSchema={validationSchema}>
      <Form className="modal-form">
        <FormListener onChange={handleChange} />
        <TextareaInput name="message" label="" placeholder="Vous souhaitez apporter des précisions ou nous faire part d'une spécificité...​" />
        <CheckboxInput inline name="contactByPhone" label="En cochant cette case vous consentez à ce qu’un conseiller prenne contact avec vous par téléphone pour vous accompagner dans votre projet de rénovation énergétique" />

        <div className="modal-form-actions">
          <button type="submit" className="btn btn-primary btn-icon-left" disabled={loading}>
            {loading ? (
              <span>
                <i className="icon-loading" /> Envoi en cours...
              </span>
            ) : (
              <span>
                <i className="icon-validate" /> Envoyer
              </span>
            )}
          </button>
        </div>
      </Form>
    </Formik>
  );
}
