import React, { useState, useEffect, useCallback } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { surveyResource, LoaderSimple, Diagnostic } from 'core';
import security from '@concerto-home/security';
import { getEnv } from 'utils/env';
import { getUserSource, getSurveySource, useSharedState } from 'states';

import type { Survey, Customer } from 'core';
import ReplacingSurvey from 'components/account/replacing-survey';
import { handleClickContactCTA, handleClickExpertCTA, handleClickExportCTA, handleClickHeaderReco, handleClickRenovPartner, handleClickSeeEarnings, handleClickSeeMore, handleFirstTimeOnDiagnosticPage } from 'data-layer';
import ModalConfirm from 'components/modal/modal';
import ProfessionnelHelp from 'features/dashboard/components/identificationProfessionnel/myProfessionnel/professionnelHelp';
import Mask from '../../components/google-optimize/mask';
import PictoItem from '../../components/google-optimize/pictoItem';
import pictoMaison from '../../assets/images/picto-maison.svg';
import pictoAccompagnement from '../../assets/images/picto-accompagnement.svg';
import pictoPrime from '../../assets/images/picto-prime.svg';

export default function DiagnosticPage(): React.ReactElement {
  const { id } = useParams();
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [query] = useState((location.state as any)?.query); // eslint-disable-line
  const [user] = useSharedState('security:user');
  const userSource = getUserSource();
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [canShowModal, setCanShowModal] = useState(!security.user);
  const [eventHandle, setEventHandle] = useState<Event | null>(null);
  const [showProfessionnelHelp, setShowProfessionnelHelp] = useState(false);

  const checkUserSurvey = useCallback((customer: Customer) => typeof customer.survey !== 'string' && customer.survey?.id === Number(survey?.id), [survey]);

  const showPopupContact = () => setShowProfessionnelHelp(true);
  const hidePopupContact = () => setShowProfessionnelHelp(false);

  const redirectToRegister = () =>
    navigate('/register', {
      state: {
        survey: survey?.['@id'],
        query,
      },
    });

  const clickCta = (action: string, rest: string[]) => {
    switch (action) {
      case 'see-more': {
        handleClickSeeMore(rest[0]);
        break;
      }
      case 'click-header': {
        handleClickHeaderReco(rest[0]);
        break;
      }
      case 'see-earnings': {
        handleClickSeeEarnings(rest[0]);
        break;
      }
      case 'contact-gorenove-partner': {
        handleClickRenovPartner(rest[0]);
        window.open('https://sonergia.fr/guide-des-aides/autres-aides/ma-prime-renov/mon-accompagnateur-renov-votre-interlocuteur-cle-pour-renover/', '_blank');
        break;
      }
      case 'contact-pop':
        showPopupContact();
        break;
      case 'contact':
      case 'contact-expert':
        if (action === 'contact-expert') {
          handleClickExpertCTA();
        } else {
          handleClickContactCTA(rest[0], rest[1]);
        }

        if (security.user) {
          navigate('/dashboard');
          break;
        }

        redirectToRegister();
        break;
      case 'export':
      default:
        handleClickExportCTA();

        if (security.user) {
          if (user && checkUserSurvey(user)) {
            surveyResource.export(Number(id));
          }
          break;
        }

        redirectToRegister();
        break;
    }
  };

  const handleClickCta = useCallback(
    (action: string, ...rest: string[]) => {
      clickCta(action, rest);
    },
    [survey, user]
  );

  useEffect(() => {
    surveyResource.read(String(id), query).then(async (item: Survey) => {
      if (!item.diagnostic) {
        navigate(`/analyse-energetique/${id}`);
        return;
      }

      if (!item.diagnosticVisited) {
        handleFirstTimeOnDiagnosticPage(item, user);
        surveyResource.update({ id, diagnosticVisited: true }, query);
      }

      setSurvey(item);
      setLoading(false);
    });
  }, []);

  const handleClickConfirmation = (e: Event) => {
    const target = (e.target || e.srcElement) as HTMLButtonElement;
    const lastElementChild = target?.lastElementChild;

    if (lastElementChild) {
      const { className } = lastElementChild as HTMLButtonElement;
      if (typeof className === 'string' && className.includes('modal-confirm') && canShowModal && !showModalConfirm) {
        setEventHandle(e);
        setShowModalConfirm(true);
        e.preventDefault();
        e.stopImmediatePropagation();
      }
    }
  };

  const handleLeaveConfirmation = (e: Event) => {
    e.preventDefault();
    e.returnValue = true;
    setShowModalConfirm(true);
  };

  useEffect(() => {
    document.querySelector('body')?.addEventListener('mouseleave', handleLeaveConfirmation);
    document.addEventListener('click', handleClickConfirmation, true);
    if (window.screen.width <= 991) {
      window.addEventListener('beforeunload', handleLeaveConfirmation);
    }

    if (eventHandle && !canShowModal && !showModalConfirm) {
      const { target } = eventHandle;
      target?.dispatchEvent(eventHandle);
    }

    return () => {
      document.removeEventListener('mouseleave', handleLeaveConfirmation);
      document.removeEventListener('click', handleClickConfirmation, true);
      window.removeEventListener('beforeunload', handleLeaveConfirmation);
    };
  }, [canShowModal, showModalConfirm]);

  const userSurvey = user?.survey?.id;
  const surveyCreator = user?.survey?.filledBy;
  const isOwnSurvey = (typeof surveyCreator === 'string' ? surveyCreator : surveyCreator?.['@id']) === user?.['@id'];

  return loading || !survey || !survey.diagnostic ? (
    <LoaderSimple />
  ) : (
    <>
      {isOwnSurvey && userSurvey && userSurvey !== Number(id) && <ReplacingSurvey id={String(survey.id)} />}
      <Diagnostic survey={survey} source={getSurveySource(survey, userSource)} debugCstb={getEnv('DEBUG_CSTB') === '1'} query={query} onClickCta={handleClickCta} />

      <ProfessionnelHelp show={showProfessionnelHelp} closeModal={hidePopupContact} />

      <ModalConfirm
        show={canShowModal && showModalConfirm}
        onHide={() => {
          setShowModalConfirm(false);
          setCanShowModal(false);
          setEventHandle(null);
        }}
        action={() => {
          setShowModalConfirm(false);
          setCanShowModal(false);
          clickCta('export', []);
        }}
        content={{
          title: 'Attention ! Vous êtes sur le point de supprimer votre analyse énergétique',
          message: 'Inscrivez-vous pour sauvegarder vos résultats',
          confirm: 'Sauvegarder le résultat',
        }}
      />
      <Mask>
        <h2 className="goptimize-mask-title">
          Lançons ensemble
          <br />
          votre projet de rénovation énergétique
        </h2>
        <button
          type="button"
          className="goptimize-mask-button btn btn-primary"
          onClick={(e) => {
            e.preventDefault();
            handleClickCta('export');
          }}
        >
          <span>Inscrivez-vous</span>
        </button>
        <div className="goptimize-picto-box mt-5">
          <PictoItem picto={pictoMaison} alt="Maison">
            <p>
              Des conseils travaux
              <br />
              complets et sur-mesure
            </p>
          </PictoItem>
          <PictoItem picto={pictoAccompagnement} alt="Accompagnement">
            <p>
              Un accompagnement
              <br />
              personnalisé
            </p>
          </PictoItem>
          <PictoItem picto={pictoPrime} alt="Prime">
            <p>
              Des primes d&lsquo;aide à la
              <br />
              rénovation énergétique
            </p>
          </PictoItem>
        </div>
      </Mask>
    </>
  );
}
