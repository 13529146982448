var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSharedState } from '../../../states';
export default function SelectInput(_a) {
    var question = _a.question, onChange = _a.onChange, value = _a.value, controlled = _a.controlled;
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var alias = question.alias, choices = question.choices;
    var currentValue = controlled ? value : responses[alias];
    var handleChange = function (nextValue) {
        if (typeof onChange === 'function') {
            onChange(nextValue);
        }
        if (!controlled) {
            setResponses(function (r) {
                var _a;
                return (__assign(__assign({}, r), (_a = {}, _a[alias] = nextValue, _a)));
            });
        }
    };
    var id = "select-".concat(question.alias);
    return (React.createElement("div", null,
        React.createElement("label", { htmlFor: id, className: "diagnosticQuestionTextLabel" }, question.label),
        React.createElement("select", { id: id, className: "form-select diagnosticQuestionSelect", onChange: function (e) { return handleChange(e.target.value); }, value: currentValue || '' }, choices &&
            choices.map(function (choice) { return (React.createElement("option", { key: choice.value, value: choice.value }, choice.label)); }))));
}
