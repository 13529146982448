import { Resource, api } from 'core';

export class SendEmailAAT extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  sendAAT(simulationId: string): Promise<{ success: boolean }> {
    return api.post(this.buildUri(`simulation/${simulationId}/aat`)).then(({ data }) => data);
  }
}

export default new SendEmailAAT();
