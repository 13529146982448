/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import axios from 'axios';
import { LuMapPin } from 'react-icons/lu';
import AsyncSelect from 'react-select/async';
import 'leaflet.vectorgrid/dist/Leaflet.VectorGrid';
import customStyles from './building-select.styles';
import config from '../../../config';
var labelizeFeature = function (feature) {
    var properties = feature.properties;
    return "".concat(properties.name, " - ").concat(properties.postcode, " ").concat(properties.city.toUpperCase());
};
var typingTimeout = null;
export default function BuildingSelect(_a) {
    var question = _a.question, onChange = _a.onChange;
    var alias = question.alias;
    var handleSelect = function (option) {
        if (!option || !onChange) {
            return;
        }
        onChange(option.label);
    };
    var loadFeatures = function (inputValue, callback) {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        typingTimeout = setTimeout(function () {
            if (inputValue.length < 3) {
                return Promise.resolve(null);
            }
            return axios
                .get(config.autocomplete_address_url, {
                params: {
                    q: inputValue,
                    autocomplete: 1,
                    // type: 'housenumber',
                    limit: 15,
                },
            })
                .then(function (_a) {
                var data = _a.data;
                callback(data.features.map(function (feature) { return ({
                    label: labelizeFeature(feature),
                    value: feature.geometry.coordinates,
                }); }));
            });
        }, 800);
        return typingTimeout;
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "diagnosticQuestionText mb-5" },
            React.createElement("label", { className: "diagnosticQuestionTextLabel", htmlFor: alias, 
                // eslint-disable-next-line
                dangerouslySetInnerHTML: {
                    __html: question.labelInput ? question.labelInput : question.label,
                } }),
            React.createElement(AsyncSelect, { id: alias, "data-cy": alias, styles: customStyles, loadOptions: loadFeatures, onChange: handleSelect, loadingMessage: function () { return 'Récupération des adresses en cours..'; }, defaultInputValue: question === null || question === void 0 ? void 0 : question.response }),
            React.createElement("div", { className: "diagnosticQuestionTextIcon" },
                React.createElement(LuMapPin, { size: 24 })))));
}
