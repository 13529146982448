import React from 'react';
import './header.scss';
import { useSharedState } from 'states';

import lang from '../../lang/dashboard.json';

const Header: React.FC = () => {
  const [user] = useSharedState('security:user');

  return (
    <header className="dashboard__header">
      <div className="dashboard__header__profile">
        <span className="dashboard__header__profile__name">Bonjour {user.firstName} !</span>
      </div>
      <h1>{lang.dashboard.title}</h1>
    </header>
  );
};

export default Header;
