import React from 'react';
import Lottie from 'react-lottie';
import './lottie.scss';

interface LottieComponentProps {
  animationData: object;
  loop?: boolean;
  title?: string;
  subtitle?: string;
  centrered?: boolean;
  middle?: boolean;
}

const LottieComponent: React.FC<LottieComponentProps> = ({ animationData, loop, title, subtitle, centrered, middle }) => {
  const defaultOptions = {
    loop,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const className = centrered ? 'lottie__container--centrered' : `lottie__container${middle ? ' lottie__container--middle' : ''}`;

  return (
    <div className={className}>
      {title && <h2 className="lottie__title">{title}</h2>}
      <Lottie options={defaultOptions} isClickToPauseDisabled height={300} width={300} />
      {subtitle && <p className="lottie__subtitle">{subtitle}</p>}
    </div>
  );
};

export default LottieComponent;
