import React from 'react';
import { IStep } from '../interfaces/generationAAT';
import Step from './Step';

const Steps: React.FC<{ dataSteps: IStep[] }> = ({ dataSteps }) => (
  <div className="aat-primes__steps">
    {dataSteps.map((step) => (
      <Step key={step.step} {...step} />
    ))}
  </div>
);

export default Steps;
