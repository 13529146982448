var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useSharedState, useSharedValue } from '../../../../states';
var TOTAL_WEEKS_IN_YEAR = 52;
export default (function () {
    var questions = useSharedValue('survey:questions');
    var _a = useSharedState('survey:responses'), responses = _a[0], setResponses = _a[1];
    var qWeeksWinter = questions.filter(function (q) { return q.alias === 'number_of_weeks_vacancy_winter'; })[0];
    var qWeeksSummer = questions.filter(function (q) { return q.alias === 'number_of_weeks_vacancy_summer'; })[0];
    var handleNoResponse = function () {
        setResponses(function (r) { return (__assign(__assign({}, r), { number_of_weeks_vacancy_winter: null, number_of_weeks_vacancy_summer: null })); });
    };
    var handleChange = function (alias, context, value) {
        var oppositeAlias = alias === 'number_of_weeks_vacancy_winter' ? 'number_of_weeks_vacancy_summer' : 'number_of_weeks_vacancy_winter';
        var nextValue = Number(responses[alias]);
        var nextOppositeValue = Number(responses[oppositeAlias]);
        switch (context) {
            case 'increment': {
                nextValue += 1;
                break;
            }
            case 'decrement': {
                nextValue -= 1;
                break;
            }
            case 'input': {
                nextValue = Number(value);
                break;
            }
            default: {
                throw new Error("The context \"".concat(context, "\" is not allowed"));
            }
        }
        while (nextValue + nextOppositeValue > TOTAL_WEEKS_IN_YEAR) {
            if (nextOppositeValue <= 0) {
                nextValue -= 1;
            }
            else {
                nextOppositeValue -= 1;
            }
        }
        if (nextValue < 0) {
            nextValue = 0;
        }
        if (nextOppositeValue < 0) {
            nextOppositeValue = 0;
        }
        setResponses(function (r) {
            var _a;
            return (__assign(__assign({}, r), (_a = {}, _a[alias] = nextValue, _a[oppositeAlias] = nextOppositeValue, _a)));
        });
    };
    return (React.createElement("div", { className: "diagnosticQuestionList displayColumn" },
        React.createElement("div", { className: "diagnosticQuestionListItem increment" },
            React.createElement("div", { className: "diagnosticQuestionListItemContent" },
                qWeeksSummer.label,
                React.createElement("div", { className: "diagnosticQuestionListItemPicto" },
                    React.createElement("i", { className: "icon-sun" }))),
            React.createElement("div", { className: "diagnosticQuestionListItemIncrement" },
                React.createElement("button", { className: "diagnosticQuestionTextInputBtn", type: "button", onClick: function () { return handleChange('number_of_weeks_vacancy_summer', 'decrement'); } }, "-"),
                React.createElement("input", { id: qWeeksSummer.value, className: "diagnosticQuestionTextInput increment", type: "text", onChange: function (e) { return handleChange('number_of_weeks_vacancy_summer', 'input', e.target.value.replace(/\D/g, '')); }, value: responses.number_of_weeks_vacancy_summer === null ? '' : responses.number_of_weeks_vacancy_summer }),
                React.createElement("button", { className: "diagnosticQuestionTextInputBtn", type: "button", onClick: function () { return handleChange('number_of_weeks_vacancy_summer', 'increment'); } }, "+"))),
        React.createElement("div", { className: "diagnosticQuestionListItem increment" },
            React.createElement("div", { className: "diagnosticQuestionListItemContent" },
                qWeeksWinter.label,
                React.createElement("div", { className: "diagnosticQuestionListItemPicto" },
                    React.createElement("i", { className: "icon-snow" }))),
            React.createElement("div", { className: "diagnosticQuestionListItemIncrement" },
                React.createElement("button", { className: "diagnosticQuestionTextInputBtn", type: "button", onClick: function () { return handleChange('number_of_weeks_vacancy_winter', 'decrement'); } }, "-"),
                React.createElement("input", { id: qWeeksWinter.value, className: "diagnosticQuestionTextInput increment", type: "text", onChange: function (e) { return handleChange('number_of_weeks_vacancy_winter', 'input', e.target.value.replace(/\D/g, '')); }, value: responses.number_of_weeks_vacancy_winter === null ? '' : responses.number_of_weeks_vacancy_winter }),
                React.createElement("button", { className: "diagnosticQuestionTextInputBtn", type: "button", onClick: function () { return handleChange('number_of_weeks_vacancy_winter', 'increment'); } }, "+"))),
        React.createElement("button", { type: "button", onClick: handleNoResponse, className: "diagnosticQuestionListItem justText ".concat(responses.number_of_weeks_vacancy_winter === null && responses.number_of_weeks_vacancy_summer === null ? 'selected' : '') },
            React.createElement("div", { className: "diagnosticQuestionListItemContent" },
                "Je ne sais pas",
                React.createElement("br", null),
                "ou je ne veux pas r\u00E9pondre"))));
});
