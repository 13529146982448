import { api, QuestionChoice, Resource } from 'core';
import { mapAPIIncomeFiscal } from '../mapper/income.mapper';

export class IncomeFiscalApi extends Resource {
  constructor(entrypoint = '/reponses') {
    super(entrypoint);
  }

  getIncomeFiscal(query?: { [p: string]: unknown }): Promise<QuestionChoice[]> {
    return api.post(this.buildUri('revenus-fiscaux'), query).then(({ data }) => mapAPIIncomeFiscal(data));
  }
}

export default new IncomeFiscalApi();
