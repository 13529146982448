import { DocumentQuestions, DocumentQuestionsApi } from '../types/dashboard';

export function mapAPIDocumentsQuestions(docQuestions: DocumentQuestionsApi): DocumentQuestions {
  return {
    devis: docQuestions.devis.map((devis) => ({
      label: devis.libelle,
      messageAvertissement: devis.messageAvertissement,
      reponse: null,
    })),
    facture: docQuestions.facture.map((devis) => ({
      label: devis.libelle,
      messageAvertissement: devis.messageAvertissement,
      reponse: null,
    })),
    ibanRib: docQuestions.ibanRib.map((devis) => ({
      label: devis.libelle,
      messageAvertissement: devis.messageAvertissement,
      reponse: null,
    })),
    ah: docQuestions.ah.map((devis) => ({
      label: devis.libelle,
      messageAvertissement: devis.messageAvertissement,
      reponse: null,
    })),
  };
}
