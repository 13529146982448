import React from 'react';
import { IconType } from 'react-icons';
import { BiPhoneCall } from 'react-icons/bi';
import { IoIosMail } from 'react-icons/io';
import { PHONE_NUMBER, SERVICE_EMAIL } from 'utils/constants';

interface ContactMethod {
  icon: IconType;
  text: string;
  href: string;
}

const contactMethods: ContactMethod[] = [
  {
    icon: BiPhoneCall,
    text: PHONE_NUMBER,
    href: `tel:${PHONE_NUMBER}`,
  },
  {
    icon: IoIosMail,
    text: SERVICE_EMAIL,
    href: `mailto:${SERVICE_EMAIL}`,
  },
];

const ContactBox: React.FC<ContactMethod> = ({ icon, text, href }) => {
  const Icon = icon;
  return (
    <a className="dossier-content__contact__content__box" href={href}>
      <div className="dossier-content__contact__content__box__icon">
        <Icon size={40} />
      </div>
      <div className="dossier-content__contact__content__box__text">{text}</div>
    </a>
  );
};

const InformationContact: React.FC = () => (
  <div className="dossier-content__contact">
    <div className="dossier-content__contact__title">
      <h2>Vous avez des questions ?</h2>
    </div>
    <div className="dossier-content__contact__content">
      {contactMethods.map((method) => (
        <ContactBox key={method.text} {...method} />
      ))}
    </div>
  </div>
);

export default InformationContact;
