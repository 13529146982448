import React from 'react';
import InformationContact from '../contact/informationContact';
import boite from '../../assets/icons/boite.svg';

const DossierIncomplet: React.FC = () => (
  <>
    <div className="controle-dossier__title">Nous avons identifié des éléments nécessitant des compléments d’informations</div>
    <div className="controle-dossier__incomplet_information">
      <div className="controle-dossier__incomplet_information__icon">
        <img src={boite} alt="Boîte aux lettres" />
      </div>
      <div className="controle-dossier__incomplet_information__title">
        Vous avez reçu un email de la part de <b>suivipartenaires@sonergia.fr.</b>
        <br /> Merci d’en prendre connaissance et de nous renvoyer les informations nécessaires
      </div>
    </div>
    <InformationContact />
  </>
);

export default DossierIncomplet;
