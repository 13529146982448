import { useEffect, useState, useRef } from 'react';
import DocumentAPI from '../services/documentAPI';
import { DocumentQuestions } from '../types/dashboard';

interface UseDocQuestionsReturn {
  docQuestions?: DocumentQuestions;
  error: unknown;
  isLoading: boolean;
}

const useDocQuestions = (simulationId: string): UseDocQuestionsReturn => {
  const isMountedRef = useRef<boolean>(true);
  const [state, setState] = useState<UseDocQuestionsReturn>({
    docQuestions: undefined,
    error: null,
    isLoading: false,
  });

  useEffect(() => {
    isMountedRef.current = true;

    const fetchData = async () => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        const res = await DocumentAPI.getDocumentsQuestions(simulationId);
        if (isMountedRef.current) {
          setState({ docQuestions: res, error: null, isLoading: false });
        }
      } catch (err) {
        if (isMountedRef.current) {
          setState((prevState) => ({ ...prevState, error: err, isLoading: false }));
        }
      }
    };

    fetchData();

    return () => {
      isMountedRef.current = false;
    };
  }, [simulationId]);

  return state;
};

export default useDocQuestions;
