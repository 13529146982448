import React, { useCallback, useState } from 'react';
import ContactForm from 'components/form/contact';
import illu from 'assets/images/illu-rejected.svg';
import { handleSubmitCollectiveHeaterForm } from 'data-layer';

import type { ContactForm as IContactForm } from 'core';

type Props = {
  backToSurvey?: () => void;
};

export default function Rejected({ backToSurvey }: Props): React.ReactElement {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const goToMessage = () => {
    const formTopElement = document.getElementById('form-top');

    if (!formTopElement) {
      throw new Error('You must define an element with "form-top" as ID placed before flash messages.');
    }

    formTopElement.scrollIntoView();
  };

  const handleComplete = (data: IContactForm) => {
    handleSubmitCollectiveHeaterForm(data);
    setError(false);
    setSuccess(true);
    goToMessage();
  };

  const handleFailed = () => {
    setSuccess(false);
    setError(true);
    goToMessage();
  };

  const handleChange = useCallback(() => {
    if (error || success) {
      setError(false);
      setSuccess(false);
    }
  }, [error, success]);

  return (
    <>
      <div className="diagnosticRejected">
        <div className="diagnosticRejectedIcon">
          <img src={illu} alt="Projet de rénovation" />
        </div>
        <div className="diagnosticRejectedContent">
          <h2 className="diagnosticRejectedContentTitle">
            Oups !<br />
            Vous venez d’indiquer un mode de chauffage collectif pour votre logement.
          </h2>
          <h3 className="diagnosticRejectedContentTitleSub">
            Malheureusement, nous ne sommes pas en mesure de vous fournir une analyse précise de votre logement sur la base des données d’un mode de chauffage collectif.
            <br />
            <br />
            Rien n’est perdu...
            <br />
            Sonergia vous accompagne dans votre projet de rénovation énergétique.
          </h3>
          <div className="diagnosticRejectedContentPhone">
            <span>Par téléphone au</span>
            04 28 70 82 73
          </div>
          <div className="diagnosticRejectedContentIntro" id="form-top">
            Ou par e-mail en remplissant ce formulaire :{' '}
          </div>
          {success && <div className="alert alert-success">Votre demande a bien été envoyée.</div>}
          {error && <div className="alert alert-danger mb-5">Une erreur est suvenue. Veuillez vérifier le formulaire.</div>}
          <ContactForm
            handleComplete={handleComplete}
            handleFailed={handleFailed}
            handleChange={handleChange}
            actions={
              <>
                <span className="mls mrs"> ou </span>
                <button onClick={backToSurvey} type="button" className="btn btn-secondary btn-icon-left">
                  <span>
                    <i className="icon-prev" />
                    Je possède un chauffage individuel
                  </span>
                </button>
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
