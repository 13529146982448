/* eslint-disable jsx-a11y/label-has-associated-control */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from 'react';
import { useSharedState, useSharedValue } from '../../../../states';
import ChoiceInput from '../choice';
import TextInput from '../text';
var checkResponse = function (value, sameWinterTemp, responses) {
    var commonErrMsg = 'Veuillez compléter la question';
    if (value === 1) {
        var complete = sameWinterTemp ? typeof responses.thermostat_fixed_temperature === 'number' : typeof responses.thermostat_temperature_day === 'number' && typeof responses.thermostat_temperature_night === 'number';
        return complete || commonErrMsg;
    }
    if (value === 2) {
        return typeof responses.thermostat_fixed_temperature === 'number' || commonErrMsg;
    }
    return null;
};
function ThermostatInput(_a) {
    var question = _a.question, onChange = _a.onChange;
    var questions = useSharedValue('survey:questions');
    var _b = useSharedState('survey:responses'), responses = _b[0], setResponses = _b[1];
    var _c = useSharedState('survey:current:validation'), setValidation = _c[1];
    var qThermostatWinterTemperatureFixed = questions.filter(function (q) { return q.alias === 'thermostat_fixed_temperature'; })[0];
    var qThermostatWinterTemperatureDay = questions.filter(function (q) { return q.alias === 'thermostat_temperature_day'; })[0];
    var qThermostatWinterTemperatureNight = questions.filter(function (q) { return q.alias === 'thermostat_temperature_night'; })[0];
    var _d = useState(responses[question.alias] === 1 && typeof responses.thermostat_fixed_temperature === 'number'), sameWinterTemp = _d[0], setSameWinterTemp = _d[1];
    var validate = useCallback(function (value) { return checkResponse(value, sameWinterTemp, responses); }, [sameWinterTemp, responses]);
    useEffect(function () {
        setValidation([validate]);
        return function () { return setValidation(null); };
    }, [responses]);
    var handleChangeTemperatureFixed = function (value, fixed) {
        setSameWinterTemp(fixed !== undefined ? fixed : value === 0);
        setResponses(function (r) { return (__assign(__assign({}, r), { thermostat: value === undefined ? r.thermostat : value, thermostat_temperature_day: null, thermostat_temperature_night: null, thermostat_fixed_temperature: null })); });
    };
    return (React.createElement(ChoiceInput, { controlled: true, question: question, onChange: function (nextValue) {
            if (nextValue !== 3) {
                handleChangeTemperatureFixed(nextValue);
            }
            else if (typeof onChange === 'function') {
                onChange(nextValue);
            }
        }, value: responses[question.alias], nested: function (value) {
            if (Number(value) === 1 && responses.thermostat === 1) {
                return (React.createElement("div", { className: "diagnosticQuestionListChildren" },
                    React.createElement("label", { className: "diagnosticQuestionTextLabel" }, "En hiver, programmez-vous la m\u00EAme temp\u00E9rature de chauffage la journ\u00E9e et la nuit\u00A0?"),
                    React.createElement("ul", { className: "diagnosticQuestionList inline" },
                        React.createElement("li", { className: "diagnosticQuestionListItem ".concat(sameWinterTemp ? 'selected' : ''), onMouseUp: function () { return !sameWinterTemp && handleChangeTemperatureFixed(undefined, true); } },
                            React.createElement("div", { className: "diagnosticQuestionListItemContent" }, "Oui")),
                        React.createElement("li", { className: "diagnosticQuestionListItem ".concat(!sameWinterTemp ? 'selected' : ''), onMouseUp: function () { return sameWinterTemp && handleChangeTemperatureFixed(undefined, false); } },
                            React.createElement("div", { className: "diagnosticQuestionListItemContent" }, "Non"))),
                    sameWinterTemp ? (React.createElement(TextInput, { question: qThermostatWinterTemperatureFixed })) : (React.createElement(React.Fragment, null,
                        React.createElement(TextInput, { question: qThermostatWinterTemperatureDay }),
                        React.createElement(TextInput, { question: qThermostatWinterTemperatureNight, autofocus: false })))));
            }
            if (Number(value) === 2 && responses.thermostat === 2) {
                return (React.createElement("div", { className: "diagnosticQuestionListChildren" },
                    React.createElement(TextInput, { question: qThermostatWinterTemperatureFixed })));
            }
            return null;
        } }));
}
ThermostatInput.isDisplay = function (r) { return typeof checkResponse(r.thermostat, r.thermostat === 1 && typeof r.thermostat_fixed_temperature === 'number', r) === 'string'; };
export default ThermostatInput;
