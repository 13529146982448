import React, { FC, JSX } from 'react';
import { IoDocumentOutline } from 'react-icons/io5';

interface DocumentItemProps {
  title?: string;
  renderIcon: JSX.Element;
}

const DocumentItem: FC<DocumentItemProps> = ({ title, renderIcon }) => (
  <div className="realisation-travaux__content__documents__item">
    <div className="realisation-travaux__content__documents__item__icon">
      <IoDocumentOutline size={25} />
    </div>
    <div className="realisation-travaux__content__documents__item__text">{title}</div>
    {renderIcon}
  </div>
);

export default DocumentItem;
