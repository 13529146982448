import { setLocale } from 'yup';
import proj4 from 'proj4';
import labels from './labels';
// @see https://epsg.io/2154
// Used to convert coordinates exprimed in Lambert-93 from cstb
proj4.defs('EPSG:2154', '+proj=lcc +lat_0=46.5 +lon_0=3 +lat_1=49 +lat_2=44 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs');
setLocale(labels.validation.yup);
// Services
export { default as labels } from './labels';
// Resources
export { default as Resource } from './resources';
export { default as surveyResource } from './resources/survey';
export { default as responseResource } from './resources/response';
export { default as questionResource } from './resources/question';
export { default as questionChoiceResource } from './resources/question-choice';
export { default as userResource } from './resources/user';
export { default as partnerResource } from './resources/partner';
export { default as customerResource } from './resources/customer';
export { default as contactFormResource } from './resources/contact-form';
export { default as assignmentResource } from './resources/assignment';
export { default as assignmentStatusResource } from './resources/assignment-status';
export { default as reportResource } from './resources/report';
export { default as invitationResource } from './resources/invitation';
export { default as adminResource } from './resources/admin';
// Validation
export { buildApiCredentialsValidationSchema } from './resources/validation';
export { buildCustomerCreateValidationSchema, buildCustomerDataValidationSchema, buildCustomerDataValidationDiagnosticSchema, buildCustomerCreateValidationDiagnosticRenovSchema } from './resources/customer/validation';
export { buildPartnerValidationSchema, buildPartnerValidateValidationSchema } from './resources/partner/validation';
export { buildReportCreateValidationSchema } from './resources/report/validation';
export { buildCreateContactFormValidationSchema, buildAccountContactFormValidationSchema } from './resources/contact-form/validation';
export { validateResponseValue } from './resources/survey/validation';
export { buildInvitationCreateValidationSchema } from './resources/invitation/validation';
// Components
export { default as SurveyDisplayer } from './components/survey';
export { default as SurveyReadOnly } from './components/survey/readonly';
export { default as LoaderSimple } from './components/survey/loader/loader-simple';
export { default as LoaderEnd } from './components/survey/loader/loader-end';
export { default as Diagnostic } from './components/survey/diagnostic';
export { default as customStyles } from './components/survey/inputs/complex/building-select.styles';
export { resolveInput } from './components/survey/inputs';
export { resolveInput as resolveInputGeneric } from './components/inputs';
export { default as Progression } from './components/progression';
export { default as Label } from './components/survey/label';
export { Actions } from './components/actions/actions';
// States
export { surveyCurrent, surveyCurrentQuestion, surveyCurrentResponse, surveyErrors, surveyQuestions, surveyResponses, surveyId, surveyToken, surveyBnb, surveySubmitting, surveyPrevious, surveyHasHouseShape } from './states/survey';
export { isDisplay, transformResponsesForApi, transformResponsesForState, downloadPayload } from './utils/survey';
export { default as config } from './config';
