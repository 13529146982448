// Exit.tsx
import React, { useState } from 'react';
import './exit.scss';
import { FaChevronDown } from 'react-icons/fa';

interface ExitProps {
  title?: string;
  picto?: string;
  text?: string;
}

const splitTextIntoParagraphs = (inputText: string): string[] => inputText.split('</p>').filter(Boolean);

const Exit: React.FC<ExitProps> = ({ title = '', picto = '', text = '' }) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const [firstParagraph, ...remainingParagraphs] = splitTextIntoParagraphs(text);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="exit">
      {picto && <img src={picto} alt="Exit Picto" className="exit-picto" />}
      {title && <div className="exit-title">{title}</div>}
      {/* eslint-disable-next-line react/no-danger */}
      {firstParagraph && <div className="exit-text" dangerouslySetInnerHTML={{ __html: firstParagraph }} />}
      {remainingParagraphs.length > 0 && (
        <>
          <button onClick={toggleAccordion} className="accordion-toggle" type="button">
            Détails <FaChevronDown className={`rotate-icon ${isAccordionOpen ? 'open' : ''}`} size={14} />
          </button>
          <div className={`exit-text accordion-content ${isAccordionOpen ? 'open' : 'closed'}`}>
            {remainingParagraphs.map((paragraph) => (
              // eslint-disable-next-line react/no-danger
              <div key={paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Exit;
