export function validateResponseValue(question, value, customRules) {
    var errors = [];
    var type = question.type, rules = question.rules, choices = question.choices;
    if (customRules && customRules.length) {
        for (var i = 0, len = customRules.length; i < len; i += 1) {
            if (typeof customRules[i] !== 'function') {
                continue; // eslint-disable-line
            }
            var result = customRules[i](value);
            if (Array.isArray(result)) {
                errors.push.apply(errors, result);
            }
            else if (typeof result === 'string') {
                errors.push(result);
            }
        }
    }
    switch (type) {
        case 'dwelling_address':
        case 'text': {
            var val = typeof value === 'string' ? value : '';
            if (!val.length && rules.required) {
                errors.push('La valeur ne doit pas être nulle');
            }
            else if (typeof rules.min === 'number' && val.length < rules.min) {
                errors.push("La longueur de la chaine doit \u00EAtre au moins de ".concat(rules.min, " caract\u00E8re"));
            }
            else if (typeof rules.max === 'number' && val.length > rules.max) {
                errors.push("La longueur de la chaine doit \u00EAtre inf\u00E9rieur \u00E0 ".concat(rules.max));
            }
            break;
        }
        case 'date': {
            var val = typeof value === 'string' ? value : '';
            if (!val.length && rules.required) {
                errors.push('La valeur ne doit pas être nulle');
            }
            else {
                var date = new Date(val);
                if (isNaN(date.getTime())) {
                    errors.push('La date est invalide');
                }
                else if (date.getFullYear() > 9999) {
                    errors.push("L'année de la date ne peut pas contenir plus de 4 chiffres");
                }
                else if (date > new Date()) {
                    errors.push('La date ne peut pas être dans le futur');
                }
            }
            break;
        }
        case 'choice': {
            if ((value === null || value === undefined) && rules.required) {
                errors.push('Un choix doit être séléctionné');
                break;
            }
            var found = false;
            if (choices && choices.length) {
                for (var i = 0, len = choices.length; i < len; i += 1) {
                    if (String(choices[i].value) === String(value)) {
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                errors.push('Le choix est invalide');
            }
            break;
        }
        case 'choices': {
            var val = Array.isArray(value) ? value : [];
            if (val.length === 0 && rules.required) {
                errors.push('Un choix doit être séléctionné');
            }
            else if (typeof rules.min === 'number' && val.length < rules.min) {
                errors.push("Au moins ".concat(rules.min, " choix doit \u00EAtre s\u00E9l\u00E9ctionn\u00E9"));
            }
            else if (typeof rules.max === 'number' && val.length > rules.max) {
                errors.push("Au maximum ".concat(rules.max, " choix peuvent \u00EAtre s\u00E9l\u00E9ctionn\u00E9s"));
            }
            break;
        }
        case 'integer':
        case 'float': {
            var val = typeof value === 'number' ? value : null;
            if (val === null && rules.required) {
                errors.push('La valeur ne doit pas être nulle');
            }
            else if (typeof rules.min === 'number' && val !== null && val < rules.min) {
                errors.push("Le nombre doit \u00EAtre sup\u00E9rieur ou \u00E9gal \u00E0 ".concat(rules.min));
            }
            else if (typeof rules.max === 'number' && val !== null && val > rules.max) {
                errors.push("Le nombre doit \u00EAtre inf\u00E9rieur ou \u00E9gal \u00E0 ".concat(rules.max));
            }
            break;
        }
        case 'object':
        default: {
            // errors.push(`No validator found for ${question.type}`);
            break;
        }
    }
    return errors;
}
