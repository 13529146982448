var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import axios from 'axios';
import qs from 'qs';
import security from '@concerto-home/security';
import Listenable from '@concerto-home/listenable';
var CancelToken = axios.CancelToken;
var Api = /** @class */ (function (_super) {
    __extends(Api, _super);
    function Api(config) {
        if (config === void 0) { config = {}; }
        var _this = _super.call(this) || this;
        _this.messages = {
            server_not_responding: 'Le serveur ne répond pas',
            unable_to_contact_api: 'Le serveur ne répond pas',
            fatal_error: 'Une erreur fatale est survenue',
            resource_not_found: "La ressource n'a pas été trouvé",
            access_denied: "Vous n'avez pas le droit d'accèder à cette ressource",
            authorization_refused: "Vous n'avez pas l'autorisation d'accèder à cette ressource",
        };
        _this.config = {};
        _this.canceller = CancelToken.source();
        _this.config = config;
        return _this;
    }
    Object.defineProperty(Api.prototype, "uri", {
        get: function () {
            var _a, _b;
            return ((_b = (_a = this.config.baseUrl) === null || _a === void 0 ? void 0 : _a.match(/(?:https?:\/\/)?.*?(\/.*)/)) === null || _b === void 0 ? void 0 : _b[1]) || '';
        },
        enumerable: false,
        configurable: true
    });
    Api.prototype.updateConfig = function (config) {
        this.config = __assign(__assign({}, this.config), config);
    };
    Api.prototype.login = function (_a) {
        var email = _a.email, password = _a.password;
        return this.post(String(this.config.tokenUrl), { email: email, password: password }, null, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(function (_a) {
            var data = _a.data;
            security.authenticate(data.uuid, data.token, data.source);
            return data;
        });
    };
    Api.prototype.get = function (url, param, config) {
        return this.request('GET', url, param, null, config);
    };
    Api.prototype.patch = function (url, data, param, config) {
        return this.request('PATCH', url, param, data, config);
    };
    Api.prototype.put = function (url, data, param, config) {
        return this.request('PUT', url, param, data, config);
    };
    Api.prototype.post = function (url, data, param, config) {
        return this.request('POST', url, param, data, config);
    };
    Api.prototype.delete = function (url, config) {
        return this.request('DELETE', url, null, null, config);
    };
    Api.prototype.request = function (method, url, params, data, config) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.checkCredentials()
                .then(function () {
                axios
                    .request(__assign(__assign({}, _this.buildParams(method, url, params, data)), config))
                    .then(function (response) {
                    resolve(response);
                })
                    .catch(function (error) {
                    if (axios.isCancel(error)) {
                        return;
                    }
                    _this.handleError(error);
                    reject(error);
                });
            })
                .catch(function () {
                _this.handleExpiredCredentials();
            });
        });
    };
    Api.prototype.cancel = function () {
        this.canceller.cancel();
        this.canceller = CancelToken.source();
    };
    Api.prototype.logout = function () {
        return this.post('/auth/logout', { access_token: security.token });
    };
    Api.prototype.buildParams = function (method, url, params, data) {
        var config = {
            method: method,
            url: url,
            params: params,
            data: data,
            baseURL: this.config.baseUrl || '',
            headers: {
                'Content-Type': 'application/ld+json',
                Authorization: "Bearer ".concat(security.token),
            },
            paramsSerializer: function (p) {
                return qs.stringify(p, {
                    arrayFormat: 'brackets',
                    encode: false,
                });
            },
            cancelToken: this.canceller.token,
        };
        if (!security.isAuthenticated() && config.headers) {
            delete config.headers.Authorization;
        }
        return config;
    };
    Api.prototype.checkCredentials = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!security.isAuthenticated() || !security.isTokenExpired()) {
                return resolve(String(security.token));
            }
            var request = axios({
                url: String(_this.config.baseUrl) + _this.config.refreshTokenUrl,
                method: 'POST',
                data: { access_token: security.token },
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            return request
                .then(function (_a) {
                var data = _a.data;
                security.authenticate(data.uuid, data.access_token, data.source);
                resolve(data.access_token);
            })
                .catch(function () {
                // Unable to get refresh token
                _this.handleExpiredCredentials();
                reject();
            });
        });
    };
    Api.prototype.handleError = function (error) {
        var type = '';
        if (error.response) {
            var _a = error.response, data = _a.data, status_1 = _a.status;
            switch (status_1) {
                case 504:
                case 503: {
                    type = 'server_not_responding';
                    break;
                }
                case 500: {
                    type = 'fatal_error';
                    break;
                }
                case 404: {
                    type = 'resource_not_found';
                    break;
                }
                case 403: {
                    type = 'access_denied';
                    break;
                }
                case 401: {
                    type = 'authorization_refused';
                    break;
                }
                default: {
                    type = data && data.message ? data.message : null;
                }
            }
        }
        else if (!error.response && error.request) {
            type = 'unable_to_contact_api';
        }
        else if (!error.response && !error.request) {
            type = 'Axios usage error';
        }
        this.trigger('request_error', this.messages[type] || type, error);
    };
    Api.prototype.handleExpiredCredentials = function () {
        this.trigger('credentials_expired');
        security.deAuthenticate(function () { return window.location.reload(); });
    };
    return Api;
}(Listenable));
export { Api };
export default new Api();
