import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { createUseStyles } from 'react-jss';
import { StatusValue } from '../StatusValue/StatusValue';
import { Co2EmissionsValue } from '../Co2EmissionsValue/Co2EmissionsValue';
import etiquetteA from './../../assets/images/etiquettes/A.svg';
import etiquetteB from './../../assets/images/etiquettes/B.svg';
import etiquetteC from './../../assets/images/etiquettes/C.svg';
import etiquetteD from './../../assets/images/etiquettes/D.svg';
import etiquetteE from './../../assets/images/etiquettes/E.svg';
import etiquetteF from './../../assets/images/etiquettes/F.svg';
import etiquetteG from './../../assets/images/etiquettes/G.svg';
var useStyles = createUseStyles({
    moyenne: {
        border: [1, 'solid', '#00556E'],
        borderRadius: 10,
        color: 'white',
        background: '#00556E',
        marginBottom: 5,
        padding: 5,
        textAlign: 'center',
        '& .montant': {
            fontWeight: 'bold',
            fontSize: '2rem',
        },
    },
});
export var Etiquette = function (_a) {
    var status = _a.status, statusValue = _a.statusValue, co2EmissionsValue = _a.co2EmissionsValue, annualCostValue = _a.annualCostValue;
    var classes = useStyles();
    var etiquette;
    switch (status) {
        case 'A':
            etiquette = etiquetteA;
            break;
        case 'B':
            etiquette = etiquetteB;
            break;
        case 'C':
            etiquette = etiquetteC;
            break;
        case 'D':
            etiquette = etiquetteD;
            break;
        case 'E':
            etiquette = etiquetteE;
            break;
        case 'F':
            etiquette = etiquetteF;
            break;
        case 'G':
            etiquette = etiquetteG;
            break;
    }
    return (React.createElement(Row, { className: "etiquette-energetique" },
        React.createElement(Col, { xl: 5 },
            React.createElement(Image, { src: etiquette, fluid: true })),
        React.createElement(Col, { xl: 7 },
            React.createElement(Row, null,
                React.createElement(Col, { xl: 6 },
                    React.createElement(StatusValue, { statusValue: statusValue })),
                React.createElement(Col, { xl: 6 },
                    React.createElement(Co2EmissionsValue, { co2EmissionsValue: co2EmissionsValue }))),
            React.createElement("div", { className: classes.moyenne },
                "Soit une moyenne de",
                React.createElement("br", null),
                React.createElement("span", { className: "montant" },
                    annualCostValue,
                    " \u20AC"),
                React.createElement("br", null),
                "par an"),
            React.createElement("a", { href: "#recommendation", className: "text-decoration-underline" }, "Nos pr\u00E9conisations travaux"))));
};
