import React from 'react';
import type { AxiosError } from 'axios';
import type { FormikHelpers } from 'formik';

class CheckApiFormViolationPromise extends Promise<void> {
  else = this.then.bind(this);
}

export function checkApiFormViolations(
  e: AxiosError,
  formik: FormikHelpers<any> // eslint-disable-line
): CheckApiFormViolationPromise {
  return new CheckApiFormViolationPromise((resolve, reject) => {
    const violations = e.response?.data?.violations;

    if (violations) {
      for (let i = 0, len = violations.length; i < len; i += 1) {
        formik.setFieldError(violations[i].propertyPath, violations[i].message);
      }

      reject();
    } else {
      resolve();
    }
  });
}

export const handleEnterKeyPress = (callback: () => void) => (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Enter') {
    callback();
  }
};
