import React, { useState } from 'react';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

import { FaUser, FaHome } from 'react-icons/fa';
import { FaLocationDot } from 'react-icons/fa6';
import { GrMoney } from 'react-icons/gr';
import { getAdress } from 'features/primes/gestion-dossier/aat/helpers/functions';

import ModalConfirm from 'components/modal/modal';
import setting from '../../../../assets/icons/setting.svg';

import Button from '../../../button/button';
import BoxItem from './boxItem';

import lang from '../../../../lang/dashboard.json';

interface ContentItemProps {
  isDetail?: boolean;
  simulation: Resultat;
}

const ContentItem: React.FC<ContentItemProps> = ({ isDetail, simulation }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="dashboard__demandes__list__item__content">
      <div className="dashboard__demandes__list__item__content__infos">
        {!isDetail && (
          <h3 className="dashboard__demandes__list__item__content__infos--title">
            <span className="dashboard__demandes__list__item__content__infos--title__icon">
              <img src={setting} alt="demande icon" />
            </span>
            <span className="dashboard__demandes__list__item__content__infos--title__text">{simulation.travaux.operation}</span>
          </h3>
        )}
        {simulation.profile && <BoxItem icon={FaUser} value={simulation.profile.status} />}
        {isDetail && simulation.logement && <BoxItem icon={FaHome} value={`${simulation.logement.typeLogement} de ${simulation.logement.surface} m²`} />}

        <BoxItem icon={FaLocationDot} value={getAdress(simulation.logement.address)} />
        {simulation.profile?.revenus && <BoxItem icon={GrMoney} value={`${simulation.profile.revenus} de revenus fiscaux`} />}
        {!isDetail && (
          <>
            {simulation.dateExpirationDossier !== null && <div className="dashboard__demandes__list__item__content__infos--actions--dateLimite">Date limite d&apos;envoi du dossier : {simulation.dateExpirationDossier}</div>}
            <div className="dashboard__demandes__list__item__content__infos--actions">
              <span className="dashboard__demandes__list__item__content__infos--actions--condition">
                <Button classNames="condition" id="offre-condition" label="Conditions de cette offre" variant="tertiary" type="button" onClick={() => setShow(true)} />
                {simulation.dateExpirationDossier === null && (
                  <span className="dashboard__demandes__list__item__content__infos--actions--condition--date">
                    |<span className="dashboard__demandes__list__item__content__infos--actions--condition--date--texte">Créée le {simulation.dateCreationSimulation}</span>
                  </span>
                )}
              </span>
            </div>
          </>
        )}
        <ModalConfirm
          show={show}
          onHide={() => setShow(false)}
          action={() => setShow(false)}
          content={{
            title: lang.conditions.title,
            message: lang.conditions.description,
            confirm: 'Fermer',
          }}
          classNames="lineBreak"
        />
      </div>
    </div>
  );
};

export default ContentItem;
