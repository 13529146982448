import { Address } from 'features/primes/simulation/resultat/interfaces/resultat';

// retourne l'année précédente et l'année avant l'année précédente
export function getYears(): string {
  const currentDate: Date = new Date();
  const currentYear: number = currentDate.getFullYear();
  const currentMonth: number = currentDate.getMonth() + 1;

  let yearA: number;
  let yearB: number;

  if (currentMonth >= 1 && currentMonth <= 8) {
    yearA = currentYear - 1;
    yearB = currentYear - 2;
  } else {
    yearA = currentYear;
    yearB = currentYear - 1;
  }

  return `${yearB} et ${yearA}`;
}

// ajouter 6 mois à la date du jour
export function addEightMonth(): string {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const day = date.getDate();
  const newDate = new Date(year, month + 8, day);
  return newDate.toLocaleDateString('fr-FR');
}

export function getAdress(address: Address): string {
  const { street, complement, postalCode, city } = address;
  return `${street} ${complement || ''} ${postalCode} ${city}`;
}

// fomatage du numéro de téléphone (replace +33 by 0)
export function formatPhone(phone?: string): string {
  if (!phone) {
    return '';
  }

  return phone.replace(/\s/g, '').replace('+33', '0');
}
