import React, { useState } from 'react';
import { useFormikContext, useField } from 'formik';
import FormError from 'components/form/error';

import type { CommonProps } from '.';

interface Prop extends CommonProps {
  rows?: number;
  placeholder?: string;
}

export default function TextareaInput({ name, label, rows, placeholder }: Prop): React.ReactElement {
  const [id] = useState(`${name}-${String(Math.floor(Math.random() * 10000))}`);
  const { handleChange, handleBlur, values } = useFormikContext();
  // eslint-disable-next-line
  const [field, meta] = useField(name);
  const hasError = meta.touched && meta.error;

  return (
    <div className="form-group">
      <label htmlFor={id}>
        {label !== '' && <div>{label}</div>}
        <div className="form-group-input">
          <textarea
            className={hasError ? 'withError' : ''}
            id={id}
            name={name}
            onChange={handleChange}
            onBlur={handleBlur}
            // eslint-disable-next-line
            // @ts-ignore
            value={values[name] || ''}
            rows={rows}
            placeholder={placeholder}
          />
        </div>
        <FormError name={name} />
      </label>
    </div>
  );
}
