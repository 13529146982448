import { contactFormResource, customerResource, surveyResource, LoaderSimple } from 'core';
import security from '@concerto-home/security';
import React, { useCallback, useEffect, useState } from 'react';
import { useSharedState } from 'states';
import { Popover, ProgressBar, OverlayTrigger, Modal } from 'react-bootstrap';

import type { Survey } from 'core';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

import ContactItem from 'components/account/contact-item';
import NoSurvey from 'components/account/no-survey';
import ContactAccountForm from 'components/form/contact-account';

import illuMeet from 'assets/images/illu-account-meet.svg';
import illuContact from 'assets/images/illu-account-contact.svg';
import illuPro from 'assets/images/illu-account-pro.svg';
import illuMoney from 'assets/images/illu-account-money.svg';
import { useConfirm } from 'utils/confirm';
import { handleClickUserContact, handleSubmitUserContact } from 'data-layer';

const settingSliderContact = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerPadding: '28%',
  slidesToScroll: 1,
  centerMode: true,
  mobileFirst: true,
};

const settingSliderDiagnostic = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        mobileFirst: true,
      },
    },
  ],
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case surveyResource.STATUS_COMPLETE:
      return 'Terminé';
    case surveyResource.STATUS_REJECTED:
    case surveyResource.STATUS_PROCESSING:
    case surveyResource.STATUS_RESOLVING:
    case surveyResource.STATUS_IN_PROGRESS:
    default:
      return 'À compléter';
  }
};

const popoverInfo = (
  <Popover className="popoverInfo">
    <Popover.Body className="popoverInfoBody">
      <i className="icon-info popoverInfoBodyIcon" />
      <p className="popoverInfoBodyDetails">Ce questionnaire a été réalisé par l&apos;expert qui vous a accompagné lors du rendez-vous à domicile. Il a accès à votre analyse énergétique pour vous conseiller tout au long de votre projet de rénovation. Si vous souhaitez lui retirer son accès, il vous suffit d&apos;en faire la demande par email à l&apos;adresse : protectiondonnees@sonergia.fr</p>
    </Popover.Body>
  </Popover>
);

type MessageAction = {
  title: string;
  icon: string;
  type: string;
  alias: string;
};

const messageActions: MessageAction[] = [
  {
    title: 'Être contacté par un conseiller',
    icon: illuContact,
    type: contactFormResource.TYPE_CONTACT_ADVISOR,
    alias: 'advisor',
  },
  {
    title: 'Faire appel à un expert pour une analyse à mon domicile',
    icon: illuMeet,
    type: contactFormResource.TYPE_CONTACT_EXPERT,
    alias: 'expert',
  },
  {
    title: 'Être mis en relation avec un artisan',
    icon: illuPro,
    type: contactFormResource.TYPE_CONTACT_ARTISANT,
    alias: 'artisan',
  },
  {
    title: 'Affiner mes primes',
    icon: illuMoney,
    type: contactFormResource.TYPE_REFINE_BONUS,
    alias: 'incentive',
  },
];

export default function Account(): React.ReactElement {
  const [user, setUser] = useSharedState('security:user');
  const [deleted, setDeleted] = useState(false);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentMessageAction, setCurrentMessageAction] = useState<MessageAction | null>(null);
  const [currentMessageActionError, setCurrentMessageActionError] = useState<string | null>(null);
  const [actionsDone, setActionsDone] = useState<string[]>([]);
  const confirm = useConfirm();

  const fetch = () => {
    customerResource.read(String(security.user)).then((u) => {
      const nextSurveys = [];

      if (u.survey) {
        nextSurveys.push(u.survey);
      }

      if (u.surveyPartner) {
        nextSurveys.push(u.surveyPartner);
      }

      setUser(u);
      setSurveys(nextSurveys);
      setLoading(false);
    });
  };

  const remove = (id: number) => {
    confirm('Attention, la suppression est irréversible', 'Souhaitez-vous vraiment supprimer ce questionnaire ?').then(() => {
      setLoading(true);
      setDeleted(true);
      surveyResource.delete(id).then(fetch);
    });
  };

  const handleResize = () => setWindowWidth(window.innerWidth);

  const handleContactForm = useCallback(() => {
    if (!currentMessageAction) {
      return;
    }

    if (!actionsDone.includes(currentMessageAction.type)) {
      actionsDone.push(currentMessageAction.type);
    }

    setCurrentMessageAction(null);
    setActionsDone(actionsDone);

    handleSubmitUserContact(currentMessageAction.alias, user);
  }, [actionsDone, currentMessageAction]);

  useEffect(() => {
    fetch();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return <LoaderSimple />;
  }

  const actions = messageActions.map((action) => (
    <ContactItem
      onClick={() => {
        setCurrentMessageAction(action);
        handleClickUserContact(action.alias);
      }}
      key={action.title}
      title={action.title}
      icon={action.icon}
      active={actionsDone.includes(action.type)}
    />
  ));

  return (
    <div className="accountSurvey">
      <div className="accountSurveyLeft">
        <h2 className="accountSurveyTitle forDesktop">
          <strong>Bonjour {user.firstName},</strong> bienvenue sur votre espace.
        </h2>
        <h2 className="accountSurveyTitle forMobile">
          <strong>Bienvenue {user.firstName}</strong>
        </h2>

        <h3 className="accountSurveyTitleSub">Vos questionnaires et analyses énergétiques</h3>

        <p className="accountSurveyInfos">Vous pouvez modifier vos questionnaires et/ou consulter vos analyses énergétiques en quelques clics&nbsp;!</p>

        {deleted && <div className="alert alert-success">Le questionnaire a bien été supprimé</div>}

        {surveys.length === 0 ? (
          <NoSurvey />
        ) : (
          <>
            <Slider {...settingSliderDiagnostic} className="accountSurveyList">
              {surveys.map((survey) => (
                <div className={`accountSurveyListCard ${survey.status === surveyResource.STATUS_COMPLETE ? 'complete' : ''}`} key={survey.id}>
                  <div className="accountSurveyListCardHeader">
                    <div className="accountSurveyListCardHeaderDate">
                      <i className="icon-date" /> {new Date(String(survey.createdAt)).toLocaleDateString()}
                    </div>
                    <div className="accountSurveyListCardHeaderStatus">
                      <i className={survey.status === surveyResource.STATUS_COMPLETE ? 'icon-validate' : 'icon-loading'} />
                      {getStatusLabel(survey.status)}
                    </div>
                  </div>
                  <div className="accountSurveyListCardBody">
                    <h3 className="accountSurveyListCardBodyTitle">Analyse énergétique</h3>
                    <div className="accountSurveyListCardBodyUser">
                      <div className="accountSurveyListCardBodyUserIcon">
                        <i className="icon-account" />
                      </div>
                      {survey.filledBy && (
                        <div className="accountSurveyListCardBodyUserLabel">
                          Réalisé par {survey.filledBy.firstName || 'vous'}
                          {survey.filledBy.lastName ? ' ' : ''}
                          {survey.filledBy.lastName?.[0].toUpperCase()}.
                          {survey.filledBy['@id'] !== user['@id'] && survey.filledBy !== user['@id'] && (
                            <OverlayTrigger trigger="click" placement="right" overlay={popoverInfo} rootClose rootCloseEvent="click">
                              <i className="icon-info popoverInfoButton" />
                            </OverlayTrigger>
                          )}
                        </div>
                      )}
                    </div>

                    <ProgressBar now={survey.status === surveyResource.STATUS_IN_PROGRESS || survey.status === surveyResource.STATUS_RESOLVING ? survey.progression : 100} />
                    <div className="accountSurveyListCardBodyStatus">{survey.status === surveyResource.STATUS_IN_PROGRESS || survey.status === surveyResource.STATUS_RESOLVING ? survey.progression : 100}%</div>

                    <div className="accountSurveyListCardBodyActions">
                      {survey.status === surveyResource.STATUS_COMPLETE ? (
                        <>
                          <Link to={`/analyse-energetique/${survey.id}/diagnostic`} className="btn btn-primary btn-icon-left">
                            <span>
                              <i className="icon-next" />
                              Voir l’analyse
                            </span>
                          </Link>
                          {survey.filledBy && survey.filledBy === user['@id'] && (
                            <Link type="button" className="btn btn-link btn-icon-left" to={`/analyse-energetique/${survey.id}`} state={{ updateContext: true }}>
                              <i className="icon-edit" />
                              <span>Modifier</span>
                            </Link>
                          )}
                        </>
                      ) : (
                        survey.filledBy &&
                        survey.filledBy === user['@id'] && (
                          <Link to={`/analyse-energetique/${survey.id}`} className="btn btn-primary btn-icon-left">
                            <span>
                              <i className="icon-diagnostic" />
                              Finaliser le questionnaire
                            </span>
                          </Link>
                        )
                      )}
                      {survey.filledBy && survey.filledBy === user['@id'] && (
                        <button type="button" className="btn btn-link btn-icon-left" onClick={() => remove(Number(survey.id))}>
                          <i className="icon-trash" />
                          <span>Supprimer</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </>
        )}
      </div>
      <div className="accountSurveyRight">
        <h3 className="accountSurveyTitleSub">Et maintenant&nbsp;?</h3>
        <p className="accountSurveyInfos">
          Vous avez besoin d&apos;aide pour votre projet de rénovation&nbsp;?
          <br />
          Les équipes de Sonergia sont là pour vous aider&nbsp;!
        </p>

        {windowWidth < 576 ? <Slider {...settingSliderContact}>{actions}</Slider> : <div className="accountSurveyRightItemContainer">{actions}</div>}

        <Modal show={currentMessageAction !== null} onHide={() => setCurrentMessageAction(null)} centered className="modalContact">
          {currentMessageAction !== null && (
            <>
              <Modal.Header closeButton className="smallPadding">
                <img src={currentMessageAction.icon} alt={currentMessageAction.title} className="img-fluid mbs" width={55} />
                <Modal.Title>{currentMessageAction.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="smallPadding">
                {currentMessageActionError && <div className="alert alert-danger">{currentMessageActionError}</div>}
                <ContactAccountForm type={currentMessageAction.type} handleComplete={handleContactForm} handleFailed={() => setCurrentMessageActionError('Une erreur est survenu, veuillez réessayer.')} handleChange={() => setCurrentMessageActionError(null)} />
              </Modal.Body>
            </>
          )}
        </Modal>
      </div>
    </div>
  );
}
