import React from 'react';
import { useSharedValue } from 'states';
import PersonalDataForm from 'components/form/personal-data';
import { BiTimeFive } from 'react-icons/bi';

export default function PersonalData(): React.ReactElement {
  const user = useSharedValue('security:user');

  return (
    <>
      <h1 className="accountInfosTitle">
        <strong>Bonjour {user.firstName},</strong> bienvenue sur votre espace Sonergia
      </h1>
      <h2 className="accountInfosTitleSub">Données personnelles de votre compte</h2>
      <div className="accountInfosDate">
        <BiTimeFive size={18} />
        Compte crée le{' '}
        {new Date(String(user.createdAt)).toLocaleDateString('default', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        })}
      </div>
      <PersonalDataForm />
    </>
  );
}
