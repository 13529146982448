import React, { FC } from 'react';
import Tooltip from 'components/tooltip/Tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import Typography from '../../typography/typography';
import horloge from '../../../assets/icons/horloge.svg';
import lang from '../../../lang/projets.json';

const DevisRaiVerify: FC = () => (
  <div className="devis-rai-verify">
    <div className="devis-rai-verify__icon">
      <img src={horloge} alt="horloge" />
    </div>
    <div className="devis-rai-verify__content">
      <Typography variant="h3" size="xl" fontWeight="normal" customClass="mt-4" color="black">
        {lang.realisation.dateDevisText}
        <br />
        <br />
        {lang.realisation.dateDevisContact}
        <Tooltip text={lang.realisation.dateDevisTooltip} showTooltip>
          <sup className="devis-rai-verify__icon-info">
            <IoIosInformationCircleOutline size={25} />
          </sup>
        </Tooltip>
      </Typography>
    </div>
  </div>
);

export default DevisRaiVerify;
