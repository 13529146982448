import React from 'react';
import { DocumentData } from 'features/dashboard/types/document';
import { formatDocumentsTitle } from 'features/dashboard/helpers/utils';
import AccordionComp from '../Accordion/accordionCustom';
import FileUpload from '../fileUpload/fileUpload';

interface DocumentsListProps {
  documents: DocumentData[];
  onDownload: (idFile: number | null) => Promise<void>;
  onDelete: (idFile: number | null) => Promise<void>;
  onUpload: (file: File, fieldName: string, foyerPrecarite?: number) => Promise<void>;
  isAvisImposition?: boolean;
  isDeletable?: boolean;
  isLoading?: boolean;
}

const documentTitle = (doc: DocumentData) => {
  if (doc.foyerPrecarite) {
    return `${doc.title} 🏠 ${doc.foyerPrecarite.prenom}`;
  }
  return doc.title || doc.type;
};

const DocumentsList: React.FC<DocumentsListProps> = ({ documents, onDownload, onUpload, onDelete, isAvisImposition, isDeletable, isLoading }) => {
  const formatedDocuments = formatDocumentsTitle(documents);

  return (
    <>
      {formatedDocuments.map((doc, index) => (
        <AccordionComp key={doc.id} title={documentTitle(doc)} fileName={doc.fileName} isUploaded={!!doc.idFile}>
          <FileUpload key={doc.id} isLoading={isLoading} isDeletable={isDeletable} name={isAvisImposition ? `${doc.type}-${index}` : doc.type} allowUpload onDelete={() => onDelete(doc.idFile)} onDownload={() => onDownload(doc.idFile)} onUpload={(file) => onUpload(file, doc.type, doc.foyerPrecarite?.idFoyer)} className="mh-50" />
        </AccordionComp>
      ))}
    </>
  );
};

export default DocumentsList;
