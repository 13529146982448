import React from 'react';
import PreFilledLabel from '../prefilled-label';
export default function LoaderSimple(_a) {
    var type = _a.type;
    return (React.createElement(React.Fragment, null,
        type === 'goRenov' && React.createElement(PreFilledLabel, { position: "onLoader" }),
        React.createElement("div", { className: "loaderSimpleContainer" },
            React.createElement("div", { className: "loaderSimple" },
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null),
                React.createElement("div", null)))));
}
