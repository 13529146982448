import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';

interface NumberFieldProps {
  name: string;
  label?: string;
  min?: number;
}

const NumberField: FC<NumberFieldProps> = ({ name, label, min = 0 }) => {
  const [field, meta] = useField(name);
  const { value } = field;
  const { setFieldValue } = useFormikContext();

  const increment = () => {
    setFieldValue(name, parseInt(value, 10) + 1);
  };

  const decrement = () => {
    // Prevent decrementing below the min value
    if (min !== undefined && parseInt(value, 10) > min) {
      setFieldValue(name, parseInt(value, 10) - 1);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let val = event.target.value;

    // Remove commas and dots
    val = val.replace(/[.,]/g, '');

    // Convert to integer
    let intValue = parseInt(val, 10);

    // If the value is not a number, set it to 0
    if (Number.isNaN(intValue)) {
      intValue = 0;
    }

    // Prevent values below the min value
    if (min !== undefined && intValue < min) {
      intValue = min;
    }

    setFieldValue(name, intValue);
  };

  return (
    <div className="diagnosticQuestionText m-4">
      <label htmlFor={name} className="diagnosticQuestionTextLabel">
        {label}
      </label>
      <div className="numberWithControls">
        <button className="buttonIncrement buttonLeft" type="button" onClick={decrement} data-cy="minus">
          -
        </button>
        <input className={`diagnosticQuestionTextInput ${name === 'number' ? 'withUnit' : ''}`} data-cy={name} name={name} id={name} value={value} onChange={handleChange} min={min} />
        <button className="buttonIncrement buttonRight" type="button" onClick={increment} data-cy="plus">
          +
        </button>
      </div>
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </div>
  );
};

export default NumberField;
