import { useEffect, useCallback, useState } from 'react';
import { useSetRecoilState, useRecoilValue, useRecoilState, selector } from 'recoil';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import SimulationsAPI from '../services/simulationsAPI';
import { inProgressSimulationsErrorState, inProgressSimulationsLoadingState, createdSimulationsErrorState, createdSimulationsLoadingState, closedSimulationsErrorState, closedSimulationsLoadingState, closedSimulationsState, createdSimulationsState, inProgressSimulationsState } from '../states/simulations';
import { SIMULATION } from '../helpers/utils';

interface useSimulationsReturn {
  isLoading: boolean;
  error: unknown;
  refresh: () => void;
}

export const useSimulations = (type: string, callApi = true): useSimulationsReturn => {
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const [, setSimulations] = useRecoilState<Resultat[]>(
    {
      [SIMULATION.IN_PROGRESS]: inProgressSimulationsState,
      [SIMULATION.CREATED]: createdSimulationsState,
      [SIMULATION.CLOSED]: closedSimulationsState,
    }[type]
  );
  const setIsLoading = useSetRecoilState<boolean>(
    {
      [SIMULATION.IN_PROGRESS]: inProgressSimulationsLoadingState,
      [SIMULATION.CREATED]: createdSimulationsLoadingState,
      [SIMULATION.CLOSED]: closedSimulationsLoadingState,
    }[type]
  );
  const setError = useSetRecoilState<unknown>(
    {
      [SIMULATION.IN_PROGRESS]: inProgressSimulationsErrorState,
      [SIMULATION.CREATED]: createdSimulationsErrorState,
      [SIMULATION.CLOSED]: closedSimulationsErrorState,
    }[type]
  );

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    if (callApi || isRefreshing) {
      try {
        const res = await SimulationsAPI.getSimulations(type);
        setSimulations(res);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
        console.error('Error fetching simulations:', err);
      }
    } else {
      setIsLoading(false);
    }
  }, [type, callApi, setIsLoading, setError, setSimulations, isRefreshing]);

  const refresh = useCallback(() => {
    setIsRefreshing(true);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    isLoading: useRecoilValue(
      {
        [SIMULATION.IN_PROGRESS]: inProgressSimulationsLoadingState,
        [SIMULATION.CREATED]: createdSimulationsLoadingState,
        [SIMULATION.CLOSED]: closedSimulationsLoadingState,
      }[type]
    ),
    error: useRecoilValue(
      {
        [SIMULATION.IN_PROGRESS]: inProgressSimulationsErrorState,
        [SIMULATION.CREATED]: createdSimulationsErrorState,
        [SIMULATION.CLOSED]: closedSimulationsErrorState,
      }[type]
    ),
    refresh,
  };
};

export const countInProgressSimulationsSelector = selector({
  key: 'countInProgressSimulationsSelector',
  get: ({ get }) => {
    const inProgressSimulations = get(inProgressSimulationsState);
    return inProgressSimulations.length.toString();
  },
});
