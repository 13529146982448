import React from 'react';
export var SwitchInput = function (_a) {
    var isOn = _a.isOn, handleToggle = _a.handleToggle, colorOne = _a.colorOne, colorTwo = _a.colorTwo, question = _a.question;
    var handleChange = function () {
        if (typeof handleToggle === 'function')
            handleToggle();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("label", { className: "diagnosticQuestionTextLabel", htmlFor: question.alias, 
            // eslint-disable-next-line
            dangerouslySetInnerHTML: {
                __html: question.labelInput,
            } }),
        React.createElement("input", { checked: isOn, onChange: handleChange, className: "switch-checkbox", id: "switch", type: "checkbox", "data-cy": "switch-checkbox" }),
        React.createElement("label", { style: { background: isOn ? colorOne : colorTwo }, className: "switch-label", htmlFor: "switch" },
            React.createElement("span", { className: "switch-button off ".concat(isOn ? '' : 'active') }, "Non"),
            React.createElement("span", { className: "switch-button on ".concat(isOn ? 'active' : '') }, "Oui"))));
};
