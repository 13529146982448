var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Resource from '..';
var QuestionResource = /** @class */ (function (_super) {
    __extends(QuestionResource, _super);
    function QuestionResource(entrypoint) {
        if (entrypoint === void 0) { entrypoint = '/questions'; }
        var _this = _super.call(this, entrypoint) || this;
        _this.ALIAS_START_PAGE = 'start_page';
        _this.ALIAS_QUESTION_ADDRESS = 'dwelling_address';
        _this.ALIAS_QUESTION_DWELLING_TYPE = 'dwelling_type';
        _this.ALIAS_QUESTION_WINDOWS_PER_WALL = 'number_of_windows_per_wall';
        _this.ALIAS_QUESTION_REASONS_FOR_RENOVATION = 'reasons_for_renovation';
        _this.ALIAS_QUESTION_THERMOSTAT = 'thermostat';
        _this.ALIAS_QUESTION_NB_OF_WEEKS = 'number_of_weeks';
        _this.ALIAS_QUESTION_OCCUPANCY_PROFILE = 'occupancy_profile';
        _this.ALIAS_QUESTION_NUMBER_OF_OCCUPANTS = 'number_of_occupants';
        _this.ALIAS_QUESTION_PLANNED_RENOVATION_WORK = 'planned_renovation_works';
        _this.ALIAS_QUESTION_SURFACE_AREA = 'surface_area';
        _this.ALIAS_QUESTION_HAS_EXTENSION = 'has_extension';
        _this.ALIAS_QUESTION_FLOOR_TYPE = 'floor_type';
        _this.ALIAS_QUESTION_HEATING_SYSTEM_TYPE = 'heating_system_type';
        _this.ALIAS_QUESTION_SECONDARY_HEATING_SYSTEM_TYPE = 'secondary_heating_system_type';
        _this.ALIAS_QUESTION_DOMESTIC_HOT_WATER_SYSTEM_TYPE = 'domestic_hot_water_system_type';
        _this.ALIAS_QUESTION_HEATING_SYSTEM_OPERATING_MODE = 'heating_system_operating_mode';
        _this.ALIAS_QUESTION_HOUSE_SHAPE = 'house_shape';
        _this.cache = null;
        return _this;
    }
    QuestionResource.prototype.all = function (useCache) {
        var _this = this;
        if (useCache === void 0) { useCache = true; }
        if (useCache && this.cache !== null) {
            return Promise.resolve(this.cache);
        }
        return this.list({ itemsPerPage: 100 }).then(function (data) {
            _this.cache = data['hydra:member'];
            return _this.cache;
        });
    };
    return QuestionResource;
}(Resource));
export { QuestionResource };
export default new QuestionResource();
