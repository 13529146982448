import React, { FC, ReactNode } from 'react';

interface EnvoiAHStepItemProps {
  number: number;
  text: string;
  children?: ReactNode;
}

const EnvoiAHStepItem: FC<EnvoiAHStepItemProps> = ({ number, text, children }) => (
  <div className="information-travaux__content__ah__items__item">
    <div className="information-travaux__content__ah__items__item__number">{number}</div>
    <div className="information-travaux__content__ah__items__item__text">
      {text}
      {children}
    </div>
  </div>
);

export default EnvoiAHStepItem;
