import { api, Resource } from 'core';
import { DocumentQuestions } from '../types/dashboard';
import { mapAPIDocumentsQuestions } from '../mapper/documentQuestions';
import { DocumentData } from '../types/document';
import { mapAPIDocuments } from '../mapper/document';

interface Document {
  fileName: string;
  mimeType: string;
  binary: string;
}

export class DocumentAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  getDocumentsQuestions(uuidSimulation: string): Promise<DocumentQuestions> {
    return api.get(this.buildUri(`simulation/${uuidSimulation}/questions-documents`)).then(({ data }) => mapAPIDocumentsQuestions(data));
  }

  getDocuments(idDocument: string): Promise<DocumentData[]> {
    return api.get(this.buildUri(`simulation/${idDocument}/documents`)).then(({ data }) => mapAPIDocuments(data.documents));
  }

  postDocument(uuidSimulation: string, query: FormData): Promise<{ success: boolean }> {
    return api.post(this.buildUri(`simulation/${uuidSimulation}/document`), query).then(({ data }) => data);
  }

  downloadDocument(idDocument: string, idFile: number | null): Promise<Document> {
    return api.get(this.buildUri(`simulation/${idDocument}/document?fileId=${idFile}`), idFile).then(({ data }) => data.documents);
  }

  deleteDocument(idDocument: string, idFile: number | null): Promise<{ success: boolean }> {
    return api.delete(this.buildUri(`simulation/${idDocument}/delete?fileId=${idFile}`)).then(({ data }) => data);
  }
}

export default new DocumentAPI();
