import React, { useState } from 'react';
import { useSharedValue } from 'states';
import PersonalInfosForm from 'components/form/personal-infos';
import ModalDeleteAccount from 'components/account/modal-delete';

export default function PersonalInfos(): React.ReactElement {
  const user = useSharedValue('security:user');
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  return (
    <>
      <h1 className="accountInfosTitle">
        <strong>Bonjour {user.firstName},</strong> bienvenue sur votre espace Sonergia
      </h1>
      <h2 className="accountInfosTitleSub">Vos informations de connexion</h2>

      <PersonalInfosForm />

      <h2 className="accountInfosTitleSub">Suppression de votre compte</h2>
      <p>
        Je souhaite
        <button type="button" className="accountInfosDeleteLink" onClick={handleShowModal}>
          supprimer mon compte.
        </button>
      </p>
      <p>La suppression de votre compte entraîne automatiquement la suppression de l’ensemble de vos informations, y compris vos questionnaires, simulations et données personnelles.</p>

      <ModalDeleteAccount show={showModal} onHide={handleCloseModal} />
    </>
  );
}
