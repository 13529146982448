var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Resource from '..';
var AssignmentStatusResource = /** @class */ (function (_super) {
    __extends(AssignmentStatusResource, _super);
    function AssignmentStatusResource(entrypoint) {
        if (entrypoint === void 0) { entrypoint = '/assignment_statuses'; }
        var _this = _super.call(this, entrypoint) || this;
        _this.CODE_VISITING = 'visiting';
        _this.CODE_RDV = 'rdv';
        _this.CODE_VISITED = 'visited';
        _this.CODE_REPORTED = 'reported';
        _this.CODE_FINANCING = 'financing';
        _this.CODE_FINANCED = 'financed';
        _this.CODE_REALIZED = 'realized';
        _this.cache = null;
        return _this;
    }
    AssignmentStatusResource.prototype.all = function (useCache) {
        var _this = this;
        if (useCache === void 0) { useCache = true; }
        if (useCache && this.cache !== null) {
            return Promise.resolve(this.cache);
        }
        return this.list({ itemsPerPage: 100 }).then(function (data) {
            _this.cache = data['hydra:member'];
            return _this.cache;
        });
    };
    return AssignmentStatusResource;
}(Resource));
export { AssignmentStatusResource };
export default new AssignmentStatusResource();
