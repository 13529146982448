import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import UtilisateurSecurity from 'features/account/access/services/utilisateurSecurity';
import { UserAccess } from 'features/account/access/interfaces/UserAccount';
import * as Yup from 'yup';
import { TextInput } from './inputs';

export default function AccessUserData(): React.ReactElement {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const accessUserDataSchema = Yup.object().shape({
    emailUser: Yup.string().email().required().default(''),
  });

  const initialValues: UserAccess = accessUserDataSchema.getDefault();

  const handleSubmit = async (data: UserAccess) => {
    setSubmitting(true);
    setSuccess(false);
    setError(false);
    const response = await UtilisateurSecurity.attribuerDonnees(data.emailUser);
    if (response.success) {
      setSuccess(true);
    } else {
      setError(true);
    }
    setSubmitting(false);
  };

  const SuccessMessage = (): React.ReactElement => (success ? <div className="alert alert-success">L&apos;attribution des données a bien été effectuée.</div> : <span />);
  const ErrorMessage = (): React.ReactElement => (error ? <div className="alert alert-danger">Une erreur a été rencontrée.</div> : <span />);

  return (
    <div>
      <SuccessMessage />
      <ErrorMessage />
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={accessUserDataSchema}>
        <Form>
          <TextInput name="emailUser" type="email" label="Email du compte à récupérer : " />
          <button type="submit" className="btn btn-primary btn-icon-left">
            {submitting ? (
              <span>
                <i className="icon-loading" /> Envoi en cours...
              </span>
            ) : (
              <span>
                <HiOutlineCheckCircle size={20} className="m-1" /> Enregistrer
              </span>
            )}
          </button>
        </Form>
      </Formik>
    </div>
  );
}
