import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { motifsAbandonStates } from '../states/motifsAbandon';
import simulationsAPI, { MotifAbandon } from '../services/simulationsAPI';

type UseRejectionResult = {
  motifsAbandon: MotifAbandon[];
  error: unknown;
  isLoading: boolean;
};

const useMotifAbandon = (): UseRejectionResult => {
  const [motifsAbandon, setMotifsAbandon] = useRecoilState(motifsAbandonStates.state);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await simulationsAPI?.getMotifsAbandon();
      setMotifsAbandon(res);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();

    return () => {
      setMotifsAbandon([]);
      setError(null);
      setIsLoading(false);
    };
  }, []);

  return { motifsAbandon, error, isLoading };
};

export default useMotifAbandon;
