import React from 'react';
import ChoiceInput from '../choice';
import questionResource from '../../../../resources/question';
import { useSharedValue } from '../../../../states';
export default (function (_a) {
    var question = _a.question, onChange = _a.onChange;
    var responses = useSharedValue('survey:responses');
    var isHouse = responses[questionResource.ALIAS_QUESTION_DWELLING_TYPE] === 0;
    return React.createElement(ChoiceInput, { question: question, showChoice: function (value) { return (isHouse ? value !== '6' && value !== '5' : true); }, onChange: onChange });
});
