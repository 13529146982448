import { useState, RefObject } from 'react';
import html2canvas from 'html2canvas';
import JsPDF, { ImageProperties } from 'jspdf';

const usePdfGenerator = (printRef: RefObject<any>) => {
  const [isDownloadPdf, setIsDownloadPdf] = useState<boolean>(false);

  const generatePdf = async () => {
    try {
      setIsDownloadPdf(true);

      const element = printRef.current;

      if (!element) {
        return;
      }

      const hideElements = (elements: HTMLElement[]) => {
        elements.forEach((el) => {
          el.classList.add('aat-primes__hideOnPdf');
        });
      };

      // cacher les éléments qui ne doivent pas apparaître dans le PDF
      const elementsToHide = element.querySelectorAll('.hideOnPdf');
      hideElements(Array.from(elementsToHide));

      // mettre a jour les styles des éléments qui doivent apparaître dans le PDF
      const amountElement = element.querySelector('.aat-primes__amount');
      if (amountElement) {
        amountElement.classList.add('aat-primes__amount--pdf');
      }

      element.classList.add('aat-primes__pdf');

      // générer le PDF
      const canvas = await html2canvas(element);
      const data = canvas.toDataURL('image/png');

      const pdf = new JsPDF('p', 'pt', 'a4');
      const imgProperties: ImageProperties = pdf.getImageProperties(data);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

      pdf.addImage(data, 'PNG', 0, 30, pdfWidth, pdfHeight);
      pdf.save('etapes_prime_V1');

      // remettre les éléments à leur état initial
      elementsToHide.forEach((el: HTMLElement) => {
        el.removeAttribute('style');
      });

      element.removeAttribute('style');
    } catch (error) {
      // TODO: gérer l'erreur avec un toast
      console.error('Error generating PDF:', error);
    } finally {
      setIsDownloadPdf(false);
    }
  };

  return { isDownloadPdf, generatePdf };
};

export default usePdfGenerator;
