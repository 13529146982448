import React from 'react';
import { LuChevronRightCircle } from 'react-icons/lu';
import { GrUpdate } from 'react-icons/gr';
import Tooltip from 'components/tooltip/Tooltip';
import Button from '../../../button/button';
import lang from '../../../../lang/dashboard.json';

interface PrimeActionButtonProps {
  label: string;
  isDisabled: boolean;
  onClick: () => void;
  isRemplassable: boolean;
  isNonCumulable: boolean;
}

const PrimeActionButton: React.FC<PrimeActionButtonProps> = ({ label, isDisabled, onClick, isRemplassable, isNonCumulable }) => {
  const iconComponent = isRemplassable ? GrUpdate : LuChevronRightCircle;
  const variant = isRemplassable && !isNonCumulable ? 'primary-border' : 'primary';

  return (
    <Tooltip showTooltip={isNonCumulable} text={lang.nonCumulable} placement="bottom" classNames="w-100">
      <Button id="request-prime-detail" label={label} icon={iconComponent} isDisabled={isDisabled} variant={variant} type="button" onClick={onClick} classNames="w-100" />
    </Tooltip>
  );
};

export default PrimeActionButton;
