import React, { useCallback, useEffect } from 'react';
import { QuestionGroup } from '@concerto-home/core';
import { QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

interface UseProgressionReturn {
  increaseProgression: () => void;
  decreaseProgression: () => void;
}

const updateGroupProgression = (groups: QuestionGroup[], currentGroup: QuestionGroup, newProgression: number, active = true) => groups.map((group) => (group.label === currentGroup.label ? { ...group, active, progression: newProgression } : group));
const useProgression = (currentQuestion: QuestionPrime, currentGroup: QuestionGroup, setGroups: React.Dispatch<React.SetStateAction<QuestionGroup[]>>): UseProgressionReturn => {
  let calculateStep = () => (currentGroup?.stepProgressionCount ? 100 / currentGroup?.stepProgressionCount : 0);

  if (currentQuestion?.shouldExit) {
    calculateStep = () => 0;
  }

  const increaseProgression = useCallback(() => {
    const step = calculateStep();
    const progression = currentGroup?.progression || 0;
    const newProgression = currentQuestion?.isStepProgression ? Math.min(progression + step, 100) : progression;

    setGroups((groups) => updateGroupProgression(groups, currentGroup, newProgression));
  }, [currentGroup, currentQuestion, setGroups]);

  const decreaseProgression = useCallback(() => {
    const step = calculateStep();
    const progression = currentGroup?.progression || 0;
    const newProgression = currentQuestion?.isStepProgression ? Math.max(progression - step, 0) : progression;

    setGroups((groups) => updateGroupProgression(groups, currentGroup, newProgression));
  }, [currentGroup, setGroups]);

  useEffect(() => {
    if (currentQuestion?.shouldExit) {
      setGroups((groups) => updateGroupProgression(groups, currentGroup, currentGroup?.progression ?? 0, false));
    }
  }, [currentQuestion?.shouldExit, setGroups]);

  return { increaseProgression, decreaseProgression };
};

export default useProgression;
