import React, { ReactElement, FC } from 'react';
import lang from 'features/dashboard/lang/projets.json';
import Button from '../../button/button';

type SubSousTraitanceProps = {
  onSelectSubSousTraitance: (isSousTraitant: boolean) => void;
};

const SubSousTraitance: FC<SubSousTraitanceProps> = ({ onSelectSubSousTraitance }): ReactElement => {
  const handleClick = (isSousTraitant: boolean) => {
    onSelectSubSousTraitance(isSousTraitant);
  };

  return (
    <div className="form__container  pt-4">
      <div className="form__container__title ">{lang.subcontractorQuestion}</div>
      <div className="form__container__content d-flex justify-content-center gap-4 m-4 p-4">
        <Button id="no-subcontractor" variant="secondary" classNames="dark" label="Non" type="button" onClick={() => handleClick(false)} />
        <Button id="yes-subcontractor" variant="secondary" classNames="dark" label="Oui" type="button" onClick={() => handleClick(true)} />
      </div>
    </div>
  );
};

export default SubSousTraitance;
