import React from 'react';
import './accordionCustom.scss';
import Accordion from 'react-bootstrap/Accordion';
import { FaCheckCircle, FaExclamationCircle, FaRegFileAlt } from 'react-icons/fa';
import { formatTitle } from 'features/dashboard/helpers/utils';

interface AccordionCustomProps {
  title: string;
  fileName: string | null;
  isUploaded: boolean;
  children: React.ReactNode;
}

const StatusIcon: React.FC<{ isUploaded: boolean }> = ({ isUploaded }) => (isUploaded ? <FaCheckCircle size={25} color="#23C275" /> : <FaExclamationCircle size={25} color="#DB7927" />);

const FileNameDisplay: React.FC<{ fileName: string }> = ({ fileName }) => (
  <div className="accordion__documents__header__file">
    <div className="accordion__documents__header__file__status">
      <FaRegFileAlt size={20} color="#00556E" />
    </div>
    <div className="accordion__documents__header__file__name">{fileName}</div>
  </div>
);

const AccordionHeader: React.FC<{ title: string; fileName: string | null; isUploaded: boolean }> = ({ title, fileName, isUploaded }) => {
  const titleFormatted = formatTitle(title);
  return (
    <div className="accordion__documents__header">
      <div className="accordion__documents__header__infos">
        <div className="accordion__documents__header__infos__status">
          <StatusIcon isUploaded={isUploaded} />
        </div>
        <div className="accordion__documents__header__infos__title">{titleFormatted}</div>
      </div>
      {fileName && <FileNameDisplay fileName={fileName} />}
    </div>
  );
};

const AccordionCustom: React.FC<AccordionCustomProps> = ({ title, fileName, isUploaded, children }) => (
  <div className="accordion__documents">
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <AccordionHeader title={title} fileName={fileName} isUploaded={isUploaded} />
        </Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  </div>
);

export default AccordionCustom;
