import React, { useEffect, useState } from 'react';
import { confettiConfig } from 'features/primes/simulation/resultat/helpers/config';
import Confetti from 'react-dom-confetti';
import cee from '../../assets/icons/cee.svg';

interface VersementPrimeProps {
  amount: number | undefined;
}

const VersementPrime: React.FC<VersementPrimeProps> = ({ amount }) => {
  const [isConfetti, setIsConfetti] = useState(false);

  const confettiConfigPrime = {
    ...confettiConfig,
    startVelocity: 50,
    elementCount: 100,
    spread: 180,
  };

  useEffect(() => {
    if (amount) {
      setIsConfetti(true);
    }
  }, [amount]);

  return (
    <>
      <Confetti active={isConfetti} config={confettiConfigPrime} />
      <div className="controle-dossier__title mt-4 pt-4">Félicitations, votre dossier de prime est validé !</div>
      <div className="controle-dossier__title sucess">
        <p>Votre prime a été versée sur votre compte.</p>
      </div>
      <div className="controle-dossier__prime sucess">
        <p className="controle-dossier__prime__content__price">{amount} €</p>
        <p className="controle-dossier__prime__content__title">Prime Énergie Sonergia</p>
        <img src={cee} alt="CEE" className="controle-dossier__prime__content__img" />
      </div>
    </>
  );
};

export default VersementPrime;
