import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import Routes from 'pages/routes';
import axios from 'axios';
import { initialize as initializeCore } from 'core';
import { env, getEnv } from 'utils/env';
import { handleCurrentPageLoaded } from 'data-layer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import reportWebVitals from 'reportWebVitals';

import './assets/styles/index.scss';

handleCurrentPageLoaded();

const main = () => {
  const gtmId = getEnv('GOOGLE_TAG_MANAGER_ID');

  if (gtmId) {
    TagManager.initialize({ gtmId });
  }

  initializeCore();

  const container = document.getElementById('root');
  if (!container) {
    throw new Error('Root element not found');
  }
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Routes />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover limit={1} />
    </React.StrictMode>
  );
};

if (getEnv('ALLOW_OVERRIDE_ENV') === '1') {
  axios
    .get('/env.json')
    .then(({ data }) => env.source(data))
    .finally(main);
} else {
  main();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
