import api from '@concerto-home/api';
var Resource = /** @class */ (function () {
    function Resource(entrypoint) {
        this.entrypoint = entrypoint;
    }
    Resource.prototype.list = function (filters, config) {
        return api.get(this.entrypoint, filters, config).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    Resource.prototype.create = function (item, params, config) {
        return api.post(this.entrypoint, item, params, config).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    Resource.prototype.update = function (item, params, config) {
        if (!item.id) {
            throw new Error('The resource must be created before use update method.');
        }
        return api.put(this.buildUri(item.id), item, params, config).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    Resource.prototype.read = function (id, params, config) {
        return api.get(this.buildUri(id), params, config).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    Resource.prototype.delete = function (id, config) {
        return api.delete(this.buildUri(id), config);
    };
    Resource.prototype.buildUri = function () {
        var uri = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            uri[_i] = arguments[_i];
        }
        return "".concat(this.entrypoint, "/").concat(uri.join('/'));
    };
    Resource.prototype.uri = function () {
        var uri = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            uri[_i] = arguments[_i];
        }
        return "".concat(api.uri).concat(this.entrypoint, "/").concat(uri.join('/')).replace(/\/\//g, '/');
    };
    // eslint-disable-next-line class-methods-use-this
    Resource.prototype.uriToId = function (uri) {
        var parts = uri.split('/');
        return parts[parts.length - 1];
    };
    return Resource;
}());
export default Resource;
