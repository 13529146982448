import React from 'react';
import concerto404 from '../assets/images/concerto_404.svg';

export default function NotFound(): React.ReactElement {
  return (
    <div className="not-found__container">
      <img className="not-found__image" src={concerto404} alt="Page non trouvée" />
    </div>
  );
}
