import { customerResource, surveyResource, LoaderSimple } from 'core';
import security from '@concerto-home/security';
import { FaRegTrashAlt } from 'react-icons/fa';
import { BiTimeFive } from 'react-icons/bi';
import { LuChevronRightCircle, LuUser2 } from 'react-icons/lu';
import { ImSpinner6 } from 'react-icons/im';
import { MdEdit } from 'react-icons/md';
import { FaRegCircleCheck } from 'react-icons/fa6';

import React, { useEffect, useState } from 'react';
import { useSharedState } from 'states';
import { Popover, ProgressBar, OverlayTrigger } from 'react-bootstrap';

import type { Survey } from 'core';
import { Link } from 'react-router-dom';

import Slider from 'react-slick';

import NoSurvey from 'components/account/no-survey';

import { useConfirm } from 'utils/confirm';

const settingSliderDiagnostic = {
  dots: false,
  arrows: false,
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
        mobileFirst: true,
      },
    },
  ],
};

const getStatusLabel = (status: string) => {
  switch (status) {
    case surveyResource.STATUS_COMPLETE:
      return 'Terminé';
    case surveyResource.STATUS_REJECTED:
    case surveyResource.STATUS_PROCESSING:
    case surveyResource.STATUS_RESOLVING:
    case surveyResource.STATUS_IN_PROGRESS:
    default:
      return 'À compléter';
  }
};

const popoverInfo = (
  <Popover className="popoverInfo">
    <Popover.Body className="popoverInfoBody">
      <i className="icon-info popoverInfoBodyIcon" />
      <p className="popoverInfoBodyDetails">Ce questionnaire a été réalisé par l&apos;expert qui vous a accompagné lors du rendez-vous à domicile. Il a accès à votre analyse énergétique pour vous conseiller tout au long de votre projet de rénovation. Si vous souhaitez lui retirer son accès, il vous suffit d&apos;en faire la demande par email à l&apos;adresse : protectiondonnees@sonergia.fr</p>
    </Popover.Body>
  </Popover>
);

export default function Préconisation(): React.ReactElement {
  const [user, setUser] = useSharedState('security:user');
  const [deleted, setDeleted] = useState(false);
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [loading, setLoading] = useState(true);
  const [, setWindowWidth] = useState(window.innerWidth);
  const confirm = useConfirm();

  const fetch = () => {
    customerResource.read(String(security.user)).then((u) => {
      const nextSurveys = [];

      if (u.survey) {
        nextSurveys.push(u.survey);
      }

      if (u.surveyPartner) {
        nextSurveys.push(u.surveyPartner);
      }

      setUser(u);
      setSurveys(nextSurveys);
      setLoading(false);
    });
  };

  const remove = (id: number) => {
    confirm('Attention, la suppression est irréversible', 'Souhaitez-vous vraiment supprimer ce questionnaire ?').then(() => {
      setLoading(true);
      setDeleted(true);
      surveyResource.delete(id).then(fetch);
    });
  };

  const handleResize = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    fetch();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (loading) {
    return <LoaderSimple />;
  }

  return (
    <div className="">
      <h2 className="dashboard__demandes__title">Mes préconisations de travaux</h2>
      <p>Obtenez des conseils de travaux de rénovation basés sur une analyse énergétique gratuite de votre logement</p>
      <div className="accountSurveyLeft">
        {deleted && <div className="alert alert-success">Le questionnaire a bien été supprimé</div>}

        {surveys.length === 0 ? (
          <NoSurvey />
        ) : (
          <>
            <Slider {...settingSliderDiagnostic} className="accountSurveyList">
              {surveys.map((survey) => (
                <div className={`accountSurveyListCard ${survey.status === surveyResource.STATUS_COMPLETE ? 'complete' : ''}`} key={survey.id}>
                  <div className="accountSurveyListCardHeader">
                    <div className="accountSurveyListCardHeaderDate">
                      <BiTimeFive size={18} className="m-1" /> {new Date(String(survey.createdAt)).toLocaleDateString()}
                    </div>
                    <div className="accountSurveyListCardHeaderStatus">
                      {survey.status === surveyResource.STATUS_COMPLETE ? <FaRegCircleCheck size={18} /> : <ImSpinner6 size={18} />}
                      {getStatusLabel(survey.status)}
                    </div>
                  </div>
                  <div className="accountSurveyListCardBody">
                    <h3 className="accountSurveyListCardBodyTitle">Analyse énergétique</h3>
                    <div className="accountSurveyListCardBodyUser">
                      <div className="accountSurveyListCardBodyUserIcon">
                        <LuUser2 size={22} className="m-2" />
                      </div>
                      {survey.filledBy && (
                        <div className="accountSurveyListCardBodyUserLabel">
                          Réalisé par {survey.filledBy.firstName || 'vous'}
                          {survey.filledBy.lastName ? ' ' : ''}
                          {survey.filledBy.lastName?.[0].toUpperCase()}.
                          {survey.filledBy['@id'] !== user['@id'] && survey.filledBy !== user['@id'] && (
                            <OverlayTrigger trigger="click" placement="right" overlay={popoverInfo} rootClose rootCloseEvent="click">
                              <i className="icon-info popoverInfoButton" />
                            </OverlayTrigger>
                          )}
                        </div>
                      )}
                    </div>

                    <ProgressBar now={survey.status === surveyResource.STATUS_IN_PROGRESS || survey.status === surveyResource.STATUS_RESOLVING ? survey.progression : 100} />
                    <div className="accountSurveyListCardBodyStatus">{survey.status === surveyResource.STATUS_IN_PROGRESS || survey.status === surveyResource.STATUS_RESOLVING ? survey.progression : 100}%</div>

                    <div className="accountSurveyListCardBodyActions">
                      {survey.status === surveyResource.STATUS_COMPLETE ? (
                        <>
                          <Link to={`/analyse-energetique/${survey.id}/diagnostic`} className="btn btn-primary btn-icon-left">
                            <span>
                              <LuChevronRightCircle size={22} className="m-2" />
                              Voir l’analyse
                            </span>
                          </Link>
                          {survey.filledBy && survey.filledBy === user['@id'] && (
                            <Link type="button" className="btn btn-link btn-icon-left" to={`/analyse-energetique/${survey.id}`} state={{ updateContext: true }}>
                              <MdEdit className="m-2" />
                              <span>Modifier</span>
                            </Link>
                          )}
                        </>
                      ) : (
                        survey.filledBy &&
                        survey.filledBy === user['@id'] && (
                          <Link to={`/analyse-energetique/${survey.id}`} className="btn btn-primary btn-icon-left">
                            <span>
                              <i className="icon-diagnostic" />
                              Finaliser le questionnaire
                            </span>
                          </Link>
                        )
                      )}
                      {survey.filledBy && survey.filledBy === user['@id'] && (
                        <button type="button" className="btn btn-link btn-icon-left" onClick={() => remove(Number(survey.id))}>
                          <FaRegTrashAlt className="m-2" />
                          <span>Supprimer</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </>
        )}
      </div>
    </div>
  );
}
