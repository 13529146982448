import { api, Resource } from 'core';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

import { mapAPISimulations } from '../mapper/dashboard';

export interface MotifAbandon {
  id: string;
  libelle: string;
}

export class SimulationsAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  getSimulations(statut: string): Promise<Resultat[]> {
    return api.get(this.buildUri('simulations'), { statut }).then(({ data }) => mapAPISimulations(data));
  }

  getMotifsAbandon(): Promise<MotifAbandon[]> {
    return api.get(this.buildUri('motifs-abandon')).then(({ data }) => data?.motifsAbandon);
  }

  abandonSimulation(uuidSimulation: string, { motifAbandon, commentaire }: { motifAbandon: string; commentaire?: string }): Promise<{ abandon: boolean }> {
    return api
      .post(this.buildUri(`simulation/${uuidSimulation}/abandonner`), {
        motifAbandon,
        commentaire,
      })
      .then(({ data }) => data);
  }

  deleteSimulation(uuidSimulation: string): Promise<{ suppression: boolean }> {
    return api.delete(this.buildUri(`simulation/${uuidSimulation}/supprimer`)).then(({ data }) => data);
  }
}

export default new SimulationsAPI();
