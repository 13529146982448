import React, { useEffect, useState } from 'react';
import './projets.scss';
import { Form, Formik } from 'formik';

import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';

import Steps from 'features/dashboard/components/steps/steps';
import { LoaderSimple } from '@concerto-home/core';

import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import ProfessionnelAPI, { AttachParams } from 'features/dashboard/services/professionnelAPI';

import { SIMULATION_STATUS } from 'features/dashboard/helpers/utils';
import MultiStepFormContent from 'features/dashboard/components/multiStepFormContent/multiStepFormContent';
import NotFound from 'pages/not-found';
import AhQuestionAPI from 'features/dashboard/services/ahQuestionAPI';
import Breadcrumbs from 'features/dashboard/components/breadcrumbs';
import { trackClickFinishPerStep } from 'data-layer';
import { countInProgressSimulationsSelector } from 'features/dashboard/hooks/dashboard';
import { useRecoilValue } from 'recoil';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';
import ProfessionnelFormModel from '../../models/formModel';
import formInitialValues, { FormInitialValues } from '../../components/identificationProfessionnel/validation/formInitialValues';
import validationSchema from '../../components/identificationProfessionnel/validation/validationSchema';

const { formId } = ProfessionnelFormModel;

const STEP_CONTROLE_DOSSIER = 4;

const Projets: React.FC = () => {
  const navigate = useNavigate();
  const { simulationId } = useParams();
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  // used in tracking analytics
  const countInProgressSimulations = useRecoilValue(countInProgressSimulationsSelector);

  if (!simulationId) {
    return <NotFound />;
  }

  const { simulation, isLoading, error, refresh } = useSimulationResultat(simulationId);

  const getStatusOrdre = (completionStatus: string) => simulation?.statuts.find((statut) => statut.completion === completionStatus)?.ordre;

  const currentStatutInprogress = getStatusOrdre(SIMULATION_STATUS.IN_PROGRESS);
  const currentStatutRejet = getStatusOrdre(SIMULATION_STATUS.REJECTED);
  const currentStatutIncomplete = getStatusOrdre(SIMULATION_STATUS.INCOMPLETE);
  const lastStatutCreated = simulation?.statuts.reduce((acc, statut) => (statut.completion === SIMULATION_STATUS.CREATED ? Math.max(acc, statut.ordre) : acc), 0);
  const currentStep = simulation?.statuts.find((statut) => statut.completion === SIMULATION_STATUS.IN_PROGRESS);
  const lastCompletedStep = lastStatutCreated ?? 0;

  useEffect(() => {
    const statusOrder = [currentStatutInprogress, currentStatutRejet, currentStatutIncomplete, lastStatutCreated];
    const activeStepToProgress = statusOrder.find((ordre) => ordre);
    if (activeStepToProgress) setActiveStep(activeStepToProgress - 1);
  }, [currentStatutInprogress, currentStatutRejet, currentStatutIncomplete, lastStatutCreated]);

  async function attachProfessionnelToSimulation(values: FormInitialValues) {
    const payload: AttachParams = {
      simulationId: simulationId ?? '',
      professionnelSiret: values.siret,
      professionnelEmail: values.email,
      ...(values.isSousTraitant && { sousTraitantSiret: values.sousTraitantSiret }),
    };
    const response = await ProfessionnelAPI.attachProfessionalToSimulation(payload);

    if (response.success) {
      setActiveStep(activeStep + 1);
      refresh();

      // Target Tracking - Finish a Step
      trackClickFinishPerStep(simulation as Resultat, countInProgressSimulations, 'Identification de votre professionnel');
    }
  }

  async function attachQuestionAH() {
    if (!simulationId) {
      return;
    }
    const response = await AhQuestionAPI.patchAHQuestion(simulationId);
    if (response.success) {
      setActiveStep(activeStep + 1);
    }
  }

  const handleSubmit = async (values: FormInitialValues) => {
    // Call different APIs based on the active step
    switch (activeStep) {
      case 0:
        attachProfessionnelToSimulation(values);
        break;
      case 1:
        attachQuestionAH();
        break;
      default:
        break;
    }
  };

  // go back to previous step or dashboard if we are at the first step
  const handleBack = () => (activeStep > 0 ? setActiveStep(activeStep - 1) : navigate('/dashboard'));

  // go to step only if it's completed or current step is in progress
  const handleGoToStep = (step: number) => {
    if (lastCompletedStep >= STEP_CONTROLE_DOSSIER) {
      return;
    }
    if (step <= lastCompletedStep || (currentStep && step === currentStep.ordre - 1)) {
      setActiveStep(step);
    }
  };

  if (isLoading) return <LoaderSimple />;

  if (error) {
    return <NotFound />;
  }

  return (
    <div>
      <p className="header__subtitle">Mes demandes en cours</p>
      <h2 className="header__title">{simulation?.travaux?.operation}</h2>

      {simulation && <Steps status={simulation.statuts} onGoToStep={handleGoToStep} />}

      <Formik initialValues={formInitialValues} validationSchema={currentValidationSchema} onSubmit={handleSubmit}>
        {() => (
          <Form id={formId}>
            {activeStep < STEP_CONTROLE_DOSSIER && <Breadcrumbs onBack={handleBack} />}
            <MultiStepFormContent step={activeStep} refresh={refresh} simulation={simulation} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Projets;
