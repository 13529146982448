import { useState } from 'react';
import RechercheProfessionnelAPI, { SearchParams } from 'features/dashboard/services/professionnelAPI';
import errorMessages from '../lang/errorMessages.json';
import { Professionnel } from '../types/professionnel';

const useProfessionalSearch = () => {
  const [professionals, setProfessionals] = useState<Professionnel[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const searchProfessional = async ({ siret, identifiant, codeTravaux }: SearchParams) => {
    try {
      setError('');
      setLoading(true);
      const response = await RechercheProfessionnelAPI.searchProfessionalBySiretOrIdentifiant({
        siret,
        identifiant,
        codeTravaux,
      });
      if (response.length === 0) {
        setError(errorMessages.professionalNotFound);
      }
      setProfessionals(response);
    } catch (err) {
      setError(errorMessages.professionalNotFound);
    } finally {
      setLoading(false);
    }
  };

  return { professionals, loading, error, searchProfessional, setProfessionals };
};

export default useProfessionalSearch;
