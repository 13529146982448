import { useEffect, useState } from 'react';
import DocumentAPI from '../services/documentAPI';
import { DocumentData } from '../types/document';

type UseAhQuestionsProps = {
  simulationId: string;
};

type UseDocumentResult = {
  documents: DocumentData[] | undefined;
  error: unknown;
  isLoading: boolean;
  refresh: () => void;
};

const useDocuments = ({ simulationId }: UseAhQuestionsProps): UseDocumentResult => {
  const [documents, setDocuments] = useState<DocumentData[] | undefined>();
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const res = await DocumentAPI.getDocuments(simulationId);

      setDocuments(res);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const refresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();

    return () => {
      setDocuments(undefined);
      setError(null);
      setIsLoading(false);
    };
  }, [simulationId]);

  return { documents, error, isLoading, refresh };
};

export default useDocuments;
