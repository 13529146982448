import { useSearchParams } from 'react-router-dom';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import { useSharedState } from './index';

const removePrescripteurParam = () => {
  const [paramsUtm, setSearchParams] = useSearchParams();
  if (paramsUtm.has('prescripteur')) {
    paramsUtm.delete('prescripteur');
    setSearchParams(paramsUtm);
  }
};

const getUserPrescripteur = (): null | string => {
  const [paramsUtm] = useSearchParams();

  const [user] = useSharedState('security:user');

  const prescripteur = user?.prescripteur ?? paramsUtm.get('prescripteur') ?? getCookie('prescripteur') ?? null;

  removePrescripteurParam();
  return prescripteur;
};

const setUserPrescripteur = (prescripteur: string | null): void => {
  removeCookie('prescripteur');
  if (prescripteur !== null) {
    setCookie('prescripteur', prescripteur, { expires: 30 });
  }
};

const removeUserPrescripteur = (): void => {
  removeCookie('prescripteur');
};

export { getUserPrescripteur, setUserPrescripteur, removeUserPrescripteur };
