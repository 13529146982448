import React from 'react';
import { Button } from 'react-bootstrap';

type Props = {
  active: boolean;
  icon: string;
  title: string;
  onClick: () => void;
};

export default function ContactItem({ active, icon, title, onClick }: Props): React.ReactElement {
  return (
    <div className={`accountSurveyRightItem ${active ? 'active' : ''}`} onClick={onClick} aria-hidden>
      <div className="accountSurveyRightItemLabel">{title}</div>
      <div className="accountSurveyRightItemIllu">
        <img src={icon} alt="Rendez-vous" />
      </div>
      {active ? (
        <div className="accountSurveyRightItemPlus">
          <i className="icon-check" />
        </div>
      ) : (
        <Button className="accountSurveyRightItemPlus">
          <span>
            <i className="icon-plus" />
          </span>
        </Button>
      )}
    </div>
  );
}
