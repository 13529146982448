import { atom, selector } from 'recoil';
export var surveyCurrent = atom({
    key: 'survey:current',
    default: 0,
});
export var surveyPrevious = atom({
    key: 'survey:previous',
    default: 0,
});
export var surveyCurrentValidation = atom({
    key: 'survey:current:validation',
    default: null,
});
export var surveyResponses = atom({
    key: 'survey:responses',
    default: {},
});
export var surveyQuestions = atom({
    key: 'survey:questions',
    default: [],
});
export var surveyErrors = atom({
    key: 'survey:errors',
    default: [],
});
export var surveyId = atom({
    key: 'survey:id',
    default: null,
});
export var surveyToken = atom({
    key: 'survey:token',
    default: null,
});
export var surveyBnb = atom({
    key: 'survey:bnb',
    default: null,
});
export var surveySubmitting = atom({
    key: 'survey:submitting',
    default: false,
});
export var surveyHasHouseShape = atom({
    key: 'survey:has_house_shape',
    default: false,
});
export var surveyCurrentQuestion = selector({
    key: 'survey:questions:current',
    get: function (_a) {
        var get = _a.get;
        var current = get(surveyCurrent);
        return get(surveyQuestions)[current] || null;
    },
});
export var surveyCurrentResponse = selector({
    key: 'survey:responses:current',
    get: function (_a) {
        var get = _a.get;
        var current = get(surveyCurrent);
        var question = get(surveyQuestions)[current];
        return get(surveyResponses)[question.alias];
    },
});
