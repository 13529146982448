import React from 'react';
import Header from 'components/header';
import { LoaderSimple } from 'core';

export default function LoadingLayout(): React.ReactElement {
  return (
    <>
      <Header />
      <LoaderSimple />
    </>
  );
}
