import { Resource } from 'core';
import api from '@concerto-home/api';

export class UtilisateurSecurity extends Resource {
  constructor(entrypoint = '/security') {
    super(entrypoint);
  }

  attribuerDonnees(emailUser: string): Promise<{ success: boolean }> {
    return api.post(this.buildUri('utilisateur/attribuer-donnees'), { email: emailUser }).then(({ data }) => data);
  }
}

export default new UtilisateurSecurity();
