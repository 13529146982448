import { api, Resource } from 'core';
import { Answer, QuestionPrime } from '../interfaces/questionnaire';
import { mapAPITravauxQuestion } from '../mapper/travaux.mapper';

interface userAnswer {
  parcoursId: string;
  userAnswers: Answer[];
}

export class TravauxApi extends Resource {
  constructor(entrypoint = '/question') {
    super(entrypoint);
  }

  getTravaux(userAnswers: userAnswer): Promise<QuestionPrime[]> {
    return api
      .post(this.buildUri('travaux'), {
        parcoursId: userAnswers.parcoursId,
        questions: userAnswers.userAnswers.map((userAnswer: Answer) => ({
          questionId: userAnswer.questionId,
          value: userAnswer.value,
          response: userAnswer.response,
        })),
      })
      .then(({ data }) => mapAPITravauxQuestion(data));
  }
}

export default new TravauxApi();
