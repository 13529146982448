import React from 'react';
import { RecoilRoot } from 'recoil';
import Confirm from 'utils/confirm';

import Layout from './layout';
import ApiListener from './core/api-listener';
import AppWrapper from './app-wapper';
import DataLayer from './data-layer-listener';

export default function App(): React.ReactElement {
  document.body.classList.add('customer');

  return (
    <RecoilRoot>
      <AppWrapper>
        <DataLayer />
        <ApiListener />
        <Confirm />
        <Layout />
      </AppWrapper>
    </RecoilRoot>
  );
}
