import { api, Resource } from 'core';
import { mapAPIQuestion } from 'features/primes/simulation/questionnaire/mapper/question.mapper';
import { Answer, QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

export class PrimeApi extends Resource {
  constructor(entrypoint = '/questionnaire') {
    super(entrypoint);
  }

  getQuestion(query?: { [p: string]: unknown }): Promise<QuestionPrime> {
    return api.post(this.buildUri('demarrer'), query).then(({ data }) => mapAPIQuestion(data));
  }

  postQuestion(query: Answer): Promise<QuestionPrime> {
    const { questionId, parcoursId, value, response, codeTravaux } = query;
    return api
      .post(this.buildUri('question-reponses'), {
        uuid_question: questionId,
        uuid_parcours: parcoursId,
        valeur: value,
        reponses: response,
        codeTravaux,
      })
      .then(({ data }) => mapAPIQuestion(data));
  }
}

export default new PrimeApi();
