import React from 'react';
import './sideBar.scss';
import NavigationItem from '../navigation/navItem';
import Contact from '../contact/contact';

interface Route {
  name: string;
  path: string;
  withSubNavigation?: boolean;
}

const routes: Route[] = [
  {
    name: 'Mon tableau de bord',
    path: '/dashboard',
  },
  {
    name: 'Mes demandes en cours',
    path: 'constitution-dossier',
    withSubNavigation: true,
  },
];

const Sidebar: React.FC = () => (
  <div className="dashboard__sideBar sidebar">
    {routes.map((route) => (
      <div key={route.name} className="dashboard__sideBar__navigation">
        <NavigationItem path={route.path} label={route.name} withSubNavigation={route.withSubNavigation} />
      </div>
    ))}
    <Contact />
  </div>
);

export default Sidebar;
