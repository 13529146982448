import { object, string, boolean, mixed, ref } from 'yup';
import customerResource from '.';
import labels from '../../labels';
import { phoneRegExp, NameRegex } from '../../utils/regex';
var _a = labels.validation, _b = _a.yup, requiredLabel = _b.mixed.required, _c = _b.string, emailLabel = _c.email, minLabel = _c.min, lowercaseLabel = _c.lowercase, uppercaseLabel = _c.uppercase, numberLabel = _c.number, specialLabel = _c.special, matchesLabel = _c.matches, _d = _a.common, passwordsMustMatchLabel = _d.passwords_must_match, phoneLabel = _d.phone, termsLabel = _d.terms, solihaLabel = _d.soliha;
export var buildCustomerDataValidationSchema = object({
    firstName: string().matches(NameRegex, matchesLabel).required(requiredLabel).default(''),
    lastName: string().matches(NameRegex, matchesLabel).required(requiredLabel).default(''),
    phone: string().matches(phoneRegExp, { message: phoneLabel, excludeEmptyString: true }).default(''),
    dayDisponibility: string().default(customerResource.DAY_MONDAY),
    hoursDisponibility: string().default(customerResource.HOURS_MORNING),
});
export var buildCustomerCreateValidationSchema = object({
    email: string().email(emailLabel).required(requiredLabel).default(''),
    plainPassword: string().required(requiredLabel).default('').min(14, minLabel).matches(/[a-z]/g, lowercaseLabel).matches(/[A-Z]/g, uppercaseLabel).matches(/[0-9]/g, numberLabel).matches(/\W|_/g, specialLabel),
    plainPasswordConfirmation: string()
        .required(requiredLabel)
        .default('')
        .oneOf([ref('plainPassword'), ''], passwordsMustMatchLabel),
    terms: boolean().required().equals([true], termsLabel).default(false),
    survey: mixed().nullable().default(null),
    surveyPartner: mixed().nullable().default(null),
    source: string().default(customerResource.SOURCE_CONCERTO),
    prescripteur: string().nullable().default(null),
    roles: mixed().default([]),
    invitation: string().nullable().default(null),
}).concat(buildCustomerDataValidationSchema);
export var buildCustomerDataValidationDiagnosticSchema = object({
    firstName: string().matches(NameRegex, matchesLabel).required(requiredLabel).default(''),
    lastName: string().matches(NameRegex, matchesLabel).required(requiredLabel).default(''),
    phone: string().matches(phoneRegExp, phoneLabel).default(''),
    dayDisponibility: string().required().default(customerResource.DAY_MONDAY),
    hoursDisponibility: string().required().default(customerResource.HOURS_MORNING),
});
export var buildCustomerCreateValidationDiagnosticRenovSchema = object({
    soliha: boolean().required().equals([true], solihaLabel).default(false),
}).concat(buildCustomerCreateValidationSchema);
