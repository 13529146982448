import { api, Resource } from 'core';
import { mapAPIRechercheProfessionnel } from '../mapper/rechercheProfessionnel';
import { Professionnel } from '../types/professionnel';

export type SearchParams = {
  siret?: string;
  identifiant?: string;
  codeTravaux: string;
};

export type AttachParams = {
  simulationId: string;
  professionnelSiret: string;
  professionnelEmail: string;
  sousTraitantSiret?: string;
};

export class ProfessionnelAPI extends Resource {
  constructor(entrypoint = '/primes') {
    super(entrypoint);
  }

  searchProfessionalBySiretOrIdentifiant(params: SearchParams): Promise<Professionnel[]> {
    const { siret, identifiant, codeTravaux } = params;

    if (!siret && !identifiant) {
      return Promise.reject(new Error('Either siret or identifiant is required.'));
    }

    const requestData: SearchParams = {
      siret: siret || undefined,
      identifiant: identifiant || undefined,
      codeTravaux,
    };

    return api.get(this.buildUri('professionnel/rechercher'), requestData).then(({ data }) => mapAPIRechercheProfessionnel(data));
  }

  attachProfessionalToSimulation(params: AttachParams): Promise<{ success: boolean }> {
    const { simulationId, professionnelSiret, professionnelEmail, sousTraitantSiret } = params;

    return api
      .post(this.buildUri(`simulation/${simulationId}/professionnel`), {
        professionnelSiret,
        professionnelEmail,
        sousTraitantSiret,
      })
      .then(({ data }) => data);
  }
}

export default new ProfessionnelAPI();
