/* eslint-disable */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export default {
    control: function () { return ({
        background: 'rgba(0, 85, 110, 0.04)',
        alignItems: 'center',
        border: 0,
        display: 'flex',
        width: '100%',
        height: 60,
        borderRadius: '0',
        color: '#00556E',
        '&:active': { border: 0, boxShadow: 'none' },
        '&:hover': { border: 0, boxShadow: 'none' },
    }); },
    placeholder: function (provided) { return (__assign(__assign({}, provided), { color: '#8390A7', fontSize: '1.4rem', display: 'none' })); },
    indicatorSeparator: function () { return ({
        display: 'none',
    }); },
    dropdownIndicator: function (provided) { return (__assign(__assign({}, provided), { display: 'none' })); },
    menu: function (provided) { return (__assign(__assign({}, provided), { margin: 0, border: 0, boxShadow: 'none', width: 'max-content', minWidth: '100%' })); },
    menuList: function (provided) { return (__assign(__assign({}, provided), { padding: 0 })); },
    noOptionsMessage: function (provided) { return (__assign(__assign({}, provided), { fontSize: '1.4rem', display: 'none' })); },
    option: function (provided, state) { return (__assign(__assign({}, provided), { fontSize: '1.4rem', color: state.isFocused ? '#FFFFFF' : '#00556E', background: state.isFocused ? '#00556E' : '#FFFFFF', height: 65, display: 'flex', alignItems: 'center', border: state.isFocused ? '2px solid #00556E' : '2px solid rgba(0, 85, 110, 0.04)', borderTop: 0, padding: '0 15px', '&:active': {
            height: 65,
            background: '#00556E',
            color: '#FFFFFF',
            border: '2px solid #00556E',
        } })); },
    singleValue: function (provided) { return (__assign(__assign({}, provided), { fontSize: '1.4rem', color: '#00556E' })); },
    input: function (provided) { return (__assign(__assign({}, provided), { fontSize: '1.4rem' })); },
    valueContainer: function (provided) { return (__assign(__assign({}, provided), { padding: '0 15px' })); },
    loadingIndicator: function () { return ({
        display: 'none',
    }); },
    loadingMessage: function () { return ({
        color: '#00556E',
        fontSize: '1.2rem',
        marginTop: 10,
        marginBottom: 30,
    }); },
};
