import { QuestionChoice } from '@concerto-home/core';
import IncomeFiscalApi from 'features/primes/simulation/questionnaire/services/incomeFiscalApi';
import { useEffect, useState } from 'react';

export const useRevenusFiscaux = (nbrPersonnes: number, adress: string) => {
  const [revenusFiscaux, setRevenusFiscaux] = useState<QuestionChoice[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (nbrPersonnes === 0 || adress === '') {
      return;
    }
    const getResponses = async () => {
      setIsLoading(true);
      const payload = {
        nb_personnes: nbrPersonnes,
        adresse: adress,
      };
      const responses = await IncomeFiscalApi.getIncomeFiscal(payload);
      setRevenusFiscaux(responses);
      setIsLoading(false);
    };
    getResponses();
  }, [nbrPersonnes, adress]);

  return { revenusFiscaux, isLoading };
};
