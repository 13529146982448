import React from 'react';
import './controleDossier.scss';
import { useParams } from 'react-router';
import useSimulationResultat from 'features/primes/simulation/resultat/hooks/useSimulationResultat';
import { SIMULATION_STATUS } from 'features/dashboard/helpers/utils';
import { LoaderSimple } from '@concerto-home/core';
import DossierEncours from './dossierEncours';
import DossierRejete from './dossierRejete';
import DossierIncomplet from './dossierIncomplet';
import DossierTermine from './dossierTermine';

const ControleDossier: React.FC = () => {
  const { simulationId } = useParams<{ simulationId: string }>();

  if (!simulationId) return null;

  const { simulation, isLoading } = useSimulationResultat(simulationId);
  const { amount } = simulation || {};
  const controleDossierStatus = simulation?.statuts?.find((statut) => statut.ordre === 5);
  const { completion } = controleDossierStatus || {};

  if (isLoading) return <LoaderSimple />;

  // Object mapping for rendering components based on completion status
  const components = {
    [SIMULATION_STATUS.INCOMPLETE]: <DossierIncomplet />,
    [SIMULATION_STATUS.IN_PROGRESS]: <DossierEncours amount={amount} />,
    [SIMULATION_STATUS.REJECTED]: <DossierRejete />,
    [SIMULATION_STATUS.CREATED]: <DossierTermine />,
  };

  const renderComponent = components[completion as keyof typeof components] || <DossierEncours amount={amount} />;

  return <div className="controle-dossier">{renderComponent}</div>;
};

export default ControleDossier;
