import React from 'react';

import { useNavigate } from 'react-router';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

import { CiCirclePlus } from 'react-icons/ci';

import { trackClickDemandPrime } from 'data-layer';
import { getStatus } from 'features/dashboard/helpers/utils';
import lang from '../../../lang/dashboard.json';

import { ListLoader } from '../../contentLoader/list.skeleton';
import List from '../list/list';
import './lists.scss';
import Button from '../../button/button';
import Note from '../list/content/note';

interface Props {
  title: string;
  simulations: Resultat[];
  isLoading: boolean;
  isClosed?: boolean;
  note?: string;
}

const SimulationList: React.FC<{ simulations: Resultat[]; isClosed?: boolean }> = ({ simulations, isClosed }) => {
  const navigate = useNavigate();

  const navigateToSimulationDetail = (simulation: Resultat) => {
    const { id, hasAat, logement, travaux, amount, actions, isDossierExpire } = simulation || {};
    const { isRemplassableCarRevision, isRemplassableCarDesactivation } = actions;
    const isRemplassable = isRemplassableCarDesactivation || isRemplassableCarRevision;

    // Target tracking event for demandes
    trackClickDemandPrime({
      buttonId: 'request-prime-detail',
      buttonName: getStatus(simulation, isClosed),
      housing: logement?.typeLogement,
      exactWork: travaux?.operation,
      amount: amount?.toString(),
    });

    if (isRemplassable || isDossierExpire) {
      navigate('/simulation-prime', {
        state: { simulationId: id, update: true },
      });
      return;
    }

    const path = hasAat ? `/dashboard/constitution-dossier/${id}` : `/dashboard/${id}`;
    navigate(path);
  };

  return (
    <div className="dashboard__demandes__list">
      {simulations.map((simulation) => (
        <List key={simulation.id} simulation={simulation} isClosed={isClosed} onClick={() => navigateToSimulationDetail(simulation)} />
      ))}
    </div>
  );
};

const Lists: React.FC<Props> = ({ title, simulations, isLoading, note, isClosed }) => {
  const navigate = useNavigate();
  return (
    <section className="dashboard__simulations">
      <h2 className="dashboard__demandes__title">{title}</h2>

      {isLoading && <ListLoader />}

      {!isLoading && simulations.length === 0 ? (
        <Button id="request-simulation" label={lang.eligibleAmount} variant="quinary" type="button" icon={CiCirclePlus} onClick={() => navigate('/simulation-prime')} />
      ) : (
        <>
          <SimulationList simulations={simulations} isClosed={isClosed} />
          {note && <Note note={note} />}
        </>
      )}
    </section>
  );
};

export default Lists;
