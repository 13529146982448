import React, { useEffect, useState } from 'react';
import { IoSettingsOutline } from 'react-icons/io5';
import { LuUser2 } from 'react-icons/lu';
import { FaBalanceScale, FaUserSecret } from 'react-icons/fa';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useSharedState } from '../../states';

const menuItems = [
  {
    label: 'Gestion de votre compte',
    icon: IoSettingsOutline,
    path: '/account/managment',
    utilisateurAccess: false,
  },
  {
    label: 'Vos données personnelles',
    icon: LuUser2,
    path: '/account/managment/data',
    utilisateurAccess: false,
  },
  {
    label: 'Récupérer les données',
    icon: FaUserSecret,
    path: '/account/managment/access',
    utilisateurAccess: true,
  },
  {
    label: 'Mentions légales',
    icon: FaBalanceScale,
    path: '/account/managment/legal',
    utilisateurAccess: false,
  },
];

export function AccountMenuLeft(): React.ReactElement {
  const location = useLocation();
  const [user] = useSharedState('security:user');
  const menuItemsFiltered = menuItems.filter((item) => !item.utilisateurAccess || (item.utilisateurAccess && user?.hasUtilisateurAccess));

  return (
    <>
      <div className="accountInfosMenu">
        <ul className="accountInfosMenuList">
          {menuItemsFiltered.map((item) => (
            <li key={item.path} className="accountInfosMenuListItem">
              <Link to={item.path} className={`accountInfosMenuListItemLink ${location.pathname === item.path ? 'active' : ''}`}>
                <div className="accountInfosMenuListItemIcon">
                  <item.icon size={24} />
                </div>
                {item.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

export function AccountMenuAccordion({ children }: React.PropsWithChildren<unknown>): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentKey, setCurrentKey] = useState(location.pathname);

  useEffect(() => {
    setCurrentKey(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className="accountInfosMenu">
        <Accordion defaultActiveKey={currentKey}>
          {menuItems.map((item) => (
            <Accordion.Item key={item.path} eventKey={item.path}>
              <Accordion.Header onClick={() => navigate(item.path)}>
                <div className="accountInfosMenuListItemIcon">
                  <item.icon size={24} />
                </div>
                {item.label}
              </Accordion.Header>
              <Accordion.Body>{children}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </>
  );
}
