import Button from 'features/dashboard/components/button/button';
import React from 'react';
import { CiCirclePlus } from 'react-icons/ci';
import { Link } from 'react-router-dom';

export default function NoSurvey(): React.ReactElement {
  return (
    <Link to="/analyse-energetique/creer" target="_blank">
      <Button variant="quinary" label="Quels travaux pour mon logement ?" type="button" icon={CiCirclePlus} id="survey-button" />
    </Link>
  );
}
