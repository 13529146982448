var Listenable = /** @class */ (function () {
    function Listenable() {
        this.listeners = {};
    }
    Listenable.prototype.on = function (event, callback) {
        this.initializeListeners(event);
        if (this.getCallbackIndex(event, callback) === null) {
            this.listeners[event].push(callback);
        }
        return this;
    };
    Listenable.prototype.off = function (event, callback) {
        var index = this.getCallbackIndex(event, callback);
        if (index !== null) {
            this.listeners[event].splice(index, 1);
        }
        return this;
    };
    Listenable.prototype.trigger = function (event) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var listeners = this.listeners[event] || [];
        for (var i = 0, len = listeners.length; i < len; i += 1) {
            listeners[i].apply(listeners, args);
        }
    };
    Listenable.prototype.initializeListeners = function (event) {
        this.listeners[event] = this.listeners[event] || [];
    };
    Listenable.prototype.getCallbackIndex = function (event, callback) {
        var listeners = this.listeners[event] || [];
        for (var i = 0, len = listeners.length; i < len; i += 1) {
            if (listeners[i] === callback) {
                return i;
            }
        }
        return null;
    };
    return Listenable;
}());
export default Listenable;
