import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { QuestionsDoc } from 'features/dashboard/types/dashboard';
import { FormikValues, useFormikContext } from 'formik';
import useDocumentActions from 'features/dashboard/hooks/useDocumentActions';
import Typography from '../typography/typography';
import { FormInitialValues } from '../identificationProfessionnel/validation/formInitialValues';
import Uploader from '../informationTravaux/devis/uploader';
import FileUploaderCta from './FileUploaderCta';
import FileUploaderQA from './FileUploaderQA';
import { FileDateUploader } from './FileDateUploader';
import FileUploaderWarning from './FileUploaderWarning';

interface QAFileUploaderPanelProps {
  label: string;
  documentQuestions: QuestionsDoc[];
  step: number;
  simulationId: string;
  fieldName: string;
  onUpload?: (file: File) => Promise<void>;
  isLoading?: boolean;
  invalidDate?: boolean;
  dateLabel: string;
  dateFieldName: string;
  DateFieldError?: string;
}

const QAFileUploaderPanel: React.FC<QAFileUploaderPanelProps> = ({ label, documentQuestions, step, simulationId, fieldName, onUpload, isLoading, invalidDate, dateLabel, dateFieldName, DateFieldError }) => {
  const { values } = useFormikContext<FormInitialValues & FormikValues>();
  const [isQuestionsBoxVisible, setQuestionsBoxVisible] = useState(false);
  const [areAllQuestionsAnswered, setAllQuestionsAnswered] = useState<boolean | null>(null);
  const [warnings, setWarnings] = useState<string[]>([]);

  const { downloadDocument } = useDocumentActions();
  const fileId = values[fieldName]?.idFile;
  const isUploadAllowed = useMemo(() => !invalidDate || false, [invalidDate]);

  const handleDownload = useCallback(async () => {
    await downloadDocument(simulationId, fileId);
  }, [downloadDocument, simulationId, fileId]);

  const handleQuestionsAnswered = useCallback((userAnswers: QuestionsDoc[]) => {
    const allAnswered = userAnswers.every((answer) => answer.reponse === true);
    const newWarnings = userAnswers.filter((answer) => answer.reponse === false).map((answer) => answer.messageAvertissement);

    setAllQuestionsAnswered(allAnswered);
    setWarnings(newWarnings);
    setQuestionsBoxVisible(false);
  }, []);

  const toggleQuestionsBox = useCallback(() => {
    setQuestionsBoxVisible((prev) => !prev);
    setWarnings([]);
  }, []);

  useEffect(() => {
    if (fileId) {
      setAllQuestionsAnswered(true);
    }
  }, [fileId]);

  return (
    <>
      <div className="information-travaux__content__devis">
        <div className="information-travaux__content__devis__questions">
          <Typography variant="h2" size="xl" fontWeight="bold" customClass="mt-4 d-flex gap-3 align-items-center">
            <div className="step__number">{step - 1}</div> {label}
          </Typography>
        </div>

        <div className="information-travaux__content__devis__container">
          <div className="information-travaux__content__devis__uploads__fields">
            <Uploader name={fieldName} isUploadAllowed={isUploadAllowed} onDownload={handleDownload} onUpload={onUpload} isLoading={isLoading} />
            <FileDateUploader label={dateLabel} name={dateFieldName} error={DateFieldError} />
          </div>
          <div className="information-travaux__content__devis__uploads__wrapper">
            <FileUploaderCta onClick={toggleQuestionsBox} isAllAnswered={areAllQuestionsAnswered} fieldName={fieldName} />
            <FileUploaderWarning warnings={warnings} />
          </div>
        </div>
      </div>
      {isQuestionsBoxVisible && <FileUploaderQA documentQuestions={documentQuestions} onDoneAnswering={handleQuestionsAnswered} isVisible={isQuestionsBoxVisible} />}
    </>
  );
};

export default QAFileUploaderPanel;
