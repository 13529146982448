import React from 'react';
import './index.scss';
import { Outlet } from 'react-router';
import Sidebar from '../components/sideBar/sidebar';
import { useSimulations } from '../hooks/dashboard';
import { SIMULATION } from '../helpers/utils';
import useMotifAbandon from '../hooks/useMotifAbandon';

const Index = () => {
  // Fetching simulations
  useSimulations(SIMULATION.IN_PROGRESS);
  useSimulations(SIMULATION.CREATED);
  useSimulations(SIMULATION.CLOSED);

  // Fetching motifs abandon
  useMotifAbandon();

  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard__content">
        <Outlet />
      </div>
    </div>
  );
};

export default Index;
