import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FormField from './FormField';

const FoyerFiscalFields: React.FC<{ index: number }> = ({ index }) => (
  <div className="lower__label">
    <Row>
      <Col md={6}>
        <FormField type="text" name={`foyerFiscal${index + 1}Nom`} label={`Foyer fiscal ${index + 1} - Nom`} />
      </Col>
      <Col md={6}>
        <FormField type="text" name={`foyerFiscal${index + 1}Prenom`} label={`Foyer fiscal ${index + 1} - Prénom`} />
      </Col>
    </Row>
  </div>
);

export default FoyerFiscalFields;
