import React from 'react';
import './typography.scss';

interface TypographyProps {
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2' | 'caption' | 'overline';
  size?: 'xs' | 's' | 'm' | 'l' | 'xl';
  color?: string;
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter' | '500' | '600' | '700' | '800' | '900';
  customClass?: string;
  children: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({ variant = 'body1', size = 'm', color = 'dark', fontWeight = 'normal', customClass, children }) => {
  const Tag = variant || 'div'; // Default to 'div' if no variant is provided

  const classes = `custom-typography ${size ? `size-${size}` : ''} ${color ? `color-${color}` : ''} ${fontWeight ? `font-weight-${fontWeight}` : ''} ${customClass || ''}`;

  return React.createElement(Tag, { className: classes }, children);
};

export default Typography;
