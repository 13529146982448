import React from 'react';
import { IStep } from '../interfaces/generationAAT';
import { addEightMonth } from '../helpers/functions';

const Step: React.FC<IStep> = ({ step, title, info }) => {
  let updatedTitle = title;

  if (title && title.includes('xx/xx/xxxx')) {
    updatedTitle = title.replace('xx/xx/xxxx', addEightMonth());
  }

  return (
    <div className="aat-primes__step">
      <div className="aat-primes__step-number">{step}</div>
      <div className="aat-primes__step-title">
        {updatedTitle}
        <br />
        <span className="aat-primes__step-title--info">{info}</span>
      </div>
    </div>
  );
};

export default Step;
