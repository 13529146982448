import React, { FC } from 'react';

interface PrimeContentBoxProps {
  isRemplassableCarRevision: boolean;
  isRemplassableCarDesactivation: boolean;
  isDetail?: boolean;
  isNonCumulable?: boolean;
  isClosed?: boolean;
  amount?: number;
  isDossierExpire?: boolean;
}

const PrimeContentBox: FC<PrimeContentBoxProps> = ({ isRemplassableCarRevision, isRemplassableCarDesactivation, isDetail, isNonCumulable, isClosed, amount = 0, isDossierExpire }) => {
  const isRemplacableAndNotNonCumulable = (isRemplassableCarRevision || isRemplassableCarDesactivation) && !isNonCumulable;
  const isDisabled = isNonCumulable || isClosed || isRemplassableCarRevision || isRemplassableCarDesactivation || isDossierExpire;

  const classNames = `dashboard__demandes__list__item__prime__amount ${isDetail ? 'details' : ''} ${isRemplacableAndNotNonCumulable ? 'left' : ''} ${isDisabled ? 'grised' : ''}`;

  const primaryText = isRemplacableAndNotNonCumulable ? 'Nouveau montant de prime' : 'Prime Énergie Sonergia *';
  const secondaryText = isRemplacableAndNotNonCumulable ? 'Mettez à jour vos informations pour découvrir votre nouvelle prime' : `${amount} €`;
  return (
    <div className={classNames}>
      {isRemplassableCarDesactivation || isDossierExpire ? (
        <>
          <p className="dashboard__demandes__list__item__prime__amount-title">Expiration de votre projet</p>
          <p className="dashboard__demandes__list__item__prime__amount-subtext">Cette simulation n’est plus éligible aux primes CEE.</p>
        </>
      ) : (
        <>
          <p className="dashboard__demandes__list__item__prime__amount-text">{primaryText}</p>
          <p className={`dashboard__demandes__list__item__prime__amount-${isRemplacableAndNotNonCumulable ? 'subtext' : 'value'}`}>{secondaryText}</p>
        </>
      )}
    </div>
  );
};

export default PrimeContentBox;
