var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import surveyResource from '../resources/survey';
export function isDisplay(question, responses) {
    var _a = question.rules, dependencies = _a.dependencies, parent = _a.parent;
    if (parent) {
        return false;
    }
    if (!Array.isArray(dependencies) || !dependencies.length) {
        return true;
    }
    for (var i = 0, len = dependencies.length, found = 0; i < len; i += 1) {
        var expectedResponses = dependencies[i].responses;
        var target = responses[dependencies[i].question];
        target = Array.isArray(target) ? target : [target];
        for (var j = 0, l = target.length; j < l; j += 1) {
            if (expectedResponses.includes(target[j])) {
                found += 1;
            }
        }
        if (found === expectedResponses.length) {
            return true;
        }
    }
    return false;
}
export function transformResponsesForState(questions, responses) {
    if (responses === void 0) { responses = []; }
    var data = {};
    var currentResponse;
    for (var i = 0, len = questions.length; i < len; i += 1) {
        currentResponse = undefined;
        for (var j = 0, l = responses.length; j < l; j += 1) {
            var resQuestion = responses[j].question;
            var questionIri = typeof resQuestion === 'object' && resQuestion['@id'] ? resQuestion['@id'] : resQuestion;
            if (questionIri === questions[i]['@id']) {
                currentResponse = JSON.parse(responses[j].value);
                break;
            }
        }
        data[questions[i].alias] = currentResponse;
    }
    return data;
}
export function transformResponsesForApi(questions, responses) {
    var data = [];
    for (var i = 0, len = questions.length, alias = null; i < len; i += 1) {
        alias = questions[i].alias;
        var item = {
            question: String(questions[i]['@id']),
            value: JSON.stringify(responses[alias]),
        };
        if (responses[alias] !== undefined) {
            item.value = item.value || 'null';
            data.push(item);
        }
    }
    return data;
}
export function download(filename, content) {
    var element = document.createElement('a');
    var href = content instanceof Blob ? window.URL.createObjectURL(content) : "data:text/plain;charset=utf-8,".concat(encodeURIComponent(content));
    element.setAttribute('href', href);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
}
export function downloadPayload(id, query) {
    surveyResource.payload(id, query).then(function (p) {
        download('payload.json', JSON.stringify(p));
    });
}
export function calcGroupProgression(group, questions, responses, current) {
    var question = questions[current];
    if (group.id !== question.group.id) {
        for (var i = 0, len = questions.length; i < len; i += 1) {
            if (questions[i].group.id === group.id && i < current) {
                return 100;
            }
            if (questions[i].group.id === group.id && i > current) {
                return 0;
            }
        }
    }
    var groupQuestions = questions.filter(function (q) { var _a; return ((_a = q.group) === null || _a === void 0 ? void 0 : _a.id) === group.id; });
    var filled = 0;
    for (var i = 0, len = groupQuestions.length; i < len; i += 1) {
        if ((question.alias !== groupQuestions[i].alias && responses[groupQuestions[i].alias] !== undefined) || !isDisplay(groupQuestions[i], responses)) {
            filled += 1;
        }
    }
    return Math.floor(groupQuestions.length > 0 ? (filled * 100) / groupQuestions.length : 0);
}
export function getGroups(questions, responses, current) {
    var _a;
    var question = questions[current];
    var groups = [];
    for (var i = 0, len = questions.length; i < len; i += 1) {
        if (i !== 0 && questions[i].group && !groups[questions[i].group.id]) {
            groups[questions[i].group.id] = __assign(__assign({}, questions[i].group), { progression: calcGroupProgression(questions[i].group, questions, responses, current), active: ((_a = question.group) === null || _a === void 0 ? void 0 : _a.id) === questions[i].group.id });
        }
    }
    return groups;
}
