import React, { ReactElement, FC } from 'react';
import lang from 'features/dashboard/lang/projets.json';
import SousTraitant from 'features/dashboard/assets/images/sous-traitant.svg';
import Button from '../../button/button';

type SubSousTraitanceNoRgeProps = {
  onSelectSubSousTraitance: (isSousTraitant: boolean) => void;
};

const SubSousTraitanceNoRge: FC<SubSousTraitanceNoRgeProps> = ({ onSelectSubSousTraitance }): ReactElement => {
  const handleClick = (isSousTraitant: boolean) => {
    onSelectSubSousTraitance(isSousTraitant);
  };

  return (
    <div className="form__container  pt-4 ">
      <div className="subcontractor_wrapper">
        <div className="subcontractor_wrapper__image">
          <img src={SousTraitant} alt="Votre professionnel n’est pas RGE" />
        </div>

        <div>
          <div className="form__container__title ">{lang.subcontractorQuestion}</div>
          <div className="subcontractor_wrapper__description">Le professionnel renseigné ne dispose pas de la certification RGE pour vos travaux. Afin de maintenir vos droits à une prime veuillez renseigner le nom de l’entreprise certifié RGE qui réalisera les travaux.</div>
          <div className="form__container__content d-flex justify-content-center gap-4 m-4 p-4">
            <Button id="no-subcontractor" variant="secondary-border" classNames="dark" label="Il n’y a pas de sous-traitant" type="button" onClick={() => handleClick(false)} />
            <Button id="yes-subcontractor" variant="secondary" classNames="dark" label="Renseigner un sous-traitant" type="button" onClick={() => handleClick(true)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubSousTraitanceNoRge;
