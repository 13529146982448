import React from 'react';
import Button from 'features/dashboard/components/button/button';
import { Modal, ModalBody } from 'react-bootstrap';
import { PHONE_NUMBER, SERVICE_EMAIL } from 'utils/constants';

interface ProfessionnelHelpProps {
  show: boolean;
  closeModal: () => void;
}

const ProfessionnelHelp: React.FC<ProfessionnelHelpProps> = ({ show, closeModal }) => (
  <Modal show={show} centered>
    <ModalBody>
      <p className="help__title">Besoin d’aide pour trouver le bon professionnel ?</p>
      <p className="help__text">
        Contactez-nous au <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>
      </p>
      <p className="help__text">
        Ou à l’adresse suivante : <a href={`mailto:${SERVICE_EMAIL}`}>{SERVICE_EMAIL}</a>
      </p>

      <p className="help__text">Nous vous accompagnons dans le choix d’un professionnel pour vos travaux.</p>
      <div className="d-flex justify-content-center">
        <Button id="confirm-modal" variant="primary" label="ok" type="button" onClick={closeModal} />
      </div>
    </ModalBody>
  </Modal>
);

export default ProfessionnelHelp;
