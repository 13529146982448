import React from 'react';
import ChoiceInput from '../choice';
import questionResource from '../../../../resources/question';
import { useSharedValue } from '../../../../states';
var choicesMapping = {
    // Je ne sais pas
    0: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    // Électricité
    1: [0, 5, 6, 7, 8, 11, 14],
    // Gaz
    2: [0, 1, 2, 3, 4],
    // Fioul
    3: [0, 1, 2, 3, 4],
    // Bois / granulés de bois
    4: [0, 1, 9],
    // Charbon
    5: [0, 1, 2, 3, 4, 10],
    // Solaire
    6: [13],
    // Réseau de chaleur
    7: [12],
};
var domesticHotWaterSystemTypeChoicesMapping = {
    // Je ne sais pas
    0: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    // Électricité
    1: [0, 5, 6, 8, 11],
    // Gaz
    2: [0, 1, 2, 3, 4],
    // Fioul
    3: [0, 1, 2, 3, 4],
    // Bois / granulés de bois
    4: [0, 7],
    // Charbon
    5: [0, 1, 2, 3, 4],
    // Solaire
    6: [10],
    // Réseau de chaleur
    7: [9],
};
export default (function (_a) {
    var question = _a.question, onChange = _a.onChange;
    var responses = useSharedValue('survey:responses');
    var energy = 0;
    var mapping = choicesMapping;
    switch (question.alias) {
        case questionResource.ALIAS_QUESTION_SECONDARY_HEATING_SYSTEM_TYPE: {
            energy = responses.secondary_heating_system_main_energy_vector || 0;
            break;
        }
        case questionResource.ALIAS_QUESTION_DOMESTIC_HOT_WATER_SYSTEM_TYPE: {
            energy = responses.domestic_hot_water_system_main_energy_vector || 0;
            mapping = domesticHotWaterSystemTypeChoicesMapping;
            break;
        }
        case questionResource.ALIAS_QUESTION_HEATING_SYSTEM_TYPE: {
            energy = responses.heating_system_main_energy_vector || 0;
            break;
        }
        default:
            break;
    }
    return React.createElement(ChoiceInput, { question: question, onChange: onChange, showChoice: function (value) { var _a; return (_a = mapping[energy]) === null || _a === void 0 ? void 0 : _a.includes(Number(value)); } });
});
