import React, { FC } from 'react';
import { IoCaretBack } from 'react-icons/io5';
import Button from '../button/button';

interface BreadcrumbsProps {
  onBack: () => void;
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ onBack }) => (
  <div className="mt-5">
    <Button id="back-button" type="button" variant="secondary" onClick={onBack} label="Retour" icon={IoCaretBack} />
  </div>
);

export default Breadcrumbs;
