import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { FaCheck } from 'react-icons/fa';
import { CircularProgressbar } from 'react-circular-progressbar';
export default function Group(_a) {
    var label = _a.label, progression = _a.progression, active = _a.active, mobile = _a.mobile;
    var complete = progression === 100 || progression >= 100;
    if (mobile) {
        return React.createElement("li", { className: "diagnosticChapterListMobileItem ".concat(complete ? 'completed' : '') }, complete ? React.createElement("i", { className: "icon-check" }) : React.createElement(CircularProgressbar, { value: progression, text: label }));
    }
    return (React.createElement("li", { className: "diagnosticChapterListItem ".concat(active || complete ? 'active' : '') },
        React.createElement("span", { className: "diagnosticChapterListItemLabel" },
            complete && (React.createElement("span", { className: "diagnosticChapterListItemLabelIcon" },
                React.createElement(FaCheck, { size: 12 }))),
            label),
        !complete && React.createElement(ProgressBar, { now: progression })));
}
