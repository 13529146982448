import React from 'react';
import { useSetSharedState } from 'states';
import { openLogin } from 'utils/sso';
import type { Customer } from 'core';

interface Props {
  handleComplete?: (_user: Customer) => void;
}

export default function Login({ handleComplete }: Props): React.ReactElement {
  const setUser = useSetSharedState('security:user');

  const handleLogin = () => {
    openLogin((c) => {
      setUser(c);
      handleComplete?.(c);
    });
  };

  return (
    <div className="text-center">
      <button className="btn btn-primary" data-cy="connect" type="submit" onClick={handleLogin}>
        <span>Se connecter</span>
      </button>
      <div className="mt-4 pb-5">
        <small>Veillez à désactiver les bloqueurs de publicité (adblock, ...) si la fenêtre de connexion ne s’ouvre pas.</small>
      </div>
    </div>
  );
}
