import React from 'react';

interface NoteProps {
  note: string;
}

const Note: React.FC<NoteProps> = ({ note }) => (
  <div className="dashboard__note">
    <p className="dashboard__note__text">{note}</p>
  </div>
);

export default Note;
