import { useField, useFormikContext } from 'formik';
import React, { FC } from 'react';

interface SwitchProps {
  name: string;
  label?: string;
}

const SwitchField: FC<SwitchProps> = ({ name, label }) => {
  const [field] = useField(name);
  const { value } = field;
  const { setFieldValue } = useFormikContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, event.target.checked);
  };

  return (
    <div className="m-4">
      <label className="diagnosticQuestionTextLabel" htmlFor={name}>
        {label}
      </label>

      <input checked={value} onChange={handleChange} className="switch-checkbox" id={name} type="checkbox" data-cy="switch-checkbox" />

      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="switch-label" htmlFor={name}>
        <span className={`switch-button off ${value ? '' : 'active'}`}>Non</span>
        <span className={`switch-button on ${value ? 'active' : ''}`}>Oui</span>
      </label>
    </div>
  );
};

export default SwitchField;
