var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect } from 'react';
import { validateResponseValue } from '../../resources/survey/validation';
import { useSetSharedState, useSharedState, useSharedValue } from '../../states';
import { getGroups, isDisplay } from '../../utils/survey';
import { resolveInput } from './inputs';
import Actions from './actions';
import Label from './label';
import Progression from '../progression';
import ScrollIndicator from './scroll-indicator';
import PreFilledLabel from './prefilled-label';
import LoaderSimple from './loader/loader-simple';
import StartPage from './startPage';
function Survey(_a) {
    var handleComplete = _a.handleComplete, handleNext = _a.handleNext, handleReject = _a.handleReject, handleContinueLater = _a.handleContinueLater, isResolving = _a.isResolving, _b = _a.rawResponses, rawResponses = _b === void 0 ? [] : _b, edition = _a.edition;
    var _c = useSharedState('survey:current'), current = _c[0], setCurrent = _c[1];
    var _d = useSharedState('survey:previous'), setPrevious = _d[1];
    var customValidation = useSharedValue('survey:current:validation');
    var questions = useSharedValue('survey:questions');
    var question = questions[current];
    if (!question) {
        return null;
    }
    var _e = useSharedState('survey:responses'), responses = _e[0], setResponses = _e[1];
    var response = responses[question.alias];
    var setErrors = useSetSharedState('survey:errors');
    var groups = getGroups(questions, responses, current);
    var handleBack = function () {
        var nextIndex = current - 1;
        while (questions[nextIndex] && !isDisplay(questions[nextIndex], responses)) {
            nextIndex -= 1;
        }
        setCurrent(nextIndex);
        setPrevious(current);
    };
    var handleContinue = useCallback(function (inputResponse) {
        var currentResponse = inputResponse !== undefined ? inputResponse : response;
        var errors = validateResponseValue(question, currentResponse, customValidation);
        if (errors.length) {
            return;
        }
        var newResponses = __assign({}, responses);
        if (inputResponse !== undefined) {
            newResponses[question.alias] = inputResponse;
        }
        var nextIndex = current + 1;
        while (questions[nextIndex] && !isDisplay(questions[nextIndex], newResponses)) {
            if (!questions[nextIndex].rules.parent) {
                // Ensure not displayable response has no value
                newResponses[questions[nextIndex].alias] = null;
            }
            nextIndex += 1;
        }
        setResponses(function (r) { return (__assign(__assign({}, r), newResponses)); });
        // Is rejectable response
        if (Array.isArray(question.rules.rejectable)) {
            var resp = !Array.isArray(currentResponse) ? [currentResponse] : currentResponse;
            for (var i = 0, len = resp.length; i < len; i += 1) {
                if (question.rules.rejectable.includes(resp[i])) {
                    if (typeof handleReject === 'function') {
                        handleReject(current, newResponses);
                    }
                    return;
                }
            }
        }
        if (questions[nextIndex]) {
            setCurrent(nextIndex);
            setPrevious(current);
            if (typeof handleNext === 'function') {
                handleNext(nextIndex, newResponses);
            }
        }
        else if (typeof handleComplete === 'function') {
            handleComplete(responses);
        }
    }, [question, response, responses, customValidation]);
    var isPreFilled = useCallback(function (alias) {
        for (var i = 0, len = questions.length; i < len; i += 1) {
            if (questions[i].alias === alias) {
                for (var j = 0, l = rawResponses.length; j < l; j += 1) {
                    if (rawResponses[j].question === questions[i]['@id']) {
                        return rawResponses[j].preFilled;
                    }
                }
            }
        }
        return false;
    }, [questions, rawResponses]);
    useEffect(function () {
        setErrors([]);
        window.scrollTo(0, 0);
        var onBackAction = function (e) {
            var confirmationMessage = 'o/';
            // Gecko + IE
            (e || window.event).returnValue = confirmationMessage;
            // Webkit, Safari, Chrome
            return confirmationMessage;
        };
        window.addEventListener('beforeunload', onBackAction);
        return function () { return window.removeEventListener('beforeunload', onBackAction); };
    }, [current]);
    var Input = resolveInput(question);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "diagnostic ".concat(current === 0 ? 'start' : '') },
            React.createElement(Progression, { isInProgress: current > 0, groups: groups, back: handleBack }),
            React.createElement("div", { className: "diagnosticQuestion ".concat(question.alias === 'number_of_windows_per_wall' ? 'coordinate' : '') },
                current === 0 && React.createElement(StartPage, null),
                isResolving ? (React.createElement(LoaderSimple, { type: question.alias === 'dwelling_type' ? 'goRenov' : '' })) : (React.createElement(React.Fragment, null,
                    React.createElement(Label, { question: question }),
                    isPreFilled(question.alias) && React.createElement(PreFilledLabel, null),
                    React.createElement(Input, { question: question, onChange: handleContinue }))),
                React.createElement(Actions, { next: handleContinue, back: handleBack, continueLater: handleContinueLater, disabled: isResolving, edition: edition }))),
        React.createElement(ScrollIndicator, null)));
}
export default Survey;
