import { QuestionsDoc } from 'features/dashboard/types/dashboard';
import React, { useEffect, useState } from 'react';
import { getClassNames } from 'features/dashboard/helpers/utils';
import Button from '../button/button';

interface FileUploaderQAProps {
  documentQuestions: QuestionsDoc[];
  onDoneAnswering: (userAnswers: QuestionsDoc[]) => void;
  isVisible: boolean;
}
interface QuestionStepProps {
  question: QuestionsDoc;
  index: number;
  currentQuestionIndex: number;
}

interface RenderQuestionStepsProps {
  answers: QuestionsDoc[];
  currentQuestionIndex: number;
}

const QuestionStep: React.FC<QuestionStepProps> = ({ question, index, currentQuestionIndex }) => <div key={question.label} className={getClassNames(question, index, currentQuestionIndex)} />;

const RenderQuestionSteps: React.FC<RenderQuestionStepsProps> = ({ answers, currentQuestionIndex }) => (
  <>
    {answers.map((question, index) => (
      <QuestionStep key={question.label} question={question} index={index} currentQuestionIndex={currentQuestionIndex} />
    ))}
  </>
);

const FileUploaderQA: React.FC<FileUploaderQAProps> = ({ documentQuestions, onDoneAnswering, isVisible }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(documentQuestions);

  const handleAnswer = ({ answer }: { answer: boolean }) => {
    setAnswers((prev) => {
      const updatedAnswers = [...prev];
      updatedAnswers[currentQuestionIndex].reponse = answer;
      return updatedAnswers;
    });

    if (currentQuestionIndex === answers.length - 1) {
      return;
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  useEffect(() => {
    setAnswers(documentQuestions.map((question) => ({ ...question, reponse: null })));
    setCurrentQuestionIndex(0);
  }, [isVisible]);

  useEffect(() => {
    if (currentQuestionIndex === answers.length - 1 && answers.every((answer) => answer.reponse !== null)) {
      onDoneAnswering(answers);
    }
  }, [currentQuestionIndex, answers]);

  return (
    <div className="file-uploader__qa">
      <div className="file-uploader__qa__steps">
        <div className="file-uploader__qa__steps__title">Vérification </div>
        <div className="file-uploader__qa__steps__indicator">
          <RenderQuestionSteps answers={answers} currentQuestionIndex={currentQuestionIndex} />
          <span className="file-uploader__qa__steps__indicator__step__state">{`${currentQuestionIndex + 1}/${answers.length}`}</span>
        </div>
      </div>
      <div className="file-uploader__qa__question">
        <div className="file-uploader__qa__question__title">{answers[currentQuestionIndex]?.label}</div>
        <div className="file-uploader__qa__question__cta">
          <Button
            id="answer-yes"
            label="Oui"
            type="button"
            variant="secondary"
            classNames="dark"
            onClick={() =>
              handleAnswer({
                answer: true,
              })
            }
          />
          <Button
            id="answer-no"
            label="Non"
            type="button"
            variant="secondary"
            classNames="dark"
            onClick={() =>
              handleAnswer({
                answer: false,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FileUploaderQA;
