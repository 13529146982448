import React, { useEffect, useState } from 'react';
import './navItem.scss';
import { NavLink, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useRecoilValue } from 'recoil';
import { inProgressSimulationsLoadingState, inProgressSimulationsState } from 'features/dashboard/states/simulations';
import SubNavigationItem from './subNavigationItem';

interface NavigationItemProps {
  path: string;
  label: string;
  withSubNavigation?: boolean;
}

const NavigationItem: React.FC<NavigationItemProps> = ({ path, label, withSubNavigation }) => {
  const simulations = useRecoilValue(inProgressSimulationsState);
  const isLoading = useRecoilValue(inProgressSimulationsLoadingState);
  const [showSubNavigation, setShowSubNavigation] = useState(false);
  const { simulationId } = useParams();

  // Check if the current path is active
  const isActivePath = window.location.pathname.includes(path);

  useEffect(() => {
    if (simulationId && simulations && simulations.find((simulation) => simulation.id === simulationId)) {
      setShowSubNavigation(true);
    }
  }, [simulationId, simulations]);

  const renderLabel = (isActive: boolean) => <span className={`navigation-item__label${isActive ? ' navigation-item__label--active' : ''}`}>{label}</span>;

  const emptySimulations = !simulations || simulations.length === 0;

  return (
    <>
      {withSubNavigation ? (
        <div className="navigation-item">
          <button type="button" disabled={isLoading || emptySimulations} className={`navigation-item__label ${isActivePath ? ' navigation-item__label--active' : ''}`} onClick={() => setShowSubNavigation(!showSubNavigation)}>
            {label}
            {isLoading && <Spinner animation="grow" variant="primary" size="sm" />}
          </button>
        </div>
      ) : (
        <NavLink to={path} className="navigation-item" end>
          {({ isActive }) => renderLabel(isActive)}
        </NavLink>
      )}
      {withSubNavigation && simulations && showSubNavigation && (
        <div className="navigation-item__sub-navigation">
          {simulations.map((simulation) => (
            <SubNavigationItem key={simulation.id} simulation={simulation} isLoading={isLoading} />
          ))}
        </div>
      )}
    </>
  );
};

export default NavigationItem;
