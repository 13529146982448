import { atom } from 'recoil';
import { Resultat } from 'features/primes/simulation/resultat/interfaces/resultat';

const simulationStates = {
  inProgress: {
    state: atom<Resultat[]>({
      key: 'inProgressSimulationsState',
      default: [],
    }),
    errorState: atom<unknown>({
      key: 'inProgressSimulationsErrorState',
      default: null,
    }),
    loadingState: atom<boolean>({
      key: 'inProgressSimulationsLoadingState',
      default: false,
    }),
  },
  created: {
    state: atom<Resultat[]>({
      key: 'createdSimulationsState',
      default: [],
    }),
    errorState: atom<unknown>({
      key: 'createdSimulationsErrorState',
      default: null,
    }),
    loadingState: atom<boolean>({
      key: 'createdSimulationsLoadingState',
      default: false,
    }),
  },
  closed: {
    state: atom<Resultat[]>({
      key: 'closedSimulationsState',
      default: [],
    }),
    errorState: atom<unknown>({
      key: 'closedSimulationsErrorState',
      default: null,
    }),
    loadingState: atom<boolean>({
      key: 'closedSimulationsLoadingState',
      default: false,
    }),
  },
};

export const {
  inProgress: { state: inProgressSimulationsState, errorState: inProgressSimulationsErrorState, loadingState: inProgressSimulationsLoadingState },
  created: { state: createdSimulationsState, errorState: createdSimulationsErrorState, loadingState: createdSimulationsLoadingState },
  closed: { state: closedSimulationsState, errorState: closedSimulationsErrorState, loadingState: closedSimulationsLoadingState },
} = simulationStates;
