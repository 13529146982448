import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { EtiquetteCombinatoire } from '../EtiquetteCombinatoire/EtiquetteCombinatoire';
import { StatusValue } from '../StatusValue/StatusValue';
import { Co2EmissionsValue } from '../Co2EmissionsValue/Co2EmissionsValue';
export var EtiquetteCombinatoireDetails = function (_a) {
    var statusAfter = _a.statusAfter, statusAfterValue = _a.statusAfterValue, statusBefore = _a.statusBefore, statusBeforeValue = _a.statusBeforeValue, co2EmissionsValue = _a.co2EmissionsValue;
    return (React.createElement(Row, { className: "etiquette-energetique-combinatoire" },
        React.createElement(Col, { xs: 6, sm: 4, xl: 3, className: "align-self-end" },
            React.createElement("div", null,
                React.createElement("b", null, "Apr\u00E8s travaux"),
                React.createElement("br", null),
                React.createElement("br", null),
                "Avant travaux",
                React.createElement("br", null),
                statusBeforeValue,
                " kWh/m\u00B2/an")),
        React.createElement(Col, { xs: 6, sm: 5, xl: 4 },
            React.createElement(EtiquetteCombinatoire, { statusAfter: statusAfter, statusBefore: statusBefore })),
        React.createElement(Col, { xs: 6, sm: 3, xl: 2 },
            React.createElement(StatusValue, { statusValue: statusAfterValue }),
            React.createElement(Co2EmissionsValue, { co2EmissionsValue: co2EmissionsValue }))));
};
