import React, { useEffect } from 'react';
import { ADRESS_QUESTION_ID, PERSON_QUESTION_ID } from 'utils/constants';
import IncomeFiscalApi from 'features/primes/simulation/questionnaire/services/incomeFiscalApi';
import { Answer, QuestionPrime } from 'features/primes/simulation/questionnaire/interfaces/questionnaire';

export default function useResponsesIncomeFiscal(responseUrl: string, userAnswers: Answer[], questions: QuestionPrime[], currentQuestionIndex: number, setQuestions: React.Dispatch<React.SetStateAction<QuestionPrime[]>>): void {
  useEffect(() => {
    if (responseUrl !== '') {
      const getResponses = async () => {
        const personsCount = userAnswers.find((answer) => answer.questionId === PERSON_QUESTION_ID)?.value;
        const address = userAnswers.find((answer) => answer.questionId === ADRESS_QUESTION_ID)?.value;

        const payload = {
          nb_personnes: personsCount,
          adresse: address,
        };
        const responses = await IncomeFiscalApi.getIncomeFiscal(payload);
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex].choices = responses;
        setQuestions(updatedQuestions);
      };
      getResponses();
    }
  }, [responseUrl]);
}
