import { useSearchParams } from 'react-router-dom';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import type { Survey } from '@concerto-home/core';
import { useSharedState } from './index';

const removeSourceParam = () => {
  const [paramsUtm, setSearchParams] = useSearchParams();
  if (paramsUtm.has('source')) {
    paramsUtm.delete('source');
    setSearchParams(paramsUtm);
  }
};

const getUserSource = (): string => {
  const [paramsUtm] = useSearchParams();

  const [user] = useSharedState('security:user');
  const sourceForced = getCookie('source_forced');

  const source = user?.source ?? sourceForced ?? paramsUtm.get('source') ?? getCookie('source') ?? 'concerto';

  if (sourceForced) {
    removeCookie('source_forced');
  }
  removeSourceParam();

  setCookie('source', source);

  return source;
};

const setUserSource = (source: string): void => {
  setCookie('source_forced', source);
};

const getSurveySource = (survey: Survey, userSource?: string): string => {
  const source = userSource ?? getUserSource();

  if (source === 'concerto-renov') {
    if (['39', '40'].includes(survey.extra?.departement ?? '')) {
      return 'concerto-renov';
    }
  }

  return 'concerto';
};

export { getUserSource, setUserSource, getSurveySource };
