var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Resource from '..';
import api from '@concerto-home/api';
import { download } from '../../utils/survey';
var SurveyResource = /** @class */ (function (_super) {
    __extends(SurveyResource, _super);
    function SurveyResource(entrypoint) {
        if (entrypoint === void 0) { entrypoint = '/surveys'; }
        var _this = _super.call(this, entrypoint) || this;
        _this.STATUS_IN_PROGRESS = 'in progress';
        _this.STATUS_COMPLETE = 'complete';
        _this.STATUS_REJECTED = 'rejected';
        _this.STATUS_RESOLVING = 'resolving';
        _this.STATUS_PROCESSING = 'processing';
        return _this;
    }
    SurveyResource.prototype.payload = function (id, query) {
        return api.get(this.buildUri(id, 'payload'), query).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    SurveyResource.prototype.export = function (id, query) {
        api.get(this.buildUri(id, 'export'), query, { responseType: 'blob' }).then(function (_a) {
            var data = _a.data;
            download('diagnostic.pdf', data);
        });
    };
    SurveyResource.prototype.exportCsv = function (id, query, filename) {
        if (filename === void 0) { filename = 'export.csv'; }
        api.get(this.buildUri(id, 'export-csv'), query, { responseType: 'blob' }).then(function (_a) {
            var data = _a.data;
            download(filename, data);
        });
    };
    // eslint-disable-next-line
    SurveyResource.prototype.buildCsvFilename = function (survey, customer) {
        var date = new Date(String(survey.createdAt)).toLocaleDateString('default').replace(/\//g, '-');
        return "Questionnaire - ".concat(customer.firstName || '', " ").concat((customer === null || customer === void 0 ? void 0 : customer.lastName) || '', " - ").concat(date, ".csv");
    };
    SurveyResource.prototype.linkSurveyToUser = function (idSurvey) {
        return api.post(this.buildUri("".concat(idSurvey, "/user"))).then(function (_a) {
            var data = _a.data;
            return data;
        });
    };
    return SurveyResource;
}(Resource));
export { SurveyResource };
export default new SurveyResource();
