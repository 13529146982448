import { useState } from 'react';
import { toast } from 'react-toastify';
import DocumentAPI from '../services/documentAPI';
import { downloadFile } from '../helpers/utils';
import errorMessages from '../lang/errorMessages.json';

const useDocumentActions = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const { downloadDocumentError, deleteDocumentError } = errorMessages;

  const handleError = (errorMessage: string, err: unknown) => {
    console.error(err);
    setError(errorMessage);
    toast.error(errorMessage);
  };

  const performAction = async (action: () => Promise<any>, successCallback: (response: any) => void, errorMessage: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await action();
      if (response) {
        successCallback(response);
      } else {
        handleError(errorMessage, new Error('No response from API'));
      }
    } catch (err) {
      handleError(errorMessage, err);
    } finally {
      setLoading(false);
    }
  };

  const downloadDocument = async (simulationId: string, idFile: number | null): Promise<void> => {
    await performAction(() => DocumentAPI.downloadDocument(simulationId, idFile), downloadFile, downloadDocumentError);
  };

  const deleteDocument = async (simulationId: string, idFile: number | null): Promise<void> => {
    await performAction(
      () => DocumentAPI.deleteDocument(simulationId, idFile),
      () => toast.success('Document supprimé avec succès'),
      deleteDocumentError
    );
  };

  return { downloadDocument, deleteDocument, loading, error };
};

export default useDocumentActions;
